import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ComplianceLineChartFrameworksScore from "./compliancelineChartFrameworksScore/compliancelineChartFrameworksScore";
import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import { ReactComponent as FrameworkIcon } from "../../../Images/general_icons/frameworks-icon.svg";
import { frameworksScore as frameWorksScoreIcon } from "../../../utils/icons";
import "./insihtsFrameworksScore.scss";
import UnavailableIconText from "../../../shared/unavailableIconText/unavailableIconText";

const InsihtsFrameworksScore = ({ loading }) => {
  const { t } = useTranslation("insights", {keyPrefix: "frameworkScore"});
  const insightsFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const framework = Array.isArray(insightsFilters?.frameworks)
    ? insightsFilters?.frameworks[0]
    : null;

  const frameworksScore = useSelector(
    (state) => state.insightsV2Reducer.frameworksScore
  );
  const { totalAssetBytype = 0, totalAssets = 0 } = frameworksScore;
  const IconComponent = frameWorksScoreIcon(framework, themeDark) || null;

  if (loading) {
    return (
      <div className="basic-card center">
        <SpinnerLoader/>
      </div>
    );
  }

  if (!totalAssets && framework && !totalAssetBytype) {
    return (
      <div className="basic-card row g15 InsihtsFrameworksScore__select center">
          <UnavailableIconText/>
      </div>
    );
  }

  if (!totalAssets && !framework) {
    return (
      <div className="basic-card row g15 InsihtsFrameworksScore__select">
        <div>
          <FrameworkIcon style={{ width: "25px", height: "25px" }} />
        </div>
        <div>
          <div className="font-12">{t("noAssetsSelcted")},</div>
          <div className="font-12">{t("noAssetsSelcted2")}.</div>
        </div>
      </div>
    );
  }

  return (
    <div className={`InsihtsFrameworksScore__selected ${themeDark ? '' : 'light'} basic-card`}>
      <div className="InsihtsFrameworksScore__selected__container">
        {IconComponent && <IconComponent />}
        <div>{framework}</div>
      </div>
      <ComplianceLineChartFrameworksScore
        affected={totalAssets}
        total={totalAssetBytype}
      />
      <div className="text font-12">{t("title")}</div>
    </div>
  );
};

export default InsihtsFrameworksScore;