import React from "react";
import { isDemoApplication } from "../../../utils/helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { FIREFLY_BOOK_DEMO_URL } from "../../../consts/links";
import { useTranslation } from "react-i18next";
import { FIREFLY_BASE_URL } from "../../../consts/links";


import "./demoAlert.scss";

const DemoAlert = () => {
  const {
    logout,
  } = useAuth0();
  const { t } = useTranslation("header", { keyPrefix: "demo" });
  const appUrl = FIREFLY_BASE_URL;

  const eye = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      version="1.1"
    >
      <defs>
        <path
          d="M0,15.089434 C0,16.3335929 5.13666091,24.1788679 14.9348958,24.1788679 C24.7325019,24.1788679 29.8697917,16.3335929 29.8697917,15.089434 C29.8697917,13.8456167 24.7325019,6 14.9348958,6 C5.13666091,6 0,13.8456167 0,15.089434 Z"
          id="outline"
        ></path>
        <mask id="mask">
          <rect width="100%" height="100%" fill="white"></rect>
        </mask>
      </defs>
      <g id="eye">
        <path
          d="M0,15.089434 C0,16.3335929 5.13666091,24.1788679 14.9348958,24.1788679 C24.7325019,24.1788679 29.8697917,16.3335929 29.8697917,15.089434 C29.8697917,13.8456167 24.7325019,6 14.9348958,6 C5.13666091,6 0,13.8456167 0,15.089434 Z M14.9348958,22.081464 C11.2690863,22.081464 8.29688487,18.9510766 8.29688487,15.089434 C8.29688487,11.2277914 11.2690863,8.09740397 14.9348958,8.09740397 C18.6007053,8.09740397 21.5725924,11.2277914 21.5725924,15.089434 C21.5725924,18.9510766 18.6007053,22.081464 14.9348958,22.081464 L14.9348958,22.081464 Z M18.2535869,15.089434 C18.2535869,17.0200844 16.7673289,18.5857907 14.9348958,18.5857907 C13.1018339,18.5857907 11.6162048,17.0200844 11.6162048,15.089434 C11.6162048,13.1587835 13.1018339,11.593419 14.9348958,11.593419 C15.9253152,11.593419 14.3271242,14.3639878 14.9348958,15.089434 C15.451486,15.7055336 18.2535869,14.2027016 18.2535869,15.089434 L18.2535869,15.089434 Z"
          fill="#ffffff"
        ></path>
      </g>
    </svg>
  );

  const handleSignUp = () => {
    logout({ returnTo: appUrl });
  }
  const handleBookDemo = () => {
    window.open(FIREFLY_BOOK_DEMO_URL, '_blank');
  }
  if (isDemoApplication()) {
    return <div className="DemoAlert row center">
    {eye}
    <div className="DemoAlert__text row">
      <span className="bold">{t("slogan")}</span>
      <span className="DemoAlert__text-seperator">|</span>
      {t("signUpDescription")}
    </div>
    <span className="DemoAlert__btn" onClick={handleSignUp}>{t("signUpButton")}</span>
    <span className="DemoAlert__btn" onClick={handleBookDemo}>{t("bookDemoButton")}</span>
  </div>
  }

  return null
};

export default DemoAlert;
