import isEmpty from "lodash/isEmpty";


export const checkCommandsListNotEmpty = (providerGroupsData) => {
    const providerGroupsDataKeys = Object.keys(providerGroupsData);
    let isCommandsListNotEmpty = false;
    if (!isEmpty(providerGroupsDataKeys)) {
      isCommandsListNotEmpty = !isEmpty(providerGroupsData[providerGroupsDataKeys[0]].commandsList);
    }
    return isCommandsListNotEmpty;
  }
  
  export const checkRemediationCommandsNotEmpty = (providersGroupData, classificationId) => {
    if(!isEmpty(providersGroupData[0]) && !isEmpty(providersGroupData[0].classificationsDetails) && !isEmpty(providersGroupData[0].classificationsDetails[classificationId]?.remediationCommands)){
      if(!isEmpty(providersGroupData[0]?.classificationsDetails[classificationId]?.remediationCommands[0])){
      return true
      }
    }
    return false;
  }
  
  export const getCommandGcp = (providersGroupData, classificationId) => {
    const commands = providersGroupData.map(provider => provider?.classificationsDetails[classificationId]?.remediationCommands || []).flat();
    return commands;
  
  }