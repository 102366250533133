import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import setIn from "lodash/set";
import { useDispatch } from "react-redux";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import { ReactComponent as CiPipelineIcon } from '../../../Images/general_icons/sidebar/ci-pipeline.svg';
import AppWizard from "../../../shared/appWizard/appWizard";
import WorkflowManagementFooter from "./workflowManagementFooter";
import GeneralWorkflowConfiguration from "./workflowsSteps/generalConfiguration";
import TerraformWorkflowConfiguration from "./workflowsSteps/tfConfiguration";
import AdvancedWorkflowConfiguration from "./workflowsSteps/advancedConfiguration";
import IacEngineSelection from "./workflowsSteps/iacEngineSelection";
import IacPipelineSelection from "./workflowsSteps/iacPiplineSelection";
import IntegrateStep from "./workflowsSteps/IntegrateStep";
import ReviewStep from "./workflowsSteps/reviewStep";

import { MANAGEMENT_NEW_PIPELINES, deleteMockArrays, turnArraysToObjects } from "./mangementHelper";
import SuccessBox from "../../../shared/successBox/successBox";
import { addNewWorkflow, editWorkflow, getWorkflowConfiguration, isWorkflowExists } from "../../../redux/actions/ciWorkflowsActions";
import { appToast } from "../../../shared/appToast/appToast";
import Loading from "../../../shared/loading/loading";

import { sendEvent } from "../../../utils/amplitude";
import { CiEvents } from "../../../utils/amplitudeEvents";
import { VCS_TYPES } from "../../../consts/general";

const NEW_ELEMENT = { name: "", value: "" };
const NEW_AWS_PROFILE_ELEMENT = { profileName: "", accessKeyId: "", secretAccessKey: "" };
const WIZARD_CREATE_MOCK = { runnerType: "github-actions", terraformVariables: [{...NEW_ELEMENT, key: uuidv4()}], terraformSensitiveVariables: [{...NEW_ELEMENT, key: uuidv4()}],
 providersCredentials: [{...NEW_ELEMENT, key: uuidv4()}], awsProfiles: [{...NEW_AWS_PROFILE_ELEMENT, key: uuidv4()}], runnerEnvironment: [{...NEW_ELEMENT, key: uuidv4()}] };

import { formatTitleByIacType } from "../../../utils/formatting";
import './workflowManagement.scss';

const WorkflowManagement = ({ isEditMode = false, modalVisible, setModalVisible, workflowName="workflow", workflowId }) => {
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });
    const history = useHistory();
    const [wizardState, setWizardState] = useState(isEditMode ? {} : WIZARD_CREATE_MOCK);
    const [currentStep, setCurrentStep] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(isEditMode ? false : true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [fetchEditLoading, setFetchEditLoading] = useState(isEditMode ? true : false);
    const [workflowUrl, setWorkflowUrl] = useState("");
    const [pipeline, setPipeline] = useState("");
    const [stepsUnvisible, setStepsUnvisible] = useState(isEditMode ? false : true);
    const [reviewFile, setReviewFile] = useState({});

    const dispatch = useDispatch();
    
    useEffect(() => {
        if (isEditMode) {
            fetchEditConfiguration();
        }
    }, [])

    const fetchEditConfiguration = async () => {
        const response = await dispatch(getWorkflowConfiguration(workflowId))
        if (!response) return setFetchEditLoading(false);
        setWizardState(response);
        return setFetchEditLoading(false);
    };

    const renderModalTitle = () => {
        return (
            <div className="row g10">
                <CiPipelineIcon className="WorkflowManagement__header-icon"/>
                <span className="bold">{t("title")}</span>
            </div>)
    }
    const isOnlyOneFile = useMemo(() => reviewFile?.workflowCode && !reviewFile?.templateCode, [reviewFile]);
    const isMultipleFiles = useMemo(() => reviewFile?.workflowCode && reviewFile?.templateCode, [reviewFile]);

    const initializeWizard = [
        {
            step_title: "",
            step_description: t("iacEngineSelection.subtitle"),
            content: <IacEngineSelection setIacEngine={(iacType) => setWizardState({...wizardState, iacType})} onItemClick={() => setCurrentStep(currentStep + 1)}/>,
            invisible: true,
        },
        {
            step_title: "",
            step_description: "",
            content: <IacPipelineSelection setPipeline={setPipeline} pipeline={pipeline} setSubmitDisabled={setSubmitDisabled} onItemClick={(clickedPipeline) => {
                setStepsUnvisible(clickedPipeline === MANAGEMENT_NEW_PIPELINES.existing ? true : false);
                setCurrentStep(currentStep + 1);
            }}/>,
            invisible: true,
        }
    ]
    
    const integratePipelineWizard = [
        {
            step_title: "",
            step_description: t("iacPipelineSelection.existing.title"),
            content: <IntegrateStep setSubmitDisabled={setSubmitDisabled} wizardState={wizardState} setWizardState={setWizardState} />,
        }
    ]
    const configWizard = [
        ...(!isEditMode ? initializeWizard : []),
        ...(isEditMode || pipeline === MANAGEMENT_NEW_PIPELINES.generate ? [
            {
            step_title: t("general.title"),
            step_description: t("general.description"),
            content: (<GeneralWorkflowConfiguration wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} isEditMode={isEditMode}/>),
            beforeNextStep: async () => {
                if (isEditMode) return;
                const { workspaceName, repo, runnerType = "" } = wizardState;
                setLoadingSubmit(true);
                const isExists = await dispatch(isWorkflowExists({ workspaceName, repo, runnerType }));
                if (isExists) {
                    const errorMessage = `Workflow for ${workspaceName} already exists in ${repo} `
                    appToast("error", "", errorMessage)
                    setLoadingSubmit(false);
                    throw new Error(errorMessage)
                }
                return setLoadingSubmit(false);
            },
            beforePreviousStep: async() => {
                if (isEditMode) return;
                setStepsUnvisible(true)
            }
        },
        {
            step_title: t("terraform.title", { iacType: formatTitleByIacType(wizardState?.iacType) || "" }),
            step_description: "",
            content: (<TerraformWorkflowConfiguration wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} isEditMode={isEditMode}/>),
        },
        {
            step_title: t("advanced.title"),
            step_description: "",
            content: (<AdvancedWorkflowConfiguration wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} isEditMode={isEditMode}/>),
            beforeNextStep: async () => {
                const cloneWizard = { ...wizardState };
                const wizardAfterDeleteArrays = deleteMockArrays(cloneWizard, ["terraformVariables", "terraformSensitiveVariables", "providersCredentials", "awsProfiles", "runnerEnvironment"]);
                const clearWizard = turnArraysToObjects(wizardAfterDeleteArrays, ["terraformVariables", "terraformSensitiveVariables", "providersCredentials", "runnerEnvironment"]);
                setLoadingSubmit(true);
                const response = isEditMode ? await dispatch(editWorkflow(workflowId, clearWizard, true)) : await dispatch(addNewWorkflow(clearWizard, true));
                const is403Response = response?.status === 403;
                if (!response || is403Response) {
                    const errorMessage = is403Response ? (wizardState?.vcsType === VCS_TYPES.github ? t("errors.github403") : t("errors.vcs403")) 
                    : isEditMode ? t("errors.editWorkflowError") : t("errors.createWorkflowError");
                    appToast("error", "", errorMessage)
                    setLoadingSubmit(false);
                    throw new Error(errorMessage)
                }
                setReviewFile(response);
                sendEvent(CiEvents.ciAdvancedConfigurationNextClick, { stageName: "advanced configuration", workspaceName: wizardState?.workspaceName, workspaceID: workflowId})
                return setLoadingSubmit(false);
            }
        },
        {
            step_title: t("review.title"),
            step_description: "",
            content: <ReviewStep reviewFile={reviewFile} setSubmitDisabled={setSubmitDisabled} title={t("review.title")}/>,
            nextButtonText: "Create PR",
            copyString: isOnlyOneFile ? reviewFile?.workflowCode : "",
            exportAll: isMultipleFiles ? reviewFile : null,
            beforeNextStep: async () => {
                const cloneWizard = { ...wizardState };
                const wizardAfterDeleteArrays = deleteMockArrays(cloneWizard, ["terraformVariables", "terraformSensitiveVariables", "providersCredentials", "awsProfiles", "runnerEnvironment"]);
                const clearWizard = turnArraysToObjects(wizardAfterDeleteArrays, ["terraformVariables", "terraformSensitiveVariables", "providersCredentials", "runnerEnvironment"]);
                setLoadingSubmit(true);
                const response = isEditMode ? await dispatch(editWorkflow(workflowId, clearWizard)) : await dispatch(addNewWorkflow(clearWizard));
                const is403Response = response?.status === 403;
                if (!response || is403Response) {
                    const errorMessage = is403Response ? (wizardState?.vcsType === VCS_TYPES.github ? t("errors.github403") : t("errors.vcs403")) 
                    : isEditMode ? t("errors.editWorkflowError") : t("errors.createWorkflowError");
                    appToast("error", "", errorMessage)
                    setLoadingSubmit(false);
                    throw new Error(errorMessage)
                }
                setWorkflowUrl(response?.pullRequestUrl);
                return setLoadingSubmit(false);
            }
        },
        {
            step_title: t("completion.title"),
            step_description: "",
            content: (
            <SuccessBox
                setSubmitDisabled={setSubmitDisabled}
                title={t("completion.successTitle")}
                customImage="workflow_success.svg"
                subTitle={
                <div className="col g20 center">
                    <div className="col g10">
                        <span>{isEditMode ? t("completion.editSuccessSubtitle") : t("completion.createSuccessSubtitle")}</span>
                        <span className="centered">
                            <a
                            href={workflowUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                                const eventPayload = isEditMode ? { workspaceID: workflowId } : { workflowName: wizardState?.workspaceName, repository: wizardState?.repo, runnerType: wizardState?.runnerType }
                                sendEvent(CiEvents.ciCompletePullRequestLinkClick, eventPayload)}
                            }>
                                {t("completion.prDescription")}
                            </a>
                        </span>
                    </div>
                    <div className="CiSteps__empty-divider"/>
                    <div className="col g10">
                            <span className="purple-text pointer bold" onClick={() => {
                            const initialData = {};
                            setIn(initialData, "scope.workspaces.include", [wizardState?.workspaceName]);
                            setIn(initialData, "scope.repositories.include", [wizardState?.repo]);
                            history.push({
                                pathname: "workflows/guardrails",
                                state: { openCreation: true, initialData }
                            });
                        }}>{t("completion.guardrailTitle")}</span>
                        <span className="form-label">{t("completion.guardrailTooltip")}</span>
                    </div>
                    <div className="CiSteps__empty-divider"/>
                    <div className="col g10">
                            <span className="purple-text pointer bold" onClick={() => {
                             const initialData = {eventType: "WorkflowRunLifecycle"};
                             setIn(initialData, "scope.workspaceNames", [wizardState?.workspaceName]);
                             setIn(initialData, "scope.workspaceRepositories", [wizardState?.repo]);
                             history.push({
                               pathname: "notifications",
                               state: { openCreation: true, initialData }
                             })
                        }}>{t("completion.addNotificationTitle")}</span>
                        <span className="form-label">{t("completion.addNotificationTooltip")}</span>
                    </div>
                </div>}
          />)
        }] : integratePipelineWizard)
    ];
    const renderModalSubTitle = () => {
        const subTitle = isEditMode ? `Edit ${workflowName} configuration` : (
            currentStep < 2 || (currentStep === 2 && pipeline === MANAGEMENT_NEW_PIPELINES.existing) ? configWizard[currentStep]?.step_description : t("addSubtitle"));
        return <span className="WorkflowManagement__subtitle">{subTitle}</span>
    }

    if (fetchEditLoading){
        return <div className="center"><Loading /></div>
    }

    return (
            <NewAppModal
                visible={modalVisible}
                handleClose={() => setModalVisible(false)}
                destroyOnClose
                centered
                width="85vw"
                bodyClassName={`WorkflowManagement__content ${stepsUnvisible ? "stepsUnvisible" : ""}`}
                customFooterClassName="WorkflowManagement__footer"
                title={renderModalTitle()}
                subtitle={renderModalSubTitle()}
                iconSrc={null}
                footer={
                    <WorkflowManagementFooter 
                        loadingSubmit={loadingSubmit} 
                        submitDisabled={submitDisabled} 
                        configWizard={configWizard} 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoadingSubmit={setLoadingSubmit}
                        setSubmitDisabled={setSubmitDisabled}
                        handleClose={() => setModalVisible(false)}
                        handleSubmit={() => setModalVisible(false)}
                        isEditMode={isEditMode}
                        stepsUnvisible={stepsUnvisible}
                        dontShowNextButton={ !isEditMode ? currentStep < 2 : false }
                    />
                }
                headerSuffix={null}
                >
                <div className="WorkflowManagement"> 
                <AppWizard
                    iconSrc={null}
                    loadingSubmit={loadingSubmit}
                    configWizard={configWizard}
                    handleClose={() => setModalVisible(false)}
                    submitDisabled={submitDisabled}
                    setSubmitDisabled={setSubmitDisabled}
                    handleSubmit={() => setModalVisible(false)}
                    customCurrent={currentStep}
                    stepsUnvisible={stepsUnvisible}
                />
                </div>
         </NewAppModal>

    )
};

export default WorkflowManagement;