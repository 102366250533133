import React from "react";
import moment from "moment";
import TableWrapper from "../../shared/tableWrapper/tableWrapper";
import { useTranslation } from "react-i18next";
import AssetTypeRowCell from "../Insights/insightsTable/assetTypeRowCell";
import { ReactComponent as AssetsRestoreIcon } from "../../Images/backup/icons/restore.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../utils/formatting";
import { HISTORY_STATUSES, HISTORY_STATUSES_TITLES, STATUS_ICONS } from "./backupHelper";

import "./backupDRTables.scss";

const DATE_FORMAT = "MMM DD YYYY, HH:mm";

const BackupHistoryTable = ({ historyData = [], loading = false }) => {
    const historyDataLength = historyData.length;
    const { t } = useTranslation("backupDR");

    const columns = [
      {
        headerName: t("columns.backupDate"),
        field: "date",
        flex: 1,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
            const { row } = params;
            const { date, period = '' } = row || {};
            const formattedDate = moment(date).format(DATE_FORMAT)
            return <span>{formattedDate} | {period}</span>
        }
      },
      {
          headerName: t("columns.backupName"),
          field: "name",
          flex: 1,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          valueGetter: ({ row }) => row?.name || ""
        },
        {
          headerName: t("columns.assetType"),
          field: "assetType",
          flex: 1,
          filterable: false,
          renderCell: (params) => {
            const { assetType = "" } = params?.row || {};
            return <AssetTypeRowCell value={[assetType]} gap="8" borderRadius="4px" />
          },
        },
        {
          headerName:  t("columns.matchingAssets"),
          field: "matchingAssets",
          width: 150,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { matchingAssets = 0 } = row || {};
            if (!matchingAssets) return;
            return (
                <span className="purple-flag row g5 pointer" onClick={() => null}>
                    <FontAwesomeIcon icon="layer-group" className="purple-text" />
                    <span>{numberWithCommas(matchingAssets)}</span>
                </span>
            )
          }
        },
        {
          headerName: t("columns.status"),
          field: "status",
          width: 130,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { status = "" } = row || {};
            const statusIcon = STATUS_ICONS[status];
            return (
            <div className="row g8">
              {statusIcon && <FontAwesomeIcon className={`BackupHistoryTable-status-icon ${status}`} icon={["fas", statusIcon]} spin={status === HISTORY_STATUSES.inProgress}/>}
              <span className="bold">{HISTORY_STATUSES_TITLES[status] || ""}</span>
            </div>)
          }
        },
        {
          headerName: t("columns.assetsRestore"),
          field: "assetsRestore",
          width: 130,
          align: "center",
          sortable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => <AssetsRestoreIcon />
        },
    ];
    return (
      <div>
          <TableWrapper
            autoHeight={!!historyDataLength}
            rowKey="id"
            tableData={historyData || []}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            pageSize={10}
            rowCount={historyDataLength}
            // handlePageChange={setPageNumber}
            // onPageSizeChange={setPageSize}
            onRowClick={(params) => null}
            // serverSide
           />
      </div>
   )
}

export default BackupHistoryTable;