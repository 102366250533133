import {CODIFY_TYPES, PROVIDERS} from "../../../consts/general";
import { appToast } from "../../../shared/appToast/appToast"
import { areAllRegionsSame } from "../../../utils/helpers";
import isEmpty from "lodash/isEmpty";
import { providers } from "../../../utils/providers";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import includes from "lodash/includes";

export const ACTIVE_CODIFICATION_TYPES = {
  naive: "naive",
  smart: "smart",
  modules: "modules",
  createModule: "createModule",
  createModuleDependencies: "createModuleDependencies",
  dependencies: "dependencies",
  fullDependencies: "fullDependencies",
  resourceGroup: "resourceGroup",
  awsMigration: "awsMigration",
  azureMigration: "azureMigration",
  googleMigration: "googleMigration",
};

export const CREATE_MODULE_CODIFICATION_MODES = [
    ACTIVE_CODIFICATION_TYPES.createModule,
    ACTIVE_CODIFICATION_TYPES.createModuleDependencies
]

export const MIGRATION_PROVIDERS_TYPES = {
  [ACTIVE_CODIFICATION_TYPES.awsMigration]: PROVIDERS.aws,
  [ACTIVE_CODIFICATION_TYPES.azureMigration]: PROVIDERS.azurerm,
  [ACTIVE_CODIFICATION_TYPES.googleMigration]: PROVIDERS.gcp,
}
export const PROVIDERS_MIGRATION_TYPES = {
  [PROVIDERS.aws]: ACTIVE_CODIFICATION_TYPES.awsMigration,
  [PROVIDERS.azurerm]: ACTIVE_CODIFICATION_TYPES.azureMigration,
  [PROVIDERS.gcp]: ACTIVE_CODIFICATION_TYPES.googleMigration,
}

export const DEFAULT_CLOUD_MIGRATION_CODIFICATION_TYPE = {
  [PROVIDERS.aws]: ACTIVE_CODIFICATION_TYPES.azureMigration,
  [PROVIDERS.azurerm]: ACTIVE_CODIFICATION_TYPES.awsMigration,
  [PROVIDERS.gcp]: ACTIVE_CODIFICATION_TYPES.awsMigration,
};

export const CODIFY_LANGUAGES = ["typescript", "python", "go", "csharp", "java"];
export const LANGS_SUFFIX = {
    typescript: "ts",
    python: "py",
    go: "go",
    csharp: "cs",
    java: "java"
}
export const CODIFICATION_MODES = {
  naive: "NAIVE",
  smart: "DATA",
  dependencies: "BY_STATE",
  fullDependencies: "FULL",
  resourceGroup: "RESOURCE_GROUP",
  modules: "MODULE",
};

const K8S = 'k8s'
const REDACT_WARNING = 'This code configuration contains sensitive data properties that should be added separately.'

export const alertCodifyRedact = (customToast) => customToast || appToast("info", "Info", REDACT_WARNING);
export const alertIfCodifyRedact = ({ activeCodificationType, activeTab, mapData = {}, createModulesRedactedFiles, moduleFileSelected, customToast, isK8sRedacted = false, isK8sSelectedProvider = false }) => {
  let isRedacted = false;
  if (includes(CREATE_MODULE_CODIFICATION_MODES, activeCodificationType)) {
    isRedacted = createModulesRedactedFiles[moduleFileSelected];
  } else if (activeCodificationType !== ACTIVE_CODIFICATION_TYPES.modules) { // no need to check modules tab (it's not redacted)
    switch (activeTab) {
      case CODIFY_TYPES.terraform:
        isRedacted = isK8sSelectedProvider ? isK8sRedacted  : mapData[CODIFY_TYPES.terraform][0]?.isRedactedValue
        break;
      case CODIFY_TYPES.tfcdk:
        isRedacted = mapData[CODIFY_TYPES.tfcdk][0]?.isRedactedValue
        break;
      case K8S:
        isRedacted = isK8sRedacted;
        break;
      default:
        isRedacted = mapData[activeTab][0]?.isRedactedValue;
        break;
    }
  }
  return isRedacted ? alertCodifyRedact(customToast) : null;
}

export const handleCallbackByActiveTab = (key = '', setActiveCodificationType = () => null, fetchIacImport = () => null, activeLanguage, fetchNewCodify = () => null, selectedResources = []) => {
  switch (key) {
    case CODIFY_TYPES.terraform:
      setActiveCodificationType(ACTIVE_CODIFICATION_TYPES.naive);
      fetchIacImport(key);
      break;
    case CODIFY_TYPES.tfcdk:
      setActiveCodificationType(ACTIVE_CODIFICATION_TYPES.naive);
      fetchIacImport(key, activeLanguage);
      break;
    case CODIFY_TYPES.cloudformation:
      const isSameRegions = areAllRegionsSame(selectedResources);
      if (isSameRegions) {
        fetchNewCodify(key);
      }
      break;
    case CODIFY_TYPES.pulumi:
    case CODIFY_TYPES.cdk:
    case CODIFY_TYPES.cdkl2:
    case CODIFY_TYPES.cdk8s:
      fetchNewCodify(key, activeLanguage);
      break;
    case CODIFY_TYPES.crossplane:
    case CODIFY_TYPES.ansible:
    case CODIFY_TYPES.configConnector:
    case CODIFY_TYPES.googleCloudSdk:
    case CODIFY_TYPES.arm:
    case CODIFY_TYPES.bicep:
    case CODIFY_TYPES.helm:
      fetchNewCodify(key);
      break;
    case K8S:
      fetchIacImport(key);
      break;
    default:
      break;
  }
}

export const onCodifyDrawerVisible = (selectedResources = [], setActiveCodificationType = () => null, setActiveTab = () => null, fetchIacImport = () => null, fetchCloudMigration = () => null) => {
  const isCloudMigration = selectedResources?.[0]?.isCloudMigration;
  if (isCloudMigration) {
    const selectedProvider = selectedResources?.[0]?.provider;
    setActiveTab(CODIFY_TYPES.terraform);
    setActiveCodificationType(DEFAULT_CLOUD_MIGRATION_CODIFICATION_TYPE[selectedProvider]);
    fetchCloudMigration(MIGRATION_PROVIDERS_TYPES[DEFAULT_CLOUD_MIGRATION_CODIFICATION_TYPE[selectedProvider]]);
  } else if (!isEmpty(selectedResources) && providers[selectedResources[0]?.provider]?.codifyDefault === CODIFY_TYPES.terraform) {
    setActiveCodificationType(ACTIVE_CODIFICATION_TYPES.naive);
    setActiveTab(CODIFY_TYPES.terraform);
    fetchIacImport(CODIFY_TYPES.terraform);
  } else {
    setActiveTab(K8S);
    fetchIacImport(K8S);
  }
  // send codify events
  if (!isEmpty(selectedResources)) {
    sendEvent(inventoryEvents.codifyDrawerOpen,
      {
        assetType: selectedResources[0]?.assetType,
        assetName: selectedResources[0]?.name,
        assetNumber: selectedResources?.length,
        assetStatus: selectedResources[0]?.state
      });
  }
}