import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import isEmpty from 'lodash/isEmpty';
import { Checkbox, Form, Input, Select } from "antd";
import Loading from "../../../../shared/loading/loading";
import { useDispatch, useSelector } from "react-redux";
import { getAllProvidersArray } from "../../../../utils/formatting";
import { VCS_TYPES } from "../../../../consts/general";
import { getAllVcs } from "../../../../redux/actions/globalAppActions";
import { clouds } from "../../../../utils/icons";
import SearchInput from "../../../inventory/pullRequestForm/searchInput";
import { getReposByVcsSelection, getVcsBranches } from "../../../../redux/actions/vcsActions";
import { createKeyPair } from "../../../../redux/actions/keyPairsActions";
import { nameRegex, validate } from "../../../../utils/validations";

import ProvidersList from "../../../composing/providersList/providersList";
import { CI_TOOLS_OPTIONS } from "./ci-tools-options";

import { CREATE_KEY_ALIAS } from "../mangementHelper";
import AuthenticationSection from "./authenticateSection";
import './workflowsSteps.scss';

const GeneralWorkflowConfiguration = ({ wizardState = {}, setWizardState, setSubmitDisabled, isEditMode = false }) => {
    const { user } = useAuth0();
    const [form] = Form.useForm();
    const history = useHistory();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });

    const [loadingGithub, setLoadingGithub] = useState(false);
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [loadingSearchRepos, setLoadingSearchRepos] = useState(false);
    const [selectedVcsType, setSelectedVcsType] = useState("");
    const [publicRepo, setPublicRepo] = useState(false);
    const [availableBranches, setAvailableBranches] = useState([]);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [keyPair, setKeyPair] = useState(null);
    const [keyPairLoading, setKeyPairLoading] = useState(false);

    const vcsIntegs = useSelector((state) => state.globalAppReducer.vcs);
    const availableRepos = useSelector((state) => state.vcsReducer.reposByVcs);
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    
    const isCodeCommitSelected = selectedVcsType === VCS_TYPES.codecommit;
    const showPrivatePublicCheckbox = selectedVcsType && !isCodeCommitSelected && !loadingGithub;

    const dispatch = useDispatch();
    const allVcsApps = useCallback(getAllProvidersArray(vcsIntegs), [vcsIntegs]);

    useEffect(()=> {
        fetchVcs();
        if (isEditMode) {
          const { repo, defaultBranch } = wizardState;
          getRepoOptions(repo);
          onChangeRepo(repo, defaultBranch)
        } 
    }, [])

    useEffect(() => {
        getRepoOptions()
    }, [publicRepo])

    useEffect(() => {
      checkValidation();
    }, [wizardState]);

    const fetchVcs = async () => {
        setLoadingGithub(true);
        await dispatch(getAllVcs());
        setLoadingGithub(false);
    };

    const checkValidation = () => {
        const { workspaceName, vcsId, repo, defaultBranch, runnerType } = wizardState;
        const formValid = workspaceName && validate(nameRegex.regex, workspaceName) && vcsId && repo && defaultBranch && runnerType;
        setSubmitDisabled(!formValid);
    }

    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const onChangeVcs = (vcsId) => {
        form.resetFields(["repo", "defaultBranch"]);
        getRepoOptions();
        onValuesChange({ vcsId, repo: "", defaultBranch: ""});
    }

    const renderVcsOptions = () => {
        return (allVcsApps || []).map((app) => (
          <Select.Option value={app?.id} key={app?.id}>
            <span className="PullRequestForm__content-body-vcs row">
              <img src={clouds(app.type, themeDark)} alt="integration" />
              {app.name}
            </span>
          </Select.Option>
        ));
      };
      
    const getVcsTypeById = (vcsId) => {
        const matchVcs = allVcsApps.find((vcsApp = {}) => vcsApp.id?.includes(vcsId)) || {}; 
        const name = matchVcs.name || VCS_TYPES.github;
        const type = matchVcs.type || VCS_TYPES.github;
        return { name, type }
    };
    
    const getRepoOptions = async(repoName = "") => {
        const vcsId = form.getFieldValue("vcsId");
        if (vcsId) {
          setLoadingSearchRepos(true);
          const { name } = getVcsTypeById(vcsId);
          await dispatch(getReposByVcsSelection(vcsId, name, repoName, publicRepo))
          setSelectedVcsType(name);
        }
        return setLoadingSearchRepos(false);
    }

    const onChangeRepo = async (repo, branchFromWizard) => {
        setLoadingBranches(true);
        form.resetFields(["defaultBranch"]);
        const vcsId = form.getFieldValue("vcsId");
        const { name, type: vcsType } = getVcsTypeById(vcsId);
        const req = await dispatch(getVcsBranches(name, vcsId, repo));

        const repoDefaultBranch = (availableRepos || []).find((item) => item?.fullName === repo)?.defaultBranch;
        const newBranched = (req || []).map((item) => ({ ...item, default: item?.name === repoDefaultBranch }));
        if (branchFromWizard) {
          form.setFieldsValue({ defaultBranch: branchFromWizard });
          onValuesChange({ defaultBranch: branchFromWizard});
          setAvailableBranches(newBranched);
          return setLoadingBranches(false);
        }
        // set the defualt branch in the form as selected
        const defaultBranch = newBranched.find((item) => item?.default);
        if (defaultBranch) {
          form.setFieldsValue({ defaultBranch: defaultBranch?.name });
        }
        onValuesChange({ repo, defaultBranch: defaultBranch?.name, vcsType });
        setAvailableBranches(newBranched);
        return setLoadingBranches(false);
    };
    
    const renderSelectByVcsSelection = () => {
          return (
              <SearchInput
                options={availableRepos}
                setFormValid={setSubmitDisabled}
                getOptions={(repoName) => getRepoOptions(repoName)}
                loading={loadingRepos || loadingSearchRepos}
                disabled={!wizardState.vcsId || isEditMode}
                initialValue={wizardState.repo || ""}
                setRepositoryValue={(val) => {
                  form.setFieldsValue({ repo: val });
                  onChangeRepo(val);
                }}
              />
          );
    };

    const renderBranchOptions = () => {
        return (availableBranches || []).map((branch) => (
          <Select.Option value={branch?.name} key={branch?.name}>
            {`${branch?.name} ${branch?.default ? " (Default Branch)" : ""}`}
          </Select.Option>
        ));
    };
    const handleCreateKeyPair = async () => {
      setKeyPairLoading(true);
      const res = await dispatch(createKeyPair(user?.name, CREATE_KEY_ALIAS));
      if (isEmpty(res)) {
      return setKeyPairLoading(false);
      }
      setKeyPair(res);
      setKeyPairLoading(false);
      setCreateModalOpen(true);
  }
  const handleCloseCreateKeyPair = () => {
      setCreateModalOpen(false);
      setKeyPair(null);
  };

    return (
        <div className="WorkflowsSteps col">
           <span className="bold wb-text">{t("general.title")}</span>
           <span className="form-label">{t("descriptions.general")}</span>
           <div className="col g10">
           <Form
                name="general-form"
                form={form}
                initialValues={{
                  workspaceName: wizardState.workspaceName,
                  vcsId: wizardState.vcsId,
                  repo: wizardState.repo,
                  defaultBranch: wizardState.defaultBranch,
                  runnerType: wizardState.runnerType,
                  workDir: wizardState.workDir,
                }}
          >
          {loadingGithub && (
            <div className="PullRequestForm__content-body tab-page center">
              <Loading />
            </div>
          )}

          {!loadingGithub && (
              <div className="col">
                  <Form.Item label="CI Tool" name="runnerType" style={{flexDirection: "column"}}
                             tooltip={t("tooltips.ciTool")}>
                      <ProvidersList providers={CI_TOOLS_OPTIONS} selected={wizardState.runnerType}
                                     onClick={(item) => onValuesChange({runnerType: item.name})}/>
                    <AuthenticationSection ciTool={wizardState.runnerType} t={t} 
                        handleCloseCreateKeyPair={handleCloseCreateKeyPair} keyPair={keyPair} 
                        createModalOpen={createModalOpen} keyPairLoading={keyPairLoading}
                        handleCreateKeyPair={handleCreateKeyPair}/>
                  </Form.Item>
                  <Form.Item
                      className="WorkflowsSteps__general-workspace"
                      label="Workspace Name"
                      name="workspaceName"
                      style={{flexDirection: "column"}}
                      rules={[
                          {
                              required: true,
                              pattern: nameRegex.regex,
                              message: nameRegex.msg,
                          },
                      ]}
                  >
                      <span className="form-label">{t("tooltips.workspaceName")}</span>
                      <Input placeholder="Enter Workspace name" value={wizardState.workspaceName || ""}
                             onChange={(e) => onValuesChange({workspaceName: e.target.value})} disabled={isEditMode}/>
                  </Form.Item>
                  <Form.Item
                      label="VCS Integration"
                      name="vcsId"
                      tooltip={t("tooltips.vcs")}
                      help={
                          !loadingGithub && isEmpty(allVcsApps) ? (
                              <div className="no-vcs-integs col">
                                  <a onClick={() => history.push({
                                      pathname: "/integrations/new-integration",
                                      state: {scrollTo: "vcs"},
                                  })}>
                                      + Add Version Control Integration
                                  </a>
                              </div>
                          ) : null
                      }
                      rules={[
                          {
                              required: true,
                              message: "Integration is required",
                          },
                      ]}
                      style={{flexDirection: "column"}}
                  >
                      <Select
                          placeholder="Select VCS Integration"
                          showSearch
                          filterOption={(input, option) =>
                              option?.children?.props?.children[1]
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                          }
                          disabled={loadingRepos || loadingSearchRepos || isEditMode}
                          style={{width: "100%"}}
                          onChange={onChangeVcs}
                      >
                          {renderVcsOptions()}
                      </Select>
                  </Form.Item>

                  {!loadingGithub ? (
                      <Form.Item
                          name="repo"
                          label="Code Repository"
                          tooltip={t("tooltips.repository")}
                          rules={[
                              {
                                  required: true,
                                  message: "Repository selection is a must",
                              },
                          ]}
                          style={{
                              flexDirection: "column",
                              marginBottom: showPrivatePublicCheckbox ? "10px" : "24px",
                          }}
                      >
                          {renderSelectByVcsSelection()}
                      </Form.Item>
                  ) : null
                  }

                  {showPrivatePublicCheckbox && (
                      <div className="col" style={{marginBottom: "20px"}}>
                          <Checkbox
                              checked={publicRepo}
                              onChange={() => setPublicRepo(!publicRepo)}
                              disabled={loadingRepos}
                          >
                    <span className="form-label">
                      Show only private repositories
                    </span>
                          </Checkbox>
                      </div>
                  )}

                  {
                      !loadingGithub && (
                          <Form.Item
                              label="Default Branch"
                              name="defaultBranch"
                              tooltip={t("tooltips.branch")}
                              style={{
                                  flexDirection: "column",
                              }}
                          >
                              <Select
                                  placeholder="Select Default Branch"
                                  showSearch
                                  filterOption={(input, option) =>
                                      option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                  }
                                  disabled={!wizardState.repo || loadingBranches}
                                  style={{width: "100%"}}
                                  loading={loadingBranches || loadingRepos}
                                  onChange={(val) => onValuesChange({defaultBranch: val})}
                              >
                                  {renderBranchOptions()}
                              </Select>
                          </Form.Item>
                      )}
                  {!loadingGithub && (
                      <Form.Item
                          label="Working Directory (optional)"
                          name="workDir"
                          tooltip={t("tooltips.workingDirectory")}
                          style={{
                              flexDirection: "column",
                          }}
                      >
                          <Input placeholder="Enter Working Directory" value={wizardState.workDir || ""}
                                 onChange={(e) => onValuesChange({workDir: e.target.value})}/>
                      </Form.Item>
                  )}
              </div>
          )}
           </Form>
           </div>
        </div>
    );
};

export default GeneralWorkflowConfiguration;