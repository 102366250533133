import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { deleteExclusion } from "../../redux/actions/driftsExclusionsActions";
import Loading from "../../shared/loading/loading";
import SearchBox from "../../shared/searchBox/searchBox";
import ConfirmationModal from "../../shared/confirmationModal/confirmationModal";
import { getExclusions } from "../../redux/actions/driftsExclusionsActions";
import SingleExcluded from "../../components/exclusions/singleExcluded/singleExcluded";
import { formatAwsStringWithUnderscore, getAllProvidersArray } from "../../utils/formatting";

import "./excludedDrifts.scss";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import { useTranslation } from "react-i18next";
import { getIntegrationsId } from "../../utils/helpers";

const ExcludedDrifts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('excluded-drifts')
  const drifts = useSelector((state) => state.driftsExclusionsReducer.rules);
  const providerIntegrations = useSelector((state) => state.globalAppReducer.clouds);

  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedDrift, setSelectedDrift] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);

  useEffect(() => {
    getDrifts();
  }, []);

  const getDrifts = async () => {
    setLoading(true);
    await Promise.all([
      dispatch(getExclusions()),
      dispatch(getAllClouds()),
    ]);
    setLoading(false);
  };

  // filter the drifts based the search
  const filteredDrifts = useMemo(() => {
    if (!_.isEmpty(drifts) && !loading) {
      let filteredDrifts = drifts
        .sort(function (a, b) {
          return a.type.localeCompare(b.type) && a.isDefault - b.isDefault;
        })
        .reverse();
      if (searchVal) {
        filteredDrifts = _.filter(filteredDrifts, (item) =>
          (item.type || "")
            .replace("_", " ")
            .toLowerCase()
            .includes(searchVal.toLowerCase())
        );
      }

      return filteredDrifts;
    }
  }, [searchVal, drifts, loading]);

  const handleValidateDelete = (drift, checked) => {
    drift.isEnabled = checked;
    setSelectedDrift(drift);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmation = () => {
    setSelectedDrift(null);
    setConfirmDeleteOpen(false);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteInProgress(true);
    const allProviders = getAllProvidersArray(providerIntegrations);

    const payload = {
      isEnabled: selectedDrift?.isEnabled,
      integrations: getIntegrationsId(selectedDrift?.filters?.integrations, allProviders),
      type: selectedDrift?.type,
    }

    await dispatch(deleteExclusion(selectedDrift?.id, payload));
    setDeleteInProgress(false);
    await getDrifts();
    handleCloseConfirmation();
  };

  const renderDrifts = () => {
    if (!_.isEmpty(filteredDrifts)) {
      return _.map(filteredDrifts, (drift) => {
        return (
          <SingleExcluded
            key={drift.id}
            id={drift.id}
            isEnabled={drift.isEnabled}
            isDefault={drift.isDefault}
            assetType={drift?.type}
            properties={drift.properties}
            onClickedDelete={(checked) => handleValidateDelete(drift, checked)}
            type="drift"
            providers={drift?.filters?.integrations}
          />
        );
      });
    }
  };

  if (loading) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="ExcludedDrifts col">
      <div className="ExcludedDrifts__wrapper basic-card col">
        <div className="ExcludedDrifts__wrapper-header row bewteen">
          <SearchBox
            placeholder={t('search-placeholder')}
            value={(val) => setSearchVal(val)}
            resetSearch={(val) => setSearchVal(val)}
            width={400}
          />
        </div>
        {renderDrifts()}
      </div>

      <ConfirmationModal
        visible={confirmDeleteOpen}
        handleClose={handleCloseConfirmation}
        title={
          !_.isEmpty(selectedDrift)
            ? t('delete-modal.title', {
              driftType: formatAwsStringWithUnderscore(
                selectedDrift?.type
              )
            })
            : null
        }
        description={t('delete-modal.description')}
        loadingConfirm={deleteInProgress}
        onConfirm={handleDeleteConfirmed}
      />
    </div>
  );
};

export default ExcludedDrifts;
