import React from "react";
import { Pie } from "@ant-design/plots";
import { useSelector } from "react-redux";
import round from "lodash/round";

import "./pieChart.scss";
const COLOROS = {
  Unmanaged: "#D76089",
  Codified: "#67CEBC",
  Ghost: "#F4B483",
  Drifted: "#9872FE",
};
const PieChart = ({ data, totalAssetsCount, datesData, isZeroPercentage }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const unmangedVal = data?.find((item) => item.type === "Unmanaged")?.value;
  const unmanagedPercent = unmangedVal ? round((unmangedVal / totalAssetsCount) * 100, 2).toFixed(2) : 0;

  const config = {
    appendPadding: 0,
    data,
    angleField: "value",
    pieStyle: {
      stroke: themeDark ? "#212133" : "white",
      lineWidth: isZeroPercentage ? 2 : 4,
      cursor: "pointer",
    },
    radius: 1,
    innerRadius: 0.8,
    tooltip: false,
    label: {
      type: "spider",
      offset: "-50%",
      style: {
        textAlign: "center",
        fill: "rgba(255, 255, 255, 0)",
      },
      position: "bottom",
      autoRotate: false,
      autoHide: true,
    },
    legend: false,
    colorField: "type", // or seriesField in some cases
    color: ({ type }) => {
      const typeColor = COLOROS[type] || "#C7C7C7";
      return typeColor;
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '10px 0'
        },
        customHtml: () => {
          return <div
          id="pieInfo"
          style={{
            fontSize: 18,
            display: 'flex',
            flexDirection: "column",
            gap: '5px',
            fontWeight: 300,
            color: COLOROS.Unmanaged,
          }}
        >
          <span id="pieInfoPercent">{unmanagedPercent}%</span>
          <span id="pieInfoDesc">Unmanaged</span>
        </div>
        },
      },
    },
  };
  const handleEvents = (plot) => {
    plot.on("element:mouseenter", (event) => {
      const {value = 0, type = ""} = event?.data?.data || {}
      const element = document.getElementById("pieInfoPercent");
      element.textContent =  round((value / totalAssetsCount) * 100, 2).toFixed(2) + "%"
      document.getElementById("pieInfo").style.color = COLOROS[type] || "#ccc";
      document.getElementById("pieInfoDesc").textContent = type;
    });
    plot.on("element:mouseleave", () => {
      const element = document.getElementById("pieInfoPercent");
      element.textContent = unmanagedPercent + "%";
      document.getElementById("pieInfo").style.color = COLOROS.Unmanaged;
      document.getElementById("pieInfoDesc").textContent = "Unmanaged";
    });
  }
  return (
    <Pie
      className="PieChart"
      {...config}
      onReady={handleEvents}
    />
  );
};

export default PieChart;
