import React from "react";
import { MANAGEMENT_IAC_ENGINES_OPTIONS } from "../mangementHelper";
import { useTranslation } from "react-i18next";

import "./workflowsSteps.scss";

const IacEngineSelection = ({ setIacEngine, onItemClick }) => {
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });
    const handleItemClick = (key) => {
        setIacEngine(key);
        onItemClick();
    }

    return (
        <div className="WorkflowsSteps__IacEngineSelection col center">
            <span className="WorkflowsSteps__IacEngineSelection-title">{t("iacEngineSelection.subtitle")}</span>
            <div className="WorkflowsSteps__IacEngineSelection__list">
                {MANAGEMENT_IAC_ENGINES_OPTIONS.map((item = "") => {
                    const disabled = item.comingSoon;
                    return <div key={item.key} className={disabled ? "" : "WorkflowsSteps__IacEngineSelection__list-gradient"} onClick={() => !disabled && handleItemClick(item.key)}>
                            <div className={`basic-card WorkflowsSteps__IacEngineSelection__list-item${disabled ? '-disabled': ''} col center `}>
                               {item.comingSoon ? <span className="WorkflowsSteps__IacEngineSelection__list-item-soon">COMING SOON</span> : null}
                                <img className="WorkflowsSteps__IacEngineSelection__list-item-icon" src={item.icon}/>
                                <div className="WorkflowsSteps__IacEngineSelection__list-item-title">{item.title}</div>
                            </div>
                        </div>
                })}
            </div>
        </div>
    )
};

export default IacEngineSelection;