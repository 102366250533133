import React, { memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelRadioFilter from "../../../inventory/inventoryFilters/inventorySingelRadioFilter";
import { sendEvent } from "../../../../utils/amplitude";
import { governanceEvents } from "../../../../utils/amplitudeEvents";
import { frameworks as frameWorksIcon } from "../../../../utils/icons";

const FrameworksFilterV2 = () => {
  const dispatch = useDispatch();

  const frameworksAggs = useSelector((state) => state.insightsV2Reducer.aggs?.frameworks);
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const handleSetSelectedFramework = useMemo(() => (selectedFramework) => {
    const payload = { ...insightFilters, frameworks: selectedFramework };
    sendEvent(governanceEvents.frameworks, {
      selectedFilter: selectedFramework,
    });
    return dispatch(setInsightsFilters(payload));
  }, [insightFilters]);

  const frameworks = (frameworksAggs || []).map((framework) => {
    const IconComponent = frameWorksIcon(framework?.value, themeDark) || null;
    return {
      name: framework?.value,
      formattedName: framework?.value,
      count: framework?.count,
      element: IconComponent && <IconComponent />,
    };
  });

  return (
    <InventorySingelRadioFilter
      allTypes={frameworks}
      selectedTypes={handleSetSelectedFramework}
      checkedList={insightFilters?.frameworks || []}
    />
  );
};

export default memo(FrameworksFilterV2);