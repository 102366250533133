import React from "react";
import "./compliancelineChartFrameworksScore.scss";
import { useSelector } from "react-redux";
import Tooltip from "antd/lib/tooltip";
import { getLabel, getPercentage, getSize, getTheme, getTotalHover, checkIfTotalGreaterThanAffected } from "./compliancelineChartFrameworksScore.utils";
import { COMPLIANCE_LINE_CHART } from "../../../../consts/insights";
import SpinnerLoader from "../../../../shared/spinnerLoader/spinnerLoader";

const ComplianceLineChartFrameworksScore = ({
  affected = 0,
  total = 0,
}) => {

  const isThemeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  
  const tooltipTitle = COMPLIANCE_LINE_CHART.tooltipTitle

  const theme = getTheme(isThemeDark);
  const isTotalGreaterThanAffected = checkIfTotalGreaterThanAffected(total, affected);
  const supplementaryNumber =isTotalGreaterThanAffected ?  total - affected : affected;
  const precntege = getPercentage(supplementaryNumber, total);
  const total_hover = getTotalHover(affected, total);
  const label = getLabel(precntege);
  const size = getSize(label);

  if(affected === 0 && total === 0){
    return null;
  }

  return (
    <>
      {(isTotalGreaterThanAffected && label) ?
        <div className="ComplianceLineChartFrameworksScore">
          <div className={`ComplianceLineChartFrameworksScore__progress-container ${theme}`}>
            <div className={`ComplianceLineChartFrameworksScore__progress-bar ${size}`} style={{ width: label }}>
            </div>
          </div>
          <span className="ComplianceLineChartFrameworksScore__progress-label">{label}</span>
        </div>
        :
        null
      }
    </>
  );
};

export default ComplianceLineChartFrameworksScore;