import React from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import "./pageName.scss";
import { PROVIDERS } from "../../../consts/general";
import { formatPageNameTitle } from "../../../utils/formatting";

const PageName = () => {
  const location = useLocation();
  const { t } = useTranslation("header");

  const renderPageName = () => {
    let urlArr = location.pathname.split("/");

    // if second item in array dont have length - its the home page
    if (_.isEmpty(urlArr) || (urlArr && urlArr[1] === "")) return t('titles.base');

    // special screens without hyphen
    switch (urlArr[urlArr.length - 1]) {

      case "create-iac-ignored-rules":
        if (!_.isEmpty(location?.state?.policy)) {
          return t('titles.edit-iac-ignored-rules');
        }
        return t('titles.create-iac-ignored-rules');

      case "gcs":
        return t('titles.gcs-integration');

      case "iac":
        return t('titles.iac');

      case "groups":
        return t('titles.stack-group');

      case "helm":
        return t('titles.helm');

      case "create-custom-control":
        if (!_.isEmpty(location?.state?.data)) {
          return t('titles.edit-insight');
        }
        return t('titles.create-insight');

      case "aws":
        return t('titles.aws');

      case "k8s":
        return t('titles.k8s');

      case "consul":
        return t('titles.consul');

      case "githubvcs":
        return t('titles.github');

      case "gitlab":
        return t('titles.gitlab');

      case "tfc":
        return t('titles.terraform');

      case "cftStacks":
        return t('titles.cft-stacks');

      case "cloud-formation":
        return t('titles.cloud-formation');

      case "github":
        return t('titles.github-provider');

      case "newrelic":
        return t('titles.newrelic');

      case "gcp":
        return t('titles.gcp');
      case "gcp-discovery":
        return t('titles.gcp-discovery');
        
      case "azure-discovery":
        return t('titles.discovery-status');

      case "pagerduty":
        return t('titles.pagerduty');
      
      case "codecommit":
        return t('titles.codecommit')
      
      case "azuredevops":
        return t('titles.azuredevops')
      
      case "ns1":
        return t('titles.ns1')
      case "workflows":
        return t('titles.workflows');
      case "mongodbatlas":
          return t('titles.mongodbatlas')
      case "azurerm":
          return t('titles.azure')
      case PROVIDERS.env0:
      case `${PROVIDERS.env0}-integration`:
        return t('titles.env0');
      case "backup": 
        return t('titles.backup');
      default:
        break;
    }

    // Replace hyphen with space and Capitalize each word
    return formatPageNameTitle(urlArr);
  };

  return <div className="PageName title">{renderPageName()}</div>;
};
export default PageName;
