import React, { useMemo } from "react";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import { Popover } from "antd";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import { v4 as uuidv4 } from "uuid";

import "../governanceDrawer/governanceDrawer.scss";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";

const ICONS_DISPLAY_LIMIT = 5;
const AssetTypeRowCellV2 = ({ value = [] }) => {

  const getPopoverContent = (type, key, data) => {
    return <ul className="InsightDetails__body__popover-content">
      {data?.map((item = {}) => <li key={uuidv4()}>
        {type === 'provider' ? <ProviderIcon provider={key} customStyle="integration" /> : <AssetTypeImage assetType={item} />}
        {type === 'provider' ? item?.name : formatAwsStringWithUnderscore(item)}
      </li>)}
    </ul>
  }

  const arrLength = useMemo(() => value.length || 0, [value]);
  const moreItems = useMemo(() => arrLength > ICONS_DISPLAY_LIMIT ? arrLength - ICONS_DISPLAY_LIMIT + 1 : null, [arrLength]);
  const popoverContent = useMemo(() => getPopoverContent('assetTypes', null, value), [value]);

  return (
    <Popover content={popoverContent} placement="bottomLeft">
      <div className="InsightDetails__body__multi-image">
        {value.map((type, index) => (!moreItems || index < ICONS_DISPLAY_LIMIT - 1) && <AssetTypeImage assetType={type} key={type} />)}
        {moreItems && <div className={`InsightDetails__body__multi-image-purpleCount ${moreItems > 9 ? 'wider': ''} row`}>
        <span className="InsightDetails__body__multi-image-purpleCount-text">+{moreItems}</span></div>}
      </div>
    </Popover>
  )
};

export default AssetTypeRowCellV2;