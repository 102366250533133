
export const BACKEND_URL = "https://prodapi.gofirefly.io/api";
export const K8S_API = "https://k8s-api.prod.external.api.infralight.cloud"
export const AUTH0_NS = "https://infralight.co"
export const AUTH0_DOMAIN = "auth.firefly.ai"
export const AUTH0_CLIENT_ID = "k7Ex3nkAFr1GsffQCdTlD9sapCrod0v1"
export const AUTH0_AUDIENCE = "infralight-api"
export const CLI_LINK_PREFIX = "app"
export const INTERCOM_ID = "s74z1ux7"
export const S3_ASSETS = "https://firefly-app-assets.s3.amazonaws.com"
export const AMPLITUDE_API_KEY = "c6b8b4a0eb84688638fc6c1bdf8c8d0f"
export const AMPLITUDE_API_KEY_STAG = "f8358e6538bbbdce2250a0d4fb49b9e2"
export const GOOGLE_TAG_ID = "GTM-KLW952G"
export const CODE_COMMIT_TEMPLATE = "https://infralight-templates-public.s3.amazonaws.com/codecommit/template.yml"
export const MUI_X_LICENSE_KEY = "145b827932f3f4afe1b03ff34e9ff64bTz03ODg1MSxFPTE3MzE3NTE5NzgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
export const CHURNZERO_API_KEY = "1!1hBpfBr9wkxq7Ux64h3nuYxm7-Ff4flmIzvAddC-W5kt1437"
export const WEBEX_URL_API = "https://app.firefly.ai/integrations/webex-integration"
export const WEBEX_CLIENT_ID = "C90f66d933b7fd81e478c983f02f007a5f132ff053d289badb2f822af3d71344b"
export const REB2B_KEY = 'ZQOQRJHE1W62';

