import React, { useState } from "react";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { deleteWorkflow } from "../../../redux/actions/ciWorkflowsActions";
import SuccessBox from "../../../shared/successBox/successBox";
import { sendEvent } from "../../../utils/amplitude";
import { CiEvents } from "../../../utils/amplitudeEvents";
import { appToast } from "../../../shared/appToast/appToast";

import './workspaceMenu.scss';

const DeleteWorkspace = ({ handleOnDeleteWorkflowClick, deleteWorkspaceData = {}, showDeleteWorkflowModal }) => {
    const [showDialog, setShowDialog] = useState(true);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [pullRequestUrl, setPullRequestUrl] = useState("");

    const { workspaceId, workspaceName , isWorkflowManaged = false } = deleteWorkspaceData;

    const { t } = useTranslation("ci-pipeline");
    const dispatch = useDispatch();
    
    const onDeleteWorkflow = async() => {
        setLoadingDelete(true);
        const res = await dispatch(deleteWorkflow(workspaceId));
        if (!res || res?.error207) {
            appToast("error", "", t(res?.error207 ? "deleteWorkspace.error207" : "deleteWorkspace.error"))
            setLoadingDelete(false);
            return handleOnDeleteWorkflowClick(false);
        }
        const isPRLink = !!res?.pullRequestURL;
        if (isPRLink) {
            setPullRequestUrl(res.pullRequestURL);
        }
        setLoadingDelete(false);
        sendEvent(CiEvents.ciWorkspaceConfirmedDeletion, { workspaceID: workspaceId, isManagedByFirefly: isWorkflowManaged });
        if (!isWorkflowManaged || !isPRLink) {
            appToast("success", "", t("deleteWorkspace.prSuccessSubtitle"));
            return handleOnDeleteWorkflowClick(false);
        }
        setShowDialog(false);
    }

    if (showDialog) {
        return (
            <ConfirmationModal
                visible={showDialog}
                handleClose={() => handleOnDeleteWorkflowClick(false)}
                title={t("deleteWorkspace.title", { name: workspaceName })}
                description={t("deleteWorkspace.description")}
                loadingConfirm={loadingDelete}
                onConfirm={onDeleteWorkflow}
          />
        )
    }

    return (
    <NewAppModal
        visible={showDeleteWorkflowModal}
        handleClose={() => handleOnDeleteWorkflowClick(false)}
        destroyOnClose
        centered
        width="70vw"
        bodyClassName="WorkspaceMenu__deletePrBody"
        title={t("deleteWorkspace.prTitle")}
        subtitle=""
        iconSrc={null}
        footer={null}
    >
        <SuccessBox
                setSubmitDisabled={() => null}
                title={t("workflowManagement.completion.successTitle")}
                customImage="workflow_success.svg"
                subTitle={<div className="col g10">
                    <span>{t("deleteWorkspace.prSuccessSubtitle")}</span>
                    <span className="centered">
                        <a href={pullRequestUrl} target="_blank" rel="noopener noreferrer">
                            {t("workflowManagement.completion.prDescription")}
                        </a>
                    </span>
                </div>}
          />
    </NewAppModal>)
};

export default DeleteWorkspace;