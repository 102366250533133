
const getShortModuleName = (moduleName = "") => moduleName?.replace("modules/", "");
const getPartiallyModulesArr = (data = {}) => {
    const partiallyAppliedModules = data.partiallyAppliedModules || [];
    const usedModules = data.metadata?.terraformMetadata?.usedModules || [];
    const userModulesDictionary = usedModules.reduce((acc, mod) => {
      acc[mod?.id] = mod.name;
      return acc;
    }, {});
    const partiallyArr = [];
    partiallyAppliedModules.forEach((id) => {
        const value = userModulesDictionary[id];
        if (value && id) {
            partiallyArr.push({ id, value: getShortModuleName(value) })
        }
    });
    return partiallyArr;
}

const formatUnsyncedStackName = (stack = "") => {
    const stackName = stack.split("_").pop() || "";
    return stackName;
};

export {
    getPartiallyModulesArr,
    formatUnsyncedStackName,
}