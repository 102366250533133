import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

import AppToggle from "../../../../shared/appToggle/appToggle";
import IconBtn from "../../../../shared/iconBtn/iconBtn";
import ConfirmationModal from "../../../../shared/confirmationModal/confirmationModal";
import LinelLoader from "../../../../shared/linelLoader/linelLoader";

import {
  getAllNotifications,
  updateNotification,
  deleteNotification,
} from "../../../../redux/actions/notificationsActions";
import { integrationIcons } from "../../../../utils/icons";

import { dateTimeFormat, getAllProvidersArray } from "../../../../utils/formatting";
import ProviderIcon from "../../../../shared/providerIcon/providerIcon";
import { useTranslation } from "react-i18next";
import { capitalize, cloneDeep, groupBy, omit, some } from "lodash";
import moment from "moment";
import { Badge, Button, Divider, Popover } from "antd";
import TimeIcon from "../../../../Images/general_icons/Time_icon.svg";
import { ReactComponent as UserIcon } from "../../../../Images/general_icons/user_icon.svg";
import { PROVIDERS_TITLE_MAP } from "../../../../consts/general";
import { getGuardrailOrWorkflowItem, notificationValues, scopesTypes } from "../../createNotification/notificationConfig";

import "./singleNotification.scss";
const SingleNotification = ({
  data,
  notificationTypesIndex,
  loadingNotificationsInteg,
  loadingProviders,
  setEditNotificationData,
  setEditModalOpen,
  guardrailsData,
}) => {
  const { t } = useTranslation('notifications')
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [notification, setNotification] = useState({});
  const [seeAll, setSeeAll] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setNotification(data);
  }, [data]);

  // ------- redux state ---------
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const slackIntegrations = useSelector(
    (state) => state.integrationsReducer.slack
  );
  const teamsIntegrations = useSelector(
    (state) => state.integrationsReducer.teams
  );
  const webhookIntegrations = useSelector(
    (state) => state.integrationsReducer.webhook
  );
  const opsgenieIntegrations = useSelector(
    (state) => state.integrationsReducer.opsgenie
  );
  const torqIntegrations = useSelector(
    (state) => state.integrationsReducer.torq
  );
  const pagerdutyIntegrations = useSelector(
    (state) => state.integrationsReducer.pagerduty
  );
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const slackChannels = useSelector(
    (state) => state.integrationsReducer.channels
  );

  const webexRooms = useSelector(
    (state) => state.integrationsReducer.rooms
  );

  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );

  const googleChatIntegrations = useSelector(
    (state) => state.integrationsReducer.googleChat
  );

  const webexIntegrations = useSelector(
    (state) => state.integrationsReducer.webex
  );

  const isWorkflowOrGuardrailNotification = [notificationValues.workflowRunLifecycle, notificationValues.workspaceGuardrailViolation].includes(notification?.eventType)
  //  -----------------------------

  const toggleNotification = async () => {
    setLoading(true);
    await dispatch(
      updateNotification(notification?._id, {
        isEnabled: !notification?.isEnabled,
      })
    );
    await dispatch(getAllNotifications());
    setLoading(false);
  };

  const handleDeleteAlert = async () => {
    setDeleteInProgress(true);
    await dispatch(deleteNotification(notification?._id));
    await dispatch(getAllNotifications());
    setDeleteInProgress(false);
    setConfirmDeleteOpen(false);
  };

  const renderNameByType = () => {
    const item = isWorkflowOrGuardrailNotification ? getGuardrailOrWorkflowItem(notification, guardrailsData) : notificationTypesIndex.find((item) => item?.value === notification?.eventType);
    const output = item?.displayName || "-" ;
    return (
      <div>
        {notification?.displayName ?? output}
        <span className="SingleNotification__header-title_divider"></span>
        {item?.icon ? <span className={`SingleNotification__header-title_icon ${item?.value}`}>
          {typeof item?.icon == "string" ? <FontAwesomeIcon icon={item?.icon} className={`${item?.value}`} /> : item.icon}
        </span> : item?.customIcon ? item.customIcon : null}
      </div>
    )
  };
  const getNotificationType = () =>{
    const type = notificationTypesIndex.find(
      (item) => item?.value === notification?.eventType
    );
    return type?.displayName;
  }

  const handleOpenEditModal = () => {
   if(isViewer) return;
   setEditNotificationData(data);
   setEditModalOpen(true);
  }
  const handleToggleSeeAll =() => {
    setSeeAll(!seeAll);
  }

  const getIntegrationsPopoverContent = (name, integrations=[]) => {
    return(
      <div className="SingleNotification__header-integration-popover__content">
        <h4> 
          <ProviderIcon provider={name} customStyle="notification" />
          {name}
        </h4>
        <ul>
          {integrations.slice(0,seeAll ? 999 : 5).map(integration => (
            <li key={uuidv4()}>
              <ProviderIcon provider={name} customStyle="notification"/> 
              {integration?.name}
            </li>
          ))}
        </ul>
        {integrations.length > 6 && <Button type="link" onClick={handleToggleSeeAll}>{seeAll ? "See Less" :"See All"}</Button>}
      </div>
    )
  }
  const allProviders = getAllProvidersArray(providerIntegrations);
  const renderScopeFlag = () => {
    if (isWorkflowOrGuardrailNotification) return;
    if (!isEmpty(notification?.scope?.providers)) {
      const matchedProviders = notification?.scope?.providers?.map(providerId => allProviders.find(p => p.id === providerId));
      const groupedProviders = groupBy(matchedProviders,'provider');
      let providersToRender = [];
      for (const providerKey in groupedProviders) {
        if(providerKey == "undefined") continue;
        providersToRender.push((
            <Badge count={groupedProviders[providerKey].length} overflowCount={9} size="small" style={{fontSize:10,backgroundColor:"#9872FE"}} key={uuidv4()}>
                 <Popover content={getIntegrationsPopoverContent(providerKey,groupedProviders[providerKey])} placement="bottomLeft" overlayClassName="SingleNotification__header-integration-popover">
                  <span
                    onClick={handleOpenEditModal}
                    className="SingleNotification__header-content-integrations-single row"
                    key={providerKey}>
                    <ProviderIcon provider={providerKey} customStyle="notification" />
                    {PROVIDERS_TITLE_MAP[providerKey] || capitalize(providerKey)}
                    {groupedProviders[providerKey].some(p => p?.isProd) && <FontAwesomeIcon icon={faFlag} className="SingleNotification__header-content-integrations-single-flag" />}
                  </span>
                 </Popover>
          </Badge>
        ))
      
        
      }
      return (
        <div className="SingleNotification__header-content-integrations row">
              {providersToRender}
        </div>
      )
 
    }
    if (!isEmpty(notification?.scope?.tags)) {
      return (
        <div className="SingleNotification__header-content-integrations row">
          {map(notification?.scope?.tags, (tag) => (
            <span
              className="SingleNotification__header-content-integrations-single row"
              key={uuidv4()}
            >
              <FontAwesomeIcon
                icon="tag"
                className="SingleNotification__header-content-integrations-single-cloud"
              />
              {tag}
            </span>
          ))}
        </div>
      );
    }
    if (!isEmpty(notification?.scope?.insights)) {
      return (
        <div className="SingleNotification__header-content-integrations row">
          {map(notification?.scope?.insights || [], (ins) => {
            const match = classifications?.find((item) => item.id === ins);
            if (!isEmpty(match)) {
              return (
                <span
                  onClick={handleOpenEditModal}
                  className="SingleNotification__header-content-integrations-single row"
                  key={match.id}
                >
                  <FontAwesomeIcon
                    icon="cloud"
                    className="SingleNotification__header-content-integrations-single-cloud"
                  />
                  {match.name}
                </span>
              );
            }
          })}
        </div>
      );
    }
    return;
  };

  const allIntegrations = [
    ...map(slackIntegrations, (item) => {
      return { ...item, icon: "slack" };
    }),
    ...map(teamsIntegrations, (item) => {
      return { ...item, icon: "teams" };
    }),
    ...map(webhookIntegrations, (item) => {
      return { ...item, icon: "webhook" };
    }),
    ...map(opsgenieIntegrations, (item) => {
      return { ...item, icon: "opsgenie" };
    }),
    ...map(torqIntegrations, (item) => {
      return { ...item, icon: "torq" };
    }),
    ...map(pagerdutyIntegrations, (item) => {
      return { ...item, icon: "pagerduty" };
    }),
    ...map(googleChatIntegrations, (item) => {
      return { ...item, icon: "googleChat" };
    }),
    ...map(webexIntegrations, (item) => {
      return { ...item, icon: "webex" };
    }),
    {
      id:"emailAddresses",
      service:"email",
      icon:"email",
      name:"Email Address"
    }
  ];

  const renderIntegrationFlag = () => {

    return (
      <div className="SingleNotification__header-content-integrations row" onClick={handleOpenEditModal}>
        {map(notification?.notificationIntegrations || [], (integ) => {
          const match = allIntegrations.find((item) => item.id === integ?.id);
          if (!isEmpty(match)) {

            if (match?.service === "slackApp") {
              return map(integ?.channels || [], (channel) => {
                if (!isEmpty(slackChannels)) {
                  const matchChannel = slackChannels.find(
                    (item) => item?.id === channel
                  );
                  if (matchChannel) {
                    return (
                      <span
                        className="SingleNotification__header-content-integrations-single row"
                        key={matchChannel?.id}
                      >
                        <img
                          src={integrationIcons["slack"]}
                          alt="integration icon"
                        />
                        {matchChannel?.name}
                      </span>
                    );
                  }
                  return;
                }
              });
            }
            
            if (match?.service === "webex") {
              return map(integ?.channels || [], (room) => {
                if (!isEmpty(webexRooms)) {
                  const matchRoom = webexRooms.find(
                    (item) => item?.id === room
                  );
                  if (matchRoom) {
                    return (
                      <span
                        className="SingleNotification__header-content-integrations-single row"
                        key={matchRoom?.id}
                      >
                        <img
                          src={integrationIcons["webex"]}
                          alt="integration icon"
                        />
                        {matchRoom?.title}
                      </span>
                    );
                  }
                  return;
                }
              });
            }

            return (
              <span
                className="SingleNotification__header-content-integrations-single row"
                key={match.id}
              >
                <img
                  src={integrationIcons[match.icon]}
                  alt="integration icon"
                />
                {match.name}
              </span>
            );
            
          }
        })}
      </div>
    );
  };
  const renderScopeType = (scopeType) => {
    if(isEmpty(notification)) return;
    
    if (!isEmpty(notification?.scope[scopeType])) {
      const scopeItem = scopesTypes[scopeType];
      const scopesLength = scopeItem?.isString ? null : notification?.scope[scopeType]?.length;
      return <>
      <div onClick={handleOpenEditModal}>
      <Badge count={scopesLength} size="small" style={{fontSize:10,backgroundColor:"#9872FE"}}>
        <div className="AppTag minimal SingleNotification__header-content-tag">
          {scopeItem?.customIcon ? scopeItem.customIcon : <img src={scopeItem.icon} alt="icon" width={12} />}
          <span>{capitalize(scopeItem.displayName)}</span>
        </div>
      </Badge>
      </div>
      </>
    }
  }
  
  const renderMinimizedFlag = () => {
    if (notification?.minimizedAlert) {
      return <div className="purple-flag">{t('single-notification.minimized')}</div>
    }
  }
  const hasCriteria = some(omit(cloneDeep(notification?.scope), 'providers','insights'), value => value?.length > 0);

  const isValidNotification = () => {
    let valid = true;
    if (!isEmpty(notification?.scope?.insights)) {
      const match = classifications?.find((item) => item.id === notification?.scope?.insights?.[0]);
      if (!match) {
        return valid = false;
      }
    }
    const allProvidersIds = map(allProviders, provider => provider.id)
    if (!notification?.scope?.providers?.some(provider => allProvidersIds?.includes(provider))) {
      return valid = false;
    }
    const allIntegrationsIds = map(allIntegrations, integration => integration.id);
    if (!allIntegrationsIds?.includes(notification?.notificationIntegrations?.[0]?.id)) {
      return valid = false;
    }

    return valid;
  }

  // if(!isValidNotification()) return null;
  
  return (
    <div
      className={`SingleNotification basic-card ${!notification.isEnabled ? "notificationOff" : ""
        }`}
    >
      <div className="SingleNotification__header col">
        <span className="SingleNotification__header-title">
          <div>
            {renderNameByType()}  
            <div className="SingleNotification__header-title-user-information">
            <div>
              <img src={TimeIcon} alt="clock icon" width={12} />
              <span>{moment(notification?.createdAt).format(dateTimeFormat)}</span>
            </div>
            {notification?.name && (
              <>
              <span className="SingleNotification__header-title_divider"></span>
              <div className="row g5">
                <UserIcon style={{width: '12px'}}/>
                <span>{notification.name}</span>
              </div>
              </>
            )}
          </div>
          </div>
          <div className="SingleNotification__actions row">
            <div className="SingleNotification__actions-action delete-btn">
              <AppToggle
                checked={notification?.isEnabled}
                toggleChecked={toggleNotification}
                loading={loading}
                disabled={isViewer}
              />
            </div>
            <IconBtn
              type="delete"
              onClick={() => setConfirmDeleteOpen(true)}
              disabled={isViewer}
              colorClass="red"
            />
            <IconBtn
              type="edit"
              onClick={() => {
                setEditNotificationData(data);
                setEditModalOpen(true);
              }}
              disabled={isViewer}
              colorClass="purple"
            />
            </div>
        </span>
      
        <Divider  style={{margin:0}}/>
        {loadingNotificationsInteg || loadingProviders ? (
          <LinelLoader />
        ) : (
          <div className="SingleNotification__header-content row">
            {renderScopeFlag()}
            {hasCriteria && (
              <>
                {!isWorkflowOrGuardrailNotification && <span className="SingleNotification__header-divider"></span>}
                <span className="SingleNotification__header-label">Criteria:</span>
              </>
            )}
            {renderScopeType("assetTypes")}
            {renderScopeType('tags')}
            {renderScopeType('owners')}
            {renderScopeType('locations')}
            {renderScopeType('workspaceNames')}
            {renderScopeType('workspaceRepositories')}
            {renderScopeType('workspaceLabels')}
            {renderScopeType('workspaceStatus')}
            {renderScopeType('guardrailRuleId')}
            <span className="SingleNotification__header-divider"></span>
            <span className="SingleNotification__header-label">Destination:</span>
            {renderIntegrationFlag()}
            {renderMinimizedFlag()}

          </div>
        )}
      </div>

      

      <ConfirmationModal
        visible={confirmDeleteOpen}
        handleClose={() => setConfirmDeleteOpen(false)}
        title={t('delete-modal.title', { type: getNotificationType() })}
        description={t('delete-modal.description',{type:getNotificationType()})}
        loadingConfirm={deleteInProgress}
        onConfirm={handleDeleteAlert}
      />
    </div>
  );
};

export default SingleNotification;
