import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as RepoIcon } from '../../Images/ci/filters/repo.svg';
import { ReactComponent as CiToolIcon } from '../../Images/ci/filters/ci-tool.svg';
import { ReactComponent as CiPipelineIcon } from '../../Images/general_icons/sidebar/ci-pipeline.svg';
import { ReactComponent as TagsIcon } from "../../Images/general_icons/tags.svg";
import { ReactComponent as StatusIcon } from '../../Images/ci/filters/status.svg';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleSetCiFilters } from '../../redux/actions/ciWorkflowsActions';
import { WORKFLOWS_STATUSES_TITLES, formatCiToolName } from '../../utils/workflowsHelper';

const iconByFilter = {
    repos: RepoIcon,
    ciTool: CiToolIcon,
    labels: TagsIcon,
    status: StatusIcon,
}

const CiFiltersBadges = ({ workspaceData = [] }) => {
    const ciFilters = useSelector((state) => state.ciWorkflowsReducer.ciFilters) || {};
    const dispatch = useDispatch();

    const filters = useMemo(() => {
        const arr = Object.keys(ciFilters).reduce((acc, key) => {
            if (ciFilters[key]?.length) {
                const filterArr = ciFilters[key];
                filterArr.forEach((filterValue) => {
                        const filterKey = key === "workspaceId" ? filterValue : `${key}-${filterValue}`
                        const value = key === "workspaceId" ? (workspaceData || []).find((workspace) => workspace?.workspaceId === filterValue)?.workspaceName 
                        : key === "status" ? WORKFLOWS_STATUSES_TITLES[filterValue] : filterValue;
                        const formattedName = key === "ciTool" ? formatCiToolName(filterValue) : "";
                        acc.push({ type: key, key: filterKey, value: value, icon: iconByFilter[key] || CiPipelineIcon, formattedName });
                   }
                )
            }
            return acc;
        }, []);
        return arr || [];
    }, [ciFilters]);

    const handleClearFilter = (elem) => {
        const { type = "", value = "", key = ""} = elem;
        const updatedFilterType = (ciFilters[type] || []).filter((filterValue) => type === 'workspaceId' ? key !== filterValue 
        : type === "status" ? value !== WORKFLOWS_STATUSES_TITLES[filterValue] 
        : value !== filterValue);
        dispatch(handleSetCiFilters({ ...ciFilters, [type]: updatedFilterType }));
    };

    if (!filters?.length) return <div className="CiFiltersBadges"/>

    return (
    <div className="CiFiltersBadges row g5">
        {filters.map((elem = {}) => {
            const { key = "", value = "", icon: IconComponent, formattedName = "" } = elem;
            return <div className="basic-filter-badge row g5" key={key} onClick={() => handleClearFilter(elem)}>
                <IconComponent />
                <span>{formattedName || value}</span>
                <FontAwesomeIcon icon="times" />
            </div>
        })}
    </div>)
};

export default CiFiltersBadges;