import React from "react";
import { Badge, Popover } from "antd";
import { useTranslation } from "react-i18next";

const GuardrailScope = ({ statementTitle , scope = {} , withPopover = true, customIcon }) => {
  const { include = [], exclude = [] } = scope;
  const isInclude = include?.length > 0;
  const isExclude = exclude?.length > 0;
  const { t: tCommon } = useTranslation("common");
  return (
    <Popover
      overlayClassName="FiltersRowBadges__popover"
      content={<div className="SingleGuardrail__popover col g10">
                    <div className={"col g5"}>
                       {isInclude && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("include")}</span>
                            <div className="SingleGuardrail__popover-list">
                                {include.map((type) => (
                                    <div className="row g5" key={type}>
                                        {customIcon}
                                        <span>{type}</span>
                                    </div>
                                ))}
                            </div>
                        </div>}
                        {isExclude && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("exclude")}</span>
                            <div className="SingleGuardrail__popover-list">
                                {exclude.map((type) => (
                                    <div className="row g5" key={type}>
                                       {customIcon}
                                       <span>{type}</span>
                                   </div>
                                ))}
                            </div>
                        </div>}
                    </div> 
      </div>
      }
      zIndex={withPopover ? 1070 : -1}
      placement="topLeft"
    >
    <Badge
        className="SingleGuardrail__badge"
        count={include.length}
        size="small">
        <div className={`basic-filter-badge row g5 SingleGuardrail__criteria`}>
            {customIcon}
            <span>{statementTitle}</span>
        </div>
      </Badge>
    </Popover>
  );
};

export default GuardrailScope;
