import React, { memo } from "react";
import Tooltip from "antd/lib/tooltip";

const AssetNameCell = ({ name = "", description = ""}) => {
  return (
    <Tooltip title={description} placement="top">
      <div className="AssetNameCell center" style={{ height: "24px" }}>
        {name}
      </div>
    </Tooltip>
  );
};

export default memo(AssetNameCell);
