import React, { useEffect, useState } from "react";
import isArray from "lodash/isArray";
import { getGuardrailsViolationsByTaskId } from "../../../../redux/actions/workflowsGuardrailsActions";
import Loading from "../../../../shared/loading/loading";
import { ReactComponent as TagIcon } from '../../../../Images/ci/guardrails/tag.svg';
import { ReactComponent as CostIcon } from '../../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourceIcon } from '../../../../Images/ci/guardrails/resources.svg';
import { useDispatch } from "react-redux";

import { Tooltip } from "antd";
import { capitalizeFirst } from "../../../../utils/formatting";
import "../postApply/diagnostic/diagnostic.scss";

const TYPE_ICONS = {
    cost: CostIcon,
    policy: PolicyIcon,
    resource: ResourceIcon,
    tag: TagIcon,
}
const GuardrailBlock = ({ taskId }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    
    const dispatch = useDispatch();

    useEffect(() => {
        fetchGuardrailsViolations()
    }, []);
    
    const fetchGuardrailsViolations = async () => {
        setLoading(true);
        const response = await dispatch(getGuardrailsViolationsByTaskId(taskId));
        if (isArray(response)) setData(response);
        setLoading(false);
    };

    if (loading) return <div className="basic-loading"><Loading /></div>
    return (
        <div className="GuardrailBlock basic-100 col g20">
          {data.map((item = {}) => {
            const { ruleId, description = "", ruleType = "", ruleName = ""} = item;
            const Icon = TYPE_ICONS[ruleType];
            return (
                <div key={ruleId} className="GuardrailBlockItem">
                        <div className="Diagnostic__header guardrail row g8">
                        {Icon && <Tooltip title={capitalizeFirst(ruleType)}><Icon className="Diagnostic__icon-guardrail pointer"/></Tooltip>}
                            <div className="row g5">
                                <span className="Diagnostic__header-title unbold">{ruleName}</span>
                            </div>
                        </div>
                        <div className="Diagnostic__body col g5">
                            <span className="Diagnostic__text guardrails">{description}</span>
                        </div>
                </div>
            )
          })}
        </div>
    );
};

export default GuardrailBlock;