import React from "react";
import { v4 as uuidv4 } from "uuid";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import "./dropMenu.scss";
import { Tooltip } from "antd";

const DropMenu = ({ links, tooltipPlacement = "bottomRight" }) => {
  if (isEmpty(links)) {
    return null;
  }

  const renderMenu = () => {
    return map(links, (item) => {
      if (item?.hide) return null;
      return (
        <Tooltip title={item?.tooltip || ""} key={uuidv4()} placement={tooltipPlacement}>
          <div
            className={`DropMenu__item row ${item?.disabled ? "disabled" : ""} ${item?.readonly ? 'readonly' : ''}`}
            onClick={
              item?.disabled
                ? () => {
                    return false;
                  }
                : item?.onClick
            }
          >
            {item?.icon && <span className="DropMenu__item-icon">{item.icon}</span>}
            {item?.text}
          </div>
        </Tooltip>
      );
    });
  };

  return <div className="DropMenu">{renderMenu()}</div>;
};

export default DropMenu;
