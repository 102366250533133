import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelFilter from "../../../inventory/inventoryFilters/inventorySingelFilter";
import { clouds } from "../../../../utils/icons";
import { providerNameFormatter } from "../../../../utils/formatting";
import { governanceEvents } from "../../../../utils/amplitudeEvents";
import { sendEvent } from "../../../../utils/amplitude";

const ProvidersFilter = () => {
  const dispatch = useDispatch();
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const providerAggs = useSelector(
    (state) => state.insightsV2Reducer.aggs?.providers
  );
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const handleSetSelectedProvider = useMemo(
    () => (arr) => {
      const payload = { ...insightFilters, providers: arr, onlyAvailableProviders: false };
      sendEvent(governanceEvents.providers, {
        selectedFilter: arr,
      });
      return dispatch(setInsightsFilters(payload));
    },
    [dispatch, insightFilters]
  );

  const providers = useMemo(() => {
    return (providerAggs || []).map((i) => ({
      name: i?.value,
      formattedName: providerNameFormatter(i?.value),
      // count: i?.count, // dont show we have a bug => count is not identical to the the hits result
      element: <img src={clouds(i?.value, themeDark)} alt={i?.value} />,
    }));
  }, [providerAggs]);

  return (
    <InventorySingelFilter
      allTypes={providers}
      selectedTypes={handleSetSelectedProvider}
      checkedList={insightFilters?.providers || []}
      title="Search"
      onRegularFilterSearch={(val) => sendEvent(governanceEvents.textSearched, {searchedText: val, filterName: "providers"})}
    />
  );
};

export default ProvidersFilter;
