import _, { keys, lowerCase, map, upperCase, reduce, isPlainObject, omit, isEqual, isEmpty } from "lodash";
import moment from "moment";
import { clouds } from "./icons";
import { CLOUD_PROVIDERS_SUPPORT, TITLES } from "../consts/general";
import { JIRA_BASE_URL } from "../consts/links";
import { emptyInventoryScreenFilters } from "../consts/inventory";

// return array with unique value from array of object
// used for ant design table column dynamic sorting and multiple selction menu
// outputs an array of objects === [ { text: str, value: str } ]
export const getUniqeKeys = (array, keyToDistict, isCapitalize) => {
  let output = [];

  if (!array && !array.length && !keyToDistict) {
    return output;
  }

  let tempArr = _.keys(_.groupBy(array, keyToDistict));
  tempArr.forEach((element) => {
    output.push({
      // this is Ant Design object filter stracture
      text: isCapitalize ? _.capitalize(element) : element,
      value: element,
    });
  });

  return output;
};

// create and download text file from string
export const downloadFile = (string, fileType, fileName) => {
  const element = document.createElement("a");
  const file = new Blob([string], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.${fileType}`;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

// copy string to clipboard
export const handleCopy = (copyText) => {
  const textArea = document.createElement("textarea");
  textArea.value = copyText;
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();
  document.execCommand("copy");
  document.body.removeChild(textArea);
};

// ------- sort by date helper for ant design table ----------
export const sortDateColumn = (a, b, keyName) => {
  if (moment(a[keyName]).isBefore(moment(b[keyName]))) {
    return -1;
  }
  return 1;
};

// --------- get user name by id from profiles ---------
export const getUserById = (id, users) => {
  if (!_.isEmpty(users)) {
    const result = users.find((user) => id === user.user_id);
    if (_.isEmpty(result)) {
      return "-";
    }
    return result.name;
  }
  return "-";
};
export const getItemById = (id = '', items = []) => items.find(item => item.id === id) || {};
export const getNameById = (id, allEnv) => {
  if (!_.isEmpty(allEnv)) {
    const env = allEnv.find((item) => item.id === id);
    if (!_.isEmpty(env)) {
      return env.name;
    }
    return id;
  }
  return id;
};

export const getInsightDataById = (id, allInsights) => {
  if (!_.isEmpty(allInsights)) {
    const insight = allInsights.find((item) => item.id === id);
    if (!_.isEmpty(insight)) {
      return { ...insight, name: insight.name, description: insight.description, labels: [insight.category] };
    }
    return id;
  }
  return id;
};

export const getProvideAccountNameByNum = (num, providerAccounts, byId) => {

  const type = byId ? 'id' : 'accountNumber';

  if (!_.isEmpty(providerAccounts)) {
    const account = providerAccounts.find(
      (item) => item?.[type] === num
    );
    if (!_.isEmpty(account)) {
      return account.name;
    }
    return num;
  }
  return num;
};

export const createLabelsFilterArray = (array) => {
  let output = [];

  if (_.isEmpty(array)) {
    return output;
  }

  output = _.map(array, (item) => {
    return {
      text: item,
      value: item,
    };
  });
  return output;
};

export const lookup = (obj, k) => {
  for (let key in obj) {
    let value = obj[key];

    if (k === key.toString()) {
      return [k, value];
    }

    if (typeof value === "object" && !Array.isArray(value)) {
      let y = lookup(value, k);
      if (y && y[0].toString() === k.toString()) return y;
    }
    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; ++i) {
        let x = lookup(value[i], k);
        if (x && x[0].toString() === k.toString()) return x;
      }
    }
  }

  return null;
};

// cut company domain name from email
export const cutDomainFromEmail = (email) => {
  if (!_.isEmpty(email)) {
    const domain = email?.substring(email?.lastIndexOf("@") + 1);
    const company = domain?.split(".");
    return company[0];
  }
  return email;
};

export const companyNameFromEmail = (email) => {
  if (!_.isEmpty(email)) {
    const domain = email.split("@");
    return !_.isEmpty(domain) ? domain[1] : domain;
  }
  return email;
};

// REPLACE SENSATIVE DATA IN A JSON FILE
export const maskJSONSensitiveData = (rawJson) => {
  const replaceString = "*** REDACTED BY FIREFLY ***";
  const sensitiveKeys = [
    "secret_key",
    "password",
    "client_secret",
    "accessKey",
    "secretKey",
    "api-key",
  ];
  let sensitiveResult = [];
  let reductedJson = rawJson;

  if (!_.isEmpty(rawJson)) {
    const parsedJson = JSON.parse(rawJson);

    // detect sensitive data by keys
    sensitiveKeys.forEach((item) => {
      let itemDetected = lookup(parsedJson, item); // output an array of key values
      if (!_.isEmpty(itemDetected) && itemDetected?.length > 1) {
        sensitiveResult.push(itemDetected[1]); // insert the secong value which is the object item value
      }
    });

    // replace this the sensitive findings with the Fifrely flag.
    if (!_.isEmpty(sensitiveResult)) {
      sensitiveResult.forEach((item) => {
        reductedJson = reductedJson.replaceAll(item, replaceString);
      });
      return reductedJson;
    }
  }
  return rawJson;
};

export const handleKeyValuePair = (keyValuePair) => {
  const keyValueArr = [];
  for (const key in keyValuePair) {
    keyValueArr.push(keyValuePair[key]);
  }
  return keyValueArr;
};

export const handleKeyValueObject = (keyValueObject) => {
  const keyValueArr = [];
  for (const key in keyValueObject) {
    keyValueArr.push(key);
    keyValueArr.push(keyValueObject[key]);
  }
  return keyValueArr;
};

export const getClassificationDescById = (id, classifications) => {
  if (!_.isEmpty(classifications)) {
    const classification = classifications.find((item) => item.id === id);
    if (!_.isEmpty(classification)) {
      return classification?.description;
    }
    return id;
  }
  return id;
};

export const getProviderDarkIconByAccount = (num, providerAccounts) => {
  if (!_.isEmpty(providerAccounts)) {
    const account = providerAccounts.find(
      (item) => item?.accountNumber === num
    );
    if (!_.isEmpty(account)) {
      return clouds(account.provider, true);
    }
    return clouds("none", true);
  }
  return clouds("none", true);
};

export const getStatusCategory = (status) => {
  if (status.endsWith("FAILED")) {
    return false;
  }
  return true;
};

export const formatCheckedKeysByFilters = (checkedKeys, providerGroups) => {
  // filters that are on second tree level but should be concidered as providers === 
  // filters that dont have subtype
  // ADD PROVIDERS IF NEEDED
  const filtersHeirarchy = {
    cloud: {
      aws: {},
      k8s: {},
      gcp: {},
      azurerm: {},
    },
    saas: {
      cdn: {
        akamai: {},
      },
      apm: {
        datadog: {},
        newrelic: {}
      },
      iam: {
        okta: {}
      },
      git: {
        github: {}
      },
    },
  };
  let sortedFilters = {
    providerType: [],
    providerSubType: [],
    provider: [],
    integrationId: [],
  };
  const children = [];
  const getChildren = (obj) => {
    _.forEach(Object.keys(obj), (child) => {
      children.push(child);
      if (!_.isEmpty(Object.keys(obj))) {
        getChildren(obj[child]);
      }
    });
  };

  // get filters' children
  _.map(checkedKeys, (checkedKey) => {
    getChildren(
      filtersHeirarchy[checkedKey] ||
      filtersHeirarchy["cloud"][checkedKey] ||
      filtersHeirarchy["saas"][checkedKey] ||
      {}
    );
  });

  // remove the filters' children
  checkedKeys = checkedKeys?.filter((key) => !children?.includes(key));

  let providerIds = checkedKeys?.filter((key) => _.isEmpty(lookup(filtersHeirarchy, key)));
  let heirarchyKeys = checkedKeys?.filter((key) => !_.isEmpty(lookup(filtersHeirarchy, key)));

  const heirerchies = {};

  _.map(heirarchyKeys, (heirerchy) => {
    // if heirerchy is in first tree level
    if (Object.keys(filtersHeirarchy)?.find((key) => key === heirerchy)) {
      return heirerchies[heirerchy] = "providerType";
    }
    // if checked key is in second tree level
    if (
      Object.keys(filtersHeirarchy["cloud"])?.find(
        (key) => key === heirerchy
      ) ||
      Object.keys(filtersHeirarchy["saas"])?.find((key) => key === heirerchy)
    ) {
      // filters that are on second tree level but should be concidered as providers === 
      // filters that dont have subtype
      if (CLOUD_PROVIDERS_SUPPORT.includes(heirerchy)) {
        return heirerchies[heirerchy] = "provider";
      }
      return heirerchies[heirerchy] = "providerSubType";
    }
    // if checked key is in third tree level
    return heirerchies[heirerchy] = "provider";
  });

  // delete provider Ids by their parent filter
  let keysToRemove = [];

  _.map(_.keys(heirerchies), (heirerchy) => {
    if (heirerchies[heirerchy] === "provider") {
      _.map(providerGroups[heirerchy], integ => {
        keysToRemove?.push(integ?.accountNumber);
      })
    }
    if (heirerchies[heirerchy] === "providerSubType") {
      let providerType = "cloud";
      if (_.keys(filtersHeirarchy["saas"])?.includes(heirerchy)) {
        providerType = "saas";
      }
      _.map(_.keys(filtersHeirarchy[providerType][heirerchy]), providerKey => {
        _.map(providerGroups[providerKey], integ => {
          keysToRemove?.push(integ?.accountNumber);
        })
      })
    }
    if (heirerchies[heirerchy] === "providerType") {
      _.map(_.keys(filtersHeirarchy[heirerchy]), (subTypeKey) => {
        if (CLOUD_PROVIDERS_SUPPORT.includes(subTypeKey)) {
          _.map(providerGroups[subTypeKey], integ => {
            keysToRemove?.push(integ?.accountNumber);
          })
        } else {
          _.map(_.keys(filtersHeirarchy[heirerchy][subTypeKey]), providerKey => {
            _.map(providerGroups[providerKey], integ => {
              keysToRemove?.push(integ?.accountNumber);
            })
          })
        }
      })

    }
  });
  checkedKeys = checkedKeys?.filter((key) => !keysToRemove?.includes(key));
  // create the final filter
  _.map(checkedKeys, (checkedKey) => {
    if (providerIds?.includes(checkedKey)) {
      return sortedFilters["integrationId"]?.push(checkedKey);
    }
    return sortedFilters[heirerchies[checkedKey]]?.push(checkedKey);
  });
  if (!_.isEmpty(sortedFilters.integrationId)) {
    sortedFilters.providerType = []
  }
  return sortedFilters;
};
export const renderUserName = (user) => {
  if (!_.isEmpty(user?.nickname)) {
    return _.capitalize(user?.nickname);
  }
  if (!_.isEmpty(user?.name)) {
    return _.capitalize(user?.name);
  }
  return;
};

export const findIntegrationTypeByKeyVal = (arr, key, val) => {
  const match = _.filter(arr || [], item => item[key] === val);
  return !_.isEmpty(match);
}

export const getOperatingSystem = () => {
  let operatingSystem = 'Not known';
  if (window.navigator.appVersion.indexOf('Win') !== -1) { operatingSystem = 'Win'; }
  if (window.navigator.appVersion.indexOf('Mac') !== -1) { operatingSystem = 'Mac'; }
  if (window.navigator.appVersion.indexOf('X11') !== -1) { operatingSystem = 'UNIX'; }
  if (window.navigator.appVersion.indexOf('Linux') !== -1) { operatingSystem = 'Linux'; }

  return operatingSystem;
}

export const getSubdomain = () => {
  const host = window.location.host;
  const subdomain = host.split('.')[0];
  if (subdomain === 'www') {
    return null;
  }
  return subdomain;
}

export const renderRandomCronJob = () => {
  let n = 15;
  let x = _.random(0, 14);
  let y = x + n;
  let z = y + n;
  let k = z + n;
  // the command is generated to match a string that is running in the helm install command,
  // without the \, it will not be generated properly.
  return `${x}\\,${y}\\,${z}\\,${k} * * * *`;
}

export const renderInitalFromName = (name) => {
  return _.isEmpty(name) ? "U" : _.capitalize(name[0]);
};

export const initalColorsByFirstLetter = (name) => {
  const letter = _.isEmpty(name) ? null : _.capitalize(name[0]);
  const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
  const index = alphabet.indexOf(letter);

  // if(index <= 5) {
  //   return '#9872FE';
  // }
  // if(index <= 10) {
  //   return '#67CEBC';
  // }
  // if(index <= 15) {
  //   return '#F4B483';
  // }
  // if(index <= 20) {
  //   return '#D76089';
  // }
  // if(index <=26) {
  //   return '#59a0fe';
  // }
  return;
}

export const getVulnerabilityData = (row) => {
  const scanData = {
    unknown: 0,
    critical: 0,
    high: 0,
    medium: 0,
    low: 0,
  };
  let scanResultsKeys = keys(row)?.filter((key) =>
    key?.startsWith("scanResultsSeverityCount")
  );
  scanResultsKeys = map(scanResultsKeys, (scanKey) =>
    lowerCase(scanKey?.replaceAll("scanResultsSeverityCount.", ""))
  );
  map(scanResultsKeys, (scanKey) => {
    let value = row[`scanResultsSeverityCount.${upperCase(scanKey)}`]
      ? row[`scanResultsSeverityCount.${upperCase(scanKey)}`][0]
      : 0;
    scanData[scanKey] = value;
  });
  return scanData;
};
export const getMisconfigurationsAggs = (baseAggs) => {
  let misconfigurations = [];
  let misconfigKeys = ["hasHIGHAgg", "hasMEDIUMAgg", "hasCRITICALAgg", "hasLOWAgg", "hasUNKNOWNAgg"];
  map(keys(baseAggs), aggKey => {
    if (misconfigKeys?.includes(aggKey) && baseAggs[aggKey]?.doc_count > 0) {
      let key = aggKey?.replaceAll("has", "");
      key = key?.replaceAll("Agg", "");
      misconfigurations.push({
        key,
        doc_count: baseAggs[aggKey]?.doc_count
      });
    }
  })
  return misconfigurations;
}

export const getIconMutedColor = (dark) => {
  return dark ? '#7F7F85' : '#ABABAC';
}

export const gradientColorChema = [
  "#8263d7",
  "#4f5dbe",
  "#4a84c7",
  "#479ccc",
  "#43b7d2",
  "#40c8d6",
];

export const getAbbreviateNumber = (number, fixedPoint = 2) => {
  if (number < 1000) return number
  if (number < 1000000) return `${(number / 1000).toFixed(fixedPoint)}K`
  if (number < 1000000000) return `${(number / 1000000).toFixed(fixedPoint)}M`
  return number;
}

export const isDateLessThan24Hours = (dateString) => {
  // Parse the input date string using Moment.js (or native JavaScript Date if preferred)
  var inputDate = moment(dateString);
  var currentDate = moment();

  // Calculate the difference in hours between the two dates
  var hoursDifference = currentDate.diff(inputDate, 'hours');

  // Check if the difference is less than 24 hours
  return hoursDifference < 24;
}


export const renderIps = (publicIps) => {
  if (_.isEmpty(publicIps)) {
    return null;
  }
  return (
    <div className="col">
      <span>Please allow external access to the following IPs:</span>
      <div className="row" style={{ gap: "5px" }}>
        {_.map(publicIps, (item) => (
          <span className="code-flag" key={item}>
            {item}
          </span>
        ))}
      </div>
    </div>
  );
};

export const areAllRegionsSame = (arr) => {
  // Check if the array is empty or if any object is empty
  if (_.isEmpty(arr)) {
    return false;
  }

  // Use _.every to check if all objects have the same "region" key as the first one
  return _.every(arr, (obj) => obj.region === arr[0].region);
}

export const calculateTotalIntegrations = (obj) => {
  return reduce(obj, (result, value) => {
    if (isPlainObject(value)) {
      return result + calculateTotalIntegrations(value);
    }
    return result + value;
  }, 0);
};

export const isDemoApplication = () => {
  var currentUrl = window.location.href.toLowerCase();
  return (
    currentUrl.includes('https://try.stag.gofirefly.io/') ||
    currentUrl.includes('https://try.firefly.ai/')
  );
}

export const addUtmParams = () => {
  const searchQuery = window.location.search;
  const urlParams = new URLSearchParams(searchQuery);
  const obj = {};
  for (const [key = "", value] of urlParams) {
    if (key?.toLowerCase().includes("utm")) {
          obj[key] = value;
    }
  }
  return obj;
}

export const stringToBoolean = (str) => {
  const lowerStr = _.toLower(str);
  return _.isEqual(lowerStr, 'true');
}

export const addProviderAndNameToProviderIdAggs = (accounts = [], integrations = {}) => {
  return accounts
    .map((account) => {
      const integration = _.flatMap(integrations).find(
        (integration) => integration?.id === account?.value
      );

      if (integration) {
        const provider = Object.keys(integrations).find((key) =>
          integrations[key].includes(integration)
        );
        account.provider = provider;
        account.name = integration?.name;
        account.isProd = integration?.isProd;
        return account;
      }

      return null;
    })
    .filter((account) => account !== null);
};

export const openJiraCreateIssue = (issueURL) => {
  window.open(issueURL, "_blank");
};

export const buildJiraIssueURL = (
  { pid, issuetype, summary, description, reporter, assignee },
  domain
) => {

  const params = {
    pid,
    issuetype,
    summary,
    description,
    reporter,
  };
  
  if (assignee) {
    params.assignee = assignee;
  }

  const paramsUrl = new URLSearchParams(params);

  return `https://${domain}.${JIRA_BASE_URL}?${paramsUrl.toString()}`;
};

export const buildTableString = (tableData) => {
  if(_.isEmpty(tableData)) return '';
  let tableString = '||Property||Runtime Value||IaC Value||\n';

  tableData.forEach(row => {
    tableString += `|${row['Property']}|${row['Runtime Value']}|${row['IaC Value']}|\n`;
  });

  tableString = tableString.trim();

  return tableString;
};

export const createLinkString = (link) => {
  return `${TITLES.link}: [${TITLES.firefly}|${link}]`
};

export const getIntegrationsId =(providers, allProviders) => { 
  return (providers|| []).map((accountNum) => {
    const match = allProviders.find((item) => item?.accountNumber === accountNum) || {};
    return { accountNumber: accountNum, integrationId: match.id  }
  })
}; 

export const getTotalAssetsLastSynced = (assets = []) => {
  if(_.isEmpty(assets)) return;

  let maxTimestamp = Math.max(...assets.map(asset => new Date(asset.lastSuccessfulSyncTime || 0).getTime()));
  let maxDate = maxTimestamp !== 0 ? new Date(maxTimestamp) : null;
  
  return maxDate;
};

export const checkIsViewActive = (screenFilters, searchVal) => {
  const keysToIgnore = ['freeSearchText', "vcsId", 'vcsRepo', 'stateLocationsStrings']
  const emptyInventoryScreenFiltersCopy = omit(emptyInventoryScreenFilters, keysToIgnore);
  const screenFiltersCopy = omit(screenFilters, keysToIgnore);
  const clearInventoryScreenFilters = {...emptyInventoryScreenFiltersCopy, masterType: []}
  return (!isEqual(clearInventoryScreenFilters, screenFiltersCopy) || !isEmpty(searchVal))
}
