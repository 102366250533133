import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import Slider from "react-slick";
import AppBtn from "../../../../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistoryCalendarPopover from "../historyCalendarPopover/historyCalendarPopover";
import HistoryNavSlide from "../historyNavSlide/historyNavSlide";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./historyNav.scss";
import { sendEvent } from "../../../../../utils/amplitude";
import { assetHistoryEvents } from "../../../../../utils/amplitudeEvents";

const HistoryNav = ({ selectedRevision, setSelectedRevision, asset }) => {
  const revisions = useSelector((state) => state.historyReducer.revisions);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const [calendarOpen, setCalendarOpen] = useState(false);
  const sliderRef = useRef();

  const handleOnClick = (item) => {
    setSelectedRevision(item);

    const timeArray = revisions.map(revision => revision.time);

    const payload = {
      arn: asset?.arn,
      assetType: asset?.assetType,
      integrationID: asset?.integrationId,
      tags: asset?.tags,
      revisionID: item?.runId,
      assetChangeTimestamps: timeArray,
      selectedAssetChangeTimestampLocation: item?.time,
      totalChanges: revisions?.length,
    }
    
    sendEvent(assetHistoryEvents.selectedRevision, payload);

  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: revisions?.length > 5 ? (revisions?.length - 5) : 0
  };

  const renderSlides = () => {
    return _.map(revisions || [], (item) => (
      <HistoryNavSlide
        key={item?.runId}
        active={item?.runId === selectedRevision?.runId}
        onClick={() => handleOnClick(item)}
        time={item?.time}
        state={item?.state}
        current={item?.current}
      />
    ));
  };

  const handleOpenChange = (newOpen) => {
    setCalendarOpen(newOpen);
    sendEvent(assetHistoryEvents.openCelendar);
  };

  const handleOnSwipe = (current) => {
    sendEvent(assetHistoryEvents.clickedNavigation, {currentSlide: current});
  }

  const handleGoToSlide = useCallback((id) => {
    return sliderRef.current.slickGoTo(id);
  }, []);

  const datesSlider = useMemo(() => {
    return (
      <Slider {...settings} ref={sliderRef} afterChange={handleOnSwipe}>
        {renderSlides()}
      </Slider>
    );
  }, [revisions, selectedRevision]);

  return (
    <div className="HistoryNav row between">
      {!_.isEmpty(revisions) && revisions?.length > 1 && (
        <Popover
          content={
            <HistoryCalendarPopover
              selectedRevision={selectedRevision}
              setSelectedRevision={(val) => {
                setCalendarOpen(false);
                setSelectedRevision(val);
                handleGoToSlide(val?.idx);
              }}
              asset={asset}
            />
          }
          title={null}
          trigger="click"
          visible={calendarOpen}
          onVisibleChange={handleOpenChange}
          placement="bottomLeft"
          overlayInnerStyle={{
            border: `2px solid ${
              themeDark ? "rgba(255, 255, 255, 0.1)" : "rgba(81, 81, 81, 0.15)"
            }`,
          }}
        >
          <AppBtn
            icon={<FontAwesomeIcon icon="calendar-alt" />}
            onClick={() => {
              return;
            }}
            secondary
          />
        </Popover>
      )}
      <div className="HistoryNav__slider">{datesSlider}</div>
    </div>
  );
};

export default HistoryNav;
