import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ActionsSummary from "../actionsSummary/actionsSummary";
import { getWorkflowResources } from "../../../../redux/actions/ciWorkflowsActions";
import { useDispatch } from "react-redux";
import ApplyResources from "./applyResources";
import Outputs from "./outputs";
import Diagnostic from "./diagnostic/diagnostic";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";

import "./postApply.scss";

const PostApply = ({ applyId = '', parsedDataSummary = {}, handleOpenConfig, planId, taskId }) => {
    const [applyData, setApplyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [applySort, setApplySort] = useState({ name: 1 });
    const [selectedPlanResource, setSelectedPlanResource] = useState({});

    const { notNoOpResourcesCount = 0, failedResourcesCount = 0 } = parsedDataSummary;
    const applyCount = notNoOpResourcesCount + failedResourcesCount;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchApplyData();
    }, [pageNumber, pageSize, applySort]);

    const handleOpenPlanConfig = async (address) => {
        const isElementExists = selectedPlanResource?.address === address;
        if (isElementExists) {
           return handleOpenConfig(selectedPlanResource);
        }
        const filters = { orClauses: { address } };
        const response = await dispatch(getWorkflowResources(planId, { filters }));
        if (response?.resources?.length > 0) {
            const element = response?.resources[0];
            handleOpenConfig(element);
            setSelectedPlanResource(element);
        }
    }
    
    const fetchApplyData = async () => {
        setLoading(true);
        const newOffset = (pageNumber - 1) * pageSize;
        const response = await dispatch(getWorkflowResources(applyId, { offset: newOffset > applyCount ? 0 : newOffset ,size: pageSize, sort: applySort }));
        if (response) setApplyData(response.resources);
        setLoading(false);
    }

    const { outputs =  {}, diagnostics: diagnosticsArr } = parsedDataSummary;
    const outputsArr = Object.keys(outputs).map((outputKey) => ({ id: uuidv4(), name: outputKey, value: outputs[outputKey] })) || [];
    const outputsLength = outputsArr.length;
    const globalDiagnosticLength = diagnosticsArr?.length;
    const applyDataLength = applyData?.length;
    
    if (!applyDataLength && !outputsLength && !globalDiagnosticLength && !loading) {
        return (
          <div className="basic-100">
            <AppEmpty customStyle="ciResourcesNotFound" text="The current Terraform apply does not contain any infrastructure changes" />
          </div>
        );
    }
    return (
        <div className="PostApply col g10">
            <ActionsSummary data={parsedDataSummary} isApply />
            {applyDataLength ? 
             <div className="col g10">
                <span className="bold">Resources</span>
                <ApplyResources data={applyData} loading={loading} handleOpenPlanConfig={handleOpenPlanConfig} pageSize={pageSize} setPageSize={setPageSize} setPageNumber={setPageNumber} applyCount={applyCount} setApplySort={setApplySort} taskId={taskId}/>
             </div> : null}
            {outputsLength ? 
                <div className="col g10">
                    <div className="row g5">
                        <span className="bold">Outputs</span>
                        <span className="sub-title">{outputsLength} total</span>
                    </div>
                    <Outputs data={outputsArr} />
                </div> : null
            }
            {globalDiagnosticLength ? 
            <div className="col g10">
                <span className="bold">Global diagnostics</span>
                <div className="col g10">
                    {diagnosticsArr.map((diagnostic) => {
                     return <Diagnostic key={diagnostic?.id} item = {diagnostic} taskId={taskId} />})
                    }
                </div>
            </div>
            : null }
        </div>);
};

export default PostApply;