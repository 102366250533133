import React, { useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import PostPlanChart from "./postPlanChart";

const PostPlanGraph = ({ data, loading, handleOpenConfig, workspaceData = {}, actionFilters, setActionFilters }) => {
  const chart = useMemo(() => {
    return <PostPlanChart data={data} handleOpenConfig={handleOpenConfig} workspaceData={workspaceData} 
    actionFilters={actionFilters} setActionFilters={setActionFilters}/>
  }, [data, actionFilters]);

  if (loading) return <div className="PostPlanGraph center"><Loading /></div>
  // no data tree - empty state
  if (!loading && isEmpty(data?.nodes)) {
    return (
      <div className="PostPlanGraph center">
        <AppEmpty customStyle="ciResourcesNotFound" text="The current Terraform plan does not contain any infrastructure changes" />
      </div>
    );
  }
  const dataLength = data?.nodes?.length || 0 ;
  return <div className={`PostPlanGraph ${dataLength > 0 ? `items-${dataLength}` : ''}`}>
          <div className="PostPlanChart">{chart}</div>
        </div>;
};

export default PostPlanGraph;
