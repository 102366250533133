import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { PROVIDERS } from "../../../consts/general";
import Loading from "../../../shared/loading/loading";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import SingleIntegrationItem from "../singleIntegrationItem/singleIntegrationItem";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";
import EditIntegration from "../editIntegration";
import EditCloudflareIntegration from "../../../containers/singleIntegrations/cloudFlareIntegration/editCloudflareIntegration";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import AppBtn from "../../../shared/appBtn/appBtn";

// delete integrations api functions;
import {
  deleteAwsIntegration,
  deleteSlackIntegration,
  deleteTeamsIntegration,
  deleteWebhookIntegration,
  deleteOpsgenieIntegration,
  deleteTorqIntegration,
  deletePagerIntegration,
  deleteProviderIntegration,
} from "../../../redux/actions/integrationsActions";
import { deleteVcsIntegration } from "../../../redux/actions/vcsActions";
import { deleteK8sIntegration } from "../../../redux/actions/k8sIntegrationActions";
import { deleteAkamaiIntegration } from "../../../redux/actions/akamaiIntegrationActions";
import { deleteDatadogIntegration } from "../../../redux/actions/datadogIntegrationActions";
import { deleteGithubIntegration } from "../../../redux/actions/githubIntegrationActions";
import { deleteOktaIntegration } from "../../../redux/actions/oktaIntegrationActions";
import { deleteGcpProviderIntegration } from "../../../redux/actions/gcpProviderIntegrationActions";
import { deleteGcpIntegration } from "../../../redux/actions/gcpIntegrationActions";
import { deleteNewrelicIntegration } from "../../../redux/actions/newrelicIntegrationActions";
import { deleteTfcIntegration } from "../../../redux/actions/tfcIntegrationActions";
import { deleteConsulIntegration } from "../../../redux/actions/consulIntegrationAction";
import { deleteJiraIntegration } from "../../../redux/actions/jiraIntegrationActions";
import { deletePulumiIntegration } from "../../../redux/actions/pulumiIntegrationActions";
import { deleteCloudFlareIntegration } from "../../../redux/actions/cloudFlareIntegrationActions";
import { deleteAzureIntegration } from "../../../redux/actions/azureIntegrationActions";
import SlackChannelsPopup from "../singleIntegrationItem/notificationHeartbeat/slackChannelsPopup";
import WebexRoomsPopup from "../singleIntegrationItem/notificationHeartbeat/webexRoomsPopup";
import SingleIntegrationTable from "../SingleIntegrationTable/SingleIntegrationTable";
import "./singleIntegrationList.scss";
import { appToast } from "../../../shared/appToast/appToast";
import { triggerVcsScan } from "../../../redux/actions/iacStacksActions";

const SingleIntegrationList = ({
  typeData,
  loading,
  category,
  handleLoadMore,
  integrationData,
  loadingMore,
  reFetchIntegrations,
  handleSetPage,
  currentPage
}) => {
  const { t } = useTranslation("integrations");
  const history = useHistory();
  const dispatch = useDispatch();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedInteg, setSelectedInteg] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  // notification heartbeat
  const [testNotificationLoading, setTestNotificationLoading] = useState({});
  const [selectedSlackApp, setSelectedSlackApp] = useState({});
  const [showSlackChannelsPopup, setShowSlackChannelsPopup] = useState(false);
  const [selectedWebex, setSelectedWebex] = useState({});
  const [showWebexRoomsPopup, setShowWebexRoomsPopup] = useState(false);

  const handleStatus = (obj) => {
    const pathname = `${typeData?.server_name}/discovery-status`;

    history.push({
      pathname: `/integrations/${pathname}`,
      state: {
        ...obj,
        id: obj?._id,
        name: obj?.name,
        header: pathname === "aws" ? obj?.accountNumber : obj?.projectId,
        type: typeData?.server_name,
        percentage: obj?.percentage,
        createdAt: obj?.createdAt,
        projectsStatus: obj?.projectsStatus,
        isPrimary: obj?.isPrimary ?? false,
        accountId: obj?.accountId,
      },
    });
  };

  const handleEdit = (obj) => {

    if (typeData?.server_name === 'gcp' && obj?.isPrimary) {
      history.push("/integrations/gcp-provider-integration/gcp", { ...obj, id: obj?._id })
      return;
    }
    if (typeData?.server_name === 'azurerm') {
      history.push("/integrations/azure-integration", obj)
      return;
    }
    setSelectedInteg(obj);
    setEditModalOpen(true);
  };

  const handleConfirmDelete = (obj) => {
    setSelectedInteg(obj);
    setConfirmDeleteOpen(true);
  };

  const handleCloseConfirmDelete = () => {
    setConfirmDeleteOpen(false);
    setSelectedInteg(null);
  };

  const handleCloseEditInteg = async () => {
    setEditModalOpen(false);
    setSelectedInteg(null);
  };

  const handleDeleteDescription = () => {
    if (typeData?.server_name === "gcp") {
      return t("gcpProviderIntegration.deleteDescription");
    }
    return t("general.defaultDeleteDescription");
  };
  const handleVCSScanning = async (item) => {
    const vcsId = item?.id || item?._id;
    const req = await dispatch(triggerVcsScan({ vcsId }));
    if (!req) {
      appToast("error", "", `Failed to scan ${item.name}`);
    }
    appToast("success", "", `Scan triggered for ${item.name}`);
  }
  const handleDeleteIntegration = async () => {
    setLoadingDelete(true);
    let integType = typeData?.server_name;
    let id = selectedInteg?._id;

    // delete vcs
    if (category === "vcs") {
      integType === "githubvcs" ? "github" : integType;
      await dispatch(deleteVcsIntegration(id, integType));
    }

    switch (integType) {
      case "aws":
        await dispatch(deleteAwsIntegration(id));
        break;
      case "gcs":
        await dispatch(deleteGcpIntegration(id));
        break;
      case "slack":
        await dispatch(deleteSlackIntegration(id));
        break;
      case "teams":
        await dispatch(deleteTeamsIntegration(id));
        break;
      case "k8s":
        await dispatch(deleteK8sIntegration(selectedInteg?.clusterId));
        break;
      case "terraform":
        await dispatch(deleteTfcIntegration(id));
        break;
      case "webhook":
        await dispatch(deleteWebhookIntegration(id));
        break;
      case "consul":
        await dispatch(deleteConsulIntegration(id));
        break;
      case "jira":
        await dispatch(deleteJiraIntegration(id));
        break;
      case "pulumi":
        await dispatch(deletePulumiIntegration(id));
        break;
      case "opsgenie":
        await dispatch(deleteOpsgenieIntegration(id));
        break;
      case "akamai":
        await dispatch(deleteAkamaiIntegration(id));
        break;
      case "datadog":
        await dispatch(deleteDatadogIntegration(id));
        break;
      case "github":
        await dispatch(deleteGithubIntegration(id));
        break;
      case "okta":
        await dispatch(deleteOktaIntegration(id));
        break;
      case "gcp":
        await dispatch(deleteGcpProviderIntegration(id));
        break;
      case "newrelic":
        await dispatch(deleteNewrelicIntegration(id));
        break;
      case "torq":
        await dispatch(deleteTorqIntegration(id));
        break;
      case "cloudflare":
        await dispatch(deleteCloudFlareIntegration(id));
        break;
      // PD notification
      case "pagerduty-notification":
        await dispatch(deletePagerIntegration(id));
        break;
      // PD provider
      case "pagerduty":
        await dispatch(deleteProviderIntegration("pagerduty-provider", id));
        break;
      case "ns1":
        await dispatch(deleteProviderIntegration(PROVIDERS.ns1, id));
        break;
      case "mongodbatlas":
        await dispatch(deleteProviderIntegration(PROVIDERS.mongodbatlas, id));
        break;
      case "azurerm":
        await dispatch(deleteAzureIntegration(id));
        break;
      case "googleChat":
        await dispatch(deleteProviderIntegration(PROVIDERS.googlechat, id));
        break;
      case "webex":
        await dispatch(deleteProviderIntegration(PROVIDERS.webex, id));
        break;
      default:
        if (category !== "vcs") {
          await dispatch(deleteProviderIntegration(integType, id));
        }
        break;
    }
    setLoadingDelete(false);
    setConfirmDeleteOpen(false);
    reFetchIntegrations();
  };

  const handleCloseRefreshEdit = () => {
    setEditModalOpen(false);
    return reFetchIntegrations();
  };

  const editIntegration = (selectedInteg) => {
    switch (typeData?.server_name) {
      case "cloudflare":
        return <EditCloudflareIntegration
          visible={editModalOpen}
          handleClose={handleCloseEditInteg}
          item={selectedInteg}
          handleCloseRefreshEdit={handleCloseRefreshEdit}
        />
      default:
        return <EditIntegration
          visible={editModalOpen}
          handleClose={handleCloseEditInteg}
          item={selectedInteg}
          type={typeData?.server_name}
          category={category}
          handleCloseRefreshEdit={handleCloseRefreshEdit}
        />
    }
  }

  const isTableView =  category == "cloud" && typeData?.server_name != PROVIDERS.gcp;

  if (loading && !isTableView) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  if (!loading && isEmpty(integrationData?.integrations) && !isTableView) {
    return (
      <div className="tab-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  }

  return (
    <>
      {
        isTableView ?
          <SingleIntegrationTable
            totalCount={integrationData?.total}
            category={category}
            providerType={typeData?.server_name}
            loading={loading}
            integrationsData={integrationData}
            onDeleteClick={handleConfirmDelete}
            handleSetPage={handleSetPage}
            currentPage={currentPage}
            handleEdit={handleEdit}
          /> : (
            <div className="SingleIntegrationList col between">
              {
                (
                  <div className="SingleIntegrationList__items">
                    {map(integrationData?.integrations || [], (i) => {
                      return (
                        <SingleIntegrationItem
                          key={i?._id}
                          data={i}
                          typeData={typeData}
                          handleEdit={() => handleEdit(i)}
                          handleDelete={() => handleConfirmDelete(i)}
                          handleStatus={() => handleStatus(i)}
                          category={category}
                          setSelectedSlackApp={(slackApp) => setSelectedSlackApp(slackApp)}
                          setShowSlackChannelsPopup={(show) => setShowSlackChannelsPopup(show)}
                          setSelectedWebex={(webex) => setSelectedWebex(webex)}
                          setShowWebexRoomsPopup={(show) => setShowWebexRoomsPopup(show)}
                          testNotificationLoading={testNotificationLoading}
                          setTestNotificationLoading={(loading) => setTestNotificationLoading(loading)}
                          handleVCSScanning={handleVCSScanning}
                        />
                      );
                    })}
                  </div>
                )
              }

              {integrationData?.total > integrationData?.integrations?.length && !isTableView && (
                <div className="SingleIntegrationList__footer center col g10">
                  <AppBtn
                    text="Load More"
                    onClick={handleLoadMore}
                    loading={loadingMore}
                  />
                  <span className="bold">{`${integrationData?.integrations?.length} / ${integrationData?.total}`}</span>
                </div>
              )}
            </div>
          )
      }
      <ConfirmationModal
        visible={confirmDeleteOpen}
        handleClose={handleCloseConfirmDelete}
        title={
          !isEmpty(selectedInteg)
            ? `Delete ${selectedInteg?.name} integration?`
            : "Delete?"
        }
        description={handleDeleteDescription()}
        loadingConfirm={loadingDelete}
        onConfirm={handleDeleteIntegration}
      />

      {editIntegration(selectedInteg)}

      <SlackChannelsPopup
        visible={showSlackChannelsPopup}
        selectedSlackApp={selectedSlackApp}
        setSelectedSlackApp={(slackApp) => setSelectedSlackApp(slackApp)}
        setShowSlackChannelsPopup={(show) => setShowSlackChannelsPopup(show)}
        testNotificationLoading={testNotificationLoading}
        setTestNotificationLoading={(loading) => setTestNotificationLoading(loading)}
      />
      <WebexRoomsPopup
        visible={showWebexRoomsPopup}
        selectedWebex={selectedWebex}
        setSelectedWebex={(webex) => setSelectedWebex(webex)}
        setShowWebexRoomsPopup={(show) => setShowWebexRoomsPopup(show)}
        testNotificationLoading={testNotificationLoading}
        setTestNotificationLoading={(loading) => setTestNotificationLoading(loading)}
      />
    </>
  );
};

export default SingleIntegrationList;
