import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import isEmpty from "lodash/isEmpty";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isString from "lodash/isString";
import map from "lodash/map";
import keys from "lodash/keys";
import groupBy from "lodash/groupBy";
import flatten from "lodash/flatten";
import {
  formatIacStatus,
  providerNameFormatter,
} from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModulesTab from "./modulesTab/modulesTab";
import CreateModuleTab from "./createModuleTab/createModuleTab";

// components
import Loading from "../../../shared/loading/loading";
import AppEmpty from "../../../shared/appEmpty/appEmpty";

import { providers } from "../../../utils/providers";
import LongLoader from "../../../shared/longLoader/longLoader";
import { useTranslation } from "react-i18next";
import {CODIFY_TYPES, PROVIDERS} from "../../../consts/general";

import "./smartCodifyTab.scss";
import { IAC_TYPES } from "../../../consts/general";
import { areAllRegionsSame } from "../../../utils/helpers";

import cloudMigration from "../../../Images/clouds/cloud_migration.svg"

import awsGrayIcon from "../../../Images/clouds/aws_gray.svg";
import awsWhiteIcon from "../../../Images/clouds/aws_white.svg";
import azureGrayIcon from "../../../Images/clouds/azure_gray.svg";
import azureWhiteIcon from "../../../Images/clouds/azure_white.svg";
import googleGrayIcon from "../../../Images/clouds/google_gray.svg";
import googleWhiteIcon from "../../../Images/clouds/google_white.svg";
import {ACTIVE_CODIFICATION_TYPES} from "../codifyDrawer/codifyHelpers";

const UNSUPPORT_MENU_CODIFY_TYPES = [CODIFY_TYPES.helm];
const MAX_STRING_LENGTH_TO_RENDER = 50000;
const OverweightDataDisplay = <AppEmpty text={
    <div>
      <span className="opac">The generated code exceeds the display limit</span>
      <div className="centered"><span className="opac">Click the </span>Download<span className="opac"> button to review it.</span></div>
    </div>}
customStyle="custom" />

const SmartCodifyTab = ({
  codifyType,
  isFetchingIaC,
  iacData,
  activeType = "naive",
  handleSetActiveTab,
  selectedResources,
  hideNonNaiveTypes,
  isCodifyAI = false,
  isImportBlocksClicked,
  handleOverweightData = () => null,
}) => {
  const { t } = useTranslation("inventory", { keyPrefix: "codifyDrawer" });
  const isTfCdkCodifyType = codifyType === CODIFY_TYPES.tfcdk; //temporary until we will support tfcdk all tabs

  const providerType = selectedResources[0]?.provider;
  const isSameRegions = areAllRegionsSame(selectedResources);
  const isRevisionOrDeletedCodify =
    !!selectedResources[0].revisionId ||
    selectedResources[0].state === "deleted";
  const prefix = `Created using ${providerNameFormatter(
    providerType
  )} Provider Version ${providers[providerType]?.providerVersion}\n# `;
  const descriptions = {
    naive:
      providerType === IAC_TYPES.k8s
        ? `#${prefix}This mode shows the Terraform code for the chosen resource in its most straightforward format.\n\n`
        : "",
    smart:
      providerType === IAC_TYPES.k8s
        ? `#${prefix}This mode is the same as Basic but with dependencies connected with a data source \n# instead of an explicit resource id where applicable.\n\n`
        : "",
    dependencies:
      providerType === IAC_TYPES.k8s
        ? `${prefix}This mode codifies the chosen resource with all of its unmanaged dependencies (that exist in the inventory). \n# Codified dependencies will be connected using a data source.\n\n`
        : "",
    fullDependencies:
      providerType === IAC_TYPES.k8s
        ? `${prefix}This mode is excellent for building modules or reusable code and codifies all dependencies (that exist in the inventory), \n# even if some are already codified.\n\n`
        : "",
    modules: prefix.replace("#", ""),
    resourceGroup: "",
  };
  const basicArr = [
    { value:  ACTIVE_CODIFICATION_TYPES.naive, text: "Explicit Values", isBeta: isCodifyAI },
    {
      value: ACTIVE_CODIFICATION_TYPES.smart,
      text: "Data Sources",
      disabled: isRevisionOrDeletedCodify,
    },
  ];

  const advancedArr = [
    {
      value: ACTIVE_CODIFICATION_TYPES.dependencies,
      text: "Codify Unmanaged Dependencies",
      disabled: isRevisionOrDeletedCodify,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.fullDependencies,
      text: "Codify All Dependencies",
      disabled: isRevisionOrDeletedCodify,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.resourceGroup,
      text: "Codify Resource Group",
      hide: providerType !== PROVIDERS.azurerm ,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.modules,
      text: "Module Call",
      isBeta: true,
      disabled: isRevisionOrDeletedCodify,
      unvisible: isTfCdkCodifyType,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.createModule,
      text: "Create Module",
      isBeta: true,
      disabled: isRevisionOrDeletedCodify || isTfCdkCodifyType,
      unvisible: isTfCdkCodifyType,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.createModuleDependencies,
      text: "Create Module With Dependencies",
      isBeta: true,
      disabled: isRevisionOrDeletedCodify || isTfCdkCodifyType,
      unvisible: isTfCdkCodifyType,
    },
  ];

  const cloudMigrationArr = [
    {
      value: ACTIVE_CODIFICATION_TYPES.awsMigration,
      text: "AWS",
      disabled: isRevisionOrDeletedCodify || providerType === PROVIDERS.aws,
      hide: providerType === PROVIDERS.aws,
      icon: awsGrayIcon,
      selectedIcon: awsWhiteIcon,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.azureMigration,
      text: "Azure",
      disabled: isRevisionOrDeletedCodify || providerType === PROVIDERS.azurerm,
      hide: providerType === PROVIDERS.azurerm,
      icon: azureGrayIcon,
      selectedIcon: azureWhiteIcon,
    },
    {
      value: ACTIVE_CODIFICATION_TYPES.googleMigration,
      text: "Google Cloud",
      disabled: isRevisionOrDeletedCodify || providerType === PROVIDERS.gcp,
      hide: providerType === PROVIDERS.gcp,
      icon: googleGrayIcon,
      selectedIcon: googleWhiteIcon,
    },
  ];

  const importBlocksComment = `\n# Terraform v 1.5.0, has made importing resources into Terraform much simpler.\n# Rather than using the terraform import command, you can now simply add the\n# block to your Terraform code and actually import resources during an apply.\n\n`;

  const canMigrate = !isEmpty(selectedResources) && includes([PROVIDERS.aws, PROVIDERS.gcp, PROVIDERS.azurerm], selectedResources[0]?.provider);

  const renderMenu = (arr) => {
    return map(arr, (item) => {
      if (item?.unvisible) return;
      const disabledSmart =
        (isEmpty(selectedResources) && item?.value !== "naive") ||
        isFetchingIaC ||
        item.disabled;
      const doNotRender =
        (hideNonNaiveTypes && item?.value !== "naive") ||
        ((item?.value === "modules" || item?.value === "createModule") &&
          codifyType === "pulumi") || item?.hide;
      if (!doNotRender) {
        return (
          <div
            onClick={() => {
              if (disabledSmart) {
                return;
              }
              handleSetActiveTab(item?.value);
            }}
            key={item?.value}
            className={`SmartCodifyTab__menu-item row ${
              activeType === item?.value ? "active" : ""
            }
            ${disabledSmart ? "disabled" : ""}`}
          >
            <span className="row" style={{ gap: "10px" }}>
              {item?.icon && (
                <img
                  className="SmartCodifyTab__menu-item-icon"
                  src={activeType === item?.value ? item?.selectedIcon || item?.icon : item?.icon}
                  alt={item?.text}
                />
              )}
              {item?.isBeta ? (
                <span className="row" style={{ gap: "10px" }}>
                  {item?.text}
                  <span className="purple-flag-outline">Preview</span>
                </span>
              ) : (
                item?.text
              )}
            </span>
          </div>
        );
      }
    });
  };
  
  const renderOverweightDataDisplay = () => {
    return (
      <div className="full-page center">
        {OverweightDataDisplay}
      </div>
    )
  };

  const renderCode = () => {
    if (!isSameRegions && codifyType === CODIFY_TYPES.cloudformation) {
      return (
        <div className="full-page center">
          <AppEmpty text="CloudFormation template doesn’t support assets from multiple regions" customStyle="code" />
        </div>
      );
    }
    if (isFetchingIaC) {
      const msg1 = t("loading.msg1");
      const msg2 = t("loading.msg2");
      return (
        <div className="full-page center bold g10 SmartCodifyTab__loading">
          <LongLoader
            msg1={msg1}
            msg2={msg2}
            loading={isFetchingIaC}
            customLoader={<Loading />}
            duration={15000}
          />
        </div>
      );
    }

    if (
      !isFetchingIaC &&
      (isEmpty(iacData) || (isArray(iacData) && isEmpty(iacData[0])))
    ) {
      return (
        <div className="full-page center">
          <AppEmpty text="No data" customStyle="code" />
        </div>
      );
    }

    switch (activeType) {
      case ACTIVE_CODIFICATION_TYPES.naive:
        const isMultipleFilesCodify = isArray(iacData) && iacData.length > 1;
        if (isCodifyAI) {
          return isMultipleFilesCodify ? (
            <CreateModuleTab codifyType={activeType} iacData={iacData} isMultiCodify handleOverweightData={handleOverweightData} />
          ) : (
            renderSyntaxHighlight(iacData[0]?.content)
          );
        }
        const hasHclAsString =
          isArray(iacData) && !isEmpty(iacData[0]?.HclAsString);
        const code = `${descriptions[activeType]}${
          hasHclAsString ? getAllCode() : iacData
        }`;
        return renderSyntaxHighlight(code);
      case ACTIVE_CODIFICATION_TYPES.smart:
        return renderSources();
      case ACTIVE_CODIFICATION_TYPES.dependencies:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.fullDependencies:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.modules:
        return (
          <ModulesTab
            description={descriptions?.modules}
            prefix={providerType === IAC_TYPES.k8s ? prefix : ""}
            selectedResources={selectedResources}
            isImportBlocksClicked={isImportBlocksClicked}
            importBlocksComment={importBlocksComment}
            handleOverweightData={handleOverweightData}
            OverweightDataDisplay={OverweightDataDisplay}
          />
        );
      case ACTIVE_CODIFICATION_TYPES.createModule:
      case ACTIVE_CODIFICATION_TYPES.createModuleDependencies:
        return <CreateModuleTab codifyType={activeType} selectedResources={selectedResources} handleOverweightData={handleOverweightData} OverweightDataDisplay={OverweightDataDisplay}/>;
      case ACTIVE_CODIFICATION_TYPES.resourceGroup:
        return renderWrappers();
      case ACTIVE_CODIFICATION_TYPES.awsMigration:
      case ACTIVE_CODIFICATION_TYPES.azureMigration:
      case ACTIVE_CODIFICATION_TYPES.googleMigration:
        return renderSyntaxHighlight(iacData[0]?.content)

      default:
        return (
          <div className="full-page center">
            <AppEmpty text="No data" customStyle="code" />
          </div>
        );
    }
  };

  const getAllCode = () => {
    let hclArray = map(iacData, (source) => source?.HclAsString);
    const returnedString = `${
      isImportBlocksClicked ? importBlocksComment : ""
    }${flatten(hclArray).join("")}`;
    return returnedString;
  };

  const getResourceName = (code) => {
    if (isString(code)) {
      let words = code?.split(" ");
      let name = words[2];
      name = name?.replaceAll(`"`, "");
      return name;
    }
    return "";
  };

  const renderSyntaxHighlight = (code = '') => {
    if (code?.length > MAX_STRING_LENGTH_TO_RENDER){
      handleOverweightData(true);
      return renderOverweightDataDisplay();
    }
    if (!isEmpty(code) && !isArray(code)) {
      return (
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {code}
        </SyntaxHighlighter>
      );
    }
    return (
      <div className="full-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  const renderWrappers = () => {
    if (!isEmpty(iacData) && isArray(iacData) && !isEmpty(iacData[0])) {
      const assetTypeGroups = groupBy(iacData, "AssetType");
      const description = `${descriptions[activeType]}`;
      const code = map(keys(assetTypeGroups), (assetType) => {
        let typeTitle = `# ${assetType}\n`;
        const inner = map(assetTypeGroups[assetType], (source) => {
          let name = `# ${getResourceName(
            source?.HclAsString
          )} [${formatIacStatus(source?.State)}]\n\n`;
          let innerCode = source?.HclAsString;
          const output = typeTitle + name + innerCode;
          return output;
        });
        const x = inner.join("");
        return x;
      });
      let join = `${
        isImportBlocksClicked ? importBlocksComment : ""
      }${code.join("")};`;
      return renderSyntaxHighlight(description + join);
    }
    return null;
  };

  const renderSources = () => {
    if (!isEmpty(iacData) && isArray(iacData) && !isEmpty(iacData[0])) {
      const description = `${descriptions[activeType]}`;
      const output = map(iacData, (source) => {
        let codeBlock = `# ${source?.AssetType} \n# ${getResourceName(
          source?.HclAsString
        )} [${formatIacStatus(source?.State)}] \n\n${source?.HclAsString}`;
        return codeBlock;
      });
      const joined = `${
        isImportBlocksClicked ? importBlocksComment : ""
      }${output.join("")}`;
      return renderSyntaxHighlight(description + joined);
    }
    return;
  };
  const isCodifyTypeSupportMenu =
    !UNSUPPORT_MENU_CODIFY_TYPES.includes(codifyType);
  return (
    <div
      className={`SmartCodifyTab ${isCodifyTypeSupportMenu ? "withMenu" : ""} `}
    >
      {isCodifyTypeSupportMenu && (
        <div className="SmartCodifyTab__menu">
          <div className="SmartCodifyTab__menu-seperator row">
            <FontAwesomeIcon icon="code" />
            Basic
          </div>
          {renderMenu(basicArr)}
          {!hideNonNaiveTypes && (
            <div className="SmartCodifyTab__menu-seperator row">
              <FontAwesomeIcon icon="sitemap" />
              Advanced
            </div>
          )}
          {renderMenu(advancedArr)}
          {canMigrate && codifyType === CODIFY_TYPES.terraform && (
              <>
                <div className="SmartCodifyTab__menu-seperator row">
                  <img className="CodifyDrawer__tab-icon" src={cloudMigration} alt="Cloud Migration" />
                  Cloud Migration
                </div>
                {renderMenu(cloudMigrationArr)}
              </>
          )}
        </div>
      )}
      <div className="SmartCodifyTab__code">{renderCode()}</div>
    </div>
  );
};

export default SmartCodifyTab;
