import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import filter from "lodash/filter";
import { Tooltip } from "antd";
import { toggleSidebar } from "../../../redux/actions/userPreferencesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generalEvents } from '../../../utils/amplitudeEvents';
import { sendEvent } from '../../../utils/amplitude';
import "./linkSubMenu.scss";

const LinkSubMenu = ({ icon, text, children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [childrenActive, setChildrenActive] = useState(false);

  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );

  useEffect(() => {
    if (!isEmpty(children)) {
      const childrenRoutes = map(children || [], (routh) => routh.url);
      const matchRoute = filter(childrenRoutes, (item) =>
        location?.pathname.includes(item)
      );
      return setChildrenActive(!isEmpty(matchRoute));
    }
  }, [location?.pathname, children]);

  useEffect(() => {
    if (open && sidebarClosed) {
      setOpen(false);
    }
  }, [sidebarClosed]);

  const handleToggleMenu = () => {
    setOpen(!open);
    if (sidebarClosed && !open) {
      dispatch(toggleSidebar(sidebarClosed));
    }
  };

  const renderChildren = () => {
    return map(children, (link) => {
      return (
        <NavLink
          to={link.url}
          key={link.url + link.text}
          className={`NavLinks__item ${sidebarClosed && "centered"}`}
          activeClassName="activeNavLink"
          exact={link.exact}
          onClick={() => sendEvent(generalEvents.pageClick, { page: link?.text })}
        >
          {!sidebarClosed && (
            <div className="row g10">
              <span className="LinkSubMenu__line"/>
              <span className="NavLinks__item-text">{link.text}</span>
              {link.new && <span className="NavLinks__item-new">NEW</span>}
            </div>
          )}
        </NavLink>
      );
    });
  };

  return (
    <div className="LinkSubMenu">
      <div
        className={`LinkSubMenu__header row between ${open ? "active" : null} ${
          sidebarClosed ? "headerIcon" : null
        } ${childrenActive ? "childActive" : null}`}
        onClick={handleToggleMenu}
      >
        <div className={`LinkSubMenu__header-title row`}>
        <Tooltip title={sidebarClosed ? text : ''} placement="right">
          <span className="NavLinks__item-icon">{icon}</span>
        </Tooltip>
          {!sidebarClosed && <span>{text}</span>}
        </div>
        {!sidebarClosed && (
          <button className="LinkSubMenu__header-btn">
            <FontAwesomeIcon icon={open ? "minus" : "plus"} />
          </button>
        )}
      </div>
      <div className={`LinkSubMenu__list col ${open ? "listOpen" : null}`}>
        {open && renderChildren()}
      </div>
    </div>
  );
};

export default LinkSubMenu;
