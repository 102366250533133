import { numberWithCommas } from "../../../../utils/formatting";

export const getSize = (label) => {
    let size;
    switch (label) {
      case '1%':
        size = "one";
        break;
      case '2%':
        size = "two";
        break;
      case '3%':
        size = "three";
        break;
      case '4%':
        size = "four";
        break;
      case '98%':
      case '99%':
      case '100%':
        size = "hundred";
        break;
      default:
        break;
    }
    return size;
  }

  export const getTotalHover = (affected, total) => `${numberWithCommas(affected)} / ${numberWithCommas(total)}`;

  export const getTheme = (isThemeDark) => isThemeDark ? "dark" : "light";

  export const checkIfTotalGreaterThanAffected = (total, affected) => total >= affected;

  export const getPercentage = (affected, total) => affected > 0 && total > 0 ? (affected / total) * 100 : 0;

  export const getLabel = (percentage) => {
    if (typeof percentage !== 'number' || isNaN(percentage)) {
      return ''
    }
  
    return `${Math.floor(percentage)}%`;
  };