import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { v4 as uuidv4 } from "uuid";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { getListGuardrails } from "../../../redux/actions/workflowsGuardrailsActions";
import { ReactComponent as CostIcon } from '../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourcesIcon } from '../../../Images/ci/guardrails/resources.svg';
import { ReactComponent as TagsIcon } from "../../../Images/general_icons/tags.svg";

const IconComponent = {
    cost: CostIcon,
    policy: PolicyIcon,
    resource: ResourcesIcon,
    tags: TagsIcon,
}

const GuardrailsScope = ({ form, initialValues = {} }) => {
    const [treeSearch, setTreeSearch] = useState("");
    const [aggsLoading, setAggsLoading] = useState(false);
    const guardrails = useSelector((state) => state.ciWorkflowsReducer.guardrailsNotificationsDetails);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(guardrails)) {
            fetchGuardrailsList();   
        }
    }, []);
    useEffect(() => {
        if (!isEmpty(initialValues) && !isEmpty(guardrails)) {
            form.setFieldsValue({ guardrailRuleId: initialValues?.scope?.guardrailRuleId || ""})
        }
    }, [guardrails]);

    const fetchGuardrailsList = async() => {
        setAggsLoading(true);
        await dispatch(getListGuardrails({}, "", {}, 0, 0, ["name", "id", "type"]));
        setAggsLoading(false);
    }

    const onChangeField = (field, value) => {
        form.setFieldsValue({ [field]: value });
    };
    const renderIcon = (type) => {
        const Icon = IconComponent[type];
        return Icon ? <Icon className="CreateNotification-guardrailsIcon"/> : null;
    }
    return (
        <div className="col g20">
            <div className="row g40">
                <Form.Item label="Guardrail rule" name="guardrailRuleId" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                     <Select placeholder="Select Guardrail" onChange={(val) => onChangeField("guardrailName", val)} disabled={aggsLoading} loading={aggsLoading}>
                        {guardrails.map((guardrail = {}) => (
                        <Select.Option key={uuidv4()} value={guardrail.id}>
                         <div className="row g8">
                            {renderIcon(guardrail.type)}
                            {guardrail.name}
                         </div>
                        </Select.Option>))}
                    </Select>
                </Form.Item>
            </div>
        </div>
    )
};

export default GuardrailsScope;