import moment from 'moment';
import {
    GET_ASSET_EVENTS,
    GET_ASSET_EVENTS_AGGS,
    GET_ASSET_EVENTS_COUNT,
    GET_ASSET_EVENTS_GRAPH_DATE,
    RESET_ASSET_EVENTS,
} from '../actions/assetEventsActions';

const initialState = {
    events: [],
    eventNamesFilterOptions: [],
    eventTimesFilterOptions: [],
    count: 0,
    total: 0,
    graphData: [],
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_ASSET_EVENTS:
        case GET_ASSET_EVENTS_AGGS:
            return { ...state, ...payload };
        case GET_ASSET_EVENTS_COUNT:
            return { ...state, total: payload?.total }
        case RESET_ASSET_EVENTS: {
            return { ...state, events: [], total: 0, count: 0, eventNamesFilterOptions: [], eventTimesFilterOptions: [], graphData: [] }
        }
        case GET_ASSET_EVENTS_GRAPH_DATE: {
            const { byTime, graphData } = payload;
            const formattedGraphData = graphData.map(({ time: timestamp, ...rest }) => {
                let formattedTime = '';
                if (byTime === 'lastYear') {
                    const month = moment(timestamp).format('MMM YYYY')
                    formattedTime = month
                }
                else if (byTime === 'lastWeek' || byTime === 'lastMonth') {
                    const day = moment(timestamp).format('MMM DD')
                    formattedTime = day
                }
                else {
                    const hour = moment(timestamp).hour()
                    formattedTime = `${hour}:00`
                }
                return { time: formattedTime, ...rest }
            })
            return { ...state, graphData: formattedGraphData }
        }
        default:
            return state;
    }
}
