import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Tooltip } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import moment from 'moment';
import AppToggle from '../../../shared/appToggle/appToggle';
import { ReactComponent as CostIcon } from '../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourcesIcon } from '../../../Images/ci/guardrails/resources.svg';
import { ReactComponent as ReposIcon } from '../../../Images/ci/guardrails/repo.svg';
import { ReactComponent as TagIcon } from '../../../Images/ci/guardrails/tag.svg';
import { ReactComponent as LabelsIcon } from "../../../Images/general_icons/tags.svg";
import { ReactComponent as TimeIcon } from "../../../Images/general_icons/Time_icon.svg";
import { ReactComponent as UserIcon } from "../../../Images/general_icons/user_icon.svg";
import { ReactComponent as WorkflowIcon } from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { ReactComponent as GuardrailsIcon } from '../../../Images/general_icons/sidebar/guardrails.svg';


import GuardrailCriteriaPopover from './guardrailCriteriaPopover';
import { capitalizeFirst } from '../../../utils/formatting';
import IconBtn from '../../../shared/iconBtn/iconBtn';
import { checkIfGuardrailScopeStatementNotEmpty } from '../../../utils/workflowsHelper';
import GuardrailScope from './guardrailScope';
import ConfirmationModal from '../../../shared/confirmationModal/confirmationModal';
import { useTranslation } from 'react-i18next';
import { deleteGuardrailRule, setGuardrailRule } from '../../../redux/actions/workflowsGuardrailsActions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { appToast } from '../../../shared/appToast/appToast';

import { GUARDRAILS_TYPES, formatCostAmount } from '../guardrailsModal/guardrailsHelper';
import './singleGuardrail.scss';

const DATE_FORMAT = "MMM DD YYYY, HH:mm"
const IconComponent = {
    cost: CostIcon,
    policy: PolicyIcon,
    resource: ResourcesIcon,
    tag: TagIcon,
}

const SingleGuardrail = ({ item = {}, onEditGuardrail }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [toggleLoading, setLoadingEnable] = useState(false);

    const { t } = useTranslation("ci-pipeline", { keyPrefix: "guardrails" });

    const isViewer = useSelector((state) => state.profilesReducer.isViewer);
    const { id, name = "", criteria = {}, scope = {}, isEnabled = false, type = "", notificationId ="", createdBy = "", createdAt = "" } = item;
    const Icon = IconComponent[type];
    const { resource = {}, cost = {}, policy = {}, tag = {} } = criteria;
    const { branches = {}, labels = {}, repositories = {}, workspaces = {}} = scope;

    const isNotificationEnabled = notificationId;
    
    const dispatch = useDispatch();
    const onDeleteGuardrail = async() => {
        setLoadingDelete(true);
        const resOk = await dispatch(deleteGuardrailRule(id));
        appToast(resOk ? "success": "error",  "" ,resOk ? t("delete.success") : t("delete.error"));
        setLoadingDelete(false);
        return setShowDeleteModal(false);
    }
    
    const renderCriteriaByType = useCallback(() => {
        const Icon = IconComponent[type];
        const isCost = type === GUARDRAILS_TYPES.cost;
        const isResource = type === GUARDRAILS_TYPES.resource;
        const isTag =  type === GUARDRAILS_TYPES.tag;
        const text = isCost ? (isNumber(cost.thresholdAmount) ? formatCostAmount(cost.thresholdAmount) : `${cost.thresholdPercentage || 0}%`) : capitalizeFirst(type);
        return ( 
        <Popover
            key={id}
            overlayClassName="FiltersRowBadges__popover"
            content={!isCost && <GuardrailCriteriaPopover isResource={isResource} isTag={isTag} data={isResource ? resource : isTag ? tag : policy} />}
            zIndex={isCost ? -1 : 1070}
            placement="topLeft">
                <div className={`basic-filter-badge row g5 SingleGuardrail__criteria ${type}`}>
                    <Icon className="SingleGuardrail__criteria-icon"/>
                    <span>{text}</span>
                </div>
        </Popover>)
    }, [criteria]);

    const renderScope = useCallback(() => {
        const isBranches = checkIfGuardrailScopeStatementNotEmpty(branches);
        const isLabels = checkIfGuardrailScopeStatementNotEmpty(labels);
        const isRepos = checkIfGuardrailScopeStatementNotEmpty(repositories);
        const isWorkspaces = checkIfGuardrailScopeStatementNotEmpty(workspaces);
        if (!isBranches && !isLabels && !isRepos && !isWorkspaces){
            return  <div className="basic-filter-badge row g5 SingleGuardrail__criteria default-cursor">
                        <GuardrailsIcon />
                        <span>All</span>
                     </div>
        }
        
        return <>
            {isBranches && <GuardrailScope statementTitle="Branches" scope={branches} customIcon={<FontAwesomeIcon icon="code-branch" />}/>}
            {isLabels && <GuardrailScope statementTitle="Labels" scope={labels} customIcon={<LabelsIcon />}/>}
            {isRepos && <GuardrailScope statementTitle="Repos" scope={repositories} customIcon={<ReposIcon />}/>}
            {isWorkspaces && <GuardrailScope statementTitle="Workspaces" scope={workspaces} customIcon={<WorkflowIcon />}/>}
        </>
    }, [scope]);

    const handleToggle = async () => {
        setLoadingEnable(true);
        const updatedItem = { ...item, isEnabled: !isEnabled };
        await dispatch(setGuardrailRule(updatedItem, true));
        setLoadingEnable(false);
    };

    const handleOnNotificationClick = () => isNotificationEnabled ? null : onEditGuardrail(item, false, true);

    return (
        <div className="SingleGuardrail basic-card col g15">
                <div className="SingleGuardrail__header row between">
                    <div className="col g10">
                        <div className="SingleGuardrail__header-title row g8">
                            <span className="bold">{name}</span>
                            <div className="SingleGuardrail-divider"/>
                            {Icon && <Tooltip title={capitalizeFirst(type)}>
                                         <Icon className={`SingleGuardrail__header-icon ${type} pointer`} />
                                      </Tooltip>}
                        </div>
                        <div className="SingleGuardrail__header-time row g8">
                            <div className="row g5">
                                <TimeIcon className="SingleGuardrail-label"/>
                                <span className="SingleGuardrail-label">{moment(createdAt || new Date()).format(DATE_FORMAT)}</span>
                            </div>
                            <div className="SingleGuardrail-divider gray"/>
                            <div className="row g5">
                                <UserIcon className="SingleGuardrail-label"/>
                                <span className="SingleGuardrail-label">{createdBy}</span>
                            </div>
                        </div>
                    </div>

                    <div className="SingleGuardrail__header-actions row g5">
                        <AppToggle
                            checked={isEnabled}
                            toggleChecked={handleToggle}
                            loading={toggleLoading}
                            disabled={isViewer}
                        />
                        <div className='SingleGuardrail-divider lighter'/>
                        <FontAwesomeIcon icon="bell" className={`SingleGuardrail__header-actions-notifIcon ${isNotificationEnabled ? "purple-text default-cursor" :"disabled pointer"}`} onClick={handleOnNotificationClick}/>
                        <IconBtn
                            type="delete"
                            onClick={() => setShowDeleteModal(true)}
                            disabled={isViewer}
                            loading={loadingDelete}
                            colorClass="red"
                        />
                        <IconBtn
                            type="edit"
                            onClick={() => onEditGuardrail(item)}
                            disabled={isViewer}
                            colorClass="purple"
                        />
                    </div>
                </div>
                <div className="SingleGuardrail-divider horizonal"/>
                <div className="SingleGuardrail__header-description row g15">
                    {!isEmpty(criteria) &&
                    <div className="row g8">
                        <span className="SingleGuardrail-label">{t("criteria")}:</span>
                        {renderCriteriaByType()}
                    </div> }
                    {!isEmpty(scope) && 
                    <div className="row g8">
                        <span className="SingleGuardrail-label">{t("modal.sections.scope")}:</span>
                        {renderScope()}
                    </div>}
                </div>
                <ConfirmationModal
                    visible={showDeleteModal}
                    handleClose={() => setShowDeleteModal(false)}
                    title={t("delete.title", { name })}
                    description={t("delete.description")}
                    loadingConfirm={loadingDelete}
                    onConfirm={onDeleteGuardrail}
                />
        </div>)
};
export default SingleGuardrail;