import React from "react";

import "./cardBox.scss";

const CardBox = ({
  title,
  onClick,
  comingSoon,
  recommended,
  img,
  active,
  activeOnHover,
}) => {
  return (
    <div
      className={`CardBox center col ${comingSoon && "comingSoon"} ${
        active && "active"
      } ${activeOnHover && "activeOnHover"}`}
      onClick={comingSoon ? null : onClick} // block the event if item is coming soon
    >
      {comingSoon && (
        <span className="CardBox__comingSoonText bold">COMING SOON</span>
      )}
      {recommended && (
        <span className="CardBox__recommended mix-purple-green-flag bold">Recommended</span>
      )}
      {img && <img src={img} alt="picture" />}
      <span className="CardBox__title">{title}</span>
    </div>
  );
};

export default CardBox;
