import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Loading from "../../../shared/loading/loading";
import {
  getModulesBaseAggs,
  queryModules,
} from "../../../redux/actions/iacStacksActions";
import { getAllVcs } from "../../../redux/actions/globalAppActions";
import {iacProviders} from "../../../utils/icons";
import { formatUTCDateTime, renderEllipsis } from "../../../utils/formatting";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import { ReactComponent as IacStack } from "../../../Images/general_icons/iac_stack.svg";
import {
  every,
  isEmpty,
  map,
  values,
} from "lodash";
import { getVulnerabilityData } from "../../../utils/helpers";
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import VulnerabilityLine from "../../../shared/vulnerabilityLine/vulnerabilityLine";
import FiltersRowBadges from "../filtersRowBadges/filtersRowBadges";
import Vulnerabilities from "./vulnerabilities";
import "./modulesTable.scss";
import EmptyIac from "../emptyIac/emptyIac";
import { moduleOriginTypes } from "../../../utils/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import { formatUnsyncedStackName } from "../iacStacksHelper";
import { IAC_TYPES } from "../../../consts/general";


const ModulesTable = ({
  setFilters,
  setSelectedCategory,
  handleRemoveBadgeFilter,
  filersRowComponent,
  setResetFilters,
  searchVal
}) => {
  const apiRef = useRef();
  const modules = useSelector(
    (state) => state.iacStacksReducer.modules?.modules
  );
  const totalModules = useSelector(
    (state) => state.iacStacksReducer.modules?.total
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const screenFilters = useSelector(
    (state) => state.iacStacksReducer.modulesFilters
  );

  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [modulesTableData, setModulesTableData] = useState([]);
  const [tablePage, setTablePage] = useState(1);
  const [tablePageSize, setTablePageSize] = useState(50);
  const [tableSorting, setTableSorting] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [vulnerabilitiesModalOpen, setVulnerabilitiesModalOpen] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!initLoading) {
      getModulesData();
      setResetFilters(false);
    }
    getNewAggs();
  }, [tablePage, tablePageSize, tableSorting, searchVal]);

  useEffect(() => {
    if (!initLoading) {
      getModulesData(false, true);
      setResetFilters(false);
    }
    getNewAggs();
  }, [screenFilters]);

  useEffect(() => {
    if (!isEmpty(modules)) {
      formatTableData();
    }
  }, [modules]);

  useEffect(() => {
    fetchInitData();
  }, []);

  const fetchInitData = async () => {
    setInitLoading(true);
    await getModulesData(true);
    setInitLoading(false);
  };

  const getNewAggs = async () => {
    await dispatch(getModulesBaseAggs(screenFilters, ""));
  };

  const formatTableData = () => {
    const formattedModules = map(modules, (mod) => {
      return { ...mod };
    });
    setModulesTableData(formattedModules);
  };

  const getModulesData = async (isInit, resetPage) => {
    setLoading(true);
    if (isInit) {
      await dispatch(getAllVcs());
    }
    await dispatch(
      queryModules(tablePageSize, searchVal, screenFilters, resetPage ? 1 : tablePage, tableSorting)
    );
    formatTableData();
    setLoading(false);
  };

  const valueComparator = (a, b) => {
    if ((a[0] || "") < (b[0] || "")) {
      return -1;
    }
    return 1;
  };

  const dateComparator = (a, b) => {
    if (moment(new Date(a[0])).isBefore(moment(new Date(b[0])))) {
      return -1;
    }
    return 1;
  };

  const handleOpenVulnerabilies = (row) => {
    setSelectedRow(row);
    setVulnerabilitiesModalOpen(true);
  };
  const handleSetIacStacksFilters = async (filters) => {
    await dispatch(setFilters(filters));
    setSelectedCategory("appliedStacks");
  }
  const columns = [
    {
      headerName: " ",
      field: "vulnerabilities",
      width: 50,
      type: "boolean",
      filterable: false,
      sortable: false,
      resizable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        if (!every(values(params?.value), (val) => val === 0)) {
          return (
            <div
              className="table-plus-btn center"
              onClick={() => handleOpenVulnerabilies(params?.row)}
            >
              +
            </div>
          );
        }
        return <div />;
      },
    },
    {
      headerName: "Name",
      field: "moduleFolderPath",
      flex: 1,
      filterable: false,
      sortComparator: valueComparator,
      renderCell: (params) => {
        apiRef.current = params?.api;
        return (
          <div className="ModulesTable__table-nameCell row">
            <img alt="module" src={iacProviders("terraform", themeDark)} />
            {params?.row?.moduleVcsPath ? (
              <a
                href={params?.row?.moduleVcsPath}
                target="_blank"
                rel="noreferrer"
              >
                {params?.row?.moduleFolderPath}
              </a>
            ) : (
              params?.row?.moduleFolderPath
            )}
          </div>
        );
      },
    },
    {
      headerName: "Version",
      field: "version",
      flex: 1,
      filterable: false,
      sortComparator: valueComparator,
      renderCell: (params) => {
        return (
            <div className="ModulesTable__table-cell row">
              {params?.row?.version}
            </div>
        );
      },
    },
    {
      headerName: "Module Source",
      field: "moduleOrigin",
      flex: 1,
      filterable: false,
      sortComparator: valueComparator,
      renderCell: (params) => {
        return (
          <div className="ModulesTable__table-cell row">
            <img
                src={moduleOriginTypes(params?.row?.moduleOriginType, themeDark)}
                alt={params?.row?.moduleOriginType}
            />
            {params?.row?.moduleOrigin}
          </div>
        );
      },
    },
    {
      headerName: "Last Contributor",
      field: "lastContributor",
      flex: 1,
      filterable: false,
      sortComparator: valueComparator,
      renderCell: (params) => (
        <div className="ModulesTable__table-cell row">
          {!isEmpty(params?.row?.lastContributor) &&
          !isEmpty(params?.row?.lastContributor[0])
            ? params?.row?.lastContributor
            : "-"}
        </div>
      ),
    },
    {
      headerName: "Misconfigurations",
      field: "id",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
       if(every(params.row?.vulnerabilities, value => value === 0)) return "";
        return (
          <VulnerabilityLine data={getVulnerabilityData(params?.row)} />
        )
      },
    },
    {
      headerName: "Last Module Update",
      field: "updateAt",
      flex: 1,
      filterable: false,
      sortComparator: dateComparator,
      renderCell: (params) => {
        const updatedAt = params?.row?.updateAt;
        const date = new Date(updatedAt);
        return (
        <div className="ModulesTable__table-cell row">
          {!isEmpty(updatedAt) &&
          date.getFullYear() !== 1 ? (
            <a
              href={params?.row?.lastContributionCommitIdLink}
              target="_blank"
              rel="noreferrer"
            >
              {formatUTCDateTime(date)}
            </a>
          ) : (
            "-"
          )}
        </div>
      )},
    },
    {
      headerName: "State Files",
      field: "usageCount",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const { unsyncedStacks = [] } = params?.row;
        const usageCount = params?.row?.usageCount?.[0] || 0;
        return (
        <div className="row g8">
          {usageCount > 0 && 
            <div
              className="ModulesTable__table-cell-flag row"
              onClick={async (e) => {
                e.stopPropagation();
                let stateFilesScreenFilters = emptyIacScreenFilters
                stateFilesScreenFilters["metadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullName"] = params?.row?.fullModulePath
                stateFilesScreenFilters.stateType = [IAC_TYPES.terraform];
                handleSetIacStacksFilters(stateFilesScreenFilters);
              }}
            >
              <IacStack />
              {usageCount}
      
            </div>}
            {!isEmpty(unsyncedStacks) &&
              <Tooltip placement="bottomLeft" title={
              <div className="col g5"> 
                  <span className="underline">{unsyncedStacks.length > 1 ? unsyncedStacks.length :  ""} Unsynced Stacks</span>
                  <div className="IacStacksTable__item-partials col g10">
                    {unsyncedStacks.map((stack = "") => {
                      const title = formatUnsyncedStackName(stack);
                      const shortTitle = renderEllipsis(title, 20);
                      return (
                      <Tooltip title={title?.length > 20 ? title : ""} placement="left" align={{offset: "70px"}} key={uuidv4()}>
                      <span className="IacStacksTable__item-partial pointer"  onClick={async(e) => {
                        e.stopPropagation();
                        handleSetIacStacksFilters({ ...emptyIacScreenFilters, stateType: [IAC_TYPES.terraform], unsyncedStacks: [stack]});
                      }}>• {shortTitle}</span>
                      </Tooltip>)})}
                  </div>
                </div>}>
                <FontAwesomeIcon className={`IacStacksTable__item-warningIcon mod ${usageCount > 0 && usageCount < 9 ? 'mag5' : !usageCount || usageCount < 0 ? 'mag52' : ''}`} icon="exclamation-triangle" />
              </Tooltip>}
        </div>)
      },
    },
  ];

  const handleRowClick = (row) => {
    if (!every(values(row?.vulnerabilities), (val) => val === 0)) {
      return handleOpenVulnerabilies(row);
    }
    return;
  };

  if (initLoading) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  if (!initLoading && isEmpty(modules) && !searchVal) {
    return <EmptyIac title="Git Integration is Missing" subtitle="Let's start with integrating your Version Control System(VCS)." scrollTo="vcs"/>;
  }

  return (
    <div className="ModulesTable col">
      <div className="ModulesTable__header row">
        {filersRowComponent && (
          <FiltersRowBadges
            screenFilters={screenFilters}
            handleRemoveFilter={handleRemoveBadgeFilter}
          />
        )}
      </div>
      <TableWrapper
        // height={"calc(100vh - 300px)"}
        rowKey="id"
        tableData={modulesTableData}
        columns={columns}
        disableSelectionOnClick
        // getDetailPanelContent={getDetailPanelContent}
        // getDetailPanelHeight={getDetailPanelHeight}
        handleSortChange={(sorter) => setTableSorting(sorter)}
        serverSide
        pageSize={tablePageSize}
        rowCount={totalModules}
        movePagination
        handlePageChange={setTablePage}
        onPageSizeChange={setTablePageSize}
        onRowClick={(row) => handleRowClick(row?.row)}
        loading={loading}
        hasCustomColumnMenu
      />
      <Vulnerabilities
        row={selectedRow}
        visible={vulnerabilitiesModalOpen}
        handleClose={() => setVulnerabilitiesModalOpen(false)}
      />
    </div>
  );
};

export default ModulesTable;
