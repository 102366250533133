import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';

export const GET_ACTIVE_FEATURES = "GET_ACTIVE_FEATURES";

const getActiveFeatures = () => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(`${BACKEND_URL}/activeFeatures`, "GET", undefined, undefined, undefined, false );
        let data = [];
        if (req?.ok) {
            data = await req.json();
        }
        dispatch({
            type: GET_ACTIVE_FEATURES,
            payload: { data }
        });
    });
}

export {
    getActiveFeatures
}
