import React from "react";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../utils/formatting";
import { ToolFilled } from "@ant-design/icons";
import { suggestionsArr } from "../../../consts/suggestions";
import { ReactComponent as DollarIncrease } from "../../../Images/general_icons/integration-filter-icons/dollar_increase.svg"
import { PROVIDERS_RECOMMENDATION, AZURE_DISK_NAMES } from "../../../consts/general";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Remediation } from "../../../Images/general_icons/remediation.svg";
import { getInventoryHits, setFilters } from "../../../redux/actions/inventoryv3Actions";


const SavingsCell = ({ row, handleShowSavings }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation("insights");

  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );
  const integrations = useSelector(
    (state) => state.insightsV2Reducer.filters?.integrations
  );
  const isSuggestionsCodeExiest = suggestionsArr?.find(
    (item) => item?.name === row?.name
  );

  const inventoryFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);

  const isGcpRecommendation =
    row?.classificationType === PROVIDERS_RECOMMENDATION.gcp;
  const isGcpPerformance = row?.cost < 0 ? true : false;
  const activeFeatures =
    useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const isAzureActive = activeFeatures.find(
    (feature = {}) => feature.path === "/azure"
  );
  const isAiRemediation = activeFeatures.find((feature = {}) => feature.path === '/ai-remediation');

  const isAzureDisk = AZURE_DISK_NAMES.includes(row?.name);

  const showSavings = async (e) => {
    e.stopPropagation();
    if (isSuggestionsCodeExiest || isGcpRecommendation || isAzureDisk) {
      return handleShowSavings(e);
    }
    if (isAiRemediation) {
      const { id = "" } = row || {};
      const payload = {
        classifications: [row?._id],
        excluded: showExclusion,
        providerTypes: {
          integrationId: integrations,
        },
        masterType: [],
      };
      dispatch(
        setFilters({
          ...inventoryFilters,
          classifications: [row?._id],
        })
      );
      await dispatch(getInventoryHits(
        payload,
        "",
        1,
        [],
        10000,
        )
      );
      return handleShowSavings(e);
    }
    return;
  };


  if (row?.cost && row?.isEnabled && row?.total_assets) {
    const { cost } = row;
    const absoluteCost = isGcpPerformance ? cost * -1 : cost;
    const rawData = isGcpPerformance ? (
      <>
        <DollarIncrease />
        <span>${numberWithCommas(absoluteCost)}/m</span>
      </>
    ) : (
      `${t("table.savings.cell")} $${numberWithCommas(absoluteCost)}/m`
    );
    return (
      <span className="row g5" onClick={showSavings}>
        <Tooltip title={isGcpPerformance ? t("table.tooltips.gcp") : ""}>
          <span
            style={{ cursor: "pointer", fontSize: "13px" }}
            className={isGcpPerformance ? "row g5 dark-red-flag" : "green-flag"}
          >
            {rawData}
          </span>
        </Tooltip>
        {(isSuggestionsCodeExiest || isGcpRecommendation) && (
          <ToolFilled
            style={
              isGcpPerformance
                ? { fontSize: 14, color: "#DA3032" }
                : { fontSize: 14, color: "#71c9ca" }
            }
          />
        )}
      </span>
    );
  }

  if (isAiRemediation) {
    return (
      <div className="row g5 purple-flag" onClick={showSavings}
      >
        <Remediation
          style={{ fontSize: 14, color: "white" }}
        />
        <span className="text">
          AI remediation
        </span>
      </div>
    );
  }
  return null;
};

export default SavingsCell;
