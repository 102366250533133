import React, { useState, useEffect } from "react";
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Input, Spin, Tabs } from "antd";
import { createKeyPair } from "../../../../redux/actions/keyPairsActions";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingOutlined } from "@ant-design/icons";
import CreateAccessKey from "../../../users/accessKeys/createAccessKey";
import { CREATE_KEY_ALIAS, SCRIPTS_TEXTS } from "../mangementHelper";
import { nameRegex } from "../../../../utils/validations";
import ActionIcon from "../../../../shared/actionIcon/actionIcon";

import { formatTitleByIacType } from "../../../../utils/formatting";
import './workflowsSteps.scss';

const CODE_TABS = {
    docker: "docker",
    cli: "cli",
};

const commandKeyByProvider = {
    terraform: "terraform",
    opentofu: "tofu",
}

const IntegrateStep = ({ wizardState = {}, setWizardState, setSubmitDisabled }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });
    const { t: tCommon } = useTranslation("common");
    const { user } = useAuth0();
    const dispatch = useDispatch();
   
    const step5DockerText = `${SCRIPTS_TEXTS.docker.five(t("cliVersion"))} ${wizardState.workspaceName || "<WORKSPACE_NAME>"}`
    const step5CliText = `${SCRIPTS_TEXTS.cli.five} ${wizardState.workspaceName || "<WORKSPACE_NAME>"}`
    const step7DockerText = `${SCRIPTS_TEXTS.docker.seven(t("cliVersion"))} ${wizardState.workspaceName || "<WORKSPACE_NAME>"}`;
    const step7CliText = `${SCRIPTS_TEXTS.cli.seven} ${wizardState.workspaceName || "<WORKSPACE_NAME>"}`

    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [keyPair, setKeyPair] = useState(null);
    const [keyPairLoading, setKeyPairLoading] = useState(false);
    const [stepsTab, setStepsTab] = useState(CODE_TABS.cli);
   
    useEffect(() => {
        setSubmitDisabled(false);
    }, [])
   
    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const handleCreateKeyPair = async () => {
        setKeyPairLoading(true);
        const keyPair = await dispatch(createKeyPair(user?.name, CREATE_KEY_ALIAS));
        if (isEmpty(keyPair)) {
        return setKeyPairLoading(false);
        }
        setKeyPair(keyPair);
        setKeyPairLoading(false);
        setCreateModalOpen(true);
    }

    const handleCloseCreateKeyPair = () => {
        setCreateModalOpen(false);
        setKeyPair(null);
    };

    const renderStepTabs = () => {
        return (
            <Tabs activeKey={stepsTab} defaultActiveKey={stepsTab} onChange={(key) => setStepsTab(key)}>
                <Tabs.TabPane tab="CLI" key={CODE_TABS.cli}/>
                <Tabs.TabPane tab="Docker" key={CODE_TABS.docker}/>
            </Tabs>
        )
    }
    const commandKey = commandKeyByProvider[wizardState?.iacType] || "terraform";

    return (
        <div className="WorkflowsSteps__integrate col">
           <span className="bold wb-text">{t("integrateStep.title")}</span>
           <span className="form-label">{t("integrateStep.description", {iacType: formatTitleByIacType(wizardState?.iacType)})}</span>
           <div className="col g10">
            <Form
                name="tf-config-form"
                form={form}
                initialValues={{ workspaceName: wizardState.workspaceName }}>    
                    <Form.Item
                        className="WorkflowsSteps__general-workspace"
                        label="Workspace Name"
                        name="workspaceName"
                        rules={[
                            {
                                required: true,
                                pattern: nameRegex.regex,
                                message: nameRegex.msg,
                            },
                        ]}
                        style={{ flexDirection: "column", marginBottom: "0px" }} 
                    >
                        <span className="form-label">{t("tooltips.workspaceName")}</span>
                        <Input placeholder={t("integrateStep.workspaceNamePlaceHolder")} value={wizardState.workspaceName || ""} onChange={(e) => onValuesChange({ workspaceName: e.target.value })} />
                    </Form.Item>
            </Form>
            <span className="bold">{tCommon("wizard.procedure")}</span>
            <ol className="WorkflowsSteps__integrate-orderedlist">
                <li key="1">
                   <span className="row g5">{t("integrateStep.procedure.1a")}{" "}
                   <div className="row g5 purple-text WorkflowsSteps__integrate-keyPair" onClick={handleCreateKeyPair}>
                    <FontAwesomeIcon icon={faKey}/>
                        {keyPairLoading ?  <Spin indicator={<LoadingOutlined/>}/>
                        : <span className="underline">{t("integrateStep.procedure.1b")}</span>}
                    </div>
                </span>
                <CreateAccessKey
                    visible={createModalOpen}
                    handleClose={handleCloseCreateKeyPair}
                    keyPair={keyPair}
                    showAsLine
                />
                </li>
                <li key="2">
                {t("integrateStep.procedure.2")}
                </li>
                <li key="3">
                    <span className="col g5">
                    {t("integrateStep.procedure.3")}
                        <div className="row g5">
                         as{" "}<div className="purple-flag font-12 row g5">FIREFLY_ACCESS_KEY<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="FIREFLY_ACCESS_KEY"/></div>{" "}and{" "}<div className="purple-flag font-12 row g5">FIREFLY_SECRET_KEY<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="FIREFLY_SECRET_KEY"/></div>.
                        </div>
                    </span>
                </li>
                <li key="4">
                    <div className="col g5">
                        <span>Modify your <span className="purple-flag font-12">{commandKey} plan</span> to include file output to <span className="purple-flag font-12">plan_log.jsonl</span> and <span className="purple-flag font-12">{commandKey} apply</span> to include file output to <span className="purple-flag font-12">plan.json</span></span>
                        <span>Example:</span>
                        <DarkCopyBox text={`${commandKey} plan -json -out=tf.plan > plan_log.jsonl && ${commandKey} show -json tf.plan > plan_output.json `}/>
                    </div>
                </li>
                <li key="5">
                    <div className="col g5">
                        <span>
                            Add a new pipeline step: Firefly CI Post Plan, which runs between <span className="purple-flag font-12">{commandKey} plan</span> and <span className="purple-flag font-12">{commandKey} apply</span>.
                        </span>
                        <span>The step should execute the command below:</span>
                        <div className="col">
                            {renderStepTabs()}
                            <DarkCopyBox text={stepsTab === CODE_TABS.cli ? step5CliText : step5DockerText} />
                        </div>
                    </div>
                </li>
                <li key="6">
                    <div className="col g5"> 
                        <span className="row">
                            Modify your{" "}<span className="purple-flag font-12">{commandKey} apply</span>{" "}to include file output to{" "}<span className="purple-flag font-12">apply_log.jsonl</span>
                        </span>
                        <span>Example:</span>
                        <DarkCopyBox text={`${commandKey} apply -auto-approve -json > apply_log.jsonl`}/>
                    </div>

                </li>
                <li key="7">
                    <div className="col g5">
                        <span>
                            Add a new pipeline: Firefly CI Post Apply, which runs after <span className="purple-flag font-12">{commandKey} apply</span>
                        </span>
                        <span>This should execute the command below:</span>
                        <div className="col">
                            {renderStepTabs()}
                            <DarkCopyBox text={stepsTab === CODE_TABS.cli ? step7CliText : step7DockerText}/>
                        </div>
                    </div>
                </li>
             </ol>
        </div>
       </div>
    );
};

export default IntegrateStep;