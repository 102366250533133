import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRelationFamily } from "../../../../redux/actions/relationshipActions";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import DiagramChart from "./relationshipChartComponents/diagramChart";

const RelationshipChart = ({ asset }) => {
  const { t } = useTranslation("inventory", { keyPrefix: "asset-popup" });

  const dispatch = useDispatch();
  const pageSize = 20;
  const page = 1;
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (asset) {
      fetchRelationship();
    }
  }, [asset]);

  const fetchRelationship = async () => {
    setLoading(true);
    const { frn, integrationId } = asset || {};
    const req = await dispatch(
      getRelationFamily(frn, integrationId, page, pageSize, true)
    );
    setChartData(req);
    setLoading(false);
  };

  const chart = useMemo(() => {
    return <DiagramChart data={chartData} />;
  }, [chartData]);

  if (loading) {
    return <Loading />;
  }

  // no data tree - empty state
  if (!loading && isEmpty(chartData)) {
    return (
      <div className="RelationshipChart center">
        <AppEmpty customStyle="code" text={t("relationships.list.no-data")} />
      </div>
    );
  }

  return <div className="RelationshipChart">{chart}</div>;
};

export default RelationshipChart;
