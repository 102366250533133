import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { ReactComponent as GuardrailsIcon } from "../../Images/general_icons/sidebar/guardrails.svg";
import { ReactComponent as EmptyGuardrailsImg } from "../../Images/ci/guardrails/emptyImg.svg";
import HeaderSearchBox from "../../shared/headerSearchBox/headerSearchBox";
import AppBtn from "../../shared/appBtn/appBtn";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import GuardrailsFilters from "../../components/guardrails/guardrailsFilters/guradrailsFilters";
import { getListGuardrails } from "../../redux/actions/workflowsGuardrailsActions";
import Loading from "../../shared/loading/loading";

import SingleGuardrail from "../../components/guardrails/singleGuardrail/SingleGuardrail";
import AppEmpty from "../../shared/appEmpty/appEmpty";
import GuardrailsModal from "../../components/guardrails/guardrailsModal/guardrailsModal";
import { guardrailsEvents } from "../../utils/amplitudeEvents";
import { sendEvent } from "../../utils/amplitude";

import "./guardrails.scss";
import "../ciSteps/ciCustomTheme.scss";

const Guardrails = () => {
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);
    const [showGuardrailModal, setShowGuardrailModal] = useState(false);
    const [isInitialData, setIsInitialData] = useState(false);
    const [editModeData, setEditModeData] = useState({});
    const [isOnlyNotificationData, setIsOnlyNotificationData] = useState(false);
    const history = useHistory();

    const { t } = useTranslation("ci-pipeline");
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const guardrailsData = useSelector((state) => state.ciWorkflowsReducer.guardrailsData) || [];
    const guardrailsFilters = useSelector((state) => state.ciWorkflowsReducer.guardrailsFilters) || {};
    const guardrailsAggs = useSelector((state) => state.ciWorkflowsReducer.guardrailsAggs) || {};

    const dispatch = useDispatch();
    
    useEffect(() => {
        const isDefaultOpenWizard = history?.location?.state?.openCreation == true;
        if (isDefaultOpenWizard) {
            const initialData = history?.location?.state?.initialData;
            handleOpenGuardarailModal(initialData, true);
        }
    },[]);

    useEffect(() => {
        if (showGuardrailModal) return;
        fetchGuardrailsData();
    }, [guardrailsFilters, searchInput, showGuardrailModal]);
   
    useEffect(() => {
        if (themeDark) {
           document.documentElement.classList.remove("ciLight");
           document.documentElement.classList.add("ciDark");
        } else {
           document.documentElement.classList.remove("ciDark");
           document.documentElement.classList.add("ciLight");
       }
   }, [themeDark]);

    const fetchGuardrailsData = async () => {
        setLoading(true);
        await dispatch(getListGuardrails(guardrailsFilters, searchInput));
        setLoading(false);
    };
    const handleOpenGuardarailModal = (editData = {}, initial = false, isOnlyNotification = false) => {
        setShowGuardrailModal(true);
        setEditModeData(editData);
        setIsInitialData(initial);
        setIsOnlyNotificationData(isOnlyNotification);
        if (isEmpty(editData)){
            sendEvent(guardrailsEvents.clickedAddGuradrail, { accessedVia: "guardrails page" });
        }
    }
    if (loading && isEmpty(guardrailsData)) return <Loading />;
    if (isEmpty(guardrailsData) && isEmpty(guardrailsAggs) && isEmpty(guardrailsFilters)) {

    return <div className="basic-card basic-100 col">
        <div className="Guardrails__empty col g20">
         <EmptyGuardrailsImg className="Guardrails__empty-icon" />
         <span className="Guardrails__empty-title font-18 bold">{t("guardrails.empty.title")}</span>
         <span className="Guardrails__empty-description">{t("guardrails.empty.description")}</span>
         <AppBtn text={t("guardrails.create-btn")} onClick={() => handleOpenGuardarailModal()} icon={<FontAwesomeIcon icon="plus" />} disabled={isViewer} />
         {showGuardrailModal && <GuardrailsModal showGuardrailModal={showGuardrailModal} onClose={() => setShowGuardrailModal(false)} isEditMode={!isEmpty(editModeData) && !isInitialData} guardrailEditData={editModeData} isInitialData={isInitialData}/>}
        </div>
    </div>
    }
    return (
        <div className="Guardrails basic-100 col g20">
            <div className="Guardrails__header row g10">
                <GuardrailsIcon className="Guardrails__header-icon" />
                <span className="Guardrails__header-title font-18 bold">{t("guardrails.title")}</span>
            </div>
            <div className="basic-card row between">
                        <HeaderSearchBox
                            placeholder="Search..."
                            value={(val) => { 
                                setSearchInput(val)
                                sendEvent(guardrailsEvents.searchText, { searchedText: val })
                            }}
                            resetSearch={(val) => setSearchInput(val)}
                            currentValue={searchInput}
                        />
                        <AppBtn
                            text={t("guardrails.create-btn")}
                            onClick={() => handleOpenGuardarailModal()}
                            icon={<FontAwesomeIcon icon="plus" />}
                            disabled={isViewer}
                        />
            </div>
            <div className="Guardrails__filters row g10">
                <span>Filter by:</span>
                <GuardrailsFilters setSearchInput={setSearchInput}/>
            </div>
            <div className="Guardrails__list col g15 basic-100">
                {loading ? <div className="basic-loading"><Loading /></div>
                : (!isEmpty(guardrailsData) ? guardrailsData.map((item) => <SingleGuardrail key={item?.id || uuidv4()} item={item} onEditGuardrail={handleOpenGuardarailModal}/>)
                : <div className="Guardrails basic-100 col g20">
                    <AppEmpty text="No guardrails found" customStyle="ciResourcesNotFound" />
                </div>)}
            </div>
            {showGuardrailModal && <GuardrailsModal showGuardrailModal={showGuardrailModal} onClose={() => setShowGuardrailModal(false)} isEditMode={!isEmpty(editModeData) && !isInitialData} guardrailEditData={editModeData} isInitialData={isInitialData} isOnlyNotificationData={isOnlyNotificationData}/>}
        </div>
    )
};

export default Guardrails;