import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faPencilAlt,
  faTrash,
  faFlag,
} from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as EventDriven } from "../../../Images/general_icons/eventdriven.svg";
import { ReactComponent as SyncIcon } from "../../../Images/general_icons/Navigation_Menu.svg";
import Tooltip from "antd/lib/tooltip";
import IntegrationSyncBadge from "./IntegrationSyncBadge";
import NotificationHeartbeatButton from "./notificationHeartbeat/notificationHeartbeatButton";

import "./singleIntegrationItem.scss";
import { PROVIDERS } from "../../../consts/general";

const SingleIntegrationItem = ({
  typeData,
  data = {},
  category,
  handleEdit,
  handleDelete,
  handleStatus,
  setSelectedSlackApp,
  setShowSlackChannelsPopup,
  setSelectedWebex,
  setShowWebexRoomsPopup,
  testNotificationLoading,
  setTestNotificationLoading,
  handleVCSScanning,
}) => {
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  
  // spesific types preferences
  const showAccount = ["aws", "gcp", 'azurerm'];
  const allowedFeaching = ["aws", "gcp",'azurerm'];
  const categoriesWithSyncStatus = ["cloud", "vcs", "notification"];

  const isGCP = typeData?.server_name === PROVIDERS.gcp;
  return (
    <div className="SingleIntegrationItem">
      <div className={`${isGCP ? 'clickable':''}`} onClick={isGCP ? handleStatus : null}>
        <div className="SingleIntegrationItem__labels row">
          {categoriesWithSyncStatus.includes(category) ? (
            <IntegrationSyncBadge
              data={data}
              type={typeData?.sever_name}
              category={category}
            />
          ) : (
            <span />
          )}
          <img
            src={typeData?.icon}
            alt={typeData?.icon}
            className={`SingleIntegrationItem__labels-${typeData?.server_name}`}
          />
        </div>

        <div className="SingleIntegrationItem__content col center">
          <span className="SingleIntegrationItem__content-name centered bold">
            {data?.name || "Choose a name"} {data.associatedIntegration && `(${data.associatedIntegration})`}
          </span>
          {showAccount.includes(typeData?.server_name) && (
            <span className="SingleIntegrationItem__content-account centered">
              {data?.accountNumber}
            </span>
          )}
        </div>
      </div>

      <div className="SingleIntegrationItem__actions">
        <div className="SingleIntegrationItem__actions-flags row">
          {data?.isProd && (
            <Tooltip title="Production Account">
              <FontAwesomeIcon
                icon={faFlag}
                className="SingleIntegrationItem__actions-flags-prod"
              />
            </Tooltip>
          )}
          {data?.isEventDriven && (
            <Tooltip title="Event-Driven Enabled">
              <EventDriven className="SingleIntegrationItem__actions-flags-event" />
            </Tooltip>
          )}
          {category === 'notification' && !isViewer && 
            <NotificationHeartbeatButton 
              item={data} 
              setSelectedSlackApp={setSelectedSlackApp}
              setShowSlackChannelsPopup={setShowSlackChannelsPopup}
              setSelectedWebex={setSelectedWebex}
              setShowWebexRoomsPopup={setShowWebexRoomsPopup}
              testNotificationLoading={testNotificationLoading}
              setTestNotificationLoading={setTestNotificationLoading}
            />}
           {category=== 'vcs' && !isViewer &&
           <Tooltip title="Run scan" onClick={() => handleVCSScanning(data)}>
              <FontAwesomeIcon icon="sync-alt" className="purple-text pointer" />
           </Tooltip>
           }
        </div>
        {data?.isProd || data?.isEventDriven || data?.isPrimary ? (
          <span className="SingleIntegrationItem__actions-line" />
        ) : null}
        <div className="SingleIntegrationItem__actions-btns row">
          <button onClick={handleEdit} disabled={isViewer}>
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
          <button onClick={handleDelete} disabled={isViewer}>
            <FontAwesomeIcon icon={faTrash} />
          </button>
          {allowedFeaching.includes(typeData?.server_name) && (
            <button onClick={handleStatus}>
                <SyncIcon className="SingleIntegrationItem__actions-flags-primary" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleIntegrationItem;
