import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAccountIntegrations } from "../../../redux/actions/onbordingActions";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import Loading from "../../../shared/loading/loading";
import IntegrationCard from "../integrationCard/integrationCard";
import { iacProviders, integrationIcons, buckets, remoteTypes, clouds } from "../../../utils/icons";
import { PROVIDERS } from "../../../consts/general";

import "./iacStep.scss";

const IacStep = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const integrations = useSelector(
    (state) => state.onbordingReducer.integrations
  );

  useEffect(() => {
    handleFetchProviders();
  }, []);

  const handleFetchProviders = async () => {
    await dispatch(getAccountIntegrations());
    setLoading(false);
  };

  const isAWSAutoDiscoveryDisabled = isEmpty(integrations?.aws) ? true : !!(integrations.aws || []).some((integ = {}) => integ.isIacAutoDiscoverDisabled);
  const isGCPAutoDiscoveryDisabled = isEmpty(integrations?.gcs)? true : !!(integrations.gcs || []).some((integ = {}) => integ.isIacAutoDiscoverDisabled);
  const isAzureAutoDiscoveryDisabled = isEmpty(integrations?.azurerm) ? true : !!(integrations.azurerm || []).some((integ = {}) => integ.isIacAutoDiscoverDisabled);

  const terraform = [
    ...(!isAWSAutoDiscoveryDisabled ? [{
      icon: buckets["aws_bucket"],
      title: "S3 Bucket",
      coming_soon: false,
      connected: false,
      auto: !isAWSAutoDiscoveryDisabled,
    }]: []),
    ...(!isGCPAutoDiscoveryDisabled ? [{
      icon: integrationIcons["gcs"],
      title: "Google Cloud Storage",
      onClick: () => history.push("/integrations/gcs-integration"),
      coming_soon: false,
      connected: !isGCPAutoDiscoveryDisabled ? false : !isEmpty(integrations?.gcs),
      auto: !isGCPAutoDiscoveryDisabled,
    }] : []),
    ...(!isAzureAutoDiscoveryDisabled ? [{
      icon: clouds("azure"),
      title: "Azure Storage Account",
      onClick: () => history.push("/integrations/azure-integration"),
      coming_soon: false,
      connected: false,
      auto: true,
    }]: []),
    {
      icon: integrationIcons["consul"],
      title: "HashiCorp Consul",
      onClick: () => history.push("/integrations/consul-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.consul),
    },
    {
      icon: iacProviders("terraform"),
      title: "Terraform Cloud",
      onClick: () => history.push({
        pathname: "/integrations/tfc-integration",
        state: { onboarding: true }
      }),
      coming_soon: false,
      connected: !isEmpty(integrations?.terraform),
    },
    {
      icon: remoteTypes(PROVIDERS.env0),
      title: PROVIDERS.env0,
      onClick: () => history.push("/integrations/env0-integration"),
      coming_soon: false,
      connected: !isEmpty(integrations?.env0),
    },
  ];

  const other = [
   ...(!isAWSAutoDiscoveryDisabled ? [{
      icon: iacProviders("cloudFormation"),
      title: "CloudFormation",
      coming_soon: false,
      connected: false,
      auto: true,
    }] : []),
  ];

 

  const renderInteg = (arr) => {
    return map(arr, (item) => {
      return (
        <IntegrationCard
          key={item?.title}
          icon={item?.icon}
          title={item?.title}
          onClick={item?.onClick}
          coming_soon={item?.coming_soon}
          connected={item?.connected}
          auto={item?.auto}
        />
      );
    });
  };
  return (
    <div className="IacStep col">
      {loading ? (
        <div className="tab-page center">
          <Loading />
        </div>
      ) : (
        <div className="IacStep__integs col">
          <span className="IacStep__integs-sub">Terrafom Backend</span>
          <div className="IacStep__integs-list">{renderInteg(terraform)}</div>
         {other.length > 0 ?
          <>
            <span className="IacStep__integs-sub">Additional IaC tools</span>
            <div className="IacStep__integs-list">{renderInteg(other)}</div>
          </> : null}
        </div>
      )}{" "}
    </div>
  );
};

export default IacStep;
