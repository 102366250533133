import React, { useState } from "react";
import { Drawer } from "antd";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";
import RemoveSingleResource from "./removeSingleResource";
import "./removeResourceDrawer.scss";

const RemoveResourceDrawer = ({ close, visible, data }) => {
    const [pullRequestResponse, setPullRequestResponse] = useState({});
    const { t } = useTranslation('inventory');

    const handleCloseDrawer = () => {
        setPullRequestResponse({});
        close();
    };
    
    const renderTitle = () => {
        return (
            <div className="RemoveResourceModal-title">
            <span className="wb-text font-18 bold">
              {data?.name}
            </span>
            <div className="row g5 font-14">
              <div className="row g5">
                <span className="bold">Type:</span>
                {formatAwsStringWithUnderscore(data?.assetType || "")}
              </div>
              <span>|</span>
              <div className="row g5">
                <span className="bold">ID:</span>
                {data?.assetId || ""}
              </div>
            </div>
          </div> 
        )
    };

    return (
        <Drawer
            title={renderTitle()}
            placement="right"
            onClose={handleCloseDrawer}
            visible={visible}
            key="right"
            className="RemoveResourceModal"
            width="80vw"
            maskStyle={{ backgroundColor: "rgba(0,0,0,.5)" }}
            destroyOnClose
            zIndex={1001}
        >
            <div className="RemoveResourceModal__content">
               <RemoveSingleResource assetData={data} pullRequestResponse={pullRequestResponse} setPullRequestResponse={setPullRequestResponse} />
            </div>
        </Drawer>
    );
};

export default RemoveResourceDrawer;
