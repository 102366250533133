import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAccountIntegrations } from "../../../redux/actions/onbordingActions";
import map from "lodash/map";
import Loading from "../../../shared/loading/loading";
import IntegrationCard from "../integrationCard/integrationCard";
import { integrationIcons } from "../../../utils/icons";
import { findIntegrationTypeByKeyVal } from "../../../utils/helpers";

import "./notificationStep.scss";

const NotificationStep = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const integrations = useSelector(
    (state) => state.onbordingReducer.integrations?.notifications
  );

  useEffect(() => {
    handleFetchProviders();
  }, []);

  const handleFetchProviders = async () => {
    await dispatch(getAccountIntegrations());
    setLoading(false);
  };
  const providers = [
    {
      icon: integrationIcons["slack"],
      title: "Slack",
      onClick: () => history.push("/integrations/slack-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", "slack") || findIntegrationTypeByKeyVal(integrations, "service", "slackApp")
      ,
    },
    {
      icon: integrationIcons["torq"],
      title: "Torq",
      onClick: () => history.push("/integrations/torq-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", "torq"),
    },
    {
      icon: integrationIcons["teams"],
      title: "Teams",
      onClick: () => history.push({
        pathname: "/integrations/teams-integration",
        state: { type: "teams" },
      }),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", "teams"),
    },
    {
      icon: integrationIcons["opsgenie"],
      title: "Opsgenie",
      onClick: () => history.push({
        pathname: "/integrations/opsgenie-integration",
        state: { type: "opsgenie" },
      }),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", 'opsgenie'),
    },
    {
      icon: integrationIcons["webhook"],
      title: "Webhooks",
      onClick: () => history.push({
        pathname: "/integrations/webhook-integration",
        state: { type: "webhook" },
      }),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", 'webhook'),
    },
    {
      icon: integrationIcons["pagerduty"],
      title: "PagerDuty",
      onClick: () => history.push("/integrations/pagerduty-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", "pagerduty"),
    },
    {
      icon: integrationIcons["discord"],
      title: "Discord",
      coming_soon: true,
    },
    {
      icon: integrationIcons["googleChat"],
      title: "Google Chat",
      onClick: () => history.push({
        pathname: "/integrations/google-chat-integration",
        state: { type: "google" },
      }),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", 'google'),
    },
    {
      icon: integrationIcons["webex"],
      title: "Webex",
      onClick: () => history.push("/integrations/webex-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "service", 'webex'),
    },
  ];

  const renderProviders = () => {
    return map(providers, (item) => {
      return (
        <IntegrationCard
          key={item?.title}
          icon={item?.icon}
          title={item?.title}
          onClick={item?.onClick}
          coming_soon={item?.coming_soon}
          connected={item?.connected}
        />
      );
    });
  };
  return <div className="NotificationStep col">{loading? <div className="tab-page center"><Loading /></div> : <div className="NotificationStep__list">{renderProviders()}</div>}</div>;
};

export default NotificationStep;
