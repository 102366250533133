import { ciDataSourceIcons } from "../../../../utils/icons";

export const CI_TOOLS = {
  azure: "azure-pipelines",
  github: "github-actions",
  gitlab: "gitlab-pipelines",
  bitbucket: "bitbucket-pipelines",
  codecommit: "codecommit-pipelines",
  jenkins: "jenkins",
}

export const CI_TOOLS_OPTIONS =  [
  {
    name: CI_TOOLS.github,
    tooltip: "GitHub Actions",
    icon: ciDataSourceIcons("github-actions"),
  },
  {
    name: CI_TOOLS.azure,
    tooltip: "Azure Pipelines",
    icon: ciDataSourceIcons("azure-pipelines"),
  },
  {
    name: CI_TOOLS.gitlab,
    tooltip: "GitLab Pipelines",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("gitlab-pipelines"),
  },
  {
    name: CI_TOOLS.bitbucket,
    tooltip: "Bitbucket Pipelines",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("bitbucket-pipelines"),
  },
  {
    name: CI_TOOLS.codecommit,
    tooltip: "CodeCommit Pipelines",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("codecommit-pipelines"),
  },
  {
    name: CI_TOOLS.jenkins,
    tooltip: "Jenkins",
    disabled: true,
    comingSoon: true,
    icon: ciDataSourceIcons("jenkins"),
  },
];
