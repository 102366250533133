import { CODIFY_TYPES, IAC_TYPES } from "../../../consts/general";
import { codifyIcons } from "../../../utils/icons";

export const deleteMockArrays = (wizardState, keys = []) => {
    keys.forEach((key) => {
        const array = wizardState[key] || [];
        const isEmptyNameField = array[0]?.name === "" || array[0]?.profileName === "";
        if (array.length === 1 && isEmptyNameField) {
            delete wizardState[key];
        }
    })
    return wizardState;
}

export const turnArraysToObjects = (wizardState = {}, keys = []) => {
    keys.forEach((key) => {
        const array = wizardState[key] || [];
        const obj = {};
        array.forEach((element = {}) => {
                const { name, value } = element;
                if (name && value) {
                    const objElem = ["runnerEnvironment", "terraformVariables"].includes(key) ? { value, isActionVar: element.isActionVar } : value;
                    obj[name] = objElem;
                }
            });
        wizardState[key] = obj;
    })
    return wizardState;
}
export const MANAGEMENT_IAC_ENGINES_OPTIONS = [
    {
        key: IAC_TYPES.terraform,
        icon: codifyIcons(IAC_TYPES.terraform),
        title: "Terraform"
    },
    {
        key: IAC_TYPES.opentofu,
        icon: codifyIcons(IAC_TYPES.opentofu),
        title: "OpenTofu",
    },
    {
        key: IAC_TYPES.cloudformation,
        icon: codifyIcons(IAC_TYPES.cloudformation),
        title: "CloudFormation",
        comingSoon: true
    },
    {
        key: CODIFY_TYPES.crossplane,
        icon: codifyIcons(CODIFY_TYPES.crossplane),
        title: "Crossplane",
        comingSoon: true
    }
];
export const MANAGEMENT_NEW_PIPELINES = {
    generate: "generate",
    existing: "existing"
}
export const TERRAFORM_VERSIONS = [
    '1.8.1',
    '1.7.5',
    '1.6.6',
    '1.5.7',
    '1.4.7',
    '1.3.10',
    '1.2.9',
    '1.1.9',
    '1.0.11',
    '0.15.5'
];

export const OPENTOFU_VERSIONS = [
    '1.7.1',
    '1.7.0',
    '1.6.2',
    '1.6.1',
    '1.6.0'
]
export const VERSIONS_BY_IAC = {
    [IAC_TYPES.terraform]: TERRAFORM_VERSIONS,
    [IAC_TYPES.opentofu]: OPENTOFU_VERSIONS
}
export const SCRIPTS_TEXTS = {
    docker: {
        five: (version) =>  `docker run --rm -e FIREFLY_ACCESS_KEY -e FIREFLY_SECRET_KEY -v $(pwd):/app/cicd public.ecr.aws/firefly/fireflyci:v${version} post-plan -l /app/cicd/plan_log.jsonl -f /app/cicd/plan_output.json -w`,
        seven: (version) => `docker run --rm -e FIREFLY_ACCESS_KEY -e FIREFLY_SECRET_KEY -v $(pwd):/app/cicd public.ecr.aws/firefly/fireflyci:v${version} post-apply -f /app/cicd/apply_log.jsonl -w`
    },
    cli: {
        five: `curl -O https://gofirefly-prod-iac-ci-cli-binaries.s3.amazonaws.com/fireflyci/latest/fireflyci_Linux_x86_64.tar.gz
tar -xf fireflyci_Linux_x86_64.tar.gz
chmod a+x fireflyci
./fireflyci post-plan -l plan_log.jsonl -f plan_output.json --workspace`,
        seven: './fireflyci post-apply -f apply_log.jsonl --workspace'
    }
}
export const CREATE_KEY_ALIAS = 'Generated for Firefly Workflows';