import React from "react";
import "./complianceLineChart.scss";
import { useSelector } from "react-redux";
import Tooltip from "antd/lib/tooltip";
import { getLabel, getPercentage, getSize, getTheme, getTotalHover, checkIfTotalGreaterThanAffected } from "./complianceLineChart.utils";
import { COMPLIANCE_LINE_CHART } from "../../consts/insights";

const ComplianceLineChart = ({
  affected = 0,
  total = 0,
}) => {

  const isThemeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  
  const tooltipTitle = COMPLIANCE_LINE_CHART.tooltipTitle

  const theme = getTheme(isThemeDark);
  const isTotalGreaterThanAffected = checkIfTotalGreaterThanAffected(total, affected);
  const supplementaryNumber =isTotalGreaterThanAffected ?  total - affected : affected;
  const precntege = getPercentage(supplementaryNumber, total);
  const total_hover = getTotalHover(affected, total);
  const label = getLabel(precntege);
  const size = getSize(label);


  if(affected === 0 && total === 0){
    return null;
  }

  return (
    <>
      {(isTotalGreaterThanAffected && label) ?
      <Tooltip overlayClassName='ComplianceLineChart-Tooltip' title={<span> {tooltipTitle} <br /> {total_hover}</span>}>
        <div className="ComplianceLineChart">
          <span className="ComplianceLineChart__progress-label">{label}</span>
          <div className={`ComplianceLineChart__progress-container ${theme}`}>
            <div className={`ComplianceLineChart__progress-bar ${size}`} style={{ width: label }}>
            </div>
          </div>
        </div>
        </Tooltip>
        :
        null
      }
    </>
  );
};

export default ComplianceLineChart;