import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { providers } from "../../utils/providers";

/**
 * INVENTORY V2
 */
export const GET_INVENTORY_AGGREGATIONS_V2 = "getAggregationsV2";
export const SET_FEATCHING_TYPE = "SET_FEATCHING_TYPE";
export const GET_INVENTORY_FIREFLY_AGGREGATIONS =
  "GET_INVENTORY_FIREFLY_AGGREGATIONS";
export const GET_INVENTORY_RESOURCE = "GET_INVENTORY_RESOURCE";
export const SET_FILTERS = "SET_FILTERS";

const setFeatchingType = (type) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_FEATCHING_TYPE,
      payload: type,
    });
  });
};

const getFireflyAggregations = (filteredAccounts, showError) => {
  return action(async (dispatch) => {
    let baseQuery = {
      queryPage: 1,
      queryAdditionalFilters: [],
    };

    baseQuery["aggregations"] = {
      classifications: {
        terms: {
          field: "classifications.keyword",
          size: 10000,
        },
      },
      excludedAssets: {
        terms: {
          field: "exclusions.keyword",
          size: 10000,
        },
      },
    };

    // create the Account filter of the query
    if (!_.isEmpty(filteredAccounts)) {
      let resourceAccountQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredAccounts, (filter) => {
        resourceAccountQuery.bool.should.push({
          term: {
            "providerId.keyword": {
              value: filter,
            },
          },
        });
      });
      baseQuery.queryAdditionalFilters.push(resourceAccountQuery);
    }

    // filter the childs
    let onlyParentsQuery = {
      bool: {
        // minimum_should_match: 1,
        must: [
          {
            term: {
              isChild: {
                value: false,
              },
            },
          },
        ],
      },
    };
    baseQuery.queryAdditionalFilters.push(onlyParentsQuery);

    baseQuery.isExcludedReturns = true;

    baseQuery.size = 0;

    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV2/`,
      "POST",
      baseQuery,
      undefined,
      undefined,
      showError
    );
    const data = await req.json();

    dispatch({
      type: GET_INVENTORY_FIREFLY_AGGREGATIONS,
      payload: data,
    });
  });
};

export const getInventoryAggregationsV2 = (
  filteredResourceTypes,
  filteredResourceStates,
  filteredAccounts,
  filterRegions,
  filterSeverity,
  freeTextSearch,
  pageNumber = 0,
  isDateSortedDescending = true,
  slectedStacks,
  filterClassifications,
  isExcludedReturns,
  filterOutRegions,
  isChild,
  filterExcludedAssets,
  showError,
  filterIacTypes,
  filterOutGhosts,
  index = "meta",
  checkMeta = false,
  tablePageSize
) =>
  queryElastic(
    filteredResourceTypes,
    filteredResourceStates,
    filteredAccounts,
    filterRegions,
    filterSeverity,
    freeTextSearch,
    isDateSortedDescending,
    pageNumber,
    slectedStacks,
    filterClassifications,
    isExcludedReturns,
    filterOutRegions,
    isChild,
    filterExcludedAssets,
    showError,
    filterIacTypes,
    filterOutGhosts,
    index,
    checkMeta,
    tablePageSize
  );

const queryElastic = (
  filteredResourceTypes,
  filteredResourceStates,
  filteredAccounts,
  filterRegions,
  filterSeverity,
  freeTextSearch,
  isDateSortedDescending,
  pageNumber,
  slectedStacks,
  filterClassifications,
  isExcludedReturns,
  filterOutRegions,
  isChild,
  filterExcludedAssets,
  showError,
  filterIacTypes,
  filterOutGhosts,
  index = "meta",
  checkMeta,
  tablePageSize
) => {
  let baseQuery = {
    queryPage: pageNumber - 1,
    queryAdditionalFilters: [],
    queryAdditionalFilterOut: [],
    size: tablePageSize
  };

  // add the basic aggregations to the query if needed
  baseQuery["aggregations"] = {
    is_managed_by_env: {
      terms: {
        field: "state.keyword",
        size: 10000,
      },
      aggs: {
        environment: {
          terms: {
            field: "environmentId.keyword",
            size: 10000,
          },
        },
      },
    },
    ismanaged: {
      terms: {
        field: "state.keyword",
        size: 10000,
      },
    },
    assetType: {
      terms: {
        field: "assetType.keyword",
        size: 10000,
      },
    },
    providerId: {
      terms: {
        field: "providerId.keyword",
        size: 10000,
      },
    },
    providerRegion: {
      terms: {
        field: "provider.keyword",
        size: 10000,
      },
      aggs: {
        regions: {
          terms: {
            field: "region.keyword",
            size: 10000,
          },
        },
      },
    },
    is_managed_by_iac_type: {
      terms: {
        field: "state.keyword",
        size: 10000,
      },
      aggs: {
        types: {
          terms: {
            field: "iacType.keyword",
            size: 10000,
          },
        },
      },
    },
    iacType: {
      terms: {
        field: "iacType.keyword",
        size: 10000,
      },
    },
    providerIdByProviderType: {
      terms: {
        field: "provider.keyword",
        size: 10000,
      },
      aggs: {
        integrationId: {
          terms: {
            field: "providerId.keyword",
            size: 10000,
          },
        },
      },
    },
    classifications: {
      terms: {
        field: "classifications.keyword",
        size: 10000,
      },
    },
    assetsVolume: {
      terms: {
        field: "resourceCreationDate",
        size: 10000,
        order: { _key: "desc" },
        min_doc_count: 1,
      },
      aggs: {
        state: {
          terms: {
            field: "state.keyword",
            size: 10000,
          },
        },
      },
    },
    assetIds: {
      terms: {
        field: "assetId.keyword",
        size: 10000,
      },
    },
  };

  // let isChild = false;

  if (index === "meta" && !isChild && !_.isUndefined(isChild)) {
    let onlyParentsQuery = {
      bool: {
        // minimum_should_match: 1,
        must: [
          {
            term: {
              isChild: {
                value: false,
              },
            },
          },
        ],
      },
    };
    baseQuery.queryAdditionalFilters.push(onlyParentsQuery);
  }

  // create the resource type filter of the query
  if (!_.isEmpty(filteredResourceTypes)) {
    let resourceTypeQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filteredResourceTypes, (filter) => {
      if(!filter?.endsWith("objects")) {
        resourceTypeQuery.bool.should.push({
          term: {
            "assetType.keyword": {
              value: filter,
            },
          },
        });
      } else {
        let filterType = filter?.replace("objects", "");
        let typePrefix = `${providers[filterType]?.insightPrefix}_`;
        if (typePrefix){
          resourceTypeQuery.bool.should.push({
            prefix: {
              'assetType.keyword': typePrefix
            },
          });
        }
      }
    });
    baseQuery.queryAdditionalFilters.push(resourceTypeQuery);
  }

  // create the iac type filter of the query
  if (!_.isEmpty(filterIacTypes)) {
    let iacTypeQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterIacTypes, (filter) => {
      iacTypeQuery.bool.should.push({
        term: {
          "iacType.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(iacTypeQuery);
  }

  // create the Account filter of the query
  if (!_.isEmpty(filteredAccounts)) {
    let resourceAccountQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filteredAccounts, (filter) => {
      resourceAccountQuery.bool.should.push({
        term: {
          "providerId.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(resourceAccountQuery);
  }

  // create the filter Regions of the query
  if (!_.isEmpty(filterRegions)) {
    let regionsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterRegions, (filter) => {
      regionsQuery.bool.should.push({
        term: {
          "region.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(regionsQuery);
  }

  // create the filter iac status of the query
  if (!_.isEmpty(filteredResourceStates)) {
    let resourceQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filteredResourceStates, (filter) => {
      resourceQuery.bool.should.push({
        term: {
          "state.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(resourceQuery);
  }

  // create the filter iac status of the query
  if (!_.isEmpty(filterClassifications)) {
    let classificationsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterClassifications, (filter) => {
      classificationsQuery.bool.should.push({
        term: {
          "classifications.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(classificationsQuery);
  }

  if (!_.isEmpty(filterExcludedAssets)) {
    let excludedAssetsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterExcludedAssets, (filter) => {
      excludedAssetsQuery.bool.should.push({
        term: {
          "exclusions.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(excludedAssetsQuery);
  }

  // create the filter by stack id of the query
  if (!_.isEmpty(slectedStacks)) {
    let slectedStacksQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(slectedStacks, (filter) => {
      if (filter?.iacType === "terraform") {
        slectedStacksQuery.bool.should.push({
          term: {
            "stackIds.keyword": {
              value: filter?.id,
            },
          },
        });
      } else if (filter?.iacType === "cloudFormation") {
        slectedStacksQuery.bool.should.push({
          term: {
            "cftStackIds.keyword": {
              value: filter?.id,
            },
          },
        });
      } else {
        slectedStacksQuery.bool.should.push({
          term: {
            "stackId.keyword": {
              value: filter?.id,
            },
          },
        });
      }
    });
    baseQuery.queryAdditionalFilters.push(slectedStacksQuery);
  }

  // create the free text search of the query
  if (freeTextSearch) {
    baseQuery["freeTextSearch"] = {
      "multi_match": {
        "type": "phrase",
        "query": freeTextSearch,
        "lenient": true
      }
    };
  }

  // create the filter --OUT-- Regions
  if (!_.isEmpty(filterOutRegions)) {
    let regionsOutQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterOutRegions, (filter) => {
      regionsOutQuery.bool.should.push({
        term: {
          "region.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilterOut.push(regionsOutQuery);
  }

  if (filterOutGhosts) {
    baseQuery.queryAdditionalFilterOut.push({
      term: {
        "state.keyword": {
          value: "ghost",
        },
      },
    });
  }

  // set the sorting by date parameter
  baseQuery.isDateSortedDescending = isDateSortedDescending;
  baseQuery.isExcludedReturns = isExcludedReturns;
  baseQuery.checkMeta = checkMeta;

  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV2/${index}`,
      "POST",
      baseQuery,
      undefined,
      undefined,
      showError
    );
    const data = await req.json();
    dispatch({
      type: GET_INVENTORY_AGGREGATIONS_V2,
      payload: data,
    });
  });
};

// GET A SPESIFIC ARN DATA FROM ELASTIC - INVENTORY
const getInventoryResource = (
  resourceId,
  assetType,
  index = "meta",
  providerIdKey = "",
  providerId = "",
  parseInternalJson = false
) => {
  return action(async (dispatch) => {
    let baseQuery = {
      queryPage: 0,
      queryAdditionalFilters: [],
      size: 1500,
    };

    // create the Account filter of the query
    if (!_.isEmpty(resourceId)) {
      let resourceIds = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(resourceId, (filter) => {
        resourceIds.bool.should.push({
          term: {
            "arn.keyword": {
              value: filter,
            },
          },
        });
      });
      if (!_.isEmpty(providerIdKey) && !_.isEmpty(providerId)) {
        const term = {};
        const key = `${providerIdKey}.keyword`;
        term[key] = { value: providerId };
        resourceIds.bool.should.push({ term: term });
      }
      baseQuery.queryAdditionalFilters.push(resourceIds);
    }

    // create the resource type filter of the query
    if (!_.isEmpty(assetType)) {
      let resourceTypeQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(assetType, (filter) => {
        resourceTypeQuery.bool.should.push({

          // index iac and meta has 2 different asset type field names
          term:
            index === "iac"
              ? {
                  "objectType.keyword": {
                    value: filter,
                  },
                }
              : {
                  "assetType.keyword": {
                    value: filter,
                  },
                },
        });
      });
      baseQuery.queryAdditionalFilters.push(resourceTypeQuery);
    }

    baseQuery.isExcludedReturns = true;
    baseQuery.resourceId = resourceId;
    baseQuery.parseInternalJson = parseInternalJson;

    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV2/${index}`,
      "POST",
      baseQuery
    );
    const data = await req.json();
    dispatch({
      type: GET_INVENTORY_RESOURCE,
      payload: data,
    });
    return data;
  });
};

// EXPORT INVENTORY
const exportInventory = (
  filteredResourceTypes,
  filteredResourceStates,
  filteredAccounts,
  filterRegions,
  freeTextSearch,
  isDateSortedDescending = true,
  filterClassifications,
  isExcludedReturns,
  filterOutRegions,
  format,
  filterExcludedAssets,
  filterIacTypes,
  slectedStacks
) =>
  queryElasticForExport(
    filteredResourceTypes,
    filteredResourceStates,
    filteredAccounts,
    filterRegions,
    freeTextSearch,
    isDateSortedDescending,
    filterClassifications,
    isExcludedReturns,
    filterOutRegions,
    format,
    filterExcludedAssets,
    filterIacTypes,
    slectedStacks
  );

const queryElasticForExport = (
  filteredResourceTypes,
  filteredResourceStates,
  filteredAccounts,
  filterRegions,
  freeTextSearch,
  isDateSortedDescending,
  filterClassifications,
  isExcludedReturns,
  filterOutRegions,
  format,
  filterExcludedAssets,
  filterIacTypes,
  slectedStacks
) => {
  let baseQuery = {
    queryPage: 0,
    queryAdditionalFilters: [],
    queryAdditionalFilterOut: [],
  };

  // create the resource type filter of the query
  if (!_.isEmpty(filteredResourceTypes)) {
    let resourceTypeQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filteredResourceTypes, (filter) => {
      resourceTypeQuery.bool.should.push({
        term: {
          "assetType.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(resourceTypeQuery);
  }

  // create the iac type filter of the query
  if (!_.isEmpty(filterIacTypes)) {
    let iacTypeQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterIacTypes, (filter) => {
      iacTypeQuery.bool.should.push({
        term: {
          "iacType.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(iacTypeQuery);
  }

  // create the Account filter of the query
  if (!_.isEmpty(filteredAccounts)) {
    let resourceAccountQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filteredAccounts, (filter) => {
      resourceAccountQuery.bool.should.push({
        term: {
          "providerId.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(resourceAccountQuery);
  }

  // create the filter Regions of the query
  if (!_.isEmpty(filterRegions)) {
    let regionsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterRegions, (filter) => {
      regionsQuery.bool.should.push({
        term: {
          "region.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(regionsQuery);
  }

  // create the filter iac status of the query
  if (!_.isEmpty(filteredResourceStates)) {
    let resourceQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filteredResourceStates, (filter) => {
      resourceQuery.bool.should.push({
        term: {
          "state.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(resourceQuery);
  }

  // create the filter iac status of the query
  if (!_.isEmpty(filterClassifications)) {
    let classificationsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterClassifications, (filter) => {
      classificationsQuery.bool.should.push({
        term: {
          "classifications.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(classificationsQuery);
  }

  if (!_.isEmpty(filterExcludedAssets)) {
    let excludedAssetsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterExcludedAssets, (filter) => {
      excludedAssetsQuery.bool.should.push({
        term: {
          "exclusions.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(excludedAssetsQuery);
  }

  // create the filter by stack id of the query
  if (!_.isEmpty(slectedStacks)) {
    let slectedStacksQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(slectedStacks, (filter) => {
      if (filter?.iacType === "terraform") {
        slectedStacksQuery.bool.should.push({
          term: {
            "stackIds.keyword": {
              value: filter?.id,
            },
          },
        });
      } else if (filter?.iacType === "cloudFormation") {
        slectedStacksQuery.bool.should.push({
          term: {
            "cftStackIds.keyword": {
              value: filter?.id,
            },
          },
        });
      } else {
        slectedStacksQuery.bool.should.push({
          term: {
            "stackId.keyword": {
              value: filter?.id,
            },
          },
        });
      }
    });
    baseQuery.queryAdditionalFilters.push(slectedStacksQuery);
  }

  // create the free text search of the query
  if (freeTextSearch) {
    baseQuery["freeTextSearch"] = {
      "multi_match": {
        "type": "phrase",
        "query": freeTextSearch,
        "lenient": true
      }
    };
  }

  // create the filter --OUT-- Regions
  if (!_.isEmpty(filterOutRegions)) {
    let regionsOutQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(filterOutRegions, (filter) => {
      regionsOutQuery.bool.should.push({
        term: {
          "region.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilterOut.push(regionsOutQuery);
  }

  // filter the childs
  let onlyParentsQuery = {
    bool: {
      // minimum_should_match: 1,
      must: [
        {
          term: {
            isChild: {
              value: false,
            },
          },
        },
      ],
    },
  };
  baseQuery.queryAdditionalFilters.push(onlyParentsQuery);

  // set the sorting by date parameter
  baseQuery.isDateSortedDescending = isDateSortedDescending;
  baseQuery.isExcludedReturns = isExcludedReturns;
  baseQuery.size = 10000;

  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV2/export`,
      "POST",
      { baseQuery, format }
    );
    if (format === "csv") {
      return { req, result: await req.blob() };
    }
    return { req, result: await req.json() };
  });
};

const setFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_FILTERS,
      payload: filters,
    });
  });
};

export {
  setFeatchingType,
  getFireflyAggregations,
  getInventoryResource,
  exportInventory,
  setFilters
};
