import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import { Checkbox, Form, Input, Select, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { nameRegex } from "../../../../utils/validations";
import { formatTitleByIacType } from "../../../../utils/formatting";
import { useDispatch, useSelector } from "react-redux";
import { getIacTypesVersions } from "../../../../redux/actions/ciWorkflowsActions";

import './workflowsSteps.scss';

const validateNameRules = [
    {
      required: true,
      pattern: nameRegex.regex,
      message: nameRegex.msg,
    },
]

const TerraformWorkflowConfiguration = ({ wizardState = {}, setWizardState, setSubmitDisabled, isEditMode }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });
    const [versionsLoading, setVersionsLoading] = useState(false);

    const selectedIacType = wizardState?.iacType || "";
    const iacVersions = useSelector((state) => state.ciWorkflowsReducer.iacTypesVersions) || {};
    const versionsByIacType = iacVersions[selectedIacType] || [];

    const dispatch = useDispatch();

    useEffect(() => {
      checkValidation();
    }, [wizardState]);
    
    useEffect(() => {
        if (!isEmpty(versionsByIacType)) return;
        setVersionsLoading(true);
        fetchIacVersions();
        setVersionsLoading(false);
    }, [selectedIacType]);

    useEffect(() => {
        if (!isEditMode && !isEmpty(versionsByIacType) && !wizardState.terraformVersion) {
            form.setFieldsValue({ terraformVersion: versionsByIacType[0]});
            onValuesChange({terraformVersion: versionsByIacType[0] });
        }
    }, [versionsByIacType])

    const fetchIacVersions = async () => await dispatch(getIacTypesVersions(selectedIacType));
    
    const checkValidation = () => {
        const { terraformVersion } = wizardState;
        const formValid = terraformVersion;
        setSubmitDisabled(!formValid);
    }

    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const onTfElementChange = (newVal = {}, isSensitiveArr) => {
        const { terraformVariables = [], terraformSensitiveVariables = [] } = wizardState;
        const arrToMap = isSensitiveArr ? terraformSensitiveVariables : terraformVariables;
        const updatedTfVarArray = arrToMap.map((tfVar) => { return tfVar.key === newVal.key ? newVal : tfVar });
        const updatedKey = isSensitiveArr ? 'terraformSensitiveVariables' : 'terraformVariables';
        onValuesChange({ [updatedKey]: updatedTfVarArray });
    }

    const handleDeleteTfElement = (key, isSensitiveArr) => {
        const { terraformVariables = [], terraformSensitiveVariables = [] } = wizardState;
        const arrayToFilter = isSensitiveArr ? terraformSensitiveVariables : terraformVariables;
        const updatedTfVarArray = arrayToFilter.filter((tfVar) => tfVar.key !== key);
        const updatedKey = isSensitiveArr ? 'terraformSensitiveVariables' : 'terraformVariables';
        onValuesChange({ [updatedKey]: updatedTfVarArray });
    }

    const onAddNewTfElements = (isMultiple, isSensitiveArr) => {
        const newVars = [];
        newVars.push({ name: '', value: '', key: uuidv4() });
        if (isMultiple) {
            newVars.push({ name: '', value: '',  key: uuidv4()}, { name: '', value: '',  key: uuidv4()});
        }
        const { terraformVariables = [], terraformSensitiveVariables = [] } = wizardState;
        const arrayKey = isSensitiveArr ? 'terraformSensitiveVariables' : 'terraformVariables';
        const oldArrayValue = isSensitiveArr ? terraformSensitiveVariables : terraformVariables;
        onValuesChange({ [arrayKey]: [...oldArrayValue, ...newVars] });
    };
    const iacTypeTitle = formatTitleByIacType(wizardState?.iacType) || "";

    return (
        <div className="WorkflowsSteps col">
           <span className="bold wb-text">{ t("terraform.title", { iacType: iacTypeTitle }) }</span>
           <span className="form-label">{t("descriptions.terraform", { iacType: iacTypeTitle })}</span>
           <div className="col g10">
           <Form
                name="tf-config-form"
                form={form}
                initialValues={{
                    tfvarsFilePath: wizardState.tfvarsFilePath,
                    terraformVersion: wizardState.terraformVersion,
                    terraformWorkspaceName: wizardState.terraformWorkspaceName,
                }}
           >
              {!versionsLoading && (
              <Form.Item
                      name="terraformVersion"
                      label={`${iacTypeTitle} Version`}
                      tooltip={t("tooltips.terraformVersion", { iacType: iacTypeTitle })}
                      rules={[
                        {
                          required: true,
                          message: `${iacTypeTitle} version selection is a must`,
                        },
                      ]}
                      style={{ flexDirection: "column" }}
                    >
                     <Select
                        placeholder={`Select ${iacTypeTitle} Version`}
                        onSearch={(val) => {
                            if (!val) return;
                            onValuesChange({ terraformVersion: val });
                            form.setFieldsValue({ terraformVersion: val });
                        }}
                        showSearch
                        loading={versionsLoading}
                        disabled={versionsLoading}
                        value={wizardState.terraformVersion || ""}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "30%" }}
                        onChange={(val) => onValuesChange({ terraformVersion: val })}
                      >
                        {versionsByIacType.map((version) => <Select.Option key={version} value={version}>{version}</Select.Option>)}
                      </Select>
                    </Form.Item>)}

                <Form.Item
                    label={`${iacTypeTitle} Vars file path (optional)`}
                    name="tfvarsFilePath"
                    tooltip={t("tooltips.tfVarsFilePath", { iacType: iacTypeTitle })}
                    style={{ flexDirection: "column" }} 
                >
                  <Input placeholder={`${iacTypeTitle} Vars file path`} value={wizardState.tfvarsFilePath || ""} onChange={(e) => onValuesChange({ tfvarsFilePath: e.target.value })}/>
                </Form.Item>

                <Form.Item
                    label={`${iacTypeTitle} Environment Name (optional)`}
                    name="terraformWorkspaceName"
                    tooltip={t("tooltips.tfEnvironment", {iacType: iacTypeTitle})}
                    style={{ flexDirection: "column" }} 
                >
                  <Input placeholder={`${iacTypeTitle} Environment name`} value={wizardState.terraformWorkspaceName || ""} onChange={(e) => onValuesChange({ terraformWorkspaceName: e.target.value })}/>
                </Form.Item>

                <Form.Item
                    label={`${iacTypeTitle} Variables (optional)`}
                    name="terraformVariables"
                    tooltip={t("tooltips.tfVars", { iacType: iacTypeTitle })}
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row WorkflowsSteps__tfConfiguration-vars">
                        <div className="WorkflowsSteps__tfConfiguration-vars-title row g5">
                            <span className="">Name</span>
                            <Tooltip title={t("tooltips.tfVarsName", {iacType: iacTypeTitle})}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </div>
                        <div className="WorkflowsSteps__tfConfiguration-vars-title val row g5">
                            <span className="">Value</span>
                            <Tooltip title={t("tooltips.tfVarsValue", { iacType: iacTypeTitle })}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </div>
                        <span className="row g5">
                            Var?
                            <Tooltip title={t("tooltips.runnerEnvironmentVar")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="col g10">
                        {(wizardState.terraformVariables || []).map((tfVar = {}) => {
                            return (
                                <div className="row g10 WorkflowsSteps-inputsRow" key={tfVar.key}>
                                    <Form.Item name={tfVar.key} rules={validateNameRules} initialValue={tfVar.name} className="WorkflowsSteps__tfConfiguration-vars-input no-margin" key={tfVar.key}>
                                        <Input placeholder="Enter Variable name" value={tfVar.name} onChange={(e) => {
                                            onTfElementChange({ ...tfVar, name: e.target.value })
                                        }}/>
                                    </Form.Item>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Variable value" value={tfVar.value} onChange={(e) => onTfElementChange({ ...tfVar, value: e.target.value })}/>
                                    <Checkbox className="WorkflowsSteps-checkbox" checked={tfVar.isActionVar} onChange={(e) => onTfElementChange({ ...tfVar, isActionVar: e.target.checked })}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteTfElement(tfVar.key)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewTfElements()}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewTfElements(true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label={`${iacTypeTitle} Sensitive Variables (optional)`}
                    name="terraformSensitiveVariables"
                    tooltip={t("tooltips.tfSensitiveVars", { iacType: iacTypeTitle })}
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row WorkflowsSteps__tfConfiguration-vars">
                        <div className="WorkflowsSteps__tfConfiguration-vars-title row g5">
                             <span className="">Name</span>
                             <Tooltip title={t("tooltips.tfSenitiveVarsName")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </div>
                        <div className="WorkflowsSteps__tfConfiguration-vars-title val row g5">
                             <span className="">Secret name (in VCS)</span>
                             <Tooltip title={t("tooltips.tfSenitiveVarsSecretValue")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="col g10">
                        {(wizardState.terraformSensitiveVariables || []).map((tfSensitive = {}) => {
                            return (
                                <div className="row g10 WorkflowsSteps-inputsRow" key={tfSensitive.key}>
                                    <Form.Item name={tfSensitive.key} rules={validateNameRules} initialValue={tfSensitive.name} className="WorkflowsSteps__tfConfiguration-vars-input no-margin" key={tfSensitive.key}>
                                        <Input placeholder="Enter Secret name" value={tfSensitive.name} onChange={(e) => {
                                            onTfElementChange({ ...tfSensitive, name: e.target.value  }, true)
                                        }}/>
                                    </Form.Item>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Secret value (VCS)" value={tfSensitive.value} onChange={(e) => onTfElementChange({ ...tfSensitive, value: e.target.value }, true)}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteTfElement(tfSensitive.key, true)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewTfElements(false, true)}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewTfElements(true, true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
         </Form>
        </div>
       </div>
    );
};

export default TerraformWorkflowConfiguration;