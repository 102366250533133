import getIn from 'lodash/get';
import setIn from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import isBoolean from 'lodash/isBoolean';
import { WORKFLOWS_ACTIONS } from '../../../consts/ci-workflows';

export const GUARDRAILS_TYPES = {
    cost: 'cost',
    policy: 'policy',
    resource: 'resource',
    tag: 'tag',
};
export const ACTIONS = [WORKFLOWS_ACTIONS.delete, WORKFLOWS_ACTIONS.create, WORKFLOWS_ACTIONS.update, WORKFLOWS_ACTIONS.import, WORKFLOWS_ACTIONS.createdelete, WORKFLOWS_ACTIONS.deletecreate];

const toIncExc = (key) => ['include', 'exclude'].map((incExc) => `${key}.${incExc}`);

export const NOTIFICATIONS_PROVIDERS = {
    email: 'email',
    slackApp: 'slackApp',
    webex: 'webex',
    pagerduty: 'pagerduty',
};
export const TAG_ENFORCEMENTS_MODES = ["Tags Missing Entirely", "Specific Tag Missing"];

export const checkNotificationValidation = (notification = {}) => {
    const { id = "", service = "", channels = []} = notification;
    if (!id) return true;
    let notificationIsValid = false;
    switch (service) {
        case NOTIFICATIONS_PROVIDERS.slackApp: case NOTIFICATIONS_PROVIDERS.webex: case NOTIFICATIONS_PROVIDERS.email:
            notificationIsValid = channels.length > 0;
            break;
        default: return true;
    }
    return notificationIsValid;
}
export const checkTagValidation = (tag, selectedType) => {
    if (selectedType !== GUARDRAILS_TYPES.tag || isEmpty(tag) || tag.tagEnforcementMode) return true;
    return tag.requiredTags?.length > 0;
}
export const formatGuardrailBeforeResponse = (data = {}, isPercentage) => {
    const { type, criteria = {}, scope = {}, name, createdBy, id, notification = {} } = data;
    const { resource = {}, cost = {}, policy = {}, tag = {} } = criteria;
    const formattedScope = formatEmptyFullArrays(scope, [...toIncExc('branches'),...toIncExc('workspaces'), ...toIncExc('labels'), ...toIncExc('repositories')]);
    const obj = { type, scope: formattedScope, name, createdBy, isEnabled: true, criteria: {}, id, notification };
    switch (type) {
        case GUARDRAILS_TYPES.cost:
        {
            if (isPercentage) {
                cost.thresholdPercentage = cost.thresholdPercentage || 0;
                delete cost.thresholdAmount;
            } else {
                cost.thresholdAmount = cost.thresholdAmount || 0;
                delete cost.thresholdPercentage;
            }
            obj.criteria.cost = cost;
            break;
        }
        case GUARDRAILS_TYPES.policy:
            const formattedPolicy = formatEmptyFullArrays(policy, [...toIncExc('policies')]);
            formattedPolicy.severity = policy.severity;
            obj.criteria.policy = formattedPolicy;
            break;
        case GUARDRAILS_TYPES.resource:
            const formattedResource = formatEmptyFullArrays(resource, ['specificResources',...toIncExc('regions'), ...toIncExc('assetTypes')]);
            formattedResource.actions = (isEmpty(resource.actions) || resource.actions[0]?.toLowerCase() === 'all') ? ACTIONS : resource.actions;
            obj.criteria.resource = formattedResource;
            break;
        case GUARDRAILS_TYPES.tag:
            const formattedTag = formatEmptyFullArrays(tag, ['requiredTags', ...toIncExc('regions'), ...toIncExc('assetTypes')]);

            formattedTag.tagEnforcementMode = isBoolean(tag.tagEnforcementMode) ? tag.tagEnforcementMode : true;
            if (formattedTag.tagEnforcementMode) {
                delete formattedTag.requiredTags;
            }
            obj.criteria.tag = formattedTag;
        default: break;
    }
    return obj;
}

export const getCostToggleValue = (isEditMode, costObj = {}) => {
    if (!isEditMode) return 0;
    if (isNumber(costObj.thresholdAmount)) return 0;
    return 1;
}

export const formatEditData = (data = {}) => {
    const { type, criteria = {}, scope = {}, name, createdBy, isEnabled, id, notification = {} } = data;
    const { resource = {}, policy = {}, cost = {}, tag = {}} = criteria;
    const formattedScope = initialiazeSection(scope, [...toIncExc('branches'),...toIncExc('workspaces'), ...toIncExc('labels'), ...toIncExc('repositories')]);
    const obj = { type, scope: formattedScope, name, createdBy, isEnabled, criteria: {}, id, notification };
    switch (type) {
        case GUARDRAILS_TYPES.cost:
            obj.criteria.cost = cost;
            break;
        case GUARDRAILS_TYPES.policy:
            const formattedPolicy = initialiazeSection(policy, [...toIncExc('policies')]);
            formattedPolicy.severity = policy.severity;
            obj.criteria.policy = formattedPolicy;
            break;
        case GUARDRAILS_TYPES.resource:
            const formattedResource = initialiazeSection(resource, ['actions', 'specificResources',...toIncExc('regions'), ...toIncExc('assetTypes')]);
            obj.criteria.resource = formattedResource;
            obj.type = GUARDRAILS_TYPES.resource;
            break;
        case GUARDRAILS_TYPES.tag:
            const formattedTag = initialiazeSection(tag, ['requiredTags', ...toIncExc('regions'), ...toIncExc('assetTypes')]);
            formattedTag.tagEnforcementMode = tag.tagEnforcementMode;
            if (formattedTag.tagEnforcementMode) {
                delete formattedTag.requiredTags;
            }
            obj.criteria.tag = formattedTag;
            break;
        default: break;
    }
    return obj;
};

export const formatEmptyFullArrays = (section, keys = []) => {
    const formattedSection = {};
    for (const key of keys) {
        const isExcludeKey = key?.toLowerCase()?.includes('exclude');
        const value = getIn(section, key, []);
        const shouldBeAll = (!isExcludeKey && !value.length) || value[0]?.toLowerCase() === 'all'
        setIn(formattedSection, key, shouldBeAll ? ['*'] : value);
    }
    return formattedSection;
}
export const initialiazeSection = (section, keys = []) => {
    const formattedSection = {};
    for (const key of keys) {
        const value = getIn(section, key, []);
        const shouldBeEmptyArray = value?.[0] === '*';
        setIn(formattedSection, key, shouldBeEmptyArray ? [] : value);
    }
    return formattedSection;
};

const arrayWithAll = ['All'];
export const formatAsetrixToAll = (element, isExcludeIncludeObj = false) => {
    if (!isExcludeIncludeObj) return element?.length === 1 && element?.[0] === '*' ? arrayWithAll : element;
    const obj = { include: [], exclude: [] };
    const { include, exclude } = element;
    obj.include = include?.length === 1 && include?.[0] === '*' ? arrayWithAll : include;
    obj.exclude = exclude?.length === 1 && exclude?.[0] === '*' ? arrayWithAll : exclude;
    return obj;

}
export const formatCostAmount = (value) => value > 0 ? `+$${value}` : value < 0 ? `-$${Math.abs(value)}` : `$${value}`;