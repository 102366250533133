import React from "react";
import isEmpty from "lodash/isEmpty";
import Configuration from "../../../../inventory/assetDataModal/assetConfig/configuration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import PlanDrift from "./planDrift";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";
import { sendEvent } from "../../../../../utils/amplitude";
import { CiEvents } from "../../../../../utils/amplitudeEvents";

import { WORKFLOWS_ACTIONS } from "../../../../../consts/ci-workflows";

const ResourcePlan = ({ item, action, workspaceId }) => {
    const isImport = action === WORKFLOWS_ACTIONS.import;
    const isCreateAction = [WORKFLOWS_ACTIONS.create, WORKFLOWS_ACTIONS["no-op"]].includes(action) || isImport;
    const isDeleteAction = action === WORKFLOWS_ACTIONS.delete;
    
    if (isEmpty(item)) {
        return (
            <div className="ResourcePlan">
                <AppEmpty text="No data" customStyle="code" />
            </div>
        )
    }
    const json = JSON.stringify(item);
    
    const onClickAction = (action) => {
        const isCopyActionClicked = action === 'copy';
        if (isCopyActionClicked) {
            sendEvent(CiEvents.ciRunResourceCopyClick, { workspaceID: workspaceId })
        }
    };

    return (
        <div className="ResourcePlan col">
            {isCreateAction ? 
            (<div className="ResourcePlan__cont col g10">
                <div className="row g8">
                    <FontAwesomeIcon className="ResourcePlan__icon create" icon={faCheckCircle} />
                    <span className="ResourcePlan__title create">{`${isImport ?'Importation' : 'Desired'}`} configuration</span>
                </div>
                <Configuration item={json} onClickAction={onClickAction} />
            </div> ) 
            : isDeleteAction ? ( 
             <div className="ResourcePlan__cont col g10">
                <div className="row g8">
                    <FontAwesomeIcon className="ResourcePlan__icon delete" icon="times-circle" />
                    <span className="ResourcePlan__title delete">Destroy configuration</span>
                </div>
                <Configuration item={json} onClickAction={onClickAction}/>
            </div> )
            : <PlanDrift diffs={item} workspaceId={workspaceId}/>}
        </div>
    )
};

export default ResourcePlan;