import "./codeDiff.scss";
import _ from "lodash";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { v4 as uuidv4 } from "uuid";

const CodeDiff = ({ obj, lang, oldValue, newValue, hideLineNumbers = true, showDiffOnly = false}) => {
  const isDarkTheme = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const diffStyles = {
    variables: {
      dark: {
        diffViewerBackground: "transparent",
        addedBackground: "#78dbc94f",
        addedColor: "white",
        removedBackground: "#e36f7e78",
        removedColor: "white",
        wordAddedBackground: "#78dbc94f",
        wordRemovedBackground: "#e36f7e78",
        addedGutterBackground: "#034148",
        removedGutterBackground: "#e36f7e78",
        gutterBackground: "transparent",
        gutterBackgroundDark: "#262933",
        highlightBackground: "#2a3967",
        highlightGutterBackground: "#2d4077",
        codeFoldGutterBackground: "transparent",
        codeFoldBackground: "transparent",
        emptyLineBackground: "#363946",
        gutterColor: "#464c67",
        addedGutterColor: "#8c8c8c",
        removedGutterColor: "#8c8c8c",
        codeFoldContentColor: "#555a7b",
        diffViewerTitleBackground: "#2f323e",
        diffViewerTitleColor: "#555a7b",
        diffViewerTitleBorderColor: "#353846",
      },
    },
    diffContainer: {
      overflowX: "auto",
      display: "block",
      "& pre": { whiteSpace: "pre", maxWidth: "100%" },
    },
  };

  const driftDiffStyles = {
    variables: {
      dark: {
        diffViewerBackground: "transparent",
        addedBackground: "#78dbc94f",
        addedColor: "white",
        removedBackground: "#e36f7e78",
        removedColor: "white",
        wordAddedBackground: "#78dbc94f",
        wordRemovedBackground: "#e36f7e78",
        addedGutterBackground: "#034148",
        removedGutterBackground: "#e36f7e78",
        gutterBackground: "transparent",
        gutterBackgroundDark: "#262933",
        highlightBackground: "#2a3967",
        highlightGutterBackground: "#2d4077",
        codeFoldGutterBackground: "transparent",
        codeFoldBackground: "transparent",
        emptyLineBackground: "#363946",
        gutterColor: "#464c67",
        addedGutterColor: "#8c8c8c",
        removedGutterColor: "#8c8c8c",
        codeFoldContentColor: "#555a7b",
        diffViewerTitleBackground: "#2f323e",
        diffViewerTitleColor: "#555a7b",
        diffViewerTitleBorderColor: "#353846",
      },
    },
    diffContainer: {
      overflowX: "auto",
      display: "block",
      "& pre": { whiteSpace: "initial", maxWidth: "100%" },
    },
  };

  const highlightSyntax = (str) => (
      <SyntaxHighlighter
        style={atomDark}
        language={lang || "text"}
        wrapLines={true}
        lineProps={{
          style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
        }}
        
      >
        {str || ""}
      </SyntaxHighlighter>
  );

  return (
    <div className="CodeDiff">
      {!_.isEmpty(obj) ? (
        _.map(_.keys(obj.filesInfo), (filePath) => {
          return (
            <div key={uuidv4()} className="CodeDiff__diffViewer">
              <span className="muted">{filePath}</span>
              <ReactDiffViewer
                oldValue={obj?.filesInfo[filePath]?.oldFileContent || ""}
                newValue={obj?.filesInfo[filePath]?.newFileContent || ""}
                splitView={true}
                renderContent={highlightSyntax}
                compareMethod={DiffMethod.WORDS}
                styles={diffStyles}
                useDarkTheme={true}
              />
            </div>
          );
        })
      ) : (
        <div className="CodeDiff__diffViewer">
          <ReactDiffViewer
            oldValue={oldValue}
            newValue={newValue}
            splitView={true}
            renderContent={highlightSyntax}
            compareMethod={DiffMethod.WORDS}
            styles={isDarkTheme ? driftDiffStyles : {}}
            useDarkTheme={true}
            hideLineNumbers={hideLineNumbers}
            showDiffOnly={showDiffOnly}
          />
        </div>
      )}
    </div>
  );
};

export default CodeDiff;
