import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form, Input } from "antd";
import isEmpty from "lodash/isEmpty";
import Select from "antd/lib/select";
import { v4 as uuidv4 } from "uuid";
import map from "lodash/map";
import CopyBox from "../../../shared/copyBox/copyBox";
import { nicknameRegex, validate } from "../../../utils/validations";
import { getBitbucketWorkspaces } from "../../../redux/actions/vcsActions";
import { getOnPremiseIp } from "../../../redux/actions/integrationsActions";
import { renderIps } from "../../../utils/helpers";

const BitBucketConfiguration = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  
  const dispatch = useDispatch();
  const publicIps = useSelector((state) => state.integrationsReducer.publicIps);

  const [onpremOpen, setOnpremOpen] = useState(false);
  const [loadingIps, setLoadingIps] = useState(false);
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(false);
  const [workspaceOptions, setWorkspaceOptions] = useState([]);
  
  useEffect(() => {
    if (isEmpty(publicIps)) {
      handleFetchOnpremIPS();
    }
  }, []);

  const handleFetchOnpremIPS = async () => {
    setLoadingIps(true);
    await dispatch(getOnPremiseIp());
    setLoadingIps(false);
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { username, name, workspace, password, domain } = formValues;

    if (isEmpty(name) || isEmpty(workspace) && !domain || isEmpty(password) ||  isEmpty(username)) {
      formValid = false;
    }

    if (!isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    setSubmitDisabled(!formValid);
    if (!isEmpty(username) && !isEmpty(password) && !domain) {
      getWorkspaces(username, password);
    }
  };

  const getWorkspaces = async (username, password) => {
    setLoadingWorkspaces(true);
    const workspaces = await dispatch(
      getBitbucketWorkspaces(username, password)
    );
    setWorkspaceOptions(workspaces);
    setLoadingWorkspaces(false);
  };

  return (
    <div className="BitBucketConfiguration col" style={{ gap: "30px" }}>
      <div
        className="BitBucketConfiguration__intro col"
        style={{ gap: "12px" }}
      >
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue BitBucketConfiguration__intro-list">
          <li key="1">Log in to your Bitbucket account.</li>
          <li key="2">Select your avatar from the navigation bar at the top of the window.</li>
          <li key="3">
            Select <span className="bold">Settings</span>
            {` > `} <span className="bold">Personal settings</span>.
          </li>
          <li key="4">
            Go to the left pane and select <span className="bold">App passwords</span>.
          </li>
          <li key="5">
            Select <span className="bold">Create app password</span>.
          </li>
          <li key="6">
            Enter a <span className="bold">Label</span> and add the following scopes:  
            <CopyBox
              text={`repositories:read, repositories:write, pullrequest:write, pullrequest:read, account:read, workspacemembership:read, webhooks:read, webhooks:write`}
            />
          </li>
          <li key="7">
            Select <span className="bold">Create</span> and copy the password.
          </li>
          <li key="8">
            In Firefly, enter the required information and paste the password in the <span className="bold">App Password</span> box.
          </li>
          <li key="9">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="bitbucket-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Bitbucket Integration",
          workspace: wizardState.workspace || "",
          password: wizardState.password || "",
          username: wizardState.username || "",
          domain: wizardState.domain || "",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Username"
          name="username"
          rules={[
            {
              required: true,
              message: "Username is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="App Password"
          name="password"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          rules={[
            {
              required: true,
              message: "App Password is required",
            },
          ]}
        >
          <Input.Password placeholder="" />
        </Form.Item>
        <Form.Item
          label="Workspace"
          name="workspace"
          rules={[
            {
              required: true,
              message: "Workspace is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder="Select Workspace"
            loading={loadingWorkspaces}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {map(workspaceOptions || [], (workspace) => (
              <Select.Option key={uuidv4()} value={workspace?.slug}>
                {workspace?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div className="GitlabConfiguration__onprem">
          <Checkbox
            checked={onpremOpen}
            onChange={() => {
              setOnpremOpen(!onpremOpen);
            }}
          >
            <span className="form-label">Are you using Bitbucket Data Center?</span>
          </Checkbox>

          {onpremOpen && (
            <Form.Item
              label="Bitbucket Data Center custom domain"
              name="domain"
              style={{
                marginBottom: "1.5rem",
                flexDirection: "column",
                marginTop: "20px",
              }}
              help={
                <span
                  className="small-dark-blue"
                  style={{
                    opacity: 0.7,
                    fontSize: "12px",
                    transform: "translateY(10px)",
                  }}
                >
                  {loadingIps ? "loading.." : renderIps(publicIps)}
                </span>
              }
            >
              <Input placeholder="" />
            </Form.Item>
          )}
        </div>
      </Form>
    </div>
  );
};

export default BitBucketConfiguration;
