import React from "react";
import './typingDots.scss';

const TypingDots = ({ isInText }) => {
  return (
    <div className={`TypingDots ${isInText ? 'text': ''}`}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypingDots;
