import React from "react";
import isEmpty from "lodash/isEmpty";
import Tooltip from "antd/lib/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./appBtn.scss";

const AppBtn = ({
  text,
  onClick,
  icon,
  disabled,
  secondary,
  plain,
  style,
  loading,
  tooltipText,
  whiteOutlined,
  gradientOutlined,
  suffixIcon
}) => {
  const handleClick = (e) => {
    if (disabled) {
      return false;
    }
    return onClick(e);
  };

  return (
    <Tooltip
      title={!isEmpty(tooltipText) ? tooltipText : ""}
      placement="top"
    >
      <button
        className={`AppBtn row 
        ${disabled ? "disabled" : ""} 
        ${secondary ? "secondaryBtn" : ""} 
        ${plain ? "plain" : ""} 
        ${gradientOutlined ? "gradientOutlined" : ""} 
        ${isEmpty(text) || !text ? "icon-only" : ""}
        ${whiteOutlined ? "whiteOutlined" : ""}`}
        onClick={handleClick}
        style={style}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin className="AppBtn__icon" />
        ) : icon ? (
          <span className="AppBtn__icon">{icon}</span>
        ) : null}
        {!isEmpty(text) && <span className="AppBtn__text"> {text}</span>}
        {suffixIcon && !loading && <span className="AppBtn__icon suffix">{suffixIcon}</span>}
      </button>
    </Tooltip>
  );
};

export default AppBtn;
