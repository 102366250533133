import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DownloadIcon } from "../../Images/general_icons/download_icon.svg"
import { ReactComponent as CopyIcon } from "../../Images/general_icons/copy_icon.svg"
import { ReactComponent as JsonIcon } from "../../Images/general_icons/json_icon.svg"
import { downloadFile, handleCopy } from "../../utils/helpers";

import "./actionIcon.scss";

const ActionIcon = ({
  disabled,
  action,
  stringToAction,
  onClick,
  customActionClassName="",
  customClassName="",
  fileName,
  fileType,
}) => {
  const [actionSuccess, setActionSuccess] = useState(false);

  useEffect(() => {
    setActionSuccess(false);
  }, []);

  useEffect(() => {
    if (actionSuccess) {
      setTimeout(() => {
        setActionSuccess(false);
      }, 2000);
    }
  }, [actionSuccess]);

  const handleDifferentActions = () => {
    if (disabled) {
      return false;
    }
    switch (action) {
      case "copy":
        handleCopy(stringToAction);
        setActionSuccess(true);
        break;
      case "download":
        downloadFile(stringToAction, fileType, fileName);
        setActionSuccess(true);
        break;
      default:
        break;
    }
  };

  const renderSelectedIcon = () => {
    switch (action) {
      case "copy":
        return <CopyIcon className={customActionClassName}/>;
      case "donwload":
        return <DownloadIcon className={customActionClassName}/>;
      case "json":
        return <JsonIcon className={customActionClassName}/>
      default:
        break;
    }
  };

  const renderIconBasedSuccess = () => {
    if (actionSuccess) {
        return <FontAwesomeIcon icon={faCheck} />;
    }
    return renderSelectedIcon();
  };

  return (
    <div className={`ActionIcon ${customClassName} ${actionSuccess ? "action-success": ""}`}
      onClick={onClick ? onClick : handleDifferentActions}
    >
      {renderIconBasedSuccess()}
    </div>
  );
};

export default ActionIcon;
