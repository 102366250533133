import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Select, Checkbox, Input } from "antd";
import { getAllVcs } from "../../../redux/actions/globalAppActions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Loading from "../../../shared/loading/loading";
import { BACKEND_URL } from "../../../consts/config";
import RequestWrapper from "../../../utils/requestWrapper";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import { S3_ASSETS } from "../../../consts/config";
import { getAllProvidersArray } from "../../../utils/formatting";
import { getVcsBranches } from "../../../redux/actions/vcsActions";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import SearchInput from "./searchInput";
import BrowseRepositoryModal from "./browseRepositoryModal/browseRepositoryModal";
import { clouds } from "../../../utils/icons";

import "./pullRequestForm.scss";

const PullRequestForm = ({ close, visible, selectedResources, ampliSource = "" }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingGithub, setLoadingGithub] = useState(true);
  const [loadingRepos, setLoadingRepos] = useState(false);
  const [availableRepos, setAvailableRepos] = useState([]);
  const [pullRequestUrl, setPullRequestUrl] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [publicRepo, setPublicRepo] = useState(false);
  const [loadingSearchRepos, setLoadingSearchRepos] = useState(false);
  const [prComment, setPrComment] = useState("");
  const [loadingBranches, setLoadingBranches] = useState(false);
  const [availableBranches, setAvailableBranches] = useState([]);
  const [isDefualtBranchSelected, setIsDefualtBranchSelected] = useState(false);
  const [isBrowseOpen, setIsBrowseOpen] = useState(false);
  const [selectedVcsType, setSelectedVcsType] = useState("");

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const vcsIntegs = useSelector((state) => state.globalAppReducer.vcs);
  const smartCodifyParams = useSelector((state) => state.iacImportReducer.pullReqParams);
  const requestWrapper = new RequestWrapper();
  const { TextArea } = Input;
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const isMultipleFilesPR = smartCodifyParams?.preparedContent?.length > 1;
  const allVcsRepos = useCallback(getAllProvidersArray(vcsIntegs), [vcsIntegs]);

  // fetch github if drawer is open
  useEffect(() => {
    if (visible) {
      setPublicRepo(false);
      form.resetFields();
      setPrComment("");
      fetchVcs();
    }
  }, [visible]);

  // fetch repos again if toggle changes
  useEffect(() => {
    if (visible) {
      const vcsValue = form.getFieldValue("vcs");
      if (!_.isUndefined(vcsValue)) {
        onChangeVcs(vcsValue);
      }
    }
  }, [visible, publicRepo]);

  const fetchVcs = async () => {
    setLoadingGithub(true);
    await Promise.all([dispatch(getAllVcs())]);
    setLoadingGithub(false);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    const repository = form.getFieldValue("repository");
    const vcsId = form.getFieldValue("vcs");
    const selectedBranch = form.getFieldValue("branch");
    const fileName = form.getFieldValue("fileName");
    let iacType = "terraform";
    if (
      _.startsWith(selectedResources[0]?.provider, "k8s") ||
      _.startsWith(selectedResources[0]?.provider, "kubernetes")
    ) {
      iacType = "k8s";
    }
    else if (smartCodifyParams?.iacType) {
      iacType = smartCodifyParams.iacType;
    }
    const pullRequest = await requestWrapper.sendRequest(
      `${BACKEND_URL}/reverseLearning/pullRequest?version=1`,
      "POST",
      {
        repository,
        vcsId,
        iacType: iacType,
        resources: _.map(selectedResources, (resource = {}) => ({
          assetId: `${resource.accountId}:${resource.assetId}`,
          assetType: resource.assetType,
          driftNumber: resource.driftNumber,
          provider: resource.provider,
          providerId: resource.integrationId,
          revisionId: resource.revisionId,
          isDeleted: resource.state === "deleted",
        })),
        codificationData: smartCodifyParams,
        prComment,
        selectedBranch,
        fileName,
      }
    );

    // send product event
    const eventName = `${inventoryEvents.createPullRequest} ${ampliSource}`
    sendEvent(eventName, {
      vcsId,
      repository,
      resources: _.map(selectedResources, (resource) => ({
        assetId: resource.assetId,
        assetType: resource.assetType,
        driftNumber: resource.driftNumber,
        provider: resource.provider,
        providerId: resource.integrationId,
      })),
      prComment,
      selectedBranch,
      fileName,
    });

    if (pullRequest?.ok) {
      const result = await pullRequest.json();
      if (!_.isEmpty(result)) {
        setPullRequestUrl(result.url);
      }
    }
    setLoadingSubmit(false);
  };

  const handleCloseDrawer = async () => {
    setLoadingSubmit(false);
    setLoadingGithub(false);
    setLoadingRepos(false);
    setFormValid(false);
    setPullRequestUrl(null);
    form.resetFields();
    close();
  };

  const renderVcsOptions = () => {
    return _.map(allVcsRepos, (app) => (
      <Select.Option value={app?.id} key={app?.id}>
        <span className="PullRequestForm__content-body-vcs row">
          <img src={clouds(app.type, themeDark)} alt="integration" />
          {app.name}
        </span>
      </Select.Option>
    ));
  };

  const renderBranchOptions = () => {
    return _.map(availableBranches || [], (branch) => (
      <Select.Option value={branch?.name} key={branch?.name}>
        {`${branch?.name} ${branch?.default ? " (Default Branch)" : ""}`}
      </Select.Option>
    ));
  };


  const getVcsTypeById = (vcsId) => {
    let type = "github";
    for (let vcsType of _.keys(vcsIntegs)) {
      if (vcsIntegs[vcsType]?.find((integ) => integ?.id?.includes(vcsId))) {
        type = vcsType;
        break;
      }
    }
    return type;
  };

  const onChangeVcs = async (vcsId) => {
    setLoadingRepos(true);

    // on change integ reset form fields
    form.resetFields(["repository", "branch"]);

    const type = getVcsTypeById(vcsId);
    const vcsReposReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/vcs/${type}/${vcsId}/repos?public=${publicRepo}`,
      "GET"
    );

    if (vcsReposReq?.ok) {
      const repos = await vcsReposReq?.json();
      setAvailableRepos(repos);
      setSelectedVcsType(type)
    } else {
      setAvailableRepos([]);
    }
    return setLoadingRepos(false);
  };

  const getRepoOptions = async (repoName) => {
    const vcsId = form.getFieldValue("vcs");
    if (!_.isUndefined(vcsId)) {
      setLoadingSearchRepos(true);
      const type = getVcsTypeById(vcsId);
      const vcsReposReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/vcs/${type}/${vcsId}/repos?public=${publicRepo}&repoName=${repoName}`,
        "GET"
      );

      if (vcsReposReq?.ok) {
        const repos = await vcsReposReq?.json();
        setAvailableRepos(repos);
      } else {
        setAvailableRepos([]);
      }

      return setLoadingSearchRepos(false);
    }
  };

  const onChangeRepo = async (repo) => {
    setLoadingBranches(true);
    form.setFieldsValue({ branch: null });
    const vcsId = form.getFieldValue("vcs");
    const type = getVcsTypeById(vcsId);
    let req = await dispatch(getVcsBranches(type, vcsId, repo));

    // add flag "defualt branch"
    const repoDefaultBranch = _.find(
      availableRepos || [],
      (item) => item?.fullName === repo
    )?.defaultBranch;
    const newBranched = _.map(req || [], (item) => {
      return { ...item, default: item?.name === repoDefaultBranch };
    });

    // set the defualt branch in the form as selected
    const findDefualtBranch = _.find(
      newBranched || [],
      (item) => item?.default
    );
    if (findDefualtBranch) {
      setIsDefualtBranchSelected(true);
      form.setFieldsValue({ branch: findDefualtBranch?.name });
    }
    setAvailableBranches(newBranched);
    return setLoadingBranches(false);
  };

  const renderSelectByVcsSelection = () => {
    const vcsId = form.getFieldValue("vcs");
    if (!_.isUndefined(vcsId)) {
        return (
          <SearchInput
            options={availableRepos}
            setFormValid={setFormValid}
            getOptions={(repoName) => getRepoOptions(repoName)}
            loading={loadingRepos || loadingSearchRepos}
            setRepositoryValue={(val) => {
              form.setFieldsValue({ repository: val });
              onChangeRepo(val);
            }}
          />
        );
    }
  };

  const checkFormChanges = (changedValues) => {
    // check if selected brach is the default one.
    if (_.has(changedValues, "branch")) {
      const isDefault = _.find(
        availableBranches || [],
        (item) => item?.name === changedValues?.branch
      )?.default;
      return setIsDefualtBranchSelected(isDefault);
    }
  };

  const renderFilenameLabel = () => {
    return (
      <div className="row between" style={{ width: "100%", marginLeft: "6px" }}>
        <span>{`${isMultipleFilesPR ? 'Directory' : 'File'} Path (Optional)`}</span>
        {isDefualtBranchSelected && !isCodeCommitSelected && !isMultipleFilesPR && (
          <span
            className="purple-text browse-repo-link"
            onClick={handleOpenBrowseRepo}
          >
            Browse Repository
          </span>
        )}
      </div>
    );
  };

  const handleOpenBrowseRepo = () => {
    return setIsBrowseOpen(true);
  };

  const isCodeCommitSelected = selectedVcsType === 'codecommit'

  return (
    <AppDrawer
      closeDrawer={handleCloseDrawer}
      visible={visible}
      title="Open pull request"
      topBorder
      zIndex={1002}
      footer={[
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={loadingSubmit}
          className="app-form-button"
          disabled={!formValid || !_.isEmpty(pullRequestUrl)} // if not selected a repository
          key="open-pr"
        >
          Create
        </Button>,
      ]}
    >
      <div className="PullRequestForm__content">
        <Form
          name="pull-request-form"
          onFinish={handleSubmit}
          form={form}
          onValuesChange={checkFormChanges}
        >
          {loadingGithub && (
            <div className="PullRequestForm__content-body tab-page center">
              <Loading />
            </div>
          )}

          {!loadingGithub && _.isEmpty(pullRequestUrl) && (
            <div className="PullRequestForm__content-body">
              <Form.Item
                label="Select VCS Integration"
                name="vcs"
                help={
                  !loadingGithub && _.isEmpty(allVcsRepos) ? (
                    <div className="no-vcs-integs col">
                      <Link to="/integrations/github-integration">
                        + Add GitHub Integration
                      </Link>
                      <Link to="/integrations/gitlab-integration">
                        + Add GitLab Integration
                      </Link>
                    </div>
                  ) : null
                }
                rules={[
                  {
                    required: true,
                    message: "Integration is required",
                  },
                ]}
                style={{ marginBottom: "1.5rem", flexDirection: "column" }}
              >
                <Select
                  placeholder=""
                  showSearch
                  filterOption={(input, option) =>
                    option?.children?.props?.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={loadingRepos || loadingSearchRepos}
                  style={{ width: "100%" }}
                  onChange={onChangeVcs}
                >
                  {renderVcsOptions()}
                </Select>
              </Form.Item>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.vcs !== currentValues.vcs
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("vcs") && !loadingGithub ? (
                    <Form.Item
                      name="repository"
                      label="Select Repository"
                      rules={[
                        {
                          required: true,
                          message: "Repository selection is a must",
                        },
                      ]}
                      style={{
                        marginBottom: "0.5rem",
                        flexDirection: "column",
                      }}
                    >
                      {renderSelectByVcsSelection()}
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              {selectedVcsType && !isCodeCommitSelected && !loadingGithub && (
                <div className="col" style={{ marginBottom: "20px" }}>
                  <Checkbox
                    checked={publicRepo}
                    onChange={() => setPublicRepo(!publicRepo)}
                    disabled={loadingRepos}
                  >
                    <span className="form-label">
                      Show only private repositories
                    </span>
                  </Checkbox>
                </div>
              )}

              {!_.isEmpty(form.getFieldValue("repository")) &&
                !loadingRepos && (
                  <div>
                    <Form.Item
                      label="Target Branch (Optional)"
                      name="branch"
                      style={{
                        marginBottom: "1.5rem",
                        flexDirection: "column",
                      }}
                    >
                      <Select
                        placeholder=""
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                        loading={loadingBranches}
                        // onChange={onChangeVcs}
                      >
                        {renderBranchOptions()}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label={renderFilenameLabel()}
                      name="fileName"
                      tooltip={`${isMultipleFilesPR ? 'Files' : 'Content'} will be appended to existing ${isMultipleFilesPR ? 'folder' : 'files'}`}
                      style={{
                        marginBottom: "1.5rem",
                        flexDirection: "column",
                      }}
                      className="fileName-form-block"
                    >
                      <Input placeholder={`Example: /folder${!isMultipleFilesPR ? '/file.tf' : ''}`} />
                    </Form.Item>

                    <div className="col">
                      <span className="form-label">
                        {`Pull Request Comment (Optional)`}
                      </span>
                      <TextArea
                        value={prComment}
                        rows={4}
                        onChange={(e) => setPrComment(e?.target?.value)}
                        placeholder=""
                      />
                    </div>
                  </div>
                )}
            </div>
          )}

          {!_.isEmpty(pullRequestUrl) && (
            <div className="PullRequestForm__content-success col center">
              <img
                src={`${S3_ASSETS}/illustrations/rocket-computer.svg`}
                alt="rocket"
              />
              <span className="title centered">You are done!</span>
              <span className="centered small-title PullRequestForm__content-success-subtitle">
                <a
                  href={pullRequestUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Take a look at your Pull request
                </a>
              </span>
            </div>
          )}
        </Form>
      </div>
      <BrowseRepositoryModal
        visible={isBrowseOpen}
        handleClose={() => setIsBrowseOpen(false)}
        repo={form.getFieldValue("repository")}
        vcsId={form.getFieldValue("vcs")}
        type={getVcsTypeById(form.getFieldValue("vcs"))}
        selectedPath={(path) => {
          form.setFieldsValue({ fileName: path });
          return setIsBrowseOpen(false);
        }}
      />
    </AppDrawer>
  );
};

export default PullRequestForm;
