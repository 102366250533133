import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import WebexCreation from "./webexCreation";
import { PROVIDERS } from "../../../consts/general";
import { createProviderIntegration } from "../../../redux/actions/integrationsActions";

import { integrationIcons } from "../../../utils/icons";
import "./webexIntegration.scss";

const WebexIntegration = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation("common", { keyPrefix: "wizard" });

  const { search } = location;

  const [wizardState, setWizardState] = useState({
    webhookUrl: "",
    username: "",
    password: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [appIntegrationDone, setAppIntegrationDone] = useState(false);

  useEffect(() => {
    if (search) {
      handleCreateApp();
    }
  }, [search]);

  const handleCreateApp = async () => {
    setSubmitDisabled(false);
    setLoadingSubmit(true);

    const searchParam = new URLSearchParams(search);
    const appCode = searchParam.get("code");
    const appNickname = !_.isEmpty(wizardState?.name) ?  wizardState.name : 'Firefly Webex app';

    const appCreation = await dispatch(
      createProviderIntegration(PROVIDERS.webex, {name: appNickname, code: appCode})
    );

    if (appCreation) {
      setAppIntegrationDone(true);
      setLoadingSubmit(false);
      return;  
    } else {
      setSubmitDisabled(true);
      setLoadingSubmit(false);
    }
  };

  const configWizard = [
    {
      step_title: t("setup"),
      step_description: t("step1_description", { integrationName: PROVIDERS.webex }),
      content_title: t("step1_content_title", { integrationName: PROVIDERS.webex }),
      content: (
        <WebexCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
    {
      step_title: t("completion"),
      step_description: t("completion_description"),
      content_title: t("integrationCreated"),
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="WebexIntegration">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={integrationIcons["webex"]}
        handleAutoComplete={appIntegrationDone}
      />
    </div>
  );
};

export default WebexIntegration;
