import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _, { capitalize } from "lodash";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFilters } from "../../../redux/actions/iacStacksActions";
import { ReactComponent as FullTriangle } from "../../../Images/general_icons/fullTriangle.svg";
import { iacProviders } from "../../../utils/icons";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";

import { IAC_STACKS_MAP, IAC_TYPES } from "../../../consts/general";
import { Tooltip } from "antd";

import "./iacType.scss";

const IacType = ({ type = {}, syncIssues }) => {
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const hoverMode = hover && type.doc_count > 0;
  const isWarning = type.key === "terraform" && syncIssues > 0;

  const toggleHover = () => {
    setHover(!hover);
  };

  const typeClick = () => {
    if (type.doc_count > 0) {
      dispatch(
        setFilters(emptyIacScreenFilters)
      );
      history.push({
        pathname: `/iac`,
        search: `?view=${type.key}`,
      });
    }
  };

  const syncIssueClick = (e) => {
    if (type.doc_count > 0) {
      e.stopPropagation();
      dispatch(
        setFilters({
          ...emptyIacScreenFilters,
          status: ["outOfSync", "partialSync", "corrupted"],
        })
      );
      history.push({
        pathname: `/iac`,
        search: `?view=${type.key}`,
      });
    }
  };

  return (
    <div
      className="IacType center col"
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={typeClick}
    >
      <div className={`IacType ${hoverMode? "hovered" : ""} col g5 basic-card center`}>
        {hoverMode && (
          <div className={`IacType__arrow`} >
            <FontAwesomeIcon icon="angle-right" />
          </div>
        )}
        <div className={`col IacType__type wrapper`}>
          <div className="IacType__type-icon col center">
            <img
              alt="iacType"
              src={iacProviders(`purple${capitalize(type?.key === IAC_TYPES.k8s ? "argo" : type?.key)}`, themeDark)}
              style={{ height: "100%", transition: "all .3s ease", opacity: hoverMode? ".6" : "1" }}
            />
          </div>
          <div className={`IacType__type-title col center ${hoverMode? "hoverTitle" : "" }`}>
            <CountUp start={0} end={type.doc_count} duration={1} />
            <div className={`IacType__titleContainer ${isWarning ? 'warningMode' : ''} row g8`}>
              <span className="IacType__type-title-name centered">
                {IAC_STACKS_MAP[type.key]}
              </span>
              {isWarning && (
                   <Tooltip title={`${syncIssues} Sync Issues`}>
                     <FullTriangle className="IacType__issue-icon-warning" onClick={syncIssueClick} />
                   </Tooltip>
                  )}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default IacType;
