import {
    FETCH_REVISIONS,
    FETCH_REVISION,
    RESET_HISTORY,
    SET_REVISION_ID,
  } from "../actions/historyActionsV2";
  
  import _ from 'lodash';
  
  const initialState = {
    revisions: [],
    revisionsWithoutCurrent: [],
    currentRevision: {},
    requestedRevision: {},
    ignores: [],
    requestedRevisionId: "",
    asset_compare: {},
  };
  
  // data
  export default (state = initialState, { type, payload }) => {
    switch (type) {
      case FETCH_REVISIONS:
        return { ...state, revisions: payload };
  
      case FETCH_REVISION:
        return { ...state, requestedRevision: payload.requested_revision, currentRevision: payload.current_revision };
          
      case SET_REVISION_ID:
        return { ...state, requestedRevisionId: payload };
    
      case RESET_HISTORY:
        return { ...state, revisions: [], asset_revision: {}, asset_compare: {} };
  
      default:
        return state;
    }
  };