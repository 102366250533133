import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AllActiveIntegrationsCatalog } from "../integrationsCatalog/integrations_index";
import { getAllIntegrationsByType } from "../../redux/actions/integrationsActions";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import SingleIntegrationHeader from "../../components/singleIntegration/singleIntegrationHeader/singleIntegrationHeader";
import SingleIntegrationFilters from "../../components/singleIntegration/singleIntegrationFilters/singleIntegrationFilters";
import SingleIntegrationList from "../../components/singleIntegration/singleIntegrationList/singleIntegrationList";
import { PROVIDERS } from "../../consts/general";
import "./singleIntegration.scss";

const SingleIntegration = (props) => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [integrationData, setIntegrationData] = useState({
    integrations: [],
    total: props?.location?.state?.count || 0,
  });
  const [filters, setFilters] = useState({ isEventDriven: null, isProd: null, isSync: null });

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const integsData = useSelector((state) => state.integrationsReducer.integsByType);

  useEffect(() => {
    fetchIntegrations();
  }, [query, filters]);

  useEffect(() => {
    const { isUpdated :isIntegrationDataUpdated, ...updatedData } = integsData || {}
    if (isIntegrationDataUpdated) {
      setIntegrationData(updatedData);
    }
  }, [integsData])

  const typeBasicData = useMemo(() => {
    const catalog = AllActiveIntegrationsCatalog(themeDark);

    for (const categoryKey in catalog) {
      const category = catalog[categoryKey];
      const obj = find(category, { server_name: type });
      if (obj) {
        return obj;
      }
    }
    return null;
  }, [type, themeDark]);

  const typeCategory = useMemo(() => {
    const catalog = AllActiveIntegrationsCatalog(themeDark);

    for (const categoryKey in catalog) {
      const category = catalog[categoryKey];
      const obj = category.find((item) => item.server_name === type);

      if (obj) {
        return categoryKey; // Return the category key
      }
    }

    return null;
  }, [type, themeDark]);

  const size = typeCategory === "cloud" && type != PROVIDERS.gcp ? 10 : 50;

  // if url param type not found
  useEffect(() => {
    if (!loading && isEmpty(typeBasicData)) {
      history.push("/integrations");
    }
  }, [loading, typeBasicData]);

  const fetchIntegrations = async (specificPage) => {
    setLoading(true);
    const req = await dispatch(
      getAllIntegrationsByType(type, specificPage ?? page, size, query, filters)
    );
    setIntegrationData(req);
    setLoading(false);
  };

  const handleSetPage = async (newPage) => {
    setPage(newPage)
    fetchIntegrations(newPage)
  }

  const handleLoadMore = async () => {
    setLoadingMore(true);
    const newPage = page + 1;
    const req = await dispatch(
      getAllIntegrationsByType(type, newPage, size, query, filters)
    );
    const newData = {
      ...integrationData,
      integrations: [...(integrationData?.integrations || []), ...(req?.integrations || [])],
    };
    setIntegrationData(newData);
    setPage(newPage);
    setLoadingMore(false);
  };

  const handleSetQuery = (q) => {
    setPage(1);
    return setQuery(q);
  };

  const handleSetFilters = (obj) => {
    setPage(1);
    return setFilters(obj);
  };

  const handleRefetchIntegrations = () => {
    setPage(1);
    return fetchIntegrations();
  };

  const handleClearFilters = () => {
    return setFilters({ isEventDriven: null, isProd: null, isSync: null });
  }

  const isTableView = typeCategory === "cloud" && type != PROVIDERS.gcp;
  return (
    <div className={`basic-page SingleIntegration col ${isTableView ? 'tableView':''}`}>
      <SingleIntegrationHeader
        data={typeBasicData}
        count={integrationData?.total || 0}
      />
      <SingleIntegrationFilters
        query={query}
        setQuery={handleSetQuery}
        filters={filters}
        setFilters={handleSetFilters}
        loading={loading}
        handleClearFilters={handleClearFilters}
        integrationType={type}
      />
      <SingleIntegrationList
        loading={loading}
        typeData={typeBasicData}
        category={typeCategory}
        handleLoadMore={handleLoadMore}
        integrationData={integrationData}
        loadingMore={loadingMore}
        reFetchIntegrations={handleRefetchIntegrations}
        handleSetPage={handleSetPage}
        currentPage={page}
      />
    </div>
  );
};

export default SingleIntegration;
