import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./historyNavSlideV2.scss";
import { dateFormat } from "../../../../../utils/formatting";

const HistoryNavSlideV2 = ({ active, onClick, time, state, current }) => {
  const NO_STATE = state ? "" : "no-state";

  return (
    <div
      className={`RevisionCompareV2__diff-code date col col center ${
        active ? "active" : ""
      }`}
      onClick={onClick}
    >
      <div>{moment(time).format(dateFormat)}</div>
      <div>{moment(time).format("H:mm")}</div>
    </div>
  );
};

export default HistoryNavSlideV2;