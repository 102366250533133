import React, { memo, useMemo, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setInsightsTableFilters } from "../../../redux/actions/insightsV2Actions";
import TableWrapperV2 from "../../../shared/tableWrapperV2/tableWrapperV2";
import CategoryCell from "./categoryCell";
import AlertCell from "./alertCell";
import AssetNameCell from "./assetNameCell";
import AssetEnableCell from "./assetEnableCell";
import MenuCell from "./menuCell";
import IssueModal from "../../inventory/issueModal/issueModal";
import MatchingAssetCell from "./matchingAssetCell";
import SavingsCell from "./savingsCell";
import SeverityCell from "./severityCell";
import DataSourceCell from "./dataSourceCell";
import SuggestionsModal from "../suggestionsModal/suggestionsModal";
import GovernanceDrawer from "../governanceDrawer/governanceDrawer";
import AssetTypeRowCellV2 from "./assetTypeRowCellV2";
import AssetTypeRowCell from "./assetTypeRowCell";
import "./insightsTable.scss";
import ComplianceCell from "./complianceCell";

const InsightsTable = ({ loading }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("insights");
  const insights = useSelector((state) => state.insightsV2Reducer);
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState(null);
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [codeDrawerOpen, setCodeDrawerOpen] = useState(false);

  const handlePageChange = (newPage) => {
    const newFilters = { ...insights.tableFilters, page: newPage };
    return dispatch(setInsightsTableFilters(newFilters));
  };

  const handleSortChange = (newSorting) => {
    const newFilters = { ...insights.tableFilters, sorting: newSorting };
    return dispatch(setInsightsTableFilters(newFilters));
  };

  const handleCloseIssueModal = useCallback(() => {
    setIssueModalOpen(false);
  }, [issueModalOpen, selectedInsight]);

  const handleOpenIssue = useCallback(
    (obj) => {
      // format insight obj for jira issue modal
      const newObj = {
        ...obj,
        id: obj?._id,
        labels: [obj.labels],
        count: 0, // TODO ADD THE COUNT HERE
      };
      setSelectedInsight(newObj);
      setIssueModalOpen(true);
    },
    [issueModalOpen, selectedInsight]
  );

  const handleShowSavings = useCallback(
    (e, obj) => {
      if(e) {
        e.stopPropagation();
      }
      setSelectedInsight(obj);
      setSuggestionsOpen(true);
    },
    [suggestionsOpen, selectedInsight]
  );

  const handleCloseCodeDrawer = () => {
    return setCodeDrawerOpen(false);
  }

  const handleRowClick = (params = {}) => {
    const rowData = params.row || {};
    setSelectedInsight(rowData);
    setCodeDrawerOpen(true);
  }

  const columns = useMemo(() => {
    return [
      {
        headerName: t("table.columns.category"),
        field: "category",
        filterable: false,
        width: 150,
        renderCell: (params) => <CategoryCell value={params.value} extraData={params.row.classificationType}/>,
      },
      {
        headerName: t("table.columns.name"),
        field: "name",
        filterable: false,
        flex: 1,
        renderCell: (params) => (
          <AssetNameCell
            name={params.value}
            description={params?.row?.description}
          />
        ),
      },
      {
        headerName: t("table.columns.severity"),
        field: "severity",
        filterable: false,
        width: 80,
        renderCell: (params) => <SeverityCell value={params.value} />,
      },
      {
        headerName: t("table.columns.data-source"),
        field: "providers",
        filterable: false,
        width: 100,
        renderCell: (params) => <DataSourceCell value={params.value} accounts={params.row?.providerIds} />,
      },
      {
        headerName: t("table.columns.asset-type"),
        field: "type",
        filterable: false,
        width: 100,
        renderCell: (params) => <AssetTypeRowCellV2 value={params.value} />,
      },
      {
        headerName: t("table.columns.saving"),
        field: "saving",
        filterable: false,
        sortable: false,
        width: 125,
        renderCell: (params) => (
          <SavingsCell
            row={params.row}
            handleShowSavings={(e) => handleShowSavings(e, params.row)}
          />
        ),
      },
      {
        headerName: t("table.columns.compliance"),
        field: "compliance",
        filterable: false,
        width: 140,
        renderCell: (params) => <ComplianceCell row={params.row} />,
      },
      {
        headerName: t("table.columns.violating"),
        field: "total_assets",
        filterable: false,
        width: 140,
        renderCell: (params) => <MatchingAssetCell row={params.row} />,
      },
      {
        headerName: t("table.columns.notification"),
        field: "isSubscribed",
        filterable: false,
        width: 70,
        renderCell: (params) => <AlertCell value={params.value} />,
      },
      {
        headerName: t("table.columns.enabled"),
        field: "isEnabled",
        filterable: false,
        width: 70,
        renderCell: (params) => (
          <AssetEnableCell value={params.value} id={params.row?._id} type={params.row?.type} />
        ),
      },
      {
        headerName: " ",
        field: "_id",
        type: "boolean",
        width: 30,
        filterable: false,
        sortable: false,
        resizable: false,
        disableColumnMenu: true,
        renderCell: (params) => (
          <MenuCell row={params.row} handleOpenIssue={handleOpenIssue} handleOpenSuggetions={handleShowSavings}/>
        ),
      },
    ];
  }, [selectedInsight, issueModalOpen]);

  return (
    <>
      <div className="basic-card InsightsTable">
        <TableWrapperV2
          rowKey="_id"
          biggerRows
          getRowId={(row) => row._id}
          tableData={insights?.hits || []}
          externalPage={insights.tableFilters?.page - 1}
          loading={loading}
          pageSize={insights.tableFilters?.page_size}
          serverSide
          movePagination
          rowCount={insights?.total || 0}
          handlePageChange={handlePageChange}
          handleSortChange={handleSortChange}
          disableSelectionOnClick
          onRowClick={handleRowClick}
          columns={columns}
          rowsPerPageOptions={[insights.tableFilters?.page_size]}
          getRowClassName={(params) =>
            `InsightsTable__${params.row?.category} ${
              params.row?.isEnabled ? "" : "not-enabled"
            }`
          }
        />
      </div>

      <IssueModal
        visible={issueModalOpen}
        handleClose={handleCloseIssueModal}
        insightsTotal={selectedInsight?.count}
        insights
        selectedInsight={selectedInsight}
        zIndex={1005}
      />

      <SuggestionsModal
        visible={suggestionsOpen}
        handleClose={() => setSuggestionsOpen(false)}
        data={selectedInsight}
        tableFilters={insights.filters}
      />

      <GovernanceDrawer
        visible={codeDrawerOpen}
        closeDrawer={handleCloseCodeDrawer}
        data={selectedInsight}
        handleShowSavings={handleShowSavings}
        handleOpenIssue={handleOpenIssue}
      />
    </>
  );
};

export default memo(InsightsTable);
