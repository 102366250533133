import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveScreenView } from "../../../redux/actions/screenViewActions";
import Collapse from "antd/lib/collapse";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import values from "lodash/values";
import map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as MatchingRules } from "../../../Images/general_icons/matchingRules.svg";
import { ReactComponent as IacIgnored } from "../../../Images/states/iacIgnored.svg";
import { ReactComponent as IacStack } from "../../../Images/general_icons/iac_stack.svg";
import { ReactComponent as Yak } from "../../../Images/general_icons/yak_new_outline.svg";
import { ReactComponent as CloudIcon } from "../../../Images/general_icons/provider_cloud.svg";
import { ReactComponent as TypesIcon } from "../../../Images/general_icons/layers_outline.svg";
import { ReactComponent as LocationIcon } from "../../../Images/general_icons/location_outline.svg";
import { ReactComponent as YearsIcon } from "../../../Images/general_icons/calendar_outline.svg";
import { ReactComponent as Chevron } from "../../../Images/general_icons/chevron.svg";
import { ReactComponent as Tags } from "../../../Images/general_icons/tags.svg";
import { ReactComponent as ResourceGroupsIcon } from "../../../Images/general_icons/Resource_groups.svg";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import InventorySingelFilter from "./inventorySingelFilter";
import InventorySingelRadioFilter from "./inventorySingelRadioFilter";
import InventoryTreeFilter from "./inventoryTreeFilter";
import "./inventoryFilters.scss";
import ProviderMasterFilter from "./providerMasterFilter/providerMasterFilter";
import _, { isArray, omit } from "lodash";
import { checkIsViewActive, getItemById } from "../../../utils/helpers";

import { getExclusions as getExcludedAssets } from "../../../redux/actions/exclusionsActions";
import {
  getAggregationByDynamicSearch,
  getYearsAggregations,
} from "../../../redux/actions/inventoryv3Actions";
import { FILTERS_KEYS, emptyInventoryScreenFilters } from "../../../consts/inventory";

const ALL_CUSTOM_EXCLUSIONS_KEY = "allCustomExclusions";
const FILTERS_INDEX = {
  dataSource: "1",
  location: "2",
  assetType: "3",
  tags: "4",
  owner: "5",
  iacType: "6",
  creationYear: "7",
  insights: "8",
  excludedAssets: "9",
  deletedAssets: "10",
  resourceGroups: "11",
  stack: "12"
}

const InventoryFilters = ({
  activeProviderTypes,
  providerTypeSelected,
  resourceTypes,
  resourceSelected,
  resetFilters,
  activeResurceTypesSelected,
  classifications,
  classificationSelected,
  activeClassificationSelected,
  handleClearAllFilters,
  activeExcludedAssetsSelected,
  excludedAssetSelected,
  activeTypesSelected,
  iacTypes,
  iacTypesSelected,
  activeRegionsSelected,
  regions,
  regionsSelected,
  handleSaveView,
  handleShareView,
  loadingShareTempView,
  yearsSelected,
  activeYearsSelected,
  activeOwnersSelected,
  owners,
  ownersSelected,
  activeTagsSelected,
  tags,
  tagsSelected,
  azureResourceGroups,
  azureResourceGroupsSelected,
  activeAzureResourceGroupsSelected,
  iacStacks,
  iacStacksSelected,
  activeIacStacksSelected,
  deletedRanges,
  activeDeletedRangeSelected,
  deletedRangeSelected,
  handleDeletedFilter,
  cloudsLoading
}) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [loadingYearsAggs, setLoadingYearsAggs] = useState(false);
  const [loadingExcludedAssetsAggs, setLoadingExcludedAssetsAggs] = useState(false);
  const [isViewActive, setIsViewActive] = useState(false);

  const closeInvFiltersByDefault = useSelector(
    (state) => state.onbordingReducer.account.disableFeatures?.closeInvFiltersByDefault
  ) || false;
  const DEFAULT_ACTIVE_FILTER = closeInvFiltersByDefault ? "" : FILTERS_INDEX.dataSource;

  const aggregationsYears = useSelector(
    (state) => state.inventoryReducer.aggregationsYears
  );

  const aggregationsExcludedAssets = useSelector(
    (state) => state.inventoryReducer.aggregationsExcludedAssets
  );
  
  const excludedAssets = useSelector((state) => state.exclusionsReducer.rules);
  
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const [activePanel, setActivePanel] = useState(DEFAULT_ACTIVE_FILTER);
  // redux
  const views = useSelector((state) => state.screenViewReducer.views);
  const screenFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);


  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const { t } = useTranslation("inventory", { keyPrefix: "filters" });

  useEffect(() => {
    return handleSetActiveViewIfScreenFilterMatch();
  }, [screenFilters, views]);

  useEffect(() => {
    if (checkIsViewActive(screenFilters, searchVal)) {
      setIsViewActive(true);
    } else {
      setIsViewActive(false);
    }
  }, [screenFilters, searchVal]);

  const handleSetActiveViewIfScreenFilterMatch = () => {
    if (!isEmpty(views)) {
      const match = views?.find((view) => {
        const filtersWithoutSearch = { ...view?.filters };
        delete filtersWithoutSearch["freeSearchText"];
        if (
          isEqual(screenFilters, filtersWithoutSearch) &&
          searchVal === view?.filters?.freeSearchText
        ) {
          return true;
        }
        return false;
      });
      if (match) {
        return dispatch(setActiveScreenView(match));
      } else {
        return dispatch(setActiveScreenView({}));
      }
    }
  };


  useEffect(() => {
    if (screenFilters?.deleted) {
      return setActivePanel(FILTERS_INDEX.deletedAssets);
    }
    return setActivePanel(DEFAULT_ACTIVE_FILTER)
  }, [screenFilters?.deleted]);

  useEffect(() => {
    const isLiveToggleAndDeletedPanel = activePanel === FILTERS_INDEX.deletedAssets && !screenFilters?.deleted
    if (isLiveToggleAndDeletedPanel) {  
      handleDeletedFilter(true)
    }
  }, [activePanel])

  const getActiveObjectNumber = (activeObj) => {
    let num = 0;
    map(_.keys(activeObj), (key) => {
      if(key != "provider"){
        num += activeObj[key].length;
      }
    });
    return num;
  };

  const handleCutsomArrow = (e) => {
    return (
      <Chevron
        style={{
          transform: e.isActive ?
            "translateY(2px)" : "rotate(-90deg) translateY(2px)",
          transition: "all .2s ease",
          width: "10px",
        }}
      />
    );
  };

  const filterIcons = [
    <CloudIcon
      className={`InventoryFilters__item-filterIcon-icon provider-type-icon ${
        !values(activeProviderTypes).every(isEmpty) ||
        (activePanel === FILTERS_INDEX.dataSource)
          ? "emptyIconActive"
          : "emptyIcon"
      }`}
      key={FILTERS_INDEX.dataSource}
    />,
    <LocationIcon
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeRegionsSelected) || activePanel === FILTERS_INDEX.location
          ? "classificationActive"
          : "classification"
      }`}
      key={FILTERS_INDEX.location}
    />,
    <TypesIcon
      className={`InventoryFilters__item-filterIcon-icon asset-type-icon ${
        !isEmpty(activeResurceTypesSelected) || activePanel === FILTERS_INDEX.assetType
          ? "classificationActive"
          : "classification"
      }`}
      key={FILTERS_INDEX.assetType}
    />,
    <Tags
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeTagsSelected) || activePanel === FILTERS_INDEX.tags
          ? "emptyIconActive"
          : "emptyIcon"
      }`}
      key={FILTERS_INDEX.tags}
    />,
    <UserOutlined
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeOwnersSelected) || activePanel === FILTERS_INDEX.owner
          ? "emptyIconActive"
          : "emptyIcon"
      }`}
      key={FILTERS_INDEX.owner}
    />,
    <Yak
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeTypesSelected) || activePanel === FILTERS_INDEX.iacType
          ? "classificationActive"
          : "classification"
      }`}
      key={FILTERS_INDEX.iacType}
    />,
    <YearsIcon
      className={`InventoryFilters__item-filterIcon-icon years-icon ${
        !isEmpty(activeYearsSelected) || activePanel === FILTERS_INDEX.creationYear
          ? "classificationActive"
          : "classification"
      }`}
      key={FILTERS_INDEX.creationYear}
    />,
    <MatchingRules
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeClassificationSelected) || activePanel === FILTERS_INDEX.insights
          ? "classificationActive"
          : "classification"
      }`}
      key={FILTERS_INDEX.insights}
    />,
    <IacIgnored
      className={`InventoryFilters__item-filterIcon-icon iac-ignored-icon ${
        !isEmpty(activeExcludedAssetsSelected) || activePanel === FILTERS_INDEX.excludedAssets
          ? "iac-ignored-active"
          : ""
      }`}
      key={FILTERS_INDEX.excludedAssets}
    />,
    <FontAwesomeIcon
    icon={["far", "trash-alt"]}
      className={`InventoryFilters__item-filterIcon-icon
      ${
        !isEmpty(activeDeletedRangeSelected) || activePanel === FILTERS_INDEX.deletedAssets
          ? "iconActive"
          : "classification"
      }`}
      key={FILTERS_INDEX.deletedAssets}
    />,
    <ResourceGroupsIcon
      className={`InventoryFilters__item-filterIcon-icon
      ${
        !isEmpty(activeAzureResourceGroupsSelected) || activePanel === FILTERS_INDEX.resourceGroups
          ? "iconActive"
          : ""
      }`}
      key={FILTERS_INDEX.resourceGroups}
    />,
    <IacStack className={`InventoryFilters__item-filterIcon-icon iacStack ${
        !isEmpty(activeIacStacksSelected) || activePanel === FILTERS_INDEX.stack 
        ? "iacStackActive" 
        : ""
      }`}
      key={FILTERS_INDEX.stack}
    />,
  ];

  const handleYearsAggs = () => {
    const uniqueTypes = [];
    if (!_.isEmpty(aggregationsYears)) {
      for (let item of aggregationsYears) {
        uniqueTypes.push({
          name: item?.year,
          formattedName: item?.year,
          count: item?.doc_count,
        });
      }
    }
    const ordered = _.orderBy(uniqueTypes, ["name"], ["desc"]);
    return ordered;
  };

  const handleExcludedAssetsAggs = () => {
    const uniqueTypes = [];
    const excludeCustomItem = {
      key: ALL_CUSTOM_EXCLUSIONS_KEY,
      name: ALL_CUSTOM_EXCLUSIONS_KEY,
      isPinnedItem: true,
      formattedName: "All Custom Ignore Rules",
      subItems: [],
      count: 0,
    };
    if (!_.isEmpty(excludedAssets) && !_.isEmpty(aggregationsExcludedAssets) && isArray(aggregationsExcludedAssets)) {
      for (let item of aggregationsExcludedAssets) {
        const { name, isDefault } = getItemById(item.key, excludedAssets);
        if (name !== item.key){
          uniqueTypes.push({
            name: item.key,
            formattedName: name,
            count: item.doc_count,
          });
          const isCustomItem = name && !isDefault;
          if (isCustomItem) {
            excludeCustomItem.subItems.push(item.key)
            excludeCustomItem.count += item.doc_count;
          }
        }
      }
    }
    const ordered = _.orderBy(uniqueTypes, ["count"], ["desc"]);
    const excludeAggs = excludeCustomItem.count > 0 ? [excludeCustomItem, ...ordered] : ordered;
    return excludeAggs;
  };

  const handlePanelClick =  async (key) => {
    setActivePanel(key)

    if (key === FILTERS_INDEX.creationYear && _.isEmpty(aggregationsYears)){
      setLoadingYearsAggs(true);
      await dispatch(getYearsAggregations());
      setLoadingYearsAggs(false);
    }else if (key === FILTERS_INDEX.excludedAssets && _.isEmpty(excludedAssets)){
      setLoadingExcludedAssetsAggs(true);
      await dispatch(getExcludedAssets(false, false))
      setLoadingExcludedAssetsAggs(false);
    }

  };

  const panelHeader = (title, activeArr, idx, activeObj) => {
    return (
      <div className="InventoryFilters__item row">
        {filterIcons[idx - 1]}
        <span
          className={`InventoryFilters__item-title row ${
            !isEmpty(activeArr) ||
            !values(activeObj).every(isEmpty) ||
            (activePanel === idx)
              ? "active"
              : ""
          }`}
        >
          <span>{title}</span>
          {!isEmpty(activeArr) && (
            <span className="purple-text">{`(${activeArr?.length})`}</span>
          )}
          {!values(activeObj).every(isEmpty) &&
            getActiveObjectNumber(activeObj) > 0 && (
              <span className="purple-text">{`(${getActiveObjectNumber(
                activeObj
              )})`}</span>
            )}
        </span>
      </div>
    );
  };

  return (
    <div className="InventoryFilters">
      <div className="InventoryFilters__header row between">
        <span className="InventoryFilters__header-title">Filters</span>
        <div className="InventoryFilters__header-actions row">
          <span className={`InventoryFilters__header-actions-clear ${isViewActive ? "active" : ""}`}
            onClick={isViewActive ? handleClearAllFilters : undefined}
          >
            Clear
          </span>
          <Divider className={`InventoryFilters__header-actions-divider ${isViewActive ? "active" : ""}`} type="vertical"/>
          <div className="row">
            <IconBtn
              onClick={handleSaveView}
              type="save"
              colorClass="filter-inventory-save"
              tooltipText="Save filters as view"
              disabled={!isViewActive}
            />
            <IconBtn
              onClick={handleShareView}
              type="share"
              colorClass="filter-inventory-share"
              tooltipText="Share as temporary view"
              loading={loadingShareTempView}
              disabled={!isViewActive}
            />
          </div>
        </div>
      </div>
      <div className="InventoryFilters__masterFilterWrapper">
        <ProviderMasterFilter />
      </div>
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={handlePanelClick}
        accordion
      >
        <Panel
          header={panelHeader(
            t("titles.dataSource"),
            [],
            FILTERS_INDEX.dataSource,
            activeProviderTypes
          )}
          key={FILTERS_INDEX.dataSource}
        >
          {cloudsLoading ? (
            <div className="center" style={{ padding: "30px 0" }}>
              <Spin />
            </div>
          ) : (
            <InventoryTreeFilter
              providerTypeSelected={(filters) => providerTypeSelected(filters)}
            />
          )}
        </Panel>
        {!isEmpty(providerIntegrations?.azurerm) && (
          <Panel
            header={panelHeader(
              t("titles.resourceGroup"),
              activeAzureResourceGroupsSelected,
              FILTERS_INDEX.resourceGroups
            )}
            key={FILTERS_INDEX.resourceGroups}
          >
            <InventorySingelFilter
              allTypes={azureResourceGroups}
              selectedTypes={azureResourceGroupsSelected}
              checkedList={activeAzureResourceGroupsSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === FILTERS_INDEX.resourceGroups}
              hideText
            />
          </Panel>
        )}
        <Panel
          header={panelHeader(
            t("titles.location"),
            activeRegionsSelected,
            FILTERS_INDEX.location
          )}
          key={FILTERS_INDEX.location}
        >
          <InventorySingelFilter
            allTypes={regions}
            selectedTypes={regionsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.location}
            checkedList={activeRegionsSelected}
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.assetType"),
            activeResurceTypesSelected,
            FILTERS_INDEX.assetType
          )}
          key={FILTERS_INDEX.assetType}
        >
          <InventorySingelFilter
            allTypes={resourceTypes}
            selectedTypes={resourceSelected}
            checkedList={activeResurceTypesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.assetType}
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.tags"),
            activeTagsSelected,
            FILTERS_INDEX.tags
          )}
          key={FILTERS_INDEX.tags}
        >
          <InventorySingelFilter
            allTypes={tags}
            selectedTypes={tagsSelected}
            checkedList={activeTagsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.tags}
            hideText
            onDynamicFilterSearch={(aggSearch) =>
              dispatch(
                getAggregationByDynamicSearch(
                  searchVal,
                  screenFilters,
                  aggSearch,
                  FILTERS_KEYS.tags
                )
              )
            }
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.owner"),
            activeOwnersSelected,
            FILTERS_INDEX.owner
          )}
          key={FILTERS_INDEX.owner}
        >
          <InventorySingelFilter
            allTypes={owners}
            selectedTypes={ownersSelected}
            checkedList={activeOwnersSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.owner}
            hideText
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.iacType"),
            activeTypesSelected,
            FILTERS_INDEX.iacType
          )}
          key={FILTERS_INDEX.iacType}
        >
          <InventorySingelFilter
            allTypes={iacTypes}
            selectedTypes={iacTypesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.iacType}
            checkedList={activeTypesSelected}
            noAnimation
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.stack"),
            activeIacStacksSelected,
            FILTERS_INDEX.stack
          )}
          key={FILTERS_INDEX.stack}
        >
          <InventorySingelFilter
            allTypes={iacStacks}
            selectedTypes={iacStacksSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.stack}
            checkedList={activeIacStacksSelected}
            onDynamicFilterSearch={(aggSearch) =>
              dispatch(
                getAggregationByDynamicSearch(
                  searchVal,
                  screenFilters,
                  aggSearch,
                  "iacStacks"
                )
              )
            }
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.creationYear"),
            activeYearsSelected,
            FILTERS_INDEX.creationYear
          )}
          key={FILTERS_INDEX.creationYear}
        >
          {loadingYearsAggs ? (
            <div className="center" style={{ padding: "30px 0" }}>
              <Spin />
            </div>
          ) : (
            <InventorySingelRadioFilter
              allTypes={handleYearsAggs()}
              selectedTypes={yearsSelected}
              checkedList={activeYearsSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === FILTERS_INDEX.creationYear}
            />
          )}
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.insights"),
            activeClassificationSelected,
            FILTERS_INDEX.insights
          )}
          key={FILTERS_INDEX.insights}
        >
          <InventorySingelRadioFilter
            allTypes={classifications}
            selectedTypes={classificationSelected}
            checkedList={activeClassificationSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.insights}
          />
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.excludedAssets"),
            activeExcludedAssetsSelected,
            FILTERS_INDEX.excludedAssets
          )}
          key={FILTERS_INDEX.excludedAssets}
        >
          {loadingExcludedAssetsAggs ? (
            <div className="center" style={{ padding: "30px 0" }}>
              <Spin />
            </div>
          ) : (
            <InventorySingelFilter
              allTypes={handleExcludedAssetsAggs()}
              selectedTypes={excludedAssetSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === FILTERS_INDEX.excludedAssets}
              checkedList={activeExcludedAssetsSelected}
            />
          )}
        </Panel>
        <Panel
          header={panelHeader(
            t("titles.deletedAssets"),
            activeDeletedRangeSelected,
            FILTERS_INDEX.deletedAssets
          )}
          key={FILTERS_INDEX.deletedAssets}
        >
          <InventorySingelRadioFilter
            allTypes={deletedRanges}
            selectedTypes={deletedRangeSelected}
            checkedList={activeDeletedRangeSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === FILTERS_INDEX.deletedAssets}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default InventoryFilters;
