import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../../utils/formatting";
import { useHistory } from "react-router-dom";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import UnavailableIconText from "../../../shared/unavailableIconText/unavailableIconText";

const MatchingAssetCell = ({ row = {}, closeDrawer, handleClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );

  const integrations = useSelector(
    (state) => state.insightsV2Reducer.filters?.integrations
  );

  const { excluded_assets, total_assets,  total_assets_by_types = 0 } = row;
  const count = showExclusion ? excluded_assets : total_assets;
  
  const handleViewAssets = (e) => {
    e.stopPropagation(); //stop the code drawer from beeing open

    dispatch(
      setFilters({
        classifications: [row?._id],
        excluded: showExclusion,
        providerTypes: {
          integrationId: integrations,
        },
        masterType: []
      })
    );
    if(closeDrawer && handleClose){
      handleClose(false);
      closeDrawer(false);
    }
     return history.push({
      pathname: "/inventory" ,
      search: "?defaultView=false",
    });
  };


  if (row?.isScanning) {
    return (
      <span className="row g8">
        Scaning
        <FontAwesomeIcon icon="spinner" className="purple-text" spin />
      </span>
    );
  }

  if (!row?.isEnabled) {
    return null;
  }

  if (count === 0 && total_assets_by_types === 0) {
    return (
        <UnavailableIconText toolTipTitle={"No assets found to scan"} />
    );
  }

  if (!count && row?.isEnabled) {
    return (
      <span
        style={{ fontSize: "13px" }}
        className="purple-flag row g5"
      >
        <FontAwesomeIcon icon="layer-group" className="purple-text" />
        0
      </span>
    );
  }

  return (
    <span
      style={{ cursor: "pointer", fontSize: "13px" }}
      className="purple-flag row g5"
      onClick={handleViewAssets}
    >
      <FontAwesomeIcon icon="layer-group" className="purple-text" />
      {numberWithCommas(count)}
      <FontAwesomeIcon
        icon="external-link-alt"
        style={{ fontSize: "11px", marginLeft: "5px" }}
      />
    </span>
  );
};

export default MatchingAssetCell;
