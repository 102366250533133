import React from 'react';
import isFunction from 'lodash/isFunction';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ActionBtn from '../../../shared/actionBtn/actionBtn';
import { forEach } from 'lodash';
import { downloadFile } from '../../../utils/helpers';

const WorkflowManagementFooter = ({ configWizard = [], currentStep, setCurrentStep, handleSubmit, loadingSubmit, prevDisabled, submitDisabled, setSubmitDisabled , handleClose, stepsUnvisible, dontShowNextButton = false}) => {
    const { t } = useTranslation('common');

    const onClickNextStep = () => {
        setSubmitDisabled(true);
        setCurrentStep(currentStep + 1);
    }
    
    const onClickPreviousStep = async() => {
        if (isFunction(configWizard[currentStep].beforePreviousStep)) {
            try {
              await configWizard[currentStep].beforePreviousStep();
            } catch (err) {
              return;
            }
          }
        setSubmitDisabled(false);
        setCurrentStep(currentStep - 1);
    }
    const downloadWorkflowCodeFiles = async() => {
        const exportAllObj =  configWizard[currentStep]?.exportAll || {};
        const workflowCodeArr = [{ fileName: exportAllObj.workflowPath?.replace('.yaml', ''), content: exportAllObj.workflowCode }, 
            { fileName: exportAllObj.templatePath?.replace('.yaml', ''), content: exportAllObj.templateCode }];
        forEach(workflowCodeArr, async (file) => {
            downloadFile(file.content, 'yaml', file.fileName);
        });
    }
    const isCopyButton = configWizard[currentStep]?.copyString;
    const isExportAll = configWizard[currentStep]?.exportAll;

    return (
        <div className="col g5">
            <div className="row between">
                <Button style={{ margin: "0 8px" }} onClick={handleClose} disabled={currentStep === configWizard.length - 1 && configWizard.length > 1}>
                Close
                </Button>
                <div className="WorkflowManagement__footer-paging row g8">
                {currentStep > 0 && (
                    <Button
                        onClick={() => onClickPreviousStep()}
                        disabled={currentStep === configWizard.length - 1 && !stepsUnvisible || prevDisabled}
                    >
                        {t('previous')}
                    </Button>
                )}
                {isCopyButton && 
                <ActionBtn
                    text={t('copy')}
                    icon="copy"
                    action="copy"
                    primary
                    stringToAction={configWizard[currentStep]?.copyString || ""}
              />}
                {isExportAll && 
                <ActionBtn
                    text={t('exportAll')}
                    icon="download"
                    primary
                    onClick={downloadWorkflowCodeFiles}
              />}
                {currentStep < configWizard.length - 1 && !dontShowNextButton && (
                <Button
                    type="primary"
                    onClick={async () => {
                    if (isFunction(configWizard[currentStep].beforeNextStep)) {
                        try {
                        await configWizard[currentStep].beforeNextStep();
                        } catch (err) {
                        return;
                        }
                    }
                    if (configWizard[currentStep].skipNextStep && currentStep + 2 <= configWizard.length - 1) {
                        return setCurrentStep(currentStep + 2);
                    }
                    onClickNextStep();
                    }}
                    disabled={submitDisabled}
                    loading={loadingSubmit}
                >
                    {configWizard[currentStep]?.nextButtonText || t('next') }
                </Button>
                )}

                {currentStep === configWizard.length - 1 && (
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingSubmit}
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                >
                    {t('done')}
                </Button>
                )}
            </div>
            </div>
            {!stepsUnvisible && <span className='WorkflowManagement__footer-comment'>Firefly will create your workflows via a PR to your Repository</span>}
        </div>
    )
};

export default WorkflowManagementFooter;