import isEmpty from "lodash/isEmpty";
import isNumber from "lodash/isNumber";
import { CLOUD_PROVIDERS, PROVIDERS_TITLE_MAP, RESOURCE_STATE_TYPES } from "../../consts/general";
import moment from "moment";
import { formatIacStatus } from "../../utils/formatting";

const OTHER_STATES_TYPES = [RESOURCE_STATE_TYPES.child, RESOURCE_STATE_TYPES.iacIgnored,  RESOURCE_STATE_TYPES.pending, RESOURCE_STATE_TYPES.undetermined]

const getSaasMissingArray = (aggs) => {
    const missingSaas = [];
    Object.keys(aggs).forEach((provider) => {
        const isSaas = ![CLOUD_PROVIDERS.aws, CLOUD_PROVIDERS.k8s, CLOUD_PROVIDERS.kubernetes, CLOUD_PROVIDERS.azurerm, CLOUD_PROVIDERS.gcp].includes(provider);
        if (isSaas) {
            const mappedArray = (aggs[provider] || []).map((val = "") => `${provider} - ${val || "Unknown identifier"}`);
            missingSaas.push(...mappedArray);
        };
    });
    return missingSaas;
};

const formatMissingProviderTitle = (title = '', isSaas = false) => {
    if (!isSaas) return title;
    const titleArr = title.split(' - ') || [];
    const provider = titleArr[0];
    return `${PROVIDERS_TITLE_MAP[provider]} - ${titleArr[1] || "Unknown identifier"}`;
}

const getMissingIntegrationsValue =  (provider, missingAggs = {}, excludeMissingIntegrations, isSaas = false) => {
    if (provider === CLOUD_PROVIDERS.k8s) {
        provider = CLOUD_PROVIDERS.kubernetes;
    }

    const missingIntegrationsValue = isSaas ? getSaasMissingArray(missingAggs) : missingAggs[provider] || []
    if (!excludeMissingIntegrations.length) return missingIntegrationsValue;
    const filtteredMissingIntegration = missingIntegrationsValue.filter((integVal) => !excludeMissingIntegrations.find((excludeVal) => !isSaas ? excludeVal === integVal : excludeVal.includes(integVal)));
    return filtteredMissingIntegration
}
const alreadyScanned = (value) => {
    if (!isEmpty(value)) {
        const thirtyMinutesFromFetch = moment.unix(parseInt(value)).add(30, "minutes");
        const difference = moment().diff(thirtyMinutesFromFetch);
        return difference < 0;
    }
    return false
}

const getProviderCount = (providers = {}, t = () => null) => {
    if (isEmpty(providers)) return;  
    const cloudProviders = {
        aws: { count: 0, name: t("integrations.awsAccounts")},
        k8s: { count: 0, name: t("integrations.k8sClusters")},
        gcp: { count: 0, name: t("integrations.gcpProjects") },
        azurerm: { count: 0, name: t("integrations.azureSubscriptions")},
        saas: { count: 0, name: t("integrations.saasIntegrations")}
    };

    cloudProviders.aws.count = providers?.aws?.length || 0;
    cloudProviders.k8s.count = providers?.k8s?.length || 0;
    cloudProviders.gcp.count = providers?.gcp?.length || 0;
    cloudProviders.azurerm.count = providers?.azurerm?.length || 0;
    cloudProviders.saas.count = calculateSaasCount(providers);

    return cloudProviders;
}

const calculateSaasCount = (providers = {}) => {
    const saasCount = Object.keys(providers).reduce((count, key) => {
        if (Array.isArray(providers[key]) && !Object.keys(CLOUD_PROVIDERS).includes(key)) {
            return count + providers[key].length;
        }
        return count;
    }, 0);

    return saasCount;
}

const getStatesStatistics = (aggregationsIsManaged = []) => {
    let managedGraphData = [];
    let totalAssetsCount = 0;
    let formattedGraphData = [];
    const otherTypesData = {};
    let isZeroPercentage = false;
    aggregationsIsManaged.forEach((agg = {}) => {
        const { key, doc_count = 0 } = agg;
        const formattedKey = formatIacStatus(key);
        if (OTHER_STATES_TYPES.includes(key)) {
            otherTypesData[key] = { key: formattedKey, value: doc_count };
        }
        else {
            totalAssetsCount += doc_count;
            managedGraphData.push({ key: formattedKey, doc_count });
            formattedGraphData.push({ type: formattedKey, value: doc_count });
        }
    })
    const statesData = managedGraphData.reduce((acc, { key, doc_count: total = 0 } = {}) => {
        if (!isNumber(total) || !isNumber(totalAssetsCount)) return acc;
        const percentage = ((total / totalAssetsCount) * 100).toFixed(2);
        if (percentage < 1) isZeroPercentage = true;
        acc[key] = { total, percentage };
        return acc;
    }, {});
    return { managedGraphData, totalAssetsCount, formattedGraphData, otherTypesData, statesData, isZeroPercentage };
}

const getSortedIntegrations = (cloudProviders = {}) => {
    const entries = Object.entries(cloudProviders);
    const sortedEntries = entries.sort((a, b) => b[1].count - a[1].count);
    return Object.fromEntries(sortedEntries);
}

export {
    alreadyScanned,
    getMissingIntegrationsValue,
    formatMissingProviderTitle,
    getProviderCount,
    getStatesStatistics,
    getSortedIntegrations,
}