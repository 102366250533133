import action from "../middleware";
import _ from "lodash";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_CLASSIFICATIONS = "GET_CLASSIFICATIONS";
export const GET_SCOPES = "GET_SCOPES";
export const DELETE_CLASSIFICATION = "DELETE_CLASSIFICATION";
export const UPDATE_CLASSIFICATION = "UPDATE_CLASSIFICATION";
export const CREATE_CLASSIFICATION = "CREATE_CLASSIFICATION";
export const GET_TOTAL_COST = "GET_TOTAL_COST";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const TOGGLE_INSIGHT_ENABLE = "TOGGLE_INSIGHT_ENABLE";
export const GET_WIZARD_ASSETS = "GET_WIZARD_ASSETS";
export const GET_CLASSIFICATIONS_NAMES = "GET_CLASSIFICATIONS_NAMES";

const getClassifications = (fetchCost, showError) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/classifications${fetchCost ? `?cost=${fetchCost}` : ""}`,
      "GET",
      {},
      undefined,
      undefined,
      showError
    );

    const data = await req.json();

    dispatch({
      type: GET_CLASSIFICATIONS,
      payload: { data },
    });
  });
};

const getAvailableScopes = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/assets`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_SCOPES,
      payload: data,
    });
  });
};

const deleteClassification = (classificationId, type, isEnabled) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const payload = { type, isEnabled }

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/classifications/${classificationId}`,
      "DELETE", payload
    );

    dispatch({
      type: DELETE_CLASSIFICATION,
      payload: {},
    });
  });
};

const updateClassification = (classificationId, payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/${classificationId}`,
      "PUT",
      payload
    );
    const result = await req.json();
    return req;
  });
};

const createClassification = (obj) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights`,
      "POST",
      obj
    );

    dispatch({
      type: CREATE_CLASSIFICATION,
      payload: {},
    });

    const result = await req.json();
    return { ok: req.ok, result };
  });
};

const getTotalCost = (onlyProd) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/classifications/totalCost`,
      "POST",
      { onlyProd }
    );

    const data = await req.json();

    dispatch({
      type: GET_TOTAL_COST,
      payload: { data },
    });
  });
};

const setCategories = (categories) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_CATEGORIES,
      payload: categories,
    });
  });
};

const getCategories = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/categories`,
      "GET"
    );

    let data = [];

    if (req?.ok) {
      data = await req.json();
    }

    return data;
  });
};

const getAssetTypes = (providers) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/types`,
      "POST",
      { providers }
    );

    let data = [];

    if (req?.ok) {
      data = await req.json();
    }

    return data;
  });
};

const generateRegoAi = (assetType, prompt, samples) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/pacai`,
      "POST",
      { assetType, prompt, samples },
      true,
      null,
      true,
      1800
    );

    let data = '';

    if (req?.ok) {
      data = await req.json();
    }

    return data;
  });
}

const getInvAssets = (providerIds, types, search, pageSize, pageNumber) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/getInventory`,
      "POST",
      { providerIds, types, search, pageSize, pageNumber }
    );

    let data = {};

    if (req?.ok) {
      data = await req.json();
    }

    dispatch({
      type: GET_WIZARD_ASSETS,
      payload: data?.hits || [],
    });

    return data;
  });
}

const toggelInsightEnable = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/insights/toggle/${id}`,
      "PUT",
    );

    let result = {};

    if (req?.ok) {
      result = await req.json();

      dispatch({
        type: TOGGLE_INSIGHT_ENABLE,
        payload: id,
      });
    }
    return result;
  });
};

const getClassificationsNames = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/kics/terraform/rules`,
      "GET",
      
    );

    let data = [];

    if (req?.ok) {
      data = await req.json();
    }
    
    dispatch({
      type: GET_CLASSIFICATIONS_NAMES,
      payload: { data },
    });
  });
}

export {
  getClassifications,
  deleteClassification,
  updateClassification,
  createClassification,
  getTotalCost,
  getAvailableScopes,
  setCategories,
  getCategories,
  getAssetTypes,
  generateRegoAi,
  getInvAssets,
  toggelInsightEnable,
  getClassificationsNames,
};
