import React from "react";
import { capitalizeFirst } from "../../../../../utils/formatting";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ReactComponent as ExpectedValueIcon } from "../../../../../Images/ci/scan-expected.svg";
import { ReactComponent as CheckmarxIcon } from "../../../../../Images/ci/checkmarx.svg";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";

import "./policyScaning.scss";

const PolicyScaning = ({ scanResults = [] }) => {
    if (!scanResults.length) {
        return <div className="PolicyScaning__empty">
            <AppEmpty text="No data" customStyle="code" />
        </div>
    }
    return (
        <div className="PolicyScaning-container">
            <div className="PolicyScaning__checkmarxIcon row">
                    <span className="PolicyScaning__checkmarxIcon-powered">Powered by</span>
                    <CheckmarxIcon />
            </div>
            {scanResults.map((result = {}) => {
            const { severity = "", actualValue = "", ruleName = "", description = "", expectedValue = "", code = "", line: codeStartLine = 1, ruleUrl = "" } = result;
            const severLower = severity.toLowerCase();
            const severityTitle = capitalizeFirst(severLower);
            return (
                <div className="PolicyScaning col g5" key={uuidv4()}>
                    <div className="row between">
                        <span className={`PolicyScaning__title ${severLower}`}><span className="bold">{severityTitle}</span> Severity</span>
                    </div>
                    {!expectedValue ? <span>{actualValue}</span> : null}
                    <div className="col">
                        <div className="PolicyScaning__code">
                            <SyntaxHighlighter
                                style={atomDark}
                                showLineNumbers={true}
                                language="hcl"
                                lineProps={{
                                style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                                }}
                                startingLineNumber={codeStartLine}
                            >
                                {code}
                            </SyntaxHighlighter>
                        </div>
                        {expectedValue ?
                        <div className="PolicyScaning__patch col g5">
                            <div className="row g8">
                                <ExpectedValueIcon className="PolicyScaning__patch-icon"/>
                                <span className="PolicyScaning__patch-title bold">{ruleName}</span>
                            </div>
                            <span className="PolicyScaning__patch-description">{description}</span>
                            <div className="row g8">
                                <span className="PolicyScaning__patch-value">{expectedValue}</span>
                                {ruleUrl ? <FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                                    e.stopPropagation();
                                    window.open(ruleUrl, "_blank");
                                    }}/> : null }
                            </div>
                        </div> : null }
                    </div>
                
                </div>
            )
            })}
        </div>
    )
}

export default PolicyScaning;