const NAVIGATION_SEARCH = "NAVIGATION_SEARCH"
const NAVIGATION_SHOW = "NAVIGATION_SHOW"
const initialState = {
  searchValue: "",
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case NAVIGATION_SEARCH:
      return { ...state, searchValue: payload.searchValue };
    case NAVIGATION_SHOW:
      return { ...state, showValue: payload.showValue };
    default:
      return state;
  }
}
