import {
    GET_AWS_INTEGRATIONS,
    GET_SLACK_INTEGRATIONS,
    GET_TEAMS_INTEGRATIONS,
    GET_WEBHOOK_INTEGRATIONS,
    GET_OPSGENIE_INTEGRATIONS,
    GET_AWS_INTEGRATION_FETCHING_STATUS,
    SET_AWS_INTEGS,
    SET_AWS_INTEGS_PERCENTAGE,
    GET_SLACK_CHANNELS,
    GET_ACCOUNT_EXTERNAL_ID,
    GET_ON_PREM_IP,
    GET_TORQ_INTEGRATIONS,
    GET_PAGERDUTY_INTEGRATIONS,
    GET_PAGERDUTY_SERVICES,
    GET_ALL_INTEGRATION_COUNTS,
    GET_INTEGRATIONS_BY_TYPE,
    UPDATE_INTEGRATIONS_BY_TYPE,
    GET_GOOGLE_CHAT_INTEGRATIONS,
    GET_WEBEX_INTEGRATIONS,
    GET_WEBEX_ROOMS,
} from '../actions/integrationsActions';
import _ from 'lodash';

const initialState = {
    aws: {
        hasError: null,
        integrations: null,
        metadata: null,
        externalId: null,
    },
    webhook: null,
    slack: null,
    teams: null,
    opsgenie: null,
    awsIntegs: {},
    channels: [],
    publicIps: [],
    torq: null,
    pagerduty: null,
    pdServices: [],
    counts: {},
    integsByType: {},
    googleChat: [],
    webex: [],
};

export default (state = initialState, { type, payload }) => {

    switch (type) {

        case GET_AWS_INTEGRATIONS:
            return { ...state, aws: { hasError: payload.hasError, integrations: payload.integrations, metadata: state?.aws?.metadata } };

        case GET_AWS_INTEGRATION_FETCHING_STATUS:
            return { ...state, aws: { hasError: payload.hasError, integrations: state?.aws?.integrations, metadata: payload.metadata } };

        case GET_SLACK_INTEGRATIONS:
            return { ...state, slack: payload.integrations }

        case GET_TEAMS_INTEGRATIONS:
            return { ...state, teams: payload.integrations }

        case GET_GOOGLE_CHAT_INTEGRATIONS:
            return { ...state, googleChat: payload.integrations }

        case GET_WEBEX_INTEGRATIONS:
            return { ...state, webex: payload.integrations }

        case GET_WEBEX_ROOMS:
            return { ...state, rooms: payload.rooms }

        case GET_WEBHOOK_INTEGRATIONS:
            return { ...state, webhook: payload.integrations }

        case GET_OPSGENIE_INTEGRATIONS:
            return { ...state, opsgenie: payload.integrations }

        case SET_AWS_INTEGS:
            return { ...state, awsIntegs: payload.awsIntegs }

        case SET_AWS_INTEGS_PERCENTAGE:
            return { ...state, awsIntegs: { ...state?.awsIntegs, [payload.integrationId]: { loading: false, percentage: payload.percentage } } }

        case GET_SLACK_CHANNELS:
            let newChannels = _.uniqBy([...state?.channels, ...payload.channels] || [], 'id');
            return { ...state, channels: newChannels };

        case GET_ACCOUNT_EXTERNAL_ID:
            return { ...state, aws: { ...state.aws, externalId: payload } }

        case GET_ON_PREM_IP:
            return { ...state, publicIps: payload }

        case GET_TORQ_INTEGRATIONS:
            return { ...state, torq: payload.integrations }

        case GET_PAGERDUTY_INTEGRATIONS:
            return { ...state, pagerduty: payload.integrations }

        case GET_PAGERDUTY_SERVICES:
            return { ...state, pdServices: payload }

        case GET_ALL_INTEGRATION_COUNTS:
            const { vcs = {},  ...restCategories } = payload || {};
            let counts = payload;
            if (vcs?.bitbucketdc) {
                vcs.bitbucket = (vcs.bitbucket || 0) + vcs.bitbucketdc;
                delete vcs.bitbucketdc;
                counts = { ...restCategories, vcs };
            }
            return { ...state, counts }

        case GET_INTEGRATIONS_BY_TYPE:
            let integsByType = payload;
            if (payload?.isVcsEdit) {
                const changeItem = state?.integsByType?.integrations?.[0] || {};
                integsByType = changeItem?._id === payload?.id ? {integrations: [{ changeItem , name: payload?.name }]} : { integrations: [] };
            }
            return { ...state, integsByType }
        case UPDATE_INTEGRATIONS_BY_TYPE:
            return { ...state, integsByType: payload }
        default:
            return state;
    }
}
