import { Badge, Popover } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { ReactComponent as TagsIcon } from "../../../Images/general_icons/tags.svg";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { handleSetCiFilters } from '../../../redux/actions/ciWorkflowsActions';
import './tagsDisplay.scss';

const TagsDisplay = ({ tags = [] }) => {
    const { t } = useTranslation('ci-pipeline');
    const ciFilters = useSelector((state) => state.ciWorkflowsReducer.ciFilters) || {};
    const labelsFilters = ciFilters.labels || [];

    const dispatch = useDispatch();

    const isTags = tags.length > 0;
    
    const handleOnLabelClick = async (e, val) => {
        e.stopPropagation();
        await dispatch(handleSetCiFilters({ ...ciFilters, labels: [...labelsFilters, val] }));
    }

    return(
        <Popover
            key={uuidv4()}
            overlayClassName="FiltersRowBadges__popover"
            content={isTags && (
            <div className="FiltersRowBadges__popover-wrapper">
                <div>
                <h5>{t("tagsDisplay.title")}</h5>
                </div>
                <div className="FiltersRowBadges__popover-wrapper__items">
                {tags.map((item) => (
                  <div className="basic-filter-badge row g5" key={uuidv4()} onClick={(e) => handleOnLabelClick(e, item)}>
                    <TagsIcon />
                    <span>{item}</span>
                  </div>
                ))}
                </div>
            </div>)
            }
            zIndex={isTags ? 1070 : -1}
            placement="topLeft">
            <Badge
                className="TagsDisplay__badge"
                count={tags.length}
                size="small"
            >
                <TagsIcon className={`TagsDisplay__icon ${!isTags ? 'disabled' : ''}`} />
            </Badge>
  </Popover>)
};

export default TagsDisplay;