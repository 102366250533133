import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import { useIntercom } from "react-use-intercom";
import { ONBORDING_STATUS_NAMES } from "./consts/onbording";
import TagManager from "react-gtm-module";
import { GOOGLE_TAG_ID } from "./consts/config";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { getActiveFeatures } from "./redux/actions/activeFeaturesActions";
import { updateUserPreferences } from "./redux/actions/userPreferencesActions";
import {
  setIsUserViewer,
  postAccountActivity,
} from "./redux/actions/profilesActions";
import { getOnbordingStatus } from "./redux/actions/onbordingActions";
import { cutDomainFromEmail } from "./utils/helpers";
import { initAmplitude } from "./utils/amplitude";

// containers
import Dashboard from "./containers/dashboard/dashboard";
import NotFound from "./containers/notFound/notFound";
import Users from "./containers/users/users";
import StackCreation from "./containers/stackCreation/stackCreation";
import Inventory from "./containers/inventory/inventory";
import Drifts from "./containers/drifts/drifts";
import Insights from "./containers/Insights/insights";
import IacStacks from "./containers/iacStacks/iacStacks";
import SplashScreen from "./shared/splashScreen/splashScreen";

// integrations
import Integrations from "./containers/integrations/integrations";
import IntegrationsCatalog from "./containers/integrationsCatalog/integrationsCatalog";
import AwsIntegrationConfig from "./containers/singleIntegrations/awsIntegration/awsIntegrationConfig/awsIntegrationConfig";
import AwsIntegration from "./containers/singleIntegrations/awsIntegration/awsIntegration";
import AwsIntegrationTerraform from "./containers/singleIntegrations/awsIntegration/terraform/awsIntegration";
import AwsIntegrationPulumi from "./containers/singleIntegrations/awsIntegration/pulumi/awsIntegration";
import AkamaiIntegration from "./containers/singleIntegrations/akamaiIntegration/akamaiIntegration";
import DatadogIntegration from "./containers/singleIntegrations/datadogIntegration/datadogIntegration";
import OktaIntegration from "./containers/singleIntegrations/oktaIntegration/oktaIntegration";
import GcpProviderIntegration from "./containers/singleIntegrations/gcpProviderIntegration/gcpProviderIntegration";
import GcpProviderTerraformIntegration from "./containers/singleIntegrations/gcpProviderIntegration/gcpProviderTerraformIntegration/gcpProviderTerraformIntegration";
import GcpProviderServiceIntegration from "./containers/singleIntegrations/gcpProviderIntegration/gcpProviderServiceIntegration/gcpProviderServiceIntegration";
import NewrelicIntegration from "./containers/singleIntegrations/newrelicIntegration/newrelicIntegration";
import WebhookIntegration from "./containers/singleIntegrations/webhookIntegrations/webhookIntegration";
import OpsgenieIntegration from "./containers/singleIntegrations/opsgenieIntegration/opsgenieIntegration";
import GcpIntegration from "./containers/singleIntegrations/gcpIntegration/gcpIntegration";
import K8sIntegration from "./containers/singleIntegrations/k8sIntegration/k8sIntegration";
import GithubIntegration from "./containers/singleIntegrations/githubIntegration/githubIntegration";
import GithubProviderIntegration from "./containers/singleIntegrations/githubProviderIntegration/githubProviderIntegration";
import GitlabIntegration from "./containers/singleIntegrations/gitlabIntegration/gitlabIntegration";
import BitBucketIntegration from "./containers/singleIntegrations/bitbucketIntegration/bitbucketIntegration";
import TfcIntegration from "./containers/singleIntegrations/tfcIntegration/tfcIntegration";
import ConsulIntegration from "./containers/singleIntegrations/consulIntegration/consulIntegration";
import SlackIntegration from "./containers/singleIntegrations/slackIntegration/slackIntegration";
import JiraIntegration from "./containers/singleIntegrations/jiraIntegration/jiraIntegration";
import PulumiIntegration from "./containers/singleIntegrations/pulumiIntegration/pulumiIntegration";
import OnBoarding from "./containers/onBoarding/onBoarding";
import TorqIntegration from "./containers/singleIntegrations/torqIntegration/torqIntegration";
import PagerDutyIntegration from './containers/singleIntegrations/pagerDutyIntegration/pagerDutyIntegration';
import PagerDutyProviderIntegration from './containers/singleIntegrations/pagerDutyProviderIntegration/pagerDutyProviderIntegration';
import CloudFlareIntegration from './containers/singleIntegrations/cloudFlareIntegration/cloudFlareIntegration';
import Ns1Integration from "./containers/singleIntegrations/ns1Integration/ns1Integration";
import MongodbAtlasIntegration from "./containers/singleIntegrations/mongodbAtlasIntegration/mongodbAtlasIntegration";
import SingleIntegration from './containers/singleIntegration/singleIntegration';
import AzureIntegration from "./containers/singleIntegrations/azureIntegration/azureIntegration";
import GoogleChatIntegration from "./containers/singleIntegrations/googleChatIntegration/googleChatIntegration";
import WebexIntegration from "./containers/singleIntegrations/webexIntegration/webexIntegration";

// policies
import ExclutionPolicyCreation from "./containers/exclutionPolicyCreation/exclutionPolicyCreation";
import CreateInsight from "./containers/createInsight/createInsight";
import ExcludedAssets from "./containers/excludedAssets/excludedAssets";
import ExcludedDrifts from "./containers/excludedDrifts/excludedDrifts";
// ---
import Notifications from "./containers/notifications/notifications";

// UI
import AppLayout from "./shared/appLayout/appLayout";
import OnBoardingLayout from "./components/onBoarding/onBoardingLayout/onBoardingLayout";
import ViewerOverlay from "./shared/viewerOverlay/viewerOverlay";
import SuspendedAccount from "./containers/suspendedAccount/suspendedAccount";
import CodeCommitIntegration from "./containers/singleIntegrations/codecommitIntegration/codecommitIntegration";
import DevOpsIntegration from "./containers/singleIntegrations/devOpsIntegration/devOpsIntegration";
import RetoolEmbedWrapper from "./shared/retoolEmbedWrapper/retoolEmbedWrapper";
import CiSteps from "./containers/ciSteps/ciSteps";
import Guardrails from "./containers/guardrails/guardrails";
import BackupDR from "./containers/backupDR/backupDR";
import IntegrationDiscovery from "./containers/integrationDiscovery/integrationDiscovery";
import Env0Integration from "./containers/singleIntegrations/env0Integration/env0Integration";
import { initChurnzero } from "./utils/churnzeron";
import { initLogRocket } from "./utils/thirdParties";

const RetoolAnalytics = () => <RetoolEmbedWrapper retoolAppName="analytics" />;

const RouteWrapper = function RouteWrapper({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <AppLayout {...props}>
          <Component {...props} />
        </AppLayout>
      )}
    />
  );
};

const OnBoardingRouteWrapper = function OnBoardingRouteWrapper({
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) => (
        <OnBoardingLayout {...props}>
          <Component {...props} />
        </OnBoardingLayout>
      )}
    />
  );
};

const mutualRoutes = [
  {
    path: "/integrations/aws-integration",
    component: AwsIntegrationConfig,
    exact: true,
  },
  {
    path: "/integrations/aws-integration/terraform",
    component: AwsIntegrationTerraform,
    exact: true,
  },
  {
    path: "/integrations/aws-integration/pulumi",
    component: AwsIntegrationPulumi,
    exact: true,
  },
  {
    path: "/integrations/aws-integration/cloud-formation",
    component: AwsIntegration,
    exact: true,
  },
  {
    path: "/integrations/akamai-integration",
    component: AkamaiIntegration,
    exact: true,
  },
  {
    path: "/integrations/datadog-integration",
    component: DatadogIntegration,
    exact: true,
  },
  {
    path: "/integrations/github-provider-integration",
    component: GithubProviderIntegration,
  },
  {
    path: "/integrations/okta-integration",
    component: OktaIntegration,
  },
  {
    path: "/integrations/gcp-provider-integration",
    component: GcpProviderIntegration,
    exact: true,
  },
  {
    path: "/integrations/gcp-provider-integration/gcp",
    component: GcpProviderServiceIntegration,
    exact: true,
  },
  {
    path: "/integrations/gcp-provider-integration/terraform",
    component: GcpProviderTerraformIntegration,
    exact: true,
  },
  {
    path: "/integrations/newrelic-integration",
    component: NewrelicIntegration,
    exact: true,
  },
  {
    path: "/integrations/webhook-integration",
    component: WebhookIntegration,
  },
  {
    path: "/integrations/opsgenie-integration",
    component: OpsgenieIntegration,
  },
  {
    path: "/integrations/teams-integration",
    component: WebhookIntegration,
  },
  {
    path: "/integrations/slack-integration",
    component: SlackIntegration,
  },
  {
    path: "/integrations/gcs-integration",
    component: GcpIntegration,
  },
  {
    path: "/integrations/k8s-integration",
    component: K8sIntegration,
  },
  {
    path: "/integrations/github-integration",
    component: GithubIntegration,
  },
  {
    path: "/integrations/gitlab-integration",
    component: GitlabIntegration,
  },
  {
    path: "/integrations/bitbucket-integration",
    component: BitBucketIntegration,
  },
  {
    path: "/integrations/codecommit-integration",
    component: CodeCommitIntegration,
  },
  {
    path: "/integrations/tfc-integration",
    component: TfcIntegration,
  },
  {
    path: "/integrations/consul-integration",
    component: ConsulIntegration,
  },
  {
    path: "/integrations/jira-integration",
    component: JiraIntegration,
  },
  {
    path: "/integrations/pulumi-integration",
    component: PulumiIntegration,
  },
  {
    path: "/integrations/torq-integration",
    component: TorqIntegration,
  },
  {
    path: "/integrations/pagerduty-integration",
    component: PagerDutyIntegration,
  },
  {
    path: "/integrations/pagerduty-provider-integration",
    component: PagerDutyProviderIntegration,
  },
  {
    path: "/integrations/cloudflare-integration",
    component: CloudFlareIntegration,
  },
  {
    path: "/integrations/ns1-integration",
    component: Ns1Integration,
  },
  {
    path: "/integrations/mongodbatlas-integration",
    component: MongodbAtlasIntegration,
  },
  {
    path: "/integrations/azuredevops-integration",
    component: DevOpsIntegration,
  },
  {
    path: "/integrations/azure-integration",
    component: AzureIntegration,
  },
  {
    path: "/integrations/google-chat-integration",
    component: GoogleChatIntegration,
  },
  {
    path: "/integrations/webex-integration",
    component: WebexIntegration,
  },
  {
    path: "/integrations/env0-integration",
    component: Env0Integration,
  }
]

const MainWrapper = () => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const { boot } = useIntercom();
  const [viewerMsgWatched, setViewerMsgWatched] = useState(true);
  const [loadingCheckOnboarding, setLoadingCheckOnboarding] = useState(true);

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const onbordingStatus = useSelector((state) => state.onbordingReducer.status);
  const account = useSelector((state) => state.onbordingReducer.account);
  const activeFeatures = useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const isAnalyticsEnabled = account?.enabledRetoolApps?.analytics;
  const isBackupEnabled = activeFeatures.find((feature = {}) => feature.path === '/backup-dr');

  useLayoutEffect(() => {
    dispatch(updateUserPreferences(user));
    checkIfIntegrations();
  }, []);
  // get inital data
  useEffect(async () => {
    const { sub: userId = "", email: userEmail = "", nickname: userNickname = "" } = user || {};
    const userAccountId = user?.["https://infralight.co/account_id"] || "";
    const userAccountName = user?.["https://infralight.co/account_name"] || "";

    await Promise.all([
      getInitialData(),
      openIntercom(),
      initAmplitude(
        userId,
        userEmail,
        userNickname,
        userAccountId,
        userAccountName
      ),
      initLogRocket(user),
      initGoogleTag(),
    ]);

    // check if user is a viewer -
    if (!_.isEmpty(user)) {
      const isViewer = user?.["https://infralight.co/roles"].includes("Viewer");
      dispatch(setIsUserViewer(isViewer));
      // display a viewer msg if not in localstorage
      const msgViewConfirm = localStorage.getItem("viewer");
      if (isViewer && !msgViewConfirm) {
        setViewerMsgWatched(false);
      }
    }
  }, []);

  // change body class according to current selected theme
  useEffect(() => {
    if (themeDark) {
      document.body.classList.remove("light");
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.add("light");
    }
  }, [themeDark]);
  useEffect(() => {
    const { tier_type = '' } = account || {};
    const { sub: userId, email: userEmail = "", nickname: userNickname = "" } = user || {}
    const userAccountName = user?.["https://infralight.co/account_name"] || "";
    if (userEmail && userAccountName && tier_type) {
      initChurnzero(userEmail, userAccountName, tier_type);
      window.Beamer.update({
        filter: `${userAccountName};${tier_type || "Unknown tier"};${userEmail}`,
        user_id: userId,
        user_email: userEmail,
        user_firstname: userNickname,
        user_lastname: userAccountName,
      });
    }
  }, [account])

  const getInitialData = () => {
    if (!user?.["https://infralight.co/ghost"]) {
      dispatch(postAccountActivity());
    }
  };

  const checkIfIntegrations = async () => {
    await Promise.all([
      dispatch(getOnbordingStatus()),
      dispatch(getActiveFeatures())
    ]);
    setLoadingCheckOnboarding(false);
  };

  const openIntercom = () => {
    boot({ name: user.name, email: user.email, imageUrl: user.picture });
  };



  const initGoogleTag = async () => {
    const tagManagerArgs = {
      gtmId: GOOGLE_TAG_ID,
    };
    const company = await cutDomainFromEmail(user?.email);
    if (company !== "gofirefly" && company !== "infralight" && company !== "firefly") {
      return TagManager.initialize(tagManagerArgs);
    }
    return;
  };

  if (loadingCheckOnboarding) {
    return <SplashScreen />;
  }

  if (
    !loadingCheckOnboarding &&
    !_.isEmpty(account) &&
    _.has(account, "active") &&
    !account?.active && 
    !user?.email.includes("gofirefly") && !user?.email.includes("infralight") && !user?.email.includes("firefly")
  ) {
    return <SuspendedAccount />;
  }

  return (
    <Router>
      {isViewer && !viewerMsgWatched && (
        <ViewerOverlay
          handleSetConfirmView={() => {
            setViewerMsgWatched(true);
          }}
        />
      )}
      {!loadingCheckOnboarding &&
      onbordingStatus < ONBORDING_STATUS_NAMES?.length ? (
        <Switch>
          <OnBoardingRouteWrapper path="/welcome" component={OnBoarding} />
          {mutualRoutes.map((route = {}) => (<OnBoardingRouteWrapper {...route} key={route?.path} />))}
          <Redirect from="/" to="/welcome" />
        </Switch>
      ) : (
        <Switch>
          <RouteWrapper exact path="/dashboard" component={Dashboard} />
          <RouteWrapper path="/inventory" component={Inventory} />
          <RouteWrapper path="/users" component={Users} />

          <RouteWrapper
            path="/iac/Add-Iac-Stack"
            component={StackCreation}
            exact
          />
          <RouteWrapper path="/iac/:type?" component={IacStacks} />

          <RouteWrapper path="/event-explorer" component={Drifts} />
          <RouteWrapper path="/integrations" component={Integrations} exact />

          {mutualRoutes.map((route = {}) => (<RouteWrapper {...route} key={route?.path} />))}

          <RouteWrapper
            path="/integrations/:type/discovery-status"
            component={IntegrationDiscovery}
          />

<RouteWrapper
            path="/integrations/new-integration"
            component={IntegrationsCatalog}
          />


          {/* <RouteWrapper
            path="/integrations/gcp/gcp-discovery"
            component={GcpDiscoveryPage}
          />
          <RouteWrapper
            path="/integrations/gcp/gcp-discovery"
            component={GcpDiscoveryPage}
          />
          <RouteWrapper
            path="/integrations/azurerm/azure-discovery"
            component={AzureDiscoveryPage}
          />
          <RouteWrapper
            path="/integrations/aws/discovery-Status"
            component={Discovery}
          /> */}

          <RouteWrapper path="/integrations/:type" component={SingleIntegration} exact />


          {/* asseet policies */}
          <RouteWrapper
            path="/governance/create-custom-control"
            component={CreateInsight}
          />

          <RouteWrapper
            path="/iac-ignored-rules"
            component={ExcludedAssets}
            exact
          />
          <RouteWrapper
            path="/create-iac-ignored-rules"
            component={ExclutionPolicyCreation}
          />

          <RouteWrapper
            path="/excluded-drifts"
            component={ExcludedDrifts}
            exact
          />

          <RouteWrapper path="/notifications" component={Notifications} />
          <RouteWrapper path="/governance" component={Insights} />
          {isAnalyticsEnabled && <RouteWrapper path="/analytics" component={RetoolAnalytics} />}
          <RouteWrapper path="/workflows" exact={true} component={CiSteps} />
          <RouteWrapper path="/workflows/guardrails" exact={true} component={Guardrails} />
          {isBackupEnabled && <RouteWrapper path="/backup" component={BackupDR} />}

          <Redirect from="/" to="/dashboard" />
          
          {/* 404 page */}
          <Route
            authRequired={true}
            path="*"
            exact={true}
            component={NotFound}
          />
        </Switch>
      )}
    </Router>
  );
};

export default MainWrapper;
