import React from "react";
import { Form } from "antd";
import _ from "lodash";
import { WEBEX_URL_API, WEBEX_CLIENT_ID } from "../../../consts/config";
import { nicknameRegex, validate } from "../../../utils/validations";
import { useTranslation } from "react-i18next";

const scope = 'spark%3Amemberships_read%20spark%3Akms%20spark%3Arooms_read%20spark%3Amemberships_write'
const webexUrl = `https://webexapis.com/v1/authorize?client_id=${WEBEX_CLIENT_ID}&response_type=code&redirect_uri=${WEBEX_URL_API}&scope=${scope}`

const WebexCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("common", { keyPrefix: "wizard" });
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, webhookUrl } = formValues;

    if (_.isEmpty(name) || _.isEmpty(webhookUrl)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

    return (
        <div className="webex-creation col">
        <Form
            {...layout}
            name="aws-wizard-form"
            form={form}
            initialValues={{
                name: wizardState.name,
                type: "webex",
            }}
            className="webex-creation__info col"
            onValuesChange={(
              changedValues,
              allValues
            ) => {
                setWizardState(Object.assign(wizardState, allValues));
                checkValidation(allValues);
            }}
        >
            <div className="dark-blue webex-creation__info">
                <span className="dark-blue bold">Procedure</span>
                <ol className="dark-blue webex-creation__info-orderedlist">
                    <ol className="dark-blue webex-creation__info-orderedlist">
                        <li key="1">
                            To install the Firefly Webex integration, go{" "}
                            <a
                              href={webexUrl}
                              className="webhook-link"
                              target="_top" rel="noopener noreferrer"
                            >
                              {t("here")}
                            </a>
                            .
                        </li>
                        <li key="2">
                            {t("select")} <span className="bold">{t("accept")}</span>.
                        </li>
                    </ol>
                </ol>
            </div>
        </Form>
    </div>
  );
};

export default WebexCreation;
