import React from "react";
import { Form, Input } from "antd";
import { nicknameRegex, validate } from "../../../utils/validations";
import { Trans, useTranslation } from "react-i18next";

const Env0Creation = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const { t } = useTranslation(["common", "integrations"]);

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, token, keySecret, keyId } = formValues;

    formValid = name && validate(nicknameRegex.regex, name) && token && keySecret && keyId;

    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="Env0Creation col">
      <div className="dark-blue Env0Creation__intro">
        <span className="dark-blue bold">{t("common:wizard.procedure")}</span>
        <ol className="dark-blue Env0Creation__intro-orderedlist">
          <li key="1">
            {t("integrations:new-integration.env0.1")}
          </li>
          <li key="2">
          <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.env0.com/docs/api-keys"
            >
              {t("integrations:new-integration.env0.2")}
            </a>.
          </li>
          <li key="3">
            <Trans i18nKey="integrations:new-integration.env0.3"
              components={{span: <span className="bold"/>}}
            />
          </li>
          <li key="4">
            <Trans i18nKey="integrations:new-integration.env0.4"
              components={{span: <span className="bold"/>}}
            />
          </li>
          <li key="5">
            <Trans i18nKey="integrations:new-integration.env0.5"
              components={{span: <span className="bold"/>}}
            />
          </li>
          <li key="6">
            <Trans i18nKey="integrations:new-integration.env0.6"
              components={{span: <span className="bold"/>}}
            />
          </li>
          <li key="7">
            {t("common:select")} <span className="bold">{t("common:next")}</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="aws-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name,
          keyId: wizardState.keyId || "",
          keySecret: wizardState.keySecret,
          token: wizardState.token,
        }}
        className="Env0Creation__intro col"
        onValuesChange={(
          changedValues,
          allValues
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder={t("common:wizard.placeholders.enterField", {fieldName: "Integration Name"})} />
        </Form.Item>
        <Form.Item
          label="API Key ID"
          name="keyId"
          rules={[
            {
              required: true,
              message: t("common:wizard.messages.required", { fieldName: "API Key ID"} )
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder={t("common:wizard.placeholders.enterField", {fieldName: "API Key ID"})} />
        </Form.Item>
        <Form.Item
          label="API Key Secret"
          name="keySecret"
          rules={[
            {
              required: true,
              message: t("common:wizard.messages.required", { fieldName: "API Key Secret"} )
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder={t("common:wizard.placeholders.enterField", {fieldName: "API Key Secret"})} />
        </Form.Item>
        <Form.Item
          label="API Key Token"
          name="token"
          rules={[
            {
              required: true,
              message: t("common:wizard.messages.required", { fieldName: "API Key Token"} )
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
            <Input.Password placeholder={t("common:wizard.placeholders.enterField", { fieldName: "API Key Token" })} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Env0Creation;