import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  getExclusions,
  deleteCustomExclusions,
} from "../../redux/actions/exclusionsActions";

// components
import SearchBox from "../../shared/searchBox/searchBox";
import Loading from "../../shared/loading/loading";
import SingleExcluded from "../../components/exclusions/singleExcluded/singleExcluded";
import PolicyDrawer from "../../components/exclusions/excludedAssets/policyDrawer/policyDrawer";
import AppBtn from "../../shared/appBtn/appBtn";
import ConfirmationModal from "../../shared/confirmationModal/confirmationModal";
import { useTranslation } from "react-i18next";

import "./excludedAssets.scss";
import AppToggle from "../../shared/appToggle/appToggle";
import AppPopoverSelect from "../../shared/appPopoverSelect/appPopoverSelect";
import { Button } from "antd";
import ProviderIcon from "../../shared/providerIcon/providerIcon";

const ExcludedAssets = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('excluded-assets')
  const history = useHistory();
  const policies = useSelector((state) => state.exclusionsReducer.rules);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(true);
  const [policyDrawerOpen, setPolicyDrawerOpen] = useState(false);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [filterLables, setFilterLables] = useState([]);
  const [filterUsers, setFilterUsers] = useState([]);
  const [filterDataSources, setFilterDataSources] = useState([]);
  const [allLabels, setAllLabels] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [allDataSources, setAllDataSources] = useState([]);
  const [exclusionFilterActive, setExclusionFilterActive] = useState(true);
  const [exclusionFilterDefault, setExclusionFilterDefault] = useState(true);
  
  useEffect(() => {
    getPolicies();
  }, []);

  useEffect(() => {
    if (!loading) {
      const tempAllLabels = [];
      const tempAllUsers = [];
      const tempAllDataSources = [];
      if (!_.isEmpty(policies)) {
        policies.forEach((item) => {
          if (!_.isEmpty(item?.labels)) {
            tempAllLabels.push(item?.labels);
          }
          if (!_.isEmpty(item?.username)) {
            tempAllUsers.push(item?.username);
          }
          if (!_.isEmpty(item?.datasources)) {
            tempAllDataSources.push(item?.datasources);
          }
        });
      }
      const output = _.union(tempAllLabels.flat(1));
      setAllLabels(output);
      const usersOutput = _.union(tempAllUsers.flat(1));
      setAllUsers(usersOutput);
      const dataSourcesOutput = _.union(tempAllDataSources.flat(1));
      setAllDataSources(dataSourcesOutput);
    }
  }, [loading]);

  const getPolicies = async () => {
    setLoading(true);
    await dispatch(getExclusions());
    setLoading(false);
  };

  // filter the policies based the search and main filter
  const filteredPolicies = useMemo(() => {
    if (!_.isEmpty(policies) && !loading) {
      let filteredPolicies = policies
        .sort(function (a, b) {
          return a.name?.localeCompare(b.name) && a.isDefault - b.isDefault;
        })
      filteredPolicies = _.sortBy(filteredPolicies, 'createdAt').reverse()
        .reverse();
      if (searchVal) {
        filteredPolicies = _.filter(filteredPolicies, (item) =>
          (item.name || "").toLowerCase().includes(searchVal.toLowerCase())
        );
      }

      if (!_.isEmpty(filterLables)) {
        filteredPolicies = filteredPolicies.filter((item) => {
          return !_.isEmpty(_.intersection(filterLables, item?.labels));
        });
      }
      if (!_.isEmpty(filterDataSources)) {
        filteredPolicies = filteredPolicies.filter((item) => {
          return !_.isEmpty(_.intersection(filterDataSources, item?.datasources));
        });
      }
      if (!_.isEmpty(filterUsers)) {
        filteredPolicies = filteredPolicies.filter((item) => {
          return filterUsers.includes(item?.username) || (filterUsers.includes('Firefly') && !item.username) ;
        });
      }

      filteredPolicies = filteredPolicies.filter((item) => item.isEnabled == exclusionFilterActive);

      if(!exclusionFilterDefault){
            filteredPolicies = filteredPolicies.filter((item) => !item.isDefault);
      }
      if(filteredPolicies.length == 0 && !_.isEmpty(filterUsers) && exclusionFilterActive){
            setExclusionFilterActive(false);
      }
      return filteredPolicies;
    }
  }, [searchVal, policies, loading, filterLables, exclusionFilterActive,exclusionFilterDefault, filterUsers, filterDataSources]);

  const handleValidateDelete = (policy, checked) => {
    policy.isEnabled = checked;
    setSelectedPolicy(policy);
    setConfirmDeleteOpen(true);
  };

  const handleViewCode = (policy) => {
    setSelectedPolicy(policy);
    setPolicyDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setPolicyDrawerOpen(false);
    setSelectedPolicy(null);
  };

  const handleCloseConfirmation = () => {
    setSelectedPolicy(null);
    setConfirmDeleteOpen(false);
  };

  const handleDeleteConfirmed = async () => {
    setDeleteInProgress(true);

    const payload = {
      isEnabled: selectedPolicy?.isEnabled,
      type: selectedPolicy?.type,
    }

    await dispatch(deleteCustomExclusions(selectedPolicy?.id, payload));
    setDeleteInProgress(false);
    getPolicies();
    handleCloseConfirmation();
  };
  
  const clearAllFilters = () => {
    setExclusionFilterDefault(true);
    setExclusionFilterActive(true);
    setFilterLables([]);
    setFilterDataSources([]);
    setFilterUsers([]);
    setSearchVal("");
  }

  const renderPolicies = () => {
    if (!_.isEmpty(filteredPolicies)) {
      return _.map(_.sortBy(filteredPolicies, 'createdAt').reverse() , (policy = {}) => {
        return (
          <SingleExcluded
            createdAt={policy.createdAt}
            username={policy.username}
            key={policy.id}
            id={policy.id}
            name={policy.name}
            description={policy.description}
            itemLabels={policy.labels}
            itemDataSources={policy.datasources}
            isEnabled={policy.isEnabled}
            isDefault={policy.isDefault}
            assetTypesArr={policy.type}
            rego={policy.rego}
            onClickedCode={() => handleViewCode(policy)}
            onClickedDelete={(checked) => handleValidateDelete(policy, checked)}
            onClickedEdit={() => history.push({
              pathname: "/create-iac-ignored-rules",
              state: { id: policy?.id, policy, labels: allLabels }
            })}
            type="asset"
            count={policy.count}
            isExcludedAssets
          />
        );
      });
    }
  };

  if (loading) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  return <div className="ExcludedAssets col">

    <div className="ExcludedAssets__filter-wrapper">
      <span>Filter by:</span>
      <div className="ExcludedAssets__filter-wrapper_switches">

        <div >
          <span>Default rules</span>
          <AppToggle
          checked={exclusionFilterDefault}
          toggleChecked={() => setExclusionFilterDefault(!exclusionFilterDefault)}
          />
        </div>
        <span className="divider"></span>
        <div >
          <span>Active rules</span>
          <AppToggle
          checked={exclusionFilterActive}
          toggleChecked={() => setExclusionFilterActive(!exclusionFilterActive)}
          />
        </div>

      </div>
      <div className="ExcludedAssets__filter-wrapper_dropdowns">
      <AppPopoverSelect
          label="Created by"
          options={[...allUsers, 'Firefly']}
          checkedOptions={filterUsers}
          onSubmit={selectedUsers => {
            setExclusionFilterDefault(false);
            setFilterUsers(selectedUsers);
          }}
        />
      <AppPopoverSelect
          label="Data Source"
          options={allDataSources.map(ds => ({label: <span className="AppPopoverSelect_content-list-withIcon" style={{color:"#f00000"}}><ProviderIcon provider={ds} customStyle="notification" /> {_.capitalize(ds)}</span>, value:ds}))}
          checkedOptions={filterDataSources}
          onSubmit={selectedDataSources => setFilterDataSources(selectedDataSources)}
        />
        <AppPopoverSelect
          label="Labels"
          options={allLabels}
          checkedOptions={filterLables}
          onSubmit={selectedLabels => setFilterLables(selectedLabels)}
        />
       
        <span className="divider"></span>
      </div>
      <Button type="link" className="clear-btn" onClick={clearAllFilters}>Clear all</Button>
    </div>
    <div className="ExcludedAssets__wrapper basic-card col">
      <div className="ExcludedAssets__wrapper-header row between">
        <SearchBox
          placeholder={t('search-placeholder')}
          value={(val) => setSearchVal(val)}
          resetSearch={(val) => setSearchVal(val)}
          darkMode
          width={320}
        />
        <AppBtn
          text={t('add-exclusion-btn')}
          onClick={() =>
            history.push({
              pathname: "/create-iac-ignored-rules",
              state: { labels: allLabels },
            })
          }
          icon={<FontAwesomeIcon icon={faPlus} />}
          disabled={isViewer}
        />
      </div>
      {renderPolicies()}
    </div>

    <PolicyDrawer
      visible={policyDrawerOpen}
      closeDrawer={handleCloseDrawer}
      policy={selectedPolicy}
    />

    <ConfirmationModal
      visible={confirmDeleteOpen}
      handleClose={handleCloseConfirmation}
      title={
        !_.isEmpty(selectedPolicy)
          ? `Delete Policy "${selectedPolicy.name}"`
          : null
      }
      description="Are you sure? This cannot be undone"
      loadingConfirm={deleteInProgress}
      onConfirm={handleDeleteConfirmed}
    />
  </div>;
};

export default ExcludedAssets;
