import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleFilters } from "../../../redux/actions/insightsV2Actions";
import PolicyTypeFilter from "./policyTypeFilter/policyTypeFilter";
import TogglesFilter from "./togglesFilter/togglesFilter";
import PolicyCollapseFilters from "./policyCollapseFilters/policyCollapseFilters";
import { ReactComponent as CollapseIcon } from "../../../Images/general_icons/insights-filters/filters-collapse.svg";
import "./insightsFilters.scss";

const InsightsFilters = () => {
  const dispatch = useDispatch();
  const insightsReducer = useSelector((state) => state.insightsV2Reducer);

  const handleToggleFilters = () => {
    return dispatch(toggleFilters());
  };
  
  return (
    <div className="InsightsFilters">
      <PolicyTypeFilter />
      <PolicyCollapseFilters />
      <TogglesFilter />
      <div
        className={`InsightsFilters__footer ${
          insightsReducer.filtersOpen ? "row between" : "col-reverse g10"
        }`}
        style={{ paddingLeft: "5px" }}
      >
        <button
          onClick={handleToggleFilters}
          className={`InsightsFilters-close-btn ${
            insightsReducer.filtersOpen ? "collapse" : "expand"
          }`}
        >
          <CollapseIcon />
        </button>
      </div>
    </div>
  );
};

export default InsightsFilters;
