import React, { memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelRadioFilter from "../../../inventory/inventoryFilters/inventorySingelRadioFilter";
import { sendEvent } from "../../../../utils/amplitude";
import { governanceEvents } from "../../../../utils/amplitudeEvents";
import { insights } from "../../../../utils/icons";

const CategoriesFilter = () => {
  const dispatch = useDispatch();

  const categorysAggs = useSelector((state) => state.insightsV2Reducer.aggs?.category);
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const handleSetSelectedCategory = useMemo(() => (selectedCategory) => {
    const selectedCategoryFormted = selectedCategory[0] ? selectedCategory[0] : '';
    const payload = { ...insightFilters, category: selectedCategoryFormted};
    sendEvent(governanceEvents.categories, {
      selectedFilter: selectedCategoryFormted,
    });
    return dispatch(setInsightsFilters(payload));
  }, [insightFilters]);

  const categories = useMemo(() => {
    return (categorysAggs || []).map((category) => {
      return {
        name: category?.value,
        formattedName: category?.value,
        count: category?.count,
        element: <img src={insights(category?.value)} alt={category?.value} />,
      };
    });
  }, [categorysAggs]);

  return (
    <InventorySingelRadioFilter
      allTypes={categories}
      selectedTypes={handleSetSelectedCategory}
      checkedList={insightFilters?.category || []}
      title="Search"
      onSearch={(val) => sendEvent(governanceEvents.textSearched, {searchedText: val, filterName: "categories"})}
    />
  );
};

export default memo(CategoriesFilter);