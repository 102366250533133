import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from "react-i18next";
import { Collapse, Drawer, Tooltip } from "antd";
import { ReactComponent as DollarImage} from "../../../../../Images/ci/dollar-img.svg"
import { ReactComponent as SecurityImage} from "../../../../../Images/ci/security-img.svg"
import { capitalizeFirst, formatAwsStringWithUnderscore, renderEllipsis } from "../../../../../utils/formatting";
import AssetTypeImage from "../../../../../shared/assetTypeImage/assetTypeImage";
import CostAnalysis from "../postPlanConfig/costAnalysis";
import { useDispatch } from "react-redux";
import { getCostPolicyData } from "../../../../../redux/actions/ciWorkflowsActions";
import Loading from "../../../../../shared/loading/loading";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";

import "./generalSummaryConfig.scss";

const { Panel } = Collapse;
const GeneralSummaryConfig = ({ visible, onClose, generalData = {}, runId, handleOpenConfig }) => {
  const { t: tCommon } = useTranslation("common");
  const { t: tCi } = useTranslation("ci-pipeline");
  const { policySummary = {}, costSummary = {} } = generalData;
  const [loading, setLoading] = useState(false);
  const [policyData, setPolicyData] = useState({});
  const [costData, setCostData] = useState([]);
  const dispatch = useDispatch();
  
  useEffect(() => {
    fetchCostPolicyData();
  }, []);

  const fetchCostPolicyData = async () => {
    setLoading(true);
    const response = await dispatch(getCostPolicyData(runId));
    if (response) {
      const { policyData = {}, costData = [] } = response;
      setPolicyData(policyData);
      setCostData(costData);
    }
    return setLoading(false);
  }
  const handleOpenConfigDrawer = (resource, triggerTab) => {
    onClose();
    handleOpenConfig(resource, triggerTab);
  };

  const isPolicyDataEmpty = isEmpty(policyData?.HIGH) && isEmpty(policyData?.MEDIUM) && isEmpty(policyData?.LOW);
  const isCostDataEmpty = costSummary?.was == 0 && costSummary?.now == 0;
  const isCostDataRemains = (costSummary?.was > 0 || costSummary?.now > 0) && costSummary?.was === costSummary?.now;

  return (
        <Drawer
            title={
            <div className="GeneralSummaryConfig__mask">
              <div className="GeneralSummaryConfig__header row g10">
                <span className="GeneralSummaryConfig__header-title">{tCommon("summary")}</span>
                <span className="GeneralSummaryConfig__header-subtitle">{tCi("policyCost")}</span>
              </div>
            </div>}
            placement="right"
            onClose={onClose}
            visible={visible}
            getContainer={false}
            style={{ position: "absolute" }}
            className="GeneralSummaryConfig"
            width="75%"
        >
            <SecurityImage className="GeneralSummaryConfig__header-securityImg" />
            <DollarImage className="GeneralSummaryConfig__header-dollarImg" />
            {loading ? <div className="GeneralSummaryConfig__body center"> <Loading/> </div>
            : (isPolicyDataEmpty && isCostDataEmpty) ? 
            <div className="basic-100 pad20 centered">
              <AppEmpty customStyle="ciResourcesNotFound" text={tCi("tooltips.summaryTabPolicyCostEmpty")} />
            </div>
            : (isPolicyDataEmpty && isCostDataRemains) ?
              <div className="basic-100 pad20 centered">
                <AppEmpty customStyle="ciResourcesNotFound" text={tCi("tooltips.summaryPolicyEmptyCostEqual", { x: costSummary?.now })} />
              </div>
            :<div className="GeneralSummaryConfig__body col">
            {!isPolicyDataEmpty ? 
              <div className="GeneralSummaryConfig__body-policy col g10" style={isCostDataEmpty ? { borderBottom: '0px', height: "100%" } : {} }>
                <span className="GeneralSummaryConfig__body-policy-title">{tCi("policyViolations")}</span>
                <div className="GeneralSummaryConfig__body-policy-content col g10">
                  {Object.keys(policyData).map((severityLevel = "", ind) => {
                    const severityData = policyData[severityLevel] || [];
                    const severityTitle = capitalizeFirst(severityLevel?.toLowerCase());
                    const severityCount = policySummary[severityLevel] || 0;
                    if (!severityCount) return null;
                    return (
                    <Collapse
                        key={severityLevel}
                        className="GeneralSummaryConfig__body-policy-content"
                        expandIconPosition="right"
                        defaultActiveKey={ind === 0 && severityCount ? severityLevel : ''}
                        >
                        <Panel className={`GeneralSummaryConfig__body-policy-content-panel ${severityLevel}`} key={severityLevel} header={<div className="GeneralSummaryConfig__body-policy-content-header row between">
                          <span className="GeneralSummaryConfig__body-policy-content-header-title">{severityTitle}</span>
                          <span className={`GeneralSummaryConfig__body-policy-content-header-count ${severityLevel}`}>{severityCount}</span>
                        </div>}>
                           <div className="col">
                            {severityData.map((policy = {}, ind) => {
                              return (
                               <div className={`GeneralSummaryConfig__body-policy-content-header-item ${ind === severityData?.length -1 ? 'last': ''} ${severityLevel} row between`} key={uuidv4()} >
                                  <div className="row g8" onClick={() => handleOpenConfigDrawer(policy.resource, "policy")}>
                                    <AssetTypeImage assetType={policy.assetType} customStyle={{ height: "20px", width: "20px", borderRadius: "3px" }}/>
                                    <div className="col">
                                      <span className="bold">{formatAwsStringWithUnderscore(policy.assetType)}</span>
                                      <span className="sub-title">{policy.resource?.name}</span>
                                    </div>
                                  </div>
                                  <Tooltip title={policy?.reason?.length > 120 ? policy?.reason : ""}>
                                    <span style={{ maxWidth: '315px' }}>{renderEllipsis(policy?.reason, 120)}</span>
                                    </Tooltip>
                               </div>
                              );
                            })}
                           </div>
                        </Panel>
                      </Collapse>);
                  })}
                </div>
              </div> : null}
            {!isCostDataEmpty ?
                <div className="GeneralSummaryConfig__body-cost col g10">
                  <div className="row between">
                    <span className="GeneralSummaryConfig__body-policy-title">{tCi("costEstimation")}</span>
                    <div className="GeneralSummaryConfig__body-cost-title row g8">
                        <div className="GeneralSummaryConfig__body-cost-title-item row g5">
                          <span className="GeneralSummaryConfig__body-cost-title-label">Was</span>
                          <span className="GeneralSummaryConfig__body-cost-title-value">${costSummary?.was || 0}</span>
                        </div>
                        <div className="GeneralSummaryConfig__body-cost-title-item row g5">
                          <span className="GeneralSummaryConfig__body-cost-title-label purple-text nowTitle">Now</span>
                          <span className="GeneralSummaryConfig__body-cost-title-item-nowValue purple-text">${costSummary?.now || 0}</span>
                        </div>
                    </div>
                  </div>
                  <div className="GeneralSummaryConfig__body-cost-content col g10">
                      <CostAnalysis data={{ generalCostResults: costData }} isGeneralSummary onGeneralNameClick={(resource) => handleOpenConfigDrawer(resource, "cost")}/>
                  </div>
                </div> : null}
            </div>}
        </Drawer>
  );
};

export default GeneralSummaryConfig;
