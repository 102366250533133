import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { Tooltip } from "antd";
import { getIconMutedColor } from "../../utils/helpers";

import "./insightsLabelIndicator.scss";

const InsightsLabelIndicator = ({ insights, onClick }) => {

  const allInsights = useSelector(
    (state) => state.classificationsReducer.classifications
  );
  
  const [activeLabels, setActiveLabels] = useState([]);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const labels = [
    {
    name: "Governance",
    icon: (
        <svg
          width="14"
          height="14"
          viewBox="0 0 22 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.3636 2.46317C17.2098 3.19711 17.7676 4.2112 17.9367 5.32267L17.9378 5.32487C18.9542 5.49854 19.8686 6.05065 20.4993 6.87158C21.1301 7.69253 21.4311 8.72222 21.3428 9.75628C21.2544 10.7903 20.783 11.7531 20.0222 12.4535C19.2614 13.1539 18.2668 13.5406 17.2359 13.5369H15.8242C16.0036 13.0043 16.1009 12.4335 16.1009 11.8397C16.1009 8.92189 13.7514 6.55653 10.8532 6.55653C7.95502 6.55653 5.60555 8.92189 5.60555 11.8397C5.60555 12.4335 5.70283 13.0043 5.8822 13.5369H4.4568C3.75409 13.5354 3.0635 13.3525 2.45109 13.0056C1.83867 12.6586 1.32491 12.1593 0.958932 11.5554C0.592954 10.9514 0.387002 10.263 0.360773 9.55605C0.334544 8.84908 0.488915 8.14715 0.809122 7.5174C1.2379 6.67413 1.94128 6.00466 2.8016 5.62095C2.89682 4.22848 3.51474 2.92467 4.52995 1.97414C5.54515 1.02361 6.88156 0.497588 8.26792 0.502847C9.48 0.502793 10.6586 0.902975 11.6231 1.64204C12.1653 1.43146 12.741 1.32218 13.3221 1.31954C14.4389 1.32362 15.5176 1.72919 16.3636 2.46317Z"
            fill={!isEmpty(activeLabels) ? "#E31174" : getIconMutedColor(themeDark)}
          />
          <path
            d="M14.7651 12.0201C14.7668 11.115 14.4698 10.2351 13.9209 9.51924C13.3721 8.80334 12.6027 8.2922 11.7341 8.06643C10.8655 7.84066 9.94713 7.91313 9.12386 8.27239C8.30058 8.63166 7.61927 9.25728 7.1874 10.0506C6.75553 10.8438 6.59767 11.7596 6.73874 12.6535C6.8798 13.5473 7.31175 14.3683 7.96646 14.987C8.62116 15.6056 9.46136 15.9868 10.3545 16.0702C11.2476 16.1536 12.1429 15.9347 12.899 15.4478C12.9317 15.4233 12.9713 15.4101 13.012 15.4101C13.0527 15.4101 13.0923 15.4233 13.125 15.4478L14.9328 17.2793C15.0005 17.3483 15.0812 17.403 15.17 17.4403C15.2588 17.4777 15.3541 17.4969 15.4503 17.4969C15.5465 17.4969 15.6418 17.4777 15.7306 17.4403C15.8195 17.403 15.9001 17.3483 15.9679 17.2793C16.0362 17.211 16.0904 17.1296 16.1274 17.04C16.1644 16.9503 16.1835 16.8542 16.1835 16.7571C16.1835 16.66 16.1644 16.5639 16.1274 16.4742C16.0904 16.3846 16.0362 16.3032 15.9679 16.2349L14.1528 14.4107C14.1285 14.3777 14.1154 14.3377 14.1154 14.2967C14.1154 14.2556 14.1285 14.2156 14.1528 14.1827C14.5554 13.5347 14.7677 12.7849 14.7651 12.0201ZM8.16093 12.0201C8.16095 11.5057 8.31243 11.0028 8.59615 10.5753C8.87987 10.1479 9.28304 9.81509 9.7545 9.61924C10.226 9.42338 10.7444 9.37326 11.2442 9.47525C11.7439 9.57724 12.2023 9.82673 12.5613 10.1921C12.9203 10.5574 13.1636 11.0221 13.2604 11.5272C13.3572 12.0324 13.3031 12.5551 13.105 13.0292C12.9069 13.5032 12.5737 13.9072 12.1477 14.1898C11.7217 14.4725 11.222 14.621 10.7122 14.6166C10.0336 14.6108 9.38468 14.3347 8.90684 13.8484C8.429 13.3621 8.16091 12.705 8.16093 12.0201Z"
            fill={!isEmpty(activeLabels) ? "#E31174" : getIconMutedColor(themeDark)}
          />
        </svg>
      ),
    },
  ];

  useEffect(() => {
    checkInsightTypeExiests()
  },[insights, allInsights])

  const checkInsightTypeExiests = () => {
    if(_.isEmpty(insights) && _.isEmpty(allInsights)) {
      return;
    }
    // enrich insights with their full data;
    const enrichInsights = insights ? allInsights?.filter((item) => insights.includes(item?.id)): [];
  
    // get label types only
    const labelsOnly = _.map(enrichInsights || [], (item) => isEmpty(item?.labels) ? item?.frameworks : item.labels);
    // distinct types
    const assetUniqLables = _.uniq(_.flatten(labelsOnly));
    return setActiveLabels(assetUniqLables)
  }

  const renderLabels = () => {

    return _.map(labels || [], (item) => {
      return (
        <Tooltip
          key={item?.name}
          placement="top"
          title={!isEmpty(activeLabels) ? `${item?.name}` : item?.name}
          overlayInnerStyle={{
            maxWidth: "150px",
            padding: "10px",
            fontSize: "13px",
          }}
        >
          <button
            onClick={
              !isEmpty(activeLabels)
                ? onClick
                : () => {
                    return false;
                  }
            }
            className="InsightsLabelIndicator center"
            style={{
              cursor: !isEmpty(activeLabels) ? "pointer" : "default",
            }}
          >
            {item?.icon}
          </button>
        </Tooltip>
      );
    });
  };
  return (
    <div className="row" style={{ gap: "10px" }}>
      {renderLabels()}
    </div>
  );
};

export default InsightsLabelIndicator;
