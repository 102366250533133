import { ReactComponent as MatchingRules } from "../../../Images/general_icons/classification.svg";
import { ReactComponent as WorkflowIcon } from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { ReactComponent as GuardrailsIcon } from '../../../Images/general_icons/sidebar/guardrails.svg';
import { ReactComponent as StatusIcon } from '../../../Images/ci/filters/status.svg';
import { ReactComponent as ReposIcon } from '../../../Images/ci/filters/repo.svg';
import { ReactComponent as CostIcon } from '../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourcesIcon } from '../../../Images/ci/guardrails/resources.svg';
import { ReactComponent as TagIcon } from '../../../Images/ci/guardrails/tag.svg';


import ListIcon from "../../../Images/general_icons/ListAdd.svg";
import TagsIcon from "../../../Images/general_icons/TagsIcon.svg";
import OwnerIcon from "../../../Images/general_icons/user_icon_primary.svg";
import LocationIcon from "../../../Images/general_icons/Location_icon.svg";

import i18n from '../../../i18n'
import { formatEmptyFullArrays } from "../../guardrails/guardrailsModal/guardrailsHelper";

export const notificationValues = {
    drift: 'Drift',
    unmanagedResource: 'UnmanagedResource',
    ghostResource: 'GhostResource',
    insightDetected: 'InsightDetected',
    stateFileChange: 'StateFileChange',
    systemActivity: 'SystemActivity',
    workflowRunLifecycle: 'WorkflowRunLifecycle',
    workspaceGuardrailViolation: 'GuardrailRuleViolation',
}

const guardrailsIconByType = { cost: CostIcon,policy: PolicyIcon, resource: ResourcesIcon, tag: TagIcon };

export const getGuardrailOrWorkflowItem = (notification = {}, guardrailsData = []) => {
  const item = {};
  if (notification?.eventType === notificationValues.workflowRunLifecycle) {
    item.customIcon = <WorkflowIcon />;
    return item;
  }
  const guardrailItemDetails = guardrailsData?.find((item = {}) => item.id === notification?.scope?.guardrailRuleId);
  const Icon = guardrailsIconByType[guardrailItemDetails?.type];
  if (Icon) {
    item.customIcon = <Icon className={`SingleGuardrail__header-icon ${guardrailItemDetails?.type} pointer`} />
  }
  return item;
};

export const scopesTypes = {
  assetTypes: {
    displayName: "Types",
    icon: ListIcon
  },
  tags: {
    displayName: "Tags",
    icon: TagsIcon
  },
  owners: {
    displayName: "Owners",
    icon: OwnerIcon
  },
  locations: {
    displayName: "Locations",
    icon: LocationIcon
  },
  workspaceNames: {
    displayName: "Workspaces",
    customIcon: <WorkflowIcon className="purple-text"/>,
  },
  workspaceRepositories: {
    displayName: "Repositories",
    customIcon: <ReposIcon className="purple-text"/>,
  },
  workspaceLabels: {
    displayName: "Labels",
    icon: TagsIcon,
  },
  workspaceStatus: {
    displayName: "Workspace Status",
    customIcon: <StatusIcon className="purple-text"/>,
  },
  guardrailRuleId: {
    displayName: "Guardrail Rule",
    customIcon: <GuardrailsIcon className="purple-text"/>,
    isString: true,
  }
}

export const notificationTypesIndex = [
    {
      id: 1,
      value: notificationValues.drift,
      name: i18n.t('notifications:create-notification.form.types.drift'),
      displayName: i18n.t('notification:types.drift.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "code-branch",
    },
    {
      id: 2,
      value: notificationValues.unmanagedResource,
      name: i18n.t('notifications:create-notification.form.types.unmanaged-resource'),
      displayName: i18n.t('notification:types.unmanaged-resource.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "exclamation-circle",
    },
    {
      id: 3,
      value: notificationValues.ghostResource,
      name: i18n.t('notifications:create-notification.form.types.ghost-resource'),
      displayName: i18n.t('notification:types.ghost-resource.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "ghost",
    },
    {
      id: 4,
      value: notificationValues.insightDetected,
      name: i18n.t('notifications:create-notification.form.types.insight-detected'),
      displayName: i18n.t('notification:types.insight-detected.display'),
      relatedScope: "insight",
      disabled: false,
      icon: <MatchingRules className={notificationValues.insightDetected}/>,
    },
    {
      id: 5,
      value: notificationValues.workflowRunLifecycle,
      name: i18n.t('notifications:create-notification.form.types.workflow'),
      displayName: i18n.t('notification:types.workflow.display'),
      relatedScope: "provider",
      disabled: false,
      icon: <WorkflowIcon className={notificationValues.workflowRunLifecycle}/>,
    },
    {
      id: 6,
      value: notificationValues.workspaceGuardrailViolation,
      name: i18n.t('notifications:create-notification.form.types.guardrails'),
      displayName: i18n.t('notification:types.guardrails.display'),
      relatedScope: "provider",
      disabled: false,
      icon: <GuardrailsIcon className={notificationValues.workspaceGuardrailViolation}/>,
    }
];

export const formatWorkflowGuardrailsConfig = (payload, values = {}, isWorkflow, isGuardrails) => {
  if (!isWorkflow && !isGuardrails) return;
  const currentScope = payload.scope || {};
  const { workspaceNames = [], workspaceRepositories = [], workspaceLabels = [], workspaceStatus = [], guardrailRuleId = "" } = values;
  if (isWorkflow) {
    const workspacesAdditions = { workspaceNames, workspaceRepositories, workspaceLabels, workspaceStatus }; 
    const scopeAdditions = formatEmptyFullArrays(workspacesAdditions, Object.keys(workspacesAdditions)) || {};
    payload.scope = { ...currentScope, ...scopeAdditions };
  }
  if (isGuardrails) {
    payload.scope = { ...currentScope, guardrailRuleId };
  }
}