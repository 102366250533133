import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import "./assetHistoryV2.scss";
import { getHistoryRevisions } from "../../../../redux/actions/historyActionsV2";
import { isEmpty } from "lodash";
import RevisionCompareV2 from "./historyContent/revisionCompreV2";
import { ReactComponent as CelandarIcon } from "../../../../Images/general_icons/celandar.svg";
import useScrollPosition from "../../../../hooks/useScrollPosition";
import { ReactComponent as ArrowIcon } from "../../../../Images/general_icons/arrow.svg";
import { sendEvent } from "../../../../utils/amplitude";

const AssetHistoryV2 = ({ asset }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("asset-history");

  const revisions = useSelector((state) => state.historyReducerV2.revisions);
  const [loadingInit, setLoadingInit] = useState(true);
  const assetHistoryDiv = document.getElementById("assetHistory-scroll");
  const { hasMoreToScroll } = useScrollPosition(assetHistoryDiv, false);

  const handleScroll = (divToScroll) => {
    if (hasMoreToScroll) {
      sendEvent("clicked navigation timeline arrow");
      divToScroll.scrollTop = divToScroll.scrollHeight;
    }
  };

  useEffect(() => {
    handleFetchItialHistoryV2();
  }, [asset]);

  const handleFetchItialHistoryV2 = async () => {
    setLoadingInit(true);
    const { provider, integrationId, assetType, frn } = asset;
    await dispatch(
      getHistoryRevisions(provider, integrationId, assetType, frn, 0, 50)
    );
    setLoadingInit(false);
  };

  return (
    <div
      className={
        loadingInit || isEmpty(revisions)
          ? "tab-page center"
          : "AssetHistoryV2 col g20"
      }
    >
      {loadingInit ? (
        <Loading />
      ) : isEmpty(revisions) ? (
        <AppEmpty customStyle="code" text={t("no-history-revisions")} />
      ) : (
        <>
          <span className="AssetHistoryV2__title text bold font-20">
            Mutation Log
          </span>
          <div className="AssetHistoryV2__revisions col g20">
            <div className="AssetHistoryV2__revisions__calandar-container">
              <div className="AssetHistoryV2__revisions__calandar-row">
                <CelandarIcon className="AssetHistoryV2__calendarIcon" />
              </div>
              <div
                className="AssetHistoryV2__sub-title text bold row g20"
              >
                <span>Revision Configuration</span>
                <span>Current Configuration</span>
              </div>
            </div>
            <div
              className="AssetHistoryV2__revisions-items col g20 "
              id="assetHistory-scroll"
            >
              {revisions.map((revision, index) => (
                <div key={index}>
                  <RevisionCompareV2
                    currentRevision={revision?.currentRevision}
                    requestedRevision={revision?.requestedRevision}
                    revisionId={revision?.revisionId}
                    timestamp={revision?.timestamp}
                    revision={revision}
                  />
                </div>
              ))}
            </div>
            <div className="AssetHistoryV2__line" />
            {
              <ArrowIcon
                className={`AssetHistoryV2__scrollIcon ${
                  hasMoreToScroll ? "" : "disabled"
                }`}
                onClick={() => handleScroll(assetHistoryDiv)}
              />
            }
          </div>
        </>
      )}
    </div>
  );
};

export default AssetHistoryV2;
