import React from "react";
import "./gradientBorderElement.scss";

const GradientBorderElement = ({ content, customContainerStyle, customInnerBackground }) => {
    return (
        <div className="GradientBorderElement" style={customContainerStyle ? customContainerStyle : {}}>
            <div className="GradientBorderElement__mask">
                <div className="GradientBorderElement__content" style={customInnerBackground ? { background: customInnerBackground } : null}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default GradientBorderElement;