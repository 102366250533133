import React, { useState } from "react";
import _ from "lodash";
import BasicInfo from "./basicInfo";
import IaCTab from "./iacTab/iacTab";
import VcsCode from "./vcsCode/vcsCode";
import ManagingResource from "./managingResource/managingResource";
import AppAccordion from "../../../../shared/appAccordion/appAccordion";
import Insights from "./insights/insights";
import "./assetInfo.scss";
import { useSelector } from "react-redux";

const AssetInfo = ({
  asset = {},
  onClickTag,
  onParentClick,
  onClickResourceGroup,
  loadingIac,
  stacksData,
  cftStacksData,
  handleOpenAnalysis,
  parentData,
  childParentData,
  infoActiveTab,
  handleAssetModalFromChildren,
  handleClose,
}) => {
  const [activePanel, setActivePanel] = useState(infoActiveTab || "info");
  const controllerSources = useSelector((state) => state.iacStacksReducer.controllerSources);

  return (
    <div className="AssetInfo col">
      <AppAccordion
        header={<div className="AssetInfo__item-title">Info</div>}
        key="info"
        card
        content={
          <BasicInfo 
            asset={asset} 
            handleOpenAnalysis={handleOpenAnalysis} 
            parentData={parentData} 
            childParentData={childParentData} 
            onClickTag={onClickTag}
            onParentClick={onParentClick}
            onClickResourceGroup={onClickResourceGroup}
          />
        }
        isAllActive={activePanel === "info"}
      />
       {!_.isEmpty(asset?.classifications) && <AppAccordion
        header={<div className="AssetInfo__item-title">Governance</div>}
        key="insights"
        card
        isAllActive={activePanel === "insights"}
        content={
          <Insights
          classificationIds={asset?.classifications} handleClose={handleClose}
          />
        }
      />}
      {!_.isEmpty(asset?.stackIds) && <AppAccordion
        header={<div className="AssetCard-title">IaC Stacks</div>}
        key="iac"
        card
        isAllActive={activePanel === "iac"}
        content={
          <IaCTab
            rowData={asset}
            isFetchingStack={loadingIac}
            stacksData={stacksData}
            cftStacksData={cftStacksData}
          />
        }
      />}
      {(!_.isEmpty(asset?.vcsCodeLink) ||
        !_.isEmpty(asset?.vcsRepo) ||
        !_.isEmpty(asset?.vcsProvider)) && (
          <AppAccordion
            header={<div className="AssetCard-title">Git</div>}
            key="git"
            card
            isAllActive={activePanel === "git"}
            content={<VcsCode asset={asset} />}
          />
        )}
        {asset.hasControllerSources &&
        !_.isEmpty(controllerSources) &&
          <AppAccordion
            header={<div className="AssetCard-title">Managing Resource</div>}
            key="managingResource"
            card
            isAllActive={activePanel === "managingResource"}
            content={
            <ManagingResource
              controllerSources={controllerSources}
              assetData={asset}
              handleAssetModalFromChildren={handleAssetModalFromChildren}/>}
          />
        }
    </div>
  );
};

export default AssetInfo;
