import React from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import AppDrawer from "../appDrawer/appDrawer";
import ActionBtn from "../actionBtn/actionBtn";
import AppEmpty from "../appEmpty/appEmpty";
import { CODIFY_TYPES } from "../../consts/general";
import { alertCodifyRedact } from "../../components/inventory/codifyDrawer/codifyHelpers";

import "./terraformImport.scss";

const TerraformImport = ({ visible, closeDrawer, codifyActiveTab}) => {
  const terraformCmds = useSelector(
    (state) => state.inventoryReducer.terraformCommands
  );
  const cftCmds = useSelector((state) => state.inventoryReducer.cftCommands);
  const isTfCmdsRedacted = useSelector((state) => state.inventoryReducer.isTfCmdsRedacted);

  const isCFT = codifyActiveTab === CODIFY_TYPES.cloudformation;
  const isCDK = codifyActiveTab === CODIFY_TYPES.cdk || codifyActiveTab === CODIFY_TYPES.cdkl2;
  const commands = isCFT || isCDK ? cftCmds : terraformCmds;
  const isRedactedValue = !isCFT && !isCDK && isTfCmdsRedacted;
  
  const renderCommands = () => {
    if (!_.isEmpty(commands)) {
      return _.map(commands || [], (item) => (
        <span className="TerraformImport__item" key={uuidv4()}>
          <SyntaxHighlighter
            style={atomDark}
            wrapLines={true}
            language={isCFT || isCDK ? "bash" : "text" }
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
          >
            {item}
          </SyntaxHighlighter>
        </span>
      ));
    }
    return <AppEmpty text="No data" customStyle="code" />;
  };


  return (
    <AppDrawer
      title={isCFT ? "CloudFormation Import Script" : isCDK ? "CloudFormation CDK Import Script" : "Terraform Import Command"}
      closeDrawer={closeDrawer}
      visible={visible}
      hideClose
      topBorder
      zIndex={1002}
      footer={[
        <ActionBtn
          text="Copy"
          icon="copy"
          disabled={_.isEmpty(commands)}
          action="copy"
          stringToAction={commands?.join(" ; ")}
          refresh={visible}
          onClickDifferentActions={() => isRedactedValue ? alertCodifyRedact() : null}
          key="copy"
        />,
        <ActionBtn
          text="Export"
          disabled={_.isEmpty(commands)}
          action="download"
          fileType={isCFT || isCDK ? "sh" : "txt"}
          fileName= {isCFT ? "FireflyCfnImport" : isCDK ? "FireflyCdkImport" : "TF_Import_Commands"}
          icon="donwload"
          stringToAction={commands?.join(" ; ")}
          onClickDifferentActions={() => isRedactedValue ? alertCodifyRedact() : null}
          refresh={visible}
          key="download"
        />
      ]}
    >
      <div className="TerraformImport col">
        {renderCommands()}
      </div>
    </AppDrawer>
  );
};

export default TerraformImport;