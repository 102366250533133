import React, { useEffect, useMemo, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { integrationIcons } from "../../../utils/icons";
import { getAllNotificationIntegrations } from "../../../redux/actions/globalAppActions";
import { useTranslation } from "react-i18next";
import { getAllSlackChannels, getPagerdutyServices, getWebexRooms } from "../../../redux/actions/integrationsActions";
import { getAllProfiles } from "../../../redux/actions/profilesActions";

const NotificationSection = ({ onGuardrailChange = () => null , guardrailData = {}, form, isEditNotification }) => {
    const dispatch = useDispatch();
    const { t: tModal } = useTranslation("ci-pipeline", { keyPrefix: "guardrails.modal" });

    const [notificationLoading, setNotificationLoading] = useState(false);
    const [slackLoading, setSlackLoading] = useState(false);
    const [pgLoading, setPgLoading] = useState(false);
    const [webexLoading, setWebexLoading] = useState(false);
    const [emailsLoading, setEmailsLoading] = useState(false);

    const { id: notificationId = "", service: serviceSelected = "", name: selectedIntegrationName = "" } = guardrailData?.notification || {};
    
    const isEmailSelected = serviceSelected === "email";
    const isSlackAppSelected = serviceSelected === "slackApp";
    const isWebex = serviceSelected === "webex";
    const isPagerDuty = serviceSelected === "pagerduty";

    const slackChannels = useSelector((state) => state.integrationsReducer.channels);
    const webexRooms = useSelector((state) => state.integrationsReducer.rooms);
    const pdServices = useSelector((state) => state.integrationsReducer.pdServices);
    const users = useSelector((state) => state.profilesReducer.profiles);

    const notificationIntegrations = useSelector((state) => state.globalAppReducer.notificationsIntegrations) || []; 
    const allNotifications = useMemo(() => {
        const flatten =  Object.values(notificationIntegrations || {}).reduce((acc, curr) => [...acc, ...curr], []) || [];
        return [...flatten, { id: "emailAddresses", name: "Email Address", service: "email" }]
    }, [notificationIntegrations]);

    useEffect(() => {
        if (isEmpty(notificationIntegrations)) {
            handleFetchNotificationIntegration();
        }
        if (isEditNotification && notificationId) {
            form.setFieldsValue({ notifications: { value: notificationId, label: renderNotificationItem({ id: notificationId, service: serviceSelected, name: selectedIntegrationName }) } });
        }
    }, []);

    useEffect(() => {
        if (isSlackAppSelected && isEmpty(slackChannels)) {
            handleFetchSlackChannels();
        }
    }, [isSlackAppSelected]);

    useEffect(() => {
        if (isWebex && isEmpty(webexRooms)) {
            handleFetchWebexRooms();
        }
    }, [isWebex]);

    useEffect(() => {
        if (isEmailSelected && isEmpty(users)) {
            handleFetchEmailAddresses();
        }
    }, [isEmailSelected]);

    useEffect(() => {
        if (isPagerDuty && isEmpty(pdServices)) {
            handleFetchPagerDutyServices();
        }
    }, [isPagerDuty]);

    const handleFetchPagerDutyServices = async () => {
        setPgLoading(true);
        await dispatch(getPagerdutyServices(notificationId));
        setPgLoading(false);
    };

    const handleFetchWebexRooms = async () => {
        setWebexLoading(true);
        await dispatch(getWebexRooms(notificationId));
        setWebexLoading(false);
    };
    
    const handleFetchEmailAddresses = async () => {
        setEmailsLoading(true);
        await dispatch(getAllProfiles({ pageNum: 1, perPage: 100 }));
        setEmailsLoading(false);
    };

    const handleFetchSlackChannels = async () => {
        setSlackLoading(true);
        await dispatch(getAllSlackChannels(notificationId));
        setSlackLoading(false);
    };

    const handleFetchNotificationIntegration = async () => {
        setNotificationLoading(true);
        await dispatch(getAllNotificationIntegrations());
        setNotificationLoading(false);
    }
    const changeNotificationData = (data = {}) => onGuardrailChange({ notification: data });

    const handleOnSelectDestination = (value = []) => {
        const [id = "", service = "", name = ""] = value;
        changeNotificationData({ id, service, name });
        form.resetFields(["channels", "services", "rooms", "emails"]);
    }
   const handleOnSlackChannelsSelect = (channels = []) => changeNotificationData({ id: notificationId, service: serviceSelected, name: selectedIntegrationName, channels });
   const handleOnPagerDutyService = (pd_service = "") => {
        const publicKey = notificationIntegrations?.pagerduty?.find((integ) => integ.id === notificationId)?.publicKey || "";
        changeNotificationData({ id: notificationId, service: serviceSelected, name: selectedIntegrationName, pd_service, integrationKey: [publicKey] });
   }
   const handleOnWebexRoomsSelect = (channels = []) => changeNotificationData({ id: notificationId, service: serviceSelected, name: selectedIntegrationName, channels });
   const handleOnEmailAddressesSelect = (channels = []) => changeNotificationData({ id: notificationId, service: serviceSelected, name: selectedIntegrationName, channels });

   const renderNotificationItem = (item) => (
    <div className="row g10">
        <img src={integrationIcons[item.service]} width={16} height={16}/>
        <span>{item.name}</span>
    </div>
   );
   return (
        <div className="row g40">
            <Form.Item label="Notification Destination (optional)" tooltip={tModal("tooltips.violationNotifications")} name="notifications" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                <Select placeholder="Select Destination" loading={notificationLoading} allowClear showArrow showSearch
                getPopupContainer={(trigger) => trigger.parentNode} labelInValue disabled={notificationLoading}
                value={{ value: notificationId, label: renderNotificationItem(notificationId ? { id: notificationId, service: serviceSelected, name: selectedIntegrationName } : {})}}
                filterOption={(input, option) => option?.children?.props?.children[1]?.props?.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}
                onChange={(elem) => handleOnSelectDestination(elem?.value)} onClear={() => handleOnSelectDestination()}>
                        {allNotifications.map((item = {}) => (
                        <Select.Option key={item.id} value={[item.id, item.service, item.name]}>
                            {renderNotificationItem(item)}
                        </Select.Option>))}
                </Select>
            </Form.Item>
            {isSlackAppSelected &&
            <Form.Item label="Channels" name="channels" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                <Select loading={slackLoading} placeholder="Select Channel" showArrow showSearch disabled={slackLoading} value={guardrailData?.notification?.channels || []}
                mode="multiple" getPopupContainer={(trigger) => trigger.parentNode} onChange={handleOnSlackChannelsSelect}
                filterOption={(input, option) => option.children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {(slackChannels || []).map((channel = {}) => <Select.Option key={channel.id} value={channel.id}># {channel.name}</Select.Option>)}
                </Select>
            </Form.Item>}
            {isPagerDuty && !isEmpty(pdServices) &&
            <Form.Item label="Services" name="services" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                <Select loading={pgLoading} placeholder="Select Pagerduty service" disabled={pgLoading} value={guardrailData?.notification?.pd_service || ""}
                getPopupContainer={(trigger) => trigger.parentNode} showArrow showSearch onChange={handleOnPagerDutyService}
                filterOption={(input, option) => option.children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {(pdServices || []).map((service = {}) => <Select.Option key={service.id} value={service.id}># {service.name}</Select.Option>)}
                </Select>
            </Form.Item>}
            {isWebex && 
            <Form.Item label="Rooms" name="rooms" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                <Select loading={webexLoading} placeholder="Select Room" mode="multiple" disabled={webexLoading} value={guardrailData?.notification?.channels || []}
                getPopupContainer={(trigger) => trigger.parentNode} showArrow showSearch onChange={handleOnWebexRoomsSelect}
                filterOption={(input, option) => option.children[1]?.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {(webexRooms || []).map((room = {}) => <Select.Option key={room.id} value={room.id}> # {room.title}</Select.Option>)}
                </Select>
            </Form.Item>}
            {isEmailSelected &&
            <Form.Item label="Email Addresses" name="emails" style={{ flexDirection: "column", marginBottom: "0", width: '50%' }}>
                <Select loading={emailsLoading} placeholder="Select Email" disabled={emailsLoading} value={guardrailData?.notification?.channels || []}
                mode="multiple" getPopupContainer={(trigger) => trigger.parentNode} showArrow showSearch onChange={handleOnEmailAddressesSelect}
                filterOption={(input, option) => option.children?.toLowerCase()?.indexOf(input.toLowerCase()) >= 0}>
                    {(users || []).map((user = {}) => <Select.Option key={user.user_id} value={user.email}>{user.email}</Select.Option>)}
                </Select>
            </Form.Item>}
        </div>
   ) 
}

export default NotificationSection;