import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { v4 as uuidv4 } from "uuid";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { providers as providersObj } from "../../../utils/providers";
import { filter, groupBy, keys } from "lodash";
import { providerNameFormatter } from "../../../utils/formatting";
import { queryProviders } from "../../../redux/actions/iacStacksActions";
import Loading from "../../../shared/loading/loading";
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import ProviderAccountsTable from "./providerAccountsTable";
import { IAC_TYPES, PROVIDERS_TITLE_MAP } from "../../../consts/general";
import "./providersTable.scss";

const ProvidersTable = ({
  setFilters,
  selectedIacType,
  setSelectedCategory,
}) => {
  const apiRef = useRef();
  const providers = useSelector(
    (state) => state.iacStacksReducer?.providers?.types
  );
  const urls = useSelector((state) => state.iacStacksReducer?.providers?.urls);
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getProviders();
  }, []);

  useEffect(() => {
    if (!isEmpty(providers)) {
      arrangeProviders();
    }
  }, [providers, searchVal]);

  const getProviders = async () => {
    setLoading(true);
    const stateType = selectedIacType === IAC_TYPES.terraform ? [IAC_TYPES.terraform, IAC_TYPES.opentofu] : [selectedIacType];
    await dispatch(queryProviders({ ...emptyIacScreenFilters, stateType }));
    setLoading(false);
  };

  const getDetailPanelContent = useCallback(
    ({ row }) =>
      !isEmpty(row?.children) ? (
        <ProviderAccountsTable
          children={row?.children}
          selectedIacType={selectedIacType}
          setSelectedCategory={setSelectedCategory}
          setFilters={setFilters}
        />
      ) : null,
    []
  );

  const getDetailPanelHeight = useCallback(() => "auto", []);

  const getChildren = (providerTypeKey, providerTypeArray) => {
    let children = [];
    for (let [providerAccount, providerAccountInfo] of Object.entries(providerTypeArray) || []) {
      const isIntegrated = providerAccountInfo?.isIntegrated || false;
      let status = "integrated";
      if (
        !keys(providersObj)?.includes(providerTypeKey) &&
        providerTypeKey !== "kubernetes"
      ) {
        status = "notSupported";
      } else {
        if (!isIntegrated) {
          status = "notIntegrated";
        }
      }
      const count = providerAccountInfo?.count || 0;
      children?.push({
        id: uuidv4(),
        providerId: providerAccount,
        type: providerTypeKey,
        status,
        link: keys(providersObj)?.includes(providerTypeKey)
          ? providersObj[providerTypeKey]?.integrationPath ||
            (providerTypeKey === "kubernetes" ? "k8s" : providerTypeKey)
          : providerTypeKey === "kubernetes"
          ? "k8s"
          : "",
        numStacks: count > 0 ? count : "",
        sourceLink: "",
        children: null,
      });
    }
    // filter out duplicated instances
    const groupedChildren = groupBy(children, "providerId");
    return keys(groupedChildren)?.map((providerId) => {
      if (groupedChildren[providerId]?.length > 1) {
        const integratedMatchIndex = groupedChildren[providerId]?.findIndex(
          (child) => child?.status === "integrated"
        );
        // found integrated account
        if (integratedMatchIndex > -1) {
          return groupedChildren[providerId][integratedMatchIndex];
        }
      }
      return groupedChildren[providerId][0];
    });
  };

  const arrangeProviders = () => {
    const providerTypes = [];
    const providerUrls = {};
    for (let linkAgg of urls) {
    let linkArr = linkAgg?.key?.split("/");
      let surfix = linkArr[linkArr?.length - 1];
      providerUrls[surfix] = linkAgg?.key;
    }
    if (!isEmpty(providers)) {
      for (let providerType of keys(providers)) {
        if (searchVal && !providerType.includes(searchVal?.toLowerCase())) {
          continue;
        }
        providerTypes.push({
          id: uuidv4(),
          providerId: "",
          type: providerType,
          status: "",
          link: "",
          numStacks: "",
          sourceLink: keys(providerUrls)?.includes(providerType)
            ? providerUrls[providerType]
            : `registry.terraform.io/providers/hashicorp/${providerType}`,
          children: getChildren(providerType, providers[providerType]),
        });
      }
    }
    setTableData(providerTypes);
  };

  const mainColumns = [
    {
      headerName: "Data source",
      field: "type",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        apiRef.current = params?.api;
        return (
          <span className="ProvidersTable__list-headerContainer-header-type">
            <ProviderIcon provider={params?.row?.type} customStyle="children" />
            <span>{`${
              !isEmpty(params?.row?.type)
                ? PROVIDERS_TITLE_MAP[params?.row?.type] || providerNameFormatter(params?.row?.type)
                : "-"
            }`}</span>
          </span>
        );
      },
    },
    {
      headerName: "Registry URL",
      field: "sourceLink",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        return (
          <a
            href={`https://${params?.row?.sourceLink}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.row?.sourceLink}
          </a>
        );
      },
    },
  ];

  const handleRowClick = (rowId) => {
    const expandedRows = apiRef?.current?.getExpandedDetailPanels();
    // close row content
    if (expandedRows?.includes(rowId)) {
      const newExpandedRows = filter(expandedRows, row => row !== rowId);
      apiRef?.current?.setExpandedDetailPanels(newExpandedRows);
    } else {
      //open row content
      apiRef?.current?.setExpandedDetailPanels([...expandedRows, rowId]);
    }
  }

  return (
    <div className="ProvidersTable">
      {loading ? (
        <div className="ProvidersTable__loaderContainer full-page center">
          <Loading />
        </div>
      ) : (
        <TableWrapper
          height={"100%"}
          rowKey="id"
          tableData={tableData}
          columns={mainColumns}
          pageSize={50}
          disableSelectionOnClick
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          hideRowCount
          disablePagination
          onRowClick={(row) => handleRowClick(row?.id)}
          hasCustomColumnMenu
        />
      )}
    </div>
  );
};

export default ProvidersTable;
