import React from "react";
import isEmpty from "lodash/isEmpty";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import CliSnippet from "../cliSnippet/cliSnippet";
import IacStatusFlag from "../../shared/iacStatusFlag/iacStatusFlag";

import "./appModal.scss";

const AppModal = ({
  visible,
  handleClose,
  children,
  title,
  subtitle,
  hideFooter,
  submitBtnDisabled,
  loadingSubmit,
  submitBtnText,
  cancelBtnText,
  hideSubmitBtn,
  hideCloseBtn,
  centered,
  bodyClassName,
  formName,
  handleOnSubmit,
  width,
  submitBtnIcon,
  cliSnippetTitle,
  cliSnippetContent,
  customFoter,
  hideFooterBtns,
  subtitleClassName,
  rowData,
  setAnalyzeDrawerOpen,
  footerExtraItemOnRight,
  iconSrc,
  destroyOnClose,
  zIndex,
  footerBtnsClassName,
  footerClassName,
}) => {
  const handleOpenAnalysis = () => {
    setAnalyzeDrawerOpen(true);
  };
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleClose}
      footer={null}
      className="AppModal"
      closable={false}
      centered={centered ? true : false}
      width={width}
      destroyOnClose={destroyOnClose}
      zIndex={zIndex ? zIndex : 1000}
    >
      {!isEmpty(title) && (
        <div className="AppModal__header col">
          <div className="AppModal row between">
            <span className="AppModal__header-wrapper row">
              {iconSrc && (
                <img
                  src={iconSrc}
                  alt="icon"
                  className="AppModal__header-wrapper-icon row"
                />
              )}
              <span className="AppModal__header-title bold">{title}</span>
              {!isEmpty(rowData) && (
                <IacStatusFlag
                  state={rowData?.state}
                  iacType={rowData?.iacType}
                  hasControllerSources={rowData?.hasControllerSources}
                  rowData={rowData}
                  handleOpenAnalysis={handleOpenAnalysis}
                  modal
                />
              )}
            </span>
            <button className="AppModal__header-close" onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {!isEmpty(subtitle) && (
            <div
              className={
                !isEmpty(subtitleClassName) ? subtitleClassName : "sub-title"
              }
            >
              {subtitle}
            </div>
          )}
        </div>
      )}
      <div className={`AppModal__body ${bodyClassName}`}>{children}</div>

      {!hideFooter ? (
        <div
          className={`AppModal__footer ${footerClassName} ${
            footerExtraItemOnRight ? "row between" : "col"
          }`}
        >
          {customFoter ? customFoter : null}
          {footerExtraItemOnRight ? footerExtraItemOnRight : null}
          {!hideFooterBtns && (
            <div className={`AppModal__footer-btns row ${footerBtnsClassName || ""}`}>
              {hideCloseBtn ? null : (
                <Button
                  className="app-form-button close"
                  type="Default"
                  onClick={handleClose}
                >
                  {cancelBtnText ? cancelBtnText : "Close"}
                </Button>
              )}
              {hideSubmitBtn ? null : (
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loadingSubmit}
                  className="app-form-button"
                  disabled={submitBtnDisabled}
                  form={formName}
                  onClick={formName ? null : handleOnSubmit}
                  icon={!isEmpty(submitBtnIcon) && submitBtnIcon}
                >
                  {submitBtnText ? submitBtnText : "Create"}
                </Button>
              )}
            </div>
          )}
          {!isEmpty(cliSnippetContent) && (
            <CliSnippet title={cliSnippetTitle} content={cliSnippetContent} />
          )}
        </div>
      ) : null}
    </Modal>
  );
};

export default AppModal;
