import React, { useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import isEmpty from "lodash/isEmpty";
import setIn from "lodash/set";
import isBoolean from "lodash/isBoolean";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import AppBtn from "../../../shared/appBtn/appBtn";
import { Form, Input, TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as GuardrailsIcon } from "../../../Images/general_icons/sidebar/guardrails.svg";

import { ReactComponent as ScopeIcon } from "../../../Images/backup/icons/scope.svg";
import { ReactComponent as RuleTypeIcon } from "../../../Images/ci/guardrails/rule-type.svg";
import { ReactComponent as RuleIcon } from "../../../Images/ci/guardrails/rule.svg";
import { ReactComponent as NotificationIcon } from "../../../Images/general_icons/Notifying_Icon.svg";
import { ReactComponent as CostIcon } from '../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourceIcon } from '../../../Images/ci/guardrails/resources.svg';
import { ReactComponent as TagsIcon } from '../../../Images/ci/guardrails/tag.svg';

import ProvidersList from "../../composing/providersList/providersList";

import { setGuardrailRule } from "../../../redux/actions/workflowsGuardrailsActions";

import EditableTagGroup from "../../../shared/editableTagGroup/editableTagGroup";
import { useDispatch, useSelector } from "react-redux";
import { getWorkflowsAggs } from "../../../redux/actions/ciWorkflowsActions";
import { EMPTY_CI_WORKFLOWS_AGGS_FILTERS } from "../../../consts/general";
import { GUARDRAILS_TYPES, checkNotificationValidation, checkTagValidation, formatEditData, formatGuardrailBeforeResponse, getCostToggleValue } from "./guardrailsHelper";
import { getClassificationsNames } from "../../../redux/actions/classificationsActions";
import NotificationSection from "./notificationSection";
import { addBotToWebexRooms } from "../../../redux/actions/integrationsActions";
import CriteriaSection from "./criteriaSection";
import "./guardrailsModal.scss";


const GuardrailsModal = ({ showGuardrailModal, onClose, isEditMode, guardrailEditData = {}, isInitialData = false, isOnlyNotificationData }) => {
    const { user } = useAuth0();
    const [form] = Form.useForm();
    const { t: tModal } = useTranslation("ci-pipeline", { keyPrefix: "guardrails.modal" });
    const { t: tCommon } = useTranslation("common");
    const [guardrailData, setGuardrailData] = useState(isEditMode ? formatEditData(guardrailEditData) : { type: GUARDRAILS_TYPES.policy, ...(isInitialData ? guardrailEditData : {}) });
    const [loading, setLoading] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(isEditMode ? false : true);
    const [treeSearch, setTreeSearch] = useState("");
    const [costTypeRadio, setCostTypeRadio] = useState(getCostToggleValue(isEditMode, guardrailEditData?.criteria?.cost));

    const labels = useSelector((state) => state.ciWorkflowsReducer.ciAggs?.labels) || [];
    const policies = useSelector((state) => state.classificationsReducer.classificationsNames) || [];

    const dispatch = useDispatch();

    const allLabels = useMemo(() => labels.map((label) => label?.name || ""), [labels]);

    const isPercentage = costTypeRadio == 1;
    const selectedType = guardrailData?.type;
    const RULE_TYPES = [
     { name: GUARDRAILS_TYPES.policy, icon: <PolicyIcon />, disabled: (isEditMode || isOnlyNotificationData) && GUARDRAILS_TYPES.policy !== selectedType, tooltip: tModal("tooltips.policyRuleType")},
     { name: GUARDRAILS_TYPES.cost, icon: <CostIcon />, disabled: (isEditMode || isOnlyNotificationData)  && GUARDRAILS_TYPES.cost !== selectedType, tooltip: tModal("tooltips.costRuleType") },
     { name: GUARDRAILS_TYPES.resource, icon: <ResourceIcon />, disabled: (isEditMode || isOnlyNotificationData)  && GUARDRAILS_TYPES.resource !== selectedType, tooltip: tModal("tooltips.resourceRuleType")},
     { name: GUARDRAILS_TYPES.tag, icon: <TagsIcon />, disabled: (isEditMode || isOnlyNotificationData)  && GUARDRAILS_TYPES.tag !== selectedType, tooltip: tModal("tooltips.tagRuleType") }];

    useEffect(() => {
        checkValidation();
    }, [guardrailData?.name, guardrailData?.notification, guardrailData?.criteria?.tag?.requiredTags]);
    
    useEffect(() => {
        if (!isEditMode) {
            onGuardrailChange({ criteria: {} })
        }
    }, [guardrailData?.type])

    useEffect(() => {
        if (isEmpty(labels)) {
            fetchCiAggs()
        }
    }, [labels]);

    useEffect(() => {
        if (isEmpty(policies)) {
            getPoliciesNames();
        }
        if (isOnlyNotificationData) {
            const notificationElement = document.getElementById("notifcationSection");
            notificationElement.scrollIntoView({ behavior: "smooth" });
        }
    }, []);

    const fetchCiAggs = async () => dispatch(getWorkflowsAggs("", EMPTY_CI_WORKFLOWS_AGGS_FILTERS));
    const getPoliciesNames = async () => dispatch(getClassificationsNames());

    const checkValidation = () => {
        if (isEditMode) return;
        const { name, notification = {} } = guardrailData;
        const formValid = name && checkNotificationValidation(notification) && checkTagValidation(guardrailData?.criteria?.tag, selectedType);
        setSubmitDisabled(!formValid);
    }

    const handleOnSubmitGuardrail = async() => {
        const createdBy = isEditMode ? guardrailData?.createdBy : user.name || user.nickname;
        const formattedGuardrail = formatGuardrailBeforeResponse({ ...guardrailData, createdBy }, isPercentage);
        setLoading(true);
        const isWebexSelected = formattedGuardrail?.notification?.service === "webex";
        if (isWebexSelected) {
            await dispatch(addBotToWebexRooms(formattedGuardrail?.notification?.id, formattedGuardrail?.notification?.channels, true));
        }
        await dispatch(setGuardrailRule(formattedGuardrail, isEditMode));
        setLoading(false);
        onClose();
    };

    const onGuardrailChange = (changedValues = {}) => setGuardrailData({ ...guardrailData, ...changedValues });
    
    const changeInnerData = (path, change) => {
        const updatedGuardrail = {...(guardrailData || {}) };
        setIn(updatedGuardrail, path, change);
        setGuardrailData(updatedGuardrail);
    }

    const renderTagsSelection = (options = [], placeholder , values = [], onChange = () => null, fieldName, optionIsObj = false) => {
        return (
            <TreeSelect
                placeholder={placeholder}
                showSearch
                filterTreeNode={(search, item) => item?.title?.toLowerCase().indexOf(search?.toLowerCase()) >= 0}
                multiple
                disabled={isOnlyNotificationData}
                treeDefaultExpandAll
                searchValue={treeSearch}
                treeData={[{
                    title: "All",
                    value: "all",
                    children: options.map((option) => ({ title: optionIsObj ? option.title : option, value: optionIsObj ? option.value : option, key: optionIsObj ? option.key : option }))
                }]}
                onSearch={(val) => !isOnlyNotificationData && setTreeSearch(val)}
                onKeyDown={(e) =>{
                    const shouldAddSearchInput =  e.key === 'Enter' && treeSearch && fieldName && !values.includes(treeSearch) && !isOnlyNotificationData;
                    if (shouldAddSearchInput) {
                        e.preventDefault();
                        const newArray = [...values, treeSearch];
                        setTreeSearch("");
                        form.setFieldsValue({ [fieldName]: newArray });
                        onChange(newArray);
                    }
                }}
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
                style={{ width: '100%' }}
                onChange={onChange}
                value={values}
                showArrow
            />
        )
    }
    return (
      <NewAppModal
        visible={showGuardrailModal}
        handleClose={onClose}
        destroyOnClose
        centered
        width="60vw"
        title={<div className="GuardrailsModal-title row g10">
            <GuardrailsIcon />
            <span>{isOnlyNotificationData ? tModal("addNotificationTitle", { name: guardrailData?.name }) 
            : isEditMode ? tModal("editTitle", { name: guardrailData?.name }) : tModal("createTitle")}</span>
        </div>}
        subtitle=""
        bodyClassName="GuardrailsModal__content"
        footer={<div className="row g8 flex-end">
            <AppBtn onClick={onClose} text={tCommon("cancel")}/>
            <AppBtn onClick={handleOnSubmitGuardrail} disabled={submitDisabled} text={tCommon(isEditMode ? "edit": "create")} loading={loading}/>
        </div>}
        >
        <div className="col">
            <span className="GuardrailsModal__description">{tModal("description")}</span>
            <Form
                name="guardrailForm"
                form={form}
                initialValues={{
                    guardrailsName: guardrailData.name,
                    ruleType: guardrailData.type,
                    labels: guardrailData?.scope?.labels?.include || [],
                    labelsExclude: guardrailData?.scope?.labels?.exclude || [],
                    //policy
                    policy: guardrailData?.criteria?.policy?.policies?.include || [],
                    policyExclude: guardrailData?.criteria?.policy?.policies?.exclude || [],
                    policySeverity: guardrailData?.criteria?.policy?.severity,
                    actionType: guardrailData?.criteria?.resource?.actions || [],
                    //resource
                    specificResources: guardrailData?.criteria?.resource?.specificResources || [],
                    regions: guardrailData?.criteria?.resource?.regions?.include || [],
                    regionsExclude: guardrailData?.criteria?.resource?.regions?.exclude || [],
                    assetTypes: guardrailData?.criteria?.resource?.assetTypes?.include || [],
                    assetTypesExclude: guardrailData?.criteria?.resource?.assetTypes?.exclude || [],
                    //cost
                    violatedAmount: costTypeRadio == 1 ? guardrailData.criteria?.cost?.thresholdPercentage : guardrailData.criteria?.cost?.thresholdAmount || 0,
                    // tag
                    tagEnforcementMode: isBoolean(guardrailData?.criteria?.tag?.tagEnforcementMode) ? guardrailData?.criteria?.tag?.tagEnforcementMode : true,
                    requiredTags: guardrailData?.criteria?.tag?.requiredTags || [],
                    //notification
                    channels: guardrailData?.notification?.channels || [],
                    emails: guardrailData?.notification?.channels || [],
                    rooms: guardrailData?.notification?.channels || [],
                    services: guardrailData?.notification?.pd_service || "",
                }}
            >
            <div className="GuardrailsModal__section col g10">
                <div className="row g8">
                    <RuleTypeIcon />
                    <span className="bold">{tModal("sections.ruleType")}</span>
                </div>
                <Form.Item
                      label="Rule Type"
                      name="ruleType"
                      tooltip={tModal("tooltips.ruleType")}
                      style={{ flexDirection: "column", marginBottom: "0"}}
                  >
                    <ProvidersList withLabel withCustomIcon providers={RULE_TYPES} onClick={(item) => onGuardrailChange({ type: item?.name })} selected={selectedType}/>
                </Form.Item>
                <Form.Item
                      label="Rule Name"
                      name="guardrailsName"
                      tooltip={tModal("tooltips.guardrailsName")}
                      style={{ flexDirection: "column", marginBottom: "0"}}
                      rules={[
                          {
                              required: true,
                              message: tCommon("wizard.messages.required", {fieldName: "Rule Name"}),
                          },
                      ]}
                  >
                      <Input placeholder="Enter rule name" value={guardrailData.name || ""} onChange={(e) => onGuardrailChange({ name : e.target.value })} 
                      style={{backgroundColor: 'transparent' }} disabled={isOnlyNotificationData}/>
                </Form.Item>
            </div>
             <div className="GuardrailsModal__section col g10">
                <div className="row g8">
                    <ScopeIcon />
                    <span className="bold">{tModal("sections.scope")}</span>
                </div>
                <span className="GuardrailsModal__description">{tModal("scopeDescription")}</span>
                <div className="row g40">
                      <Form.Item label="Workspaces (optional)" tooltip={tModal("tooltips.workspaceInc")} name="workspaceName" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toInc", { name:  "workspaces" })} onChange={(change) => changeInnerData("scope.workspaces.include", change) } defaultValues={guardrailData?.scope?.workspaces?.include || []} rowDisplay disabled={isOnlyNotificationData}/>
                      </Form.Item>
                      <Form.Item label="Exclude Workspaces (optional)" tooltip={tModal("tooltips.workspaceExc")} name="workspaceExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toExc", {name: "workspaces"} )} onChange={(change) => changeInnerData("scope.workspaces.exclude", change) } defaultValues={guardrailData?.scope?.workspaces?.exclude || []} rowDisplay disabled={isOnlyNotificationData}/>
                      </Form.Item>
                </div>
                <div className="row g40">
                      <Form.Item label="Repositories (optional)" tooltip={tModal("tooltips.repositoryInc")} name="repository" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toInc", {name: "repositories"})} onChange={(change) => changeInnerData("scope.repositories.include", change) } defaultValues={guardrailData?.scope?.repositories?.include || []} rowDisplay disabled={isOnlyNotificationData}/>
                      </Form.Item>
                      <Form.Item label="Exclude Repositories (optional)" tooltip={tModal("tooltips.repositoryExc")} name="repositoryExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toExc", {name: "repositories"})} onChange={(change) => changeInnerData("scope.repositories.exclude", change) } defaultValues={guardrailData?.scope?.repositories?.exclude || []} rowDisplay disabled={isOnlyNotificationData}/>
                      </Form.Item>
                </div>
                <div className="row g40">
                      <Form.Item label="Branches (optional)" tooltip={tModal("tooltips.branchInc")} name="branch" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toInc", {name: "branches"})} onChange={(change) => changeInnerData("scope.branches.include", change) } defaultValues={guardrailData?.scope?.branches?.include || []} rowDisplay  disabled={isOnlyNotificationData}/>
                      </Form.Item>
                      <Form.Item label="Exclude Branches (optional)" tooltip={tModal("tooltips.branchExc")} name="repositoryExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toExc", {name: "branches"})} onChange={(change) => changeInnerData("scope.branches.exclude", change) } defaultValues={guardrailData?.scope?.branches?.exclude || []} rowDisplay  disabled={isOnlyNotificationData}/>
                      </Form.Item>
                </div>
                <div className="row g40">
                    <Form.Item label="Labels (optional)" tooltip={tModal("tooltips.labelsInc")} name="labels" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        {renderTagsSelection(allLabels, tModal("placeholders.toInc", {name: "labels"}), guardrailData?.scope?.labels?.include || [], (change) => changeInnerData("scope.labels.include", change), "labels")}
                    </Form.Item>
                    <Form.Item label="Exclude Labels (optional)" tooltip={tModal("tooltips.labelsExc")} name="labelsExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        {renderTagsSelection(allLabels, tModal("placeholders.toExc", { name: "labels" }), guardrailData?.scope?.labels?.exclude || [], (change) => changeInnerData("scope.labels.exclude", change), "labelsExclude")}
                    </Form.Item>
                </div>          
            </div>
            <div className={`GuardrailsModal__section ${isEditMode && !isOnlyNotificationData ? 'noBottom' : ''} col g10`}>
                <div className="row g8">
                    <RuleIcon />
                    <span className="bold">{tModal("sections.criteria")}</span>
                </div>
                <span className="GuardrailsModal__descrition">{tModal(`${selectedType}Description`)}</span>
                <CriteriaSection guardrailData={guardrailData} tCommon={tCommon} tModal={tModal} isOnlyNotificationData={isOnlyNotificationData} costTypeRadio={costTypeRadio} 
                form={form} setCostTypeRadio={setCostTypeRadio} changeInnerData={changeInnerData} renderTagsSelection={renderTagsSelection}/>
            </div>
            {(isOnlyNotificationData || !isEditMode) &&
            <div className="GuardrailsModal__section noBottom col g10" id="notifcationSection">
                <div className="row g8">
                    <NotificationIcon />
                    <span className="bold">{tModal("sections.notifications")}</span>
                </div>
                <span className="GuardrailsModal__descrition">{tModal(`notificationsDescription`)}</span>
                <NotificationSection onGuardrailChange={onGuardrailChange} guardrailData={guardrailData} form={form} isEditNotification={isOnlyNotificationData} />
            </div>}
            </Form>
        </div>
    </NewAppModal>)
};

export default GuardrailsModal;