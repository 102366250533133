import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FireflyLogo from "../Images/firefly_logo.svg";
import CookiesManager from "../utils/cookiesManager";
import { FIREFLY_BASE_URL } from "../consts/links";
import { useTranslation } from "react-i18next";
import "./auth_error.scss";

const AuthError = (props) => {
  const { t } = useTranslation("general");
  const { logout } = useAuth0();
  const cookieMgr = new CookiesManager();

  return (
    <div className="AuthError center">
      <div className="AuthError__card col center">
        <div className="text-center mt-4">
          <img src={FireflyLogo} alt="Firefly" />
        </div>
        {props.error.message ===
        "Please verify your email before logging in." ? (
          <div className="alert-card alert-success">
            <p className="title">Verify Your Email to Continue</p>
            <p className="small-title col">
              <span>Thank you for signing up with Firefly!</span>A message has
              been sent to your email address, please click the link in the
              message to verify your address and continue to the Firefly
              console.
            </p>
          </div>
        ) : (
          <div className="alert-card alert-danger">
            <div className="small-title">{props.error.message}</div>
          </div>
        )}
        <a
          className="logout"
          href="/"
          onClick={() => {
            cookieMgr.setAccessToken(null, 0);
            logout({ returnTo: FIREFLY_BASE_URL });
          }}
        >
          {t('auth-error.logout')}
        </a>
      </div>
    </div>
  );
};

export default AuthError;
