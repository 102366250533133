import React from "react";
import isEmpty from "lodash/isEmpty";
import { Collapse } from 'antd';
import map from "lodash/map";
import MinimalCopy from "../../../shared/minimalCopy/minimalCopy";
import { ReactComponent as DollarIncrease } from "../../../Images/general_icons/integration-filter-icons/dollar_increase.svg"

import { v4 as uuidv4 } from "uuid";

import "./singleSuggestion.scss";

const SingleSuggestion = ({ accountName, commandsList = [], icon, accountId, cost }) => {

  const { Panel } = Collapse;

  const isCostMinus = cost < 0 ? true : false;
  const isCommandsListNotEmpty = commandsList.length > 0

  const renderList = () => {
    if (!isEmpty(commandsList)) {
      return map(commandsList, (item) => {
        return (
          <span className="SingleSuggestion__item" key={uuidv4()}>
            {item}
          </span>
        );
      });
    }
  };

  const handleFormatToCopy = () => {
    if (!isEmpty(commandsList)) {
      const copyList = commandsList.join("\n");
      return copyList;
    }
  }

  return (
    <Collapse accordion={false}>
      <Panel
        key={accountId}
        showArrow={isCommandsListNotEmpty}
        header={
          <div className="SingleSuggestion">
            <div className="SingleSuggestion__header row between">
              <div className="SingleSuggestion__header-account row">
                <img
                  src={icon}
                  alt="cloud"
                />
                <div className="bold">
                  {accountName !== accountId ? `${accountName} - ${accountId}` : accountId}
                </div>
              </div>
              <div className="row g8">
                {isCostMinus ? <div className="row g5 dark-red-flag"> <DollarIncrease /> <sapn>${cost * -1}/m</sapn> </div>
                  : <div className="SingleSuggestion__cost green-flag">Save ${cost}/m</div>
                }
              </div>
            </div>
          </div>
        }
      >
        {isCommandsListNotEmpty &&
          <>
            <div className="MinimalCopyItem" > <MinimalCopy text={handleFormatToCopy()} /></div>
            <div className="col">{renderList()}</div>
          </>
        }
      </Panel>
    </Collapse>
  );
};

export default SingleSuggestion;
