import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { ReactComponent as Chevron } from "../../../../Images/general_icons/chevron.svg";
import './workflowsSteps.scss';

const ReviewStep = ({ reviewFile = {}, setSubmitDisabled={setSubmitDisabled} }) => {
    const { workflowCode = "", workflowPath = "", templateCode = "", templatePath="" } = reviewFile;
    
    const [workflowCollapsed, setWorkflowCollapsed] = useState(false);
    const [templateCollapsed, setTemplateCollapsed] = useState(false);

    useEffect(() => {
        setSubmitDisabled(false)
    }, []);
    
    return (
        <div className="WorkflowsSteps__review col g10">
          <div className="col">
            <div className={`WorkflowsSteps__review-filePath ${workflowCollapsed ? 'collapsed': ''} row between`} onClick={() => setWorkflowCollapsed(!workflowCollapsed)}>
                <span className="row g15">
                    <div className="WorkflowsSteps__review-filePath-mark">
                        <FontAwesomeIcon icon="code" className="WorkflowsSteps__review-filePath-mark-dot"/>
                        <div className="WorkflowsSteps__review-filePath-mark-line"/>
                    </div>
                    {workflowPath}
                </span>
                <Chevron className={`WorkflowsSteps__review-chevron ${workflowCollapsed ? 'up' : 'down'}`}/>
            </div>

            {!workflowCollapsed && 
                <div className="WorkflowsSteps__review-code-container col">
                    <SyntaxHighlighter
                        style={atomDark}
                        showLineNumbers={true}
                        language="yaml"
                        lineProps={{
                            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                        }}
                        >
                        {workflowCode}
                    </SyntaxHighlighter>
                </div>
            }
          </div>
          {templateCode && (
            <div className="col">
                <div className={`WorkflowsSteps__review-filePath ${templateCollapsed ? 'collapsed': ''} row between`} onClick={() => setTemplateCollapsed(!templateCollapsed)}> 
                    <span className="row g15">
                        <div className="WorkflowsSteps__review-filePath-mark">
                            <FontAwesomeIcon icon="code" className="WorkflowsSteps__review-filePath-mark-dot"/>
                            <div className="WorkflowsSteps__review-filePath-mark-line"/>
                        </div>
                        {templatePath}
                    </span>
                    <Chevron className={`WorkflowsSteps__review-chevron ${templateCollapsed ? 'up' : 'down'}`}/>
                </div>
                {!templateCollapsed && 
                <div className="WorkflowsSteps__review-code-container col">
                    <SyntaxHighlighter
                        style={atomDark}
                        showLineNumbers={true}
                        language="yaml"
                        lineProps={{
                            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                        }}
                        >
                        {templateCode}
                    </SyntaxHighlighter>
                </div>
                }
            </div>
          )}
        </div>
    )
};

export default ReviewStep;