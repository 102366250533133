import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import DarkCopyBox from "../../../shared/darkCopyBox/darkCopyBox";
import { searchResource, removeResource, cleanData as cleanRemoveResourceData } from "../../../redux/actions/removeResourceActions";
import { ReactComponent as PullRequestIcon } from "../../../Images/ci/icons/ci-pull-request.svg";

import AppBtn from "../../../shared/appBtn/appBtn";
import { getFixDriftError as getRemoveResourceError } from "../../drifts/driftAnalyzeDrawer/fixDrift/fixDriftHelper"
import Loading from "../../../shared/loading/loading";
import { Collapse } from "antd";
import CodeDiff from "../../../shared/codeDiff/codeDiff";
import { appToast } from "../../../shared/appToast/appToast";
import { IAC_TYPES, RESOURCE_STATE_TYPES } from "../../../consts/general";
import { useTranslation } from "react-i18next";
import AppToggle from "../../../shared/appToggle/appToggle";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./removeSingleResource.scss";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";

const { Panel } = Collapse;
const RemoveSingleResource = ({ assetData = {}, pullRequestResponse = {}, setPullRequestResponse }) => {
    const { terraformObjectFullAddress = "", terraformObjectReferencesAncestry, frn, vcsId, iacType = "", vcsCodeLink = "", state = "" } = assetData || {};
    const dispatch = useDispatch();
    const {t } = useTranslation('inventory', { keyPrefix: 'remove-asset' });
    const [shouldCommentCode, setShouldCommentCode] = useState(false);
    
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [prLoading, setPrLoading] = useState(false);
    const [prActionSuccess, setPrActionSuccess] = useState(false);

    const foundResource = useSelector((state) => state.removeResourceReducer.findRemoveResult) || [];
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    const { repo: repoName, filesInfo = {}, numUpdated = 0 } = foundResource?.[0] || {};
    
    const isFiles = !isEmpty(filesInfo);
    const isTerraform = iacType === IAC_TYPES.terraform;
    const showDestroyAsset = [RESOURCE_STATE_TYPES.managed, RESOURCE_STATE_TYPES.modified, RESOURCE_STATE_TYPES.undetermined].includes(state);
    const showDeleteState = state === RESOURCE_STATE_TYPES.ghost;

    const deleteIacDescription = `${t("descriptions.deleteIac")} ${repoName}:`
    const destroyAssetText = `terraform destroy -target ${terraformObjectFullAddress}`;
    const deleteStateText  = `terraform state rm ${terraformObjectFullAddress}`;
    const prUrl = pullRequestResponse?.data?.url;

    useEffect(() => {
        runSearch(shouldCommentCode);
        return () => {
            setPullRequestResponse({});
            dispatch(cleanRemoveResourceData());
        }
    }, []);

    useEffect(() => {
        if (prActionSuccess){ 
            setTimeout(() => {
                openPrLink();
                setPrActionSuccess(false);
            }, 500);
        }
    },[prActionSuccess]);
    
    const openPrLink = () => window.open(prUrl, "_blank");

    const runSearch = async (shouldComment) => {
        if (!isTerraform && !vcsCodeLink) return;
        setLoadingSearch(true);
        await dispatch(searchResource(terraformObjectReferencesAncestry, shouldComment));
        setLoadingSearch(false);
    };

    const handleCreatePR = async () => {
        if (prActionSuccess || prUrl) return openPrLink();
        setPrLoading(true);
        const result = await dispatch(removeResource(frn, vcsId, repoName, filesInfo, ""));
        if (!result?.ok) {
            const errMsg = getRemoveResourceError(result?.data?.error, { numUpdated, repo: repoName, filesInfo }) || "Failed to create pull request";
            appToast("error", "", errMsg);
        }
        setPullRequestResponse(result);
        setPrLoading(false);
        if (result?.ok) {
            setPrActionSuccess(true);
        }
        sendEvent(`${inventoryEvents.createPullRequest} (asset deletion) (inventory)`, {});
    };

    const handleOnCommentToggle = () => {
        const toggle = !shouldCommentCode;
        setShouldCommentCode(toggle);
        setPullRequestResponse({});
        runSearch(toggle);
    };

    if (loadingSearch && isEmpty(foundResource)) {
        return <div className="basic-loading"><Loading /></div>;
    }

    return (
        <div className="col g10">
            {showDestroyAsset ? 
            <div className="RemoveSingleResource col g10 pad20">
                <div className="col g5">
                    <span className="RemoveSingleResource__title font-16 bold">{`${isFiles ? 'Step 1: ' : ""}${t("titles.destroyAsset")}`}</span>
                    <span className="RemoveSingleResource__description">{t("descriptions.destroyAsset")}</span>
                </div>
                <DarkCopyBox text={destroyAssetText} />
            </div>
            : showDeleteState ? <div className="RemoveSingleResource col g10 pad20">
                <div className="col g5">
                    <span className="RemoveSingleResource__title font-16 bold">{`${isFiles ? 'Step 1: ' : ""}${t("titles.deleteState")}`}</span>
                    <span className="RemoveSingleResource__description">{t("descriptions.deleteState")}</span>
                </div>
                <DarkCopyBox text={deleteStateText} />
            </div> : null}

            {isFiles && (
            <div className="RemoveSingleResource col g10 pad20">
                <div className="col g10">
                    <div className="row between">
                        <span className="RemoveSingleResource__title font-16 bold">{t("titles.deleteIac")}</span>
                        {isTerraform && <AppBtn loading={prLoading} disabled={isViewer} onClick={handleCreatePR} text={prUrl ? "Pull request" : "Create pull request"} key="create-pr" 
                        icon={prActionSuccess ? <FontAwesomeIcon icon="check"/> : prUrl ? <FontAwesomeIcon icon='external-link-alt'/>
                         : <PullRequestIcon className="RemoveSingleResource-prIcon" />} />}
                    </div>
                    <div className="row between">
                        <span className="RemoveSingleResource__description">{deleteIacDescription}</span>
                            <span className="RemoveSingleResource-toggle row g10">
                                <span>{t("commentOut")}</span>
                                <AppToggle checked={shouldCommentCode} toggleChecked={() => handleOnCommentToggle()} />
                            </span>
                    </div>
                    {loadingSearch ? <div className="basic-loading"><Loading /></div> 
                    : Object.entries(filesInfo).map(([filePath = "", fileContent = {}]) => {
                        const { oldFileContent = "", newFileContent = ""} = fileContent;
                        const oldValue = isString(oldFileContent) ? oldFileContent : JSON.stringify(oldFileContent, null, 2);
                        const newValue = isString(newFileContent) ? newFileContent : JSON.stringify(newFileContent, null, 2);
                        const id = uuidv4();
                        return (
                        <div key={id}>
                              <Collapse className="RemoveSingleResource__content-collapse" defaultActiveKey={id} expandIconPosition="right">
                                <Panel header={filePath} key={id}>
                                    <div className="RemoveSingleResource__content-collapse-content">
                                        <CodeDiff oldValue={oldValue} newValue={newValue} lang="hcl" hideLineNumbers={false} showDiffOnly />
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>)
                    })}
                </div>
            </div>)}
            
        </div>
    );
};

export default RemoveSingleResource;