import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Steps, Button } from "antd";
import "./appWizard.scss";
import { useTranslation } from "react-i18next";

const AppWizard = ({
  loadingSubmit,
  iconSrc,
  handleClose,
  configWizard,
  submitDisabled,
  setSubmitDisabled,
  handleSubmit,
  iconStyle,
  noPaddingBody,
  prevDisabled,
  handleAutoComplete,
  customDoneText,
  customDoneFunction,
  customCurrent,
  customFirstNextText,
  enableLastStepPrevious = false,
  stepsUnvisible = false,
}) => {
  const { t } = useTranslation('common')
  const { Step } = Steps;
  const [current, setCurrent] = useState(0);

  const next = () => {
    setSubmitDisabled(true);
    setCurrent(current + 1);
  };

  const prev = async() => {
    if (_.isFunction(configWizard[current].beforePreviousStep)) {
      try {
        await configWizard[current].beforePreviousStep();
      } catch (err) {
        return;
      }
    }
    setSubmitDisabled(false);
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (handleAutoComplete) {
      setCurrent(configWizard?.length - 1)
    }
  }, [handleAutoComplete])

  useEffect(() => {
    if (_.isNumber(customCurrent)) {
      setCurrent(customCurrent);
    }
  }, [customCurrent])

  const currentCustomIcon = configWizard[current]?.customStepIcon;

  const usedInIntegrationsPage = location.pathname.includes('/integrations');

  return (
    <div className={`AppWizard ${usedInIntegrationsPage ? 'integration-ui':''}`}>
      <div className={`AppWizard__steps ${stepsUnvisible ? 'unvisible' : ''}`}>
        <Steps
          progressDot
          current={current}
          direction="vertical"
          className="AppWizard__fixStep"
        >
          {configWizard.map((item = {}, idx) => (
            <Step
              key={item.step_title}
              title={item.step_title}
              description={item.step_description}
              // using disable prop - to find out who is the last step for styling issues **** dont remove that!!
              disabled={configWizard.length - 1 === idx}
              className={item.invisible ? 'AppWizard__steps-step-invisible' : ''}
            />
          ))}
        </Steps>
      </div>
      <div className="AppWizard__content">
        <div className="AppWizard__content-header">
          {iconSrc && (
            <img
              src={currentCustomIcon || iconSrc}
              alt="icon"
              className="AppWizard__content-header-icon"
              style={iconStyle}
            />
          )}
          {configWizard[current]?.content_title}
        </div>
        <div
          className="AppWizard__content-body"
          style={{ padding: noPaddingBody ? "none" : "33px 40px" }}
        >
          {configWizard[current]?.content}
        </div>
        <div className="AppWizard__content-footer row between">
          <button
            className={`AppWizard__content-footer-cancel ${current === configWizard.length - 1 && configWizard.length > 1 ? 'disabled' : null}`}
            onClick={handleClose}
            disabled={current === configWizard.length - 1 && configWizard.length > 1}
          >
            {t('cancel')}
          </button>

          <div className="AppWizard__content-footer-paging">
            {current > 0 && (
              <Button
                style={{ margin: "0 8px" }}
                onClick={() => prev()}
                disabled={current === configWizard.length - 1 ? !enableLastStepPrevious : prevDisabled}
              >
                {t('previous')}
              </Button>
            )}
            {current < configWizard.length - 1 && (
              <Button
                type="primary"
                onClick={async () => {
                  if (_.isFunction(configWizard[current].beforeNextStep)) {
                    try {
                      await configWizard[current].beforeNextStep();
                    } catch (err) {
                      return;
                    }
                  }
                  if (configWizard[current].skipNextStep && current + 2 <= configWizard.length - 1) {
                    return setCurrent(current + 2);
                  }
                  next();
                }}
                disabled={submitDisabled}
                loading={loadingSubmit}
              >
                {(customFirstNextText && current === 0) ? customFirstNextText : t('next')}
              </Button>
            )}
            {current === configWizard.length - 1 && (
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingSubmit}
                disabled={submitDisabled}
                onClick={customDoneFunction ? customDoneFunction : handleSubmit}
              >
                {customDoneText ? customDoneText : t('done')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppWizard;