import { useMemo } from "react";
import { insights } from "../../../utils/icons";

const CategoryCell = ({ value }) => {
  const generateIconWithTitle = (icon, altText) => (
    <>
      <img src={icon} alt={altText} />
      {value}
    </>
  );

  const title = useMemo(() => {
    return generateIconWithTitle(insights(value))
  }, [value]);

  return (
    <div className="CategoryCell row g10">
      {title}
    </div>
  );
};

export default CategoryCell;