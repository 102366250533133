import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import capitalize from "lodash/capitalize";
import map from "lodash/map";
import compact from "lodash/compact";
import { iacProviders } from "../../../utils/icons";
import Tooltip from "antd/lib/tooltip";
import { v4 as uuidv4 } from "uuid";
import { IAC_STACKS_MAP, IAC_TYPES } from "../../../consts/general";
import { ReactComponent as CollapseIcon } from '../../../Images/general_icons/collapse.svg'

import "./iacStacksTypes.scss";

const IacStacksTypes = ({
  iacTypes = [],
  aggTypes = [],
  selectedIacType,
  setSelectedIacType,
  loading,
  collapsed,
  setCollapsed
}) => {
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const disabledTypes = [];
  const iacTypesArr = useMemo(() => compact(map(iacTypes, (type) => {
    if (type === IAC_TYPES.opentofu) return null;
    let { doc_count: count = 0 } = aggTypes?.find((aggType) => aggType?.key === type) || {};
    if (type === IAC_TYPES.terraform) {
      count += aggTypes?.find((aggType) => aggType?.key === IAC_TYPES.opentofu)?.doc_count || 0;
    }
    if (count === 0) {
      disabledTypes.push({ type, count });
      return null;
    }
    return { type, count };
  })), [iacTypes, aggTypes]);
  if (iacTypesArr?.length > 0 && disabledTypes.length > 0) {
    iacTypesArr.push(...disabledTypes);
  }
  return (
    <div>
      <div className={`IacStacksTypes ${collapsed ? 'collapsed' : ''}`}>
        {map(iacTypesArr, ({ type, count } = {}) => {
          const disabled = !count;
          const active = selectedIacType === type;
          const title = IAC_STACKS_MAP[type] || capitalize(type);
          return (
            <Tooltip
              title={disabled ? `No ${capitalize(type)} stacks` : ""}
              placement="top"
              key={uuidv4()}
            >
              <button
                className={`IacStacksTypes__button row ${ active ? "active" : ""} ${disabled ? "disabled" : ""}`}
                onClick={() => (!loading && !disabled) && setSelectedIacType(type)}>
                <img
                  src={iacProviders(
                    active
                      ? `darkPurple${capitalize(type === IAC_TYPES.k8s ? "argo" : type)}`
                      : `darkPurple${capitalize(type === IAC_TYPES.k8s ? "argo" : type)}`,
                    themeDark
                  )}
                  alt="iacType"
                />
                <div className="IacStacksTypes__button-text row">
                  <span>
                    {title}
                  </span>
                  {count > 0 && (
                    <span
                      className={`IacStacksTypes__button-text-flag ${
                        active ? "active" : ""
                      }`}
                    >
                      {count}
                    </span>
                  )}
                </div>
              </button>
            </Tooltip>
          );
        })}
      </div>
      <button className={`collapseToggle ${collapsed ? 'collapsed' : ''}`} onClick={() => setCollapsed(!collapsed)}>
          <CollapseIcon />
      </button>
    </div>
  );
};

export default IacStacksTypes;