import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import has from "lodash/has";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import isEmpty from "lodash/isEmpty";
import { Spin } from "antd";
import { v4 as uuidv4 } from "uuid";

import AppEmpty from "../../../shared/appEmpty/appEmpty";
import DriftItem from "../driftItem/driftItem";
import FreeTierMask from "../../inventory/freeTierMask/freeTierMask";

import "./driftList.scss";
import AppAccordion from "../../../shared/appAccordion/appAccordion";
import { getAllProvidersArray } from "../../../utils/formatting";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";
import { useHistory } from "react-router-dom";

const DriftList = ({
  loading,
  handleSelectRow,
  handleOpenAnalyzer,
  isAllActive,
}) => {
  const listData = useSelector((state) => state.driftsReducer.driftsList);
  const tierType = useSelector((state) => state?.onbordingReducer.account.tier_type);
  const isFreeTier = Boolean(tierType === "FREE_TIER");
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const dispatch = useDispatch();
  const history = useHistory()

  const handleViewAssets = (frn) => {

    dispatch(
      setFilters({
        ...emptyInventoryScreenFilters,
        frns: [frn],
        masterType: []
      })
    );
    return history.push({
      pathname: "/inventory" ,
      search: "?defaultView=false",
    });
  };

  // render intengraion name using it's id.
  const renderIntegrationNameById = (item) => {
    let integrations = getAllProvidersArray(providerIntegrations)
    const match = integrations.find((integ) => integ.id === item);
    return has(match, "name") ? match?.name : match?.clusterId;
  };

  // filter drifts based on the search and filter btns
  const filteredData = useMemo(() => {
    if (!isEmpty(listData)) {
      let filteredData = map(listData, (item) => {
        const providerId = isEmpty(item?.integrationId)
          ? "-"
          : renderIntegrationNameById(item?.integrationId);
        return { ...item, providerId };
      });

      return filteredData;
    }
  }, [listData, loading, providerIntegrations]);

  const renderList = () => {
    if (!isEmpty(filteredData)) {
      return map(filteredData, (item) => (
        <AppAccordion
          key={uuidv4()}
          header={
            <DriftItem
              data={item}
              handleSelectRow={handleSelectRow}
              handleOpenAnalyzer={handleOpenAnalyzer}
            />
          }
          content={
            <div className="DriftList__content col">
              {item?.frns &&
                map(sortBy(item?.frns, frnObject => frnObject?.isLocked), (frnObj, key) =>
                  (frnObj?.isLocked && isFreeTier) ? (
                    <FreeTierMask>{frnObj?.frn}</FreeTierMask>
                  ) : (
                    <span
                      className="DriftList__content-item"
                      key={frnObj?.frn + key}
                      onClick={() => handleViewAssets(frnObj?.frn)}
                    >
                      {frnObj?.frn}
                    </span>
                  )
                )}
            </div>
          }
          card
          isAllActive={isAllActive}
        />
      ));
    }
    if (isEmpty(filteredData) && !loading) {
      return <AppEmpty />;
    }
  };

  return (
    <span>
      {loading ? <Spin /> : <div className="DriftList col">{renderList()}</div>}
    </span>
  );
};

export default DriftList;
