import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const SEARCH_DRIFT_FIX = "SEARCH_DRIFT_FIX";
export const REMEDIATE_AI = "REMEDIATE_AI";
export const FIX_DRIFT = "FIX_DRIFT";
export const CLEAN_DRIFT_FIX_DATA = "CLEAN_DRIFT_FIX_DATA";

const cleanData = () => {
  return action(async (dispatch) => {
    dispatch({
      type: CLEAN_DRIFT_FIX_DATA,
      payload: null,
    });
  });
};

const searchDriftFix = (references, vcsWorkingDir, drift) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const searchReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fix-drift/search`,
      "POST",
      {
        references,
        vcsWorkingDir,
        drifts: drift,
      },
      true,
      null,
      false
    );

    const data = await searchReq.json();
    dispatch({
      type: SEARCH_DRIFT_FIX,
      payload: data,
    });
    return { ok: searchReq?.ok };
  });
};
let remediateAbortController
const remediateAi = (integrationId, frn, references, vcsWorkingDir, drift) => {
  if (remediateAbortController) {
    remediateAbortController.abort();
  }
  remediateAbortController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    try {

    const searchReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fix-drift/remediate-ai`,
      "POST",
      {
        references,
        vcsWorkingDir,
        drifts: drift,
        integrationId,
        frn

      },
      true,
      null,
      false,
      undefined,
      remediateAbortController.signal
    );

    const data = await searchReq.json();
    dispatch({
      type: REMEDIATE_AI,
      payload: data,
    });
    return { ok: searchReq?.ok };
  } catch (err) {
    if (err.name === 'AbortError') {
      return { abort: true }
    }
    return {};
  }
  });
};

const fixDrift = (frn, vcsId, repo, filesInfo, prComment) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const fixReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fix-drift/fix`,
      "POST",
      {
        frn,
        vcsId,
        repo,
        filesInfo,
        prComment
      },
      true,
      null,
      false
    );

    const data = await fixReq.json();
    dispatch({
      type: FIX_DRIFT,
      payload: data,
    });
    return { ok: fixReq?.ok, data };
  });
};
let cftAbortController;
const getCftDrift = (frn) => {
  if (cftAbortController) {
    cftAbortController.abort();
  }
  cftAbortController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/fix-cloudformation-drift/fixCloudformationDrift`,
        "POST",
        { frn },
        undefined,
        undefined,
        false,
        undefined,
        cftAbortController.signal
      );

    const data = await req.json();
    return { ok: req?.ok, data };
  } catch (err) {
    if (err.name === 'AbortError') {
      return { abort: true }
    }
    return {};
  }
  });
};

export { searchDriftFix, fixDrift, cleanData, getCftDrift, remediateAi };
