import React, { useMemo } from "react";
import { Form, InputNumber, Radio, Select, Tooltip } from "antd";
import { ACTIONS, GUARDRAILS_TYPES, TAG_ENFORCEMENTS_MODES, formatCostAmount } from "./guardrailsHelper";
import { QuestionCircleOutlined } from "@ant-design/icons";
import EditableTagGroup from "../../../shared/editableTagGroup/editableTagGroup";
import { WORKFLOWS_ACTIONS_MAP } from "../../../consts/ci-workflows";
import { useSelector } from "react-redux";

const SEVERITIES = ["LOW", "MEDIUM", "HIGH"];

const CriteriaSection = ({ guardrailData, tModal, tCommon, isOnlyNotificationData, costTypeRadio, form, setCostTypeRadio, changeInnerData, renderTagsSelection }) => {
    const isPercentage = costTypeRadio == 1;
    
    const policies = useSelector((state) => state.classificationsReducer.classificationsNames) || [];

    const allPolicies = useMemo(() => policies.map((policy = {}) => ({ title: policy.name, value: policy.id, key: policy.id }), [policies]));
    const allActions = ACTIONS.map((action) => ({ title: WORKFLOWS_ACTIONS_MAP[action], value: action, key: action }));
    
    const isTagEnforcementSpecificTag = guardrailData?.criteria?.tag?.tagEnforcementMode === false;
    
    switch (guardrailData?.type) {
        case GUARDRAILS_TYPES.cost:
            return <Form.Item label={<div className="row g20">
                <div className="row g10">
                    <span className="form-label">Cost Change</span>
                    <Tooltip title={tModal("tooltips.violatedAmount")}>
                        <QuestionCircleOutlined className="pointer"/>
                    </Tooltip>
                </div>
                <Radio.Group onChange={(e) => {
                    form.setFieldsValue({ violatedAmount: 0 });
                    setCostTypeRadio(e.target.value);
                    changeInnerData("criteria.cost.thresholdPercentage", 0);
                    changeInnerData("criteria.cost.thresholdAmount", 0);
                }} value={costTypeRadio} disabled={isOnlyNotificationData}>
                    <Radio value={0} key="0">Exact amount</Radio>
                    <Radio value={1} key="1">Percentage</Radio>
                </Radio.Group>
            </div>} name="violatedAmount" style={{ flexDirection: "column", marginBottom: "0", width: "70%"}}>
                {isPercentage ?  <InputNumber key="percentage" formatter={(value) => `${value}%`} min={-100} max={1000} onChange={(val) => changeInnerData(`criteria.cost.thresholdPercentage`, val)} disabled={isOnlyNotificationData}/>
                : <InputNumber key="amount" formatter={(value) => formatCostAmount(value)} min={-100000} max={100000} onChange={(val) => changeInnerData(`criteria.cost.thresholdAmount`, val)} disabled={isOnlyNotificationData}/>}
            </Form.Item>
        case GUARDRAILS_TYPES.policy:
            return <div className="col g10">
                <div className="row g40">
                    <Form.Item label="Policies (optional)" tooltip={tModal("tooltips.policyInc")} name="policy" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        {renderTagsSelection(allPolicies, tModal("placeholders.toInc", { name: "policies" }), guardrailData?.criteria?.policy?.policies?.include || [], (change) => changeInnerData("criteria.policy.policies.include", change), "", true)}
                    </Form.Item>
                    <Form.Item label="Exclude Policies (optional)" tooltip={tModal("tooltips.policyExc")} name="policyExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        {renderTagsSelection(allPolicies, tModal("placeholders.toExc", { name: "policies" }), guardrailData?.criteria?.policy?.policies?.exclude || [], (change) => changeInnerData("criteria.policy.policies.exclude", change), "", true)}
                    </Form.Item>
                </div>
                <div className="row g40">
                    <Form.Item label="Lowest Severity (optional)" tooltip={tModal("tooltips.policySeverity")} name="policySeverity" style={{ flexDirection: "column", marginBottom: "0", width: "50%", paddingRight: "20px"}}>
                        <Select placeholder={tModal("placeholders.select", {name: "lowest severity"})} onChange={(val) => changeInnerData("criteria.policy.severity", val)} allowClear onClear={() => changeInnerData("criteria.policy.severity", "")} disabled={isOnlyNotificationData}>
                            {SEVERITIES.map((severity) => <Select.Option key={severity} value={severity}>{severity}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </div>
            </div>
        case GUARDRAILS_TYPES.resource:
            return <div className="col g10">
                <div className="row g8">
                    <Form.Item label="Actions (optional)" tooltip={tModal("tooltips.action")} name="actionType" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        {renderTagsSelection(allActions, tModal("placeholders.select", {name: "actions"}), guardrailData?.criteria?.resource?.actions || [], (arr = []) => changeInnerData("criteria.resource.actions", arr), "", true)}
                    </Form.Item>
                    <Form.Item label="Specific Asset Addresses (optional)" tooltip={tModal("tooltips.specificResource")} name="specificResources" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.enter", {name: "asset addresses"})} onChange={(change) => changeInnerData("criteria.resource.specificResources", change) } defaultValues={guardrailData?.criteria?.resource?.specificResources || []} rowDisplay disabled={isOnlyNotificationData}/>
                    </Form.Item>
                </div>
                <div className="row g8">
                        <Form.Item label="Regions (optional)" tooltip={tModal("tooltips.regionsInc")} name="regions" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                            <EditableTagGroup placeholder={tModal("placeholders.enter", {name: "regions"})} onChange={(change) => changeInnerData("criteria.resource.regions.include", change) } defaultValues={guardrailData?.criteria?.resource?.regions?.include || []} rowDisplay disabled={isOnlyNotificationData}/>
                        </Form.Item>
                        <Form.Item label="Exclude Regions (optional)" tooltip={tModal("tooltips.regionsExc")} name="regionsExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                            <EditableTagGroup placeholder={tModal("placeholders.toExc", {name: "regions"})} onChange={(change) => changeInnerData("criteria.resource.regions.exclude", change) } defaultValues={guardrailData?.criteria?.resource?.regions?.exclude || []} rowDisplay disabled={isOnlyNotificationData}/>
                        </Form.Item>
                </div>
                <div className="row g8">
                    <Form.Item label="Asset Types (optional)" tooltip={tModal("tooltips.assetTypesInc")} name="assetTypes" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.enter", {name: "asset types"})} onChange={(change) => changeInnerData("criteria.resource.assetTypes.include", change) } defaultValues={guardrailData?.criteria?.resource?.assetTypes?.include || []} rowDisplay disabled={isOnlyNotificationData}/>
                    </Form.Item>
                    <Form.Item label="Exclude Asset Types (optional)" tooltip={tModal("tooltips.assetTypesExc")} name="assetTypesExclude" style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                        <EditableTagGroup placeholder={tModal("placeholders.toExc", {name: "asset types"})} onChange={(change) => changeInnerData('criteria.resource.assetTypes.exclude', change) } defaultValues={guardrailData?.criteria?.resource?.assetTypes?.exclude || []} rowDisplay disabled={isOnlyNotificationData}/>
                    </Form.Item>
                </div>
            </div>
        case GUARDRAILS_TYPES.tag:
            return <div className="col g10">
                <div className="row g8 align-items-start">
                    <Form.Item label="Tag Enforcement Mode" tooltip={tModal("tooltips.tagEnforcementMode")} name="tagEnforcementMode" style={{ flexDirection: "column", marginBottom: "0", width: "50%" }}>
                        <Select placeholder={tModal("placeholders.select", { name: "Tag Enforcement Mode" })} onChange={(val) => changeInnerData("criteria.tag.tagEnforcementMode", val)} disabled={isOnlyNotificationData} >
                            {TAG_ENFORCEMENTS_MODES.map((mode, ind) => <Select.Option key={mode} value={ind === 0 ? true : false}>{mode}</Select.Option>)}
                        </Select>
                    </Form.Item>
                    {isTagEnforcementSpecificTag && 
                    <Form.Item label="Required Tags (optional)" tooltip={tModal("tooltips.requiredTags")} name="requiredTags" rules={[
                        {
                            required: true,
                            validator: () => guardrailData?.criteria?.tag?.requiredTags?.length > 0 ? Promise.resolve() : Promise.reject(),
                            message: tCommon("wizard.messages.required", { fieldName: "At least 1 tag" }),
                        },
                    ]}
                    style={{ flexDirection: "column", marginBottom: "0", width: "50%"}}>
                            <EditableTagGroup placeholder={tModal("placeholders.enter", {name: "specific tag"})} onChange={(change) => changeInnerData("criteria.tag.requiredTags", change) } defaultValues={guardrailData?.criteria?.tag?.requiredTags || []} rowDisplay disabled={isOnlyNotificationData} />
                    </Form.Item>}
                </div>
            </div>
        default:
            return null;
    }
};

export default CriteriaSection;