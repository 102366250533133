import CookiesManager from "./cookiesManager";
// import { BACKEND_URL } from '../consts/config';
import { appToast } from "../shared/appToast/appToast";
import { lookup } from "../utils/helpers";
import _ from "lodash";
import { AUTH0_DOMAIN } from "../consts/config";
class RequestWrapper {
  constructor() {
    this.cookies = new CookiesManager();
  }

  async sendRequest(
    url,
    method,
    payload = {},
    authRequired = true,
    headers = null,
    errorToast = true,
    timeout = 300,
    signal = null,
    returnErrorResponse = false,
  ) {
    method = method.toUpperCase();
    payload = payload || {};
    headers = headers || { "Content-Type": "application/json" };

    if (authRequired) {
      const cookieMgr = new CookiesManager();
      const accessToken = cookieMgr.getAccessToken();
      headers = { ...headers, Authorization: `Bearer ${accessToken}` };
    }

    const availableCalls = ["GET", "POST", "PUT", "DELETE", "PATCH"];

    if (!availableCalls.includes(method)) {
      throw new Error("HTTP Request must be [GET, POST, PUT, PATCH, DELETE]");
    }

    let requestOptions = {
      method,
      headers,
      timeout,
      signal,
    };

    if (method === "POST" || method === "PUT" || method === "PATCH" || method === "DELETE") {
      requestOptions = {
        ...requestOptions,
        body: JSON.stringify(payload),
      };
    }

    const errorStatuses = [400, 401, 403, 404, 409, 500];

    const response = await fetch(url, requestOptions);

    if (response?.status === 401) {
      const cookieMgr = new CookiesManager();
      cookieMgr.setAccessToken(null, 0);

      const logoutUrl = `https://${AUTH0_DOMAIN}/v2/logout?returnTo=${window.location.origin}`;
      return window.location.href = logoutUrl;
    }

    if (errorStatuses.includes(response.status) && errorToast) {
      const errorJson = await response.json() || {};
      let message = null;
      if (!_.isEmpty(lookup(errorJson, "message"))) {
        message = lookup(errorJson, "message")[1];
      }
      const title = errorJson?.title;
      return appToast("error", title, message);
    } else if (errorStatuses.includes(response.status) && returnErrorResponse) {
      return response;
    }

    return response;
  }
}

export default RequestWrapper;
