import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "antd";
import "./gradientBtn.scss";

const GradientBtn = ({ onClick, text, loading, icon, disabled, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      <div className={`GradientBtn row g10 ${disabled ? "disabled" : ""}`}>
        <button
          className="row g10"
          onClick={onClick}
          type="button"
          disabled={disabled}
        >
          {loading ? <FontAwesomeIcon icon="spinner" spin /> : icon}
          {text}
        </button>
      </div>
    </Tooltip>
  );
};

export default GradientBtn;
