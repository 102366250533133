import React, { useMemo, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { Select } from "antd";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sendEvent } from "../../../utils/amplitude";
import { CiEvents } from "../../../utils/amplitudeEvents";

import AppBtn from "../../../shared/appBtn/appBtn";
import { setWorkspaceLabels } from "../../../redux/actions/ciWorkflowsActions";
import { appToast } from "../../../shared/appToast/appToast";

import './workspaceMenu.scss';

const LabelsModal = ({ onClickLabels, data = {}, showLabelsModal }) => {
    const [loading, setLoading] = useState(false);
    const ciLabels = useSelector((state) => state.ciWorkflowsReducer.ciAggs?.labels) || [];
    const allLabels = useMemo(() => ciLabels.map((label) => label?.name || ""), [ciLabels]);

    const { workspaceId, workspaceName, isWorkflowManaged = false, labels: workspacesLabels = [],
        lastRunStatus: workspaceLastStatus = "", repo = "", runnerType: ciTool, lastApplyTime: lastApply, provider , vcsType } = data;
    const [labels, setLabels] = useState(workspacesLabels);

    const { t } = useTranslation(["ci-pipeline", "common"]);
    const dispatch = useDispatch();
    
    
    const handleOnSaveClick = async() => {
        setLoading(true);
        const res = await dispatch(setWorkspaceLabels(workspaceId, labels));
        if (!res) {
            appToast("error", "", t("labelsModal.error"))
        }
        sendEvent(CiEvents.ciClickedSetWorkspaceLabel, { workspaceID: workspaceId, workspaceName, vcs: provider || vcsType,
            repo, ciTool, lastApply, workspaceLastStatus, isFireflyManaged: isWorkflowManaged })
        setLoading(false);
        onClickLabels(false);
    };

    return (
    <NewAppModal
        visible={showLabelsModal}
        handleClose={() => onClickLabels(false)}
        destroyOnClose
        centered
        width="40vw"
        title={t("ci-pipeline:labelsModal.title")}
        subtitle=""
        iconSrc={null}
        footer={<div className="row g8 flex-end">
            <AppBtn onClick={() => onClickLabels(false)} text={t("common:cancel")}/>
            <AppBtn onClick={handleOnSaveClick} text={t("common:save")} loading={loading}/>
        </div>}
    >
        <Select
            placeholder={t("ci-pipeline:labelsModal.placeholder")}
            showSearch
            filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
            mode="tags"
            style={{ width: '100%' }}
            onChange={(newLabels) => setLabels(newLabels)}
            defaultValue={labels}
            >
                {allLabels.map((label) => <Select.Option value={label} key={uuidv4()}>{label}</Select.Option>)}
        </Select>
    </NewAppModal>)
};

export default LabelsModal;