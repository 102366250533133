import React, { useState, useEffect } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import ActionBtn from "../../../shared/actionBtn/actionBtn";
import AppBtn from "../../../shared/appBtn/appBtn";
import MinimalCopy from "../../../shared/minimalCopy/minimalCopy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { greenIacProviders } from "../../../utils/icons";

import { IAC_TYPES, RESOURCE_STATE_TYPES } from "../../../consts/general";
import "./multiRemoveAssetCode.scss";

const MultiRemoveAssetCode = ({
  visible,
  handleClose,
  selectedAssets,
  onClickRemoveAssetPR,
}) => {
  const [activeTab, setActiveTab] = useState("cmd");
  const [assetsByTab, setAssetsByTab] = useState({
    cmd: [],
    cmdCopy: null,
    ghost: [],
    pr: [],
  });
  
  const isMultipleView = selectedAssets?.length > 1;
  
  useEffect(() => {
    if (visible && !_.isEmpty(selectedAssets)) {
      handleSortAssets();
    }
  }, [visible, selectedAssets]);

  const handleSortAssets = () => {
    const cmd = [];
    const pr = [];
    _.forEach(selectedAssets || [], (item = {}) => {
        const { state, iacType, deleteCommand = "" } = item;
        if (state === RESOURCE_STATE_TYPES.unmanaged || iacType !== IAC_TYPES.terraform && deleteCommand) {
          return cmd.push(deleteCommand);
        }
        if (state !== RESOURCE_STATE_TYPES.unmanaged && iacType === IAC_TYPES.terraform) {
          return pr.push(item);
        }
      }
    );

    let cmdCopy = cmd.join("\n");
    if (selectedAssets?.length === 1 && pr?.length === 1) {
      onClickRemoveAssetPR(pr[0]);
      return handleClose()
    }
    const defaultTab = _.isEmpty(cmd) ? "pr" : "cmd";
    setActiveTab(defaultTab);
    return setAssetsByTab({ cmd, cmdCopy, pr });
  };

  const renderCmdCommandsList = () => {
    if (_.isEmpty(assetsByTab?.cmd)) {
      return <AppEmpty text="No assets" customStyle="code" />;
    }
    return _.map(assetsByTab?.cmd || [], (item) => {
      return (
        <span
          key={uuidv4()}
          className="MultiRemoveAssetCode__body-cmd code-text row between"
        >
          {item}
          <MinimalCopy text={item} purple />
        </span>
      );
    });
  };

  const renderPullRequestList = () => {
    const arrToIterate = assetsByTab?.pr;
    if (_.isEmpty(arrToIterate)) {
      return <AppEmpty text="No assets" customStyle="code" />;
    }
    return (
      <div>
        {_.map(arrToIterate || [], (item) => {
          return (
            <span
              key={uuidv4()}
              className="MultiRemoveAssetCode__body-pr row between"
            >
              <span className="code-text">{item?.name}</span>
              <AppBtn
                text="More info"
                onClick={() => onClickRemoveAssetPR(item)}
                icon={<FontAwesomeIcon className="MultiRemoveAssetCode__body-pr-icon"icon="info-circle" />}
              />
            </span>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "cmd":
        return renderCmdCommandsList();
      case "pr":
        return renderPullRequestList();
      default:
        return <AppEmpty text="No Data" customStyle="code" />;
    }
  };

  return (
    <AppDrawer
      title="Delete Assets"
      visible={visible}
      closeDrawer={handleClose}
      width={800}
      hideClose={activeTab !== "pr"}
      zIndex={1001}
      footer={
        activeTab === "pr"
          ? []
          : [
              <ActionBtn
                text="Export"
                disabled={_.isEmpty(assetsByTab?.cmdCopy)}
                action="download"
                fileType="txt"
                fileName="AWS Delete CMD"
                icon="donwload"
                stringToAction={assetsByTab?.cmdCopy}
                refresh={visible}
                key="export"
              />,
              <ActionBtn
                text="Copy All"
                icon="copy"
                disabled={_.isEmpty(assetsByTab?.cmdCopy)}
                action="copy"
                stringToAction={assetsByTab?.cmdCopy}
                refresh={visible}
                key="copy"
              />,
            ]
      }
    >
      <div className="MultiRemoveAssetCode col">
        {isMultipleView &&
        <div className="MultiRemoveAssetCode__tabs row">
          {assetsByTab?.cmd?.length > 0 && (
            <button
              onClick={() => setActiveTab("cmd")}
              className={`MultiRemoveAssetCode__tabs-tab row center ${
                activeTab === "cmd" ? "cmd" : ""
              }`}
            >
              <FontAwesomeIcon icon="exclamation-circle" />
              {`Unmanaged (${assetsByTab?.cmd?.length})`}
            </button>
          )}
          {assetsByTab?.pr?.length > 0 && (
            <button
              onClick={() => setActiveTab("pr")}
              className={`MultiRemoveAssetCode__tabs-tab row center ${
                activeTab === "pr" ? "pr" : ""
              }`}
            >
              <img src={greenIacProviders["terraform"]} alt="terraform" />
              {`Managed (${assetsByTab?.pr?.length})`}
            </button>
          )}
          {assetsByTab?.ghost?.length > 0 && (
            <button
              onClick={() => setActiveTab("ghost")}
              className={`MultiRemoveAssetCode__tabs-tab row center ${
                activeTab === "ghost" ? "ghost" : ""
              }`}
            >
              <FontAwesomeIcon icon="ghost" />
              {`Ghost (${assetsByTab?.ghost?.length})`}
            </button>
          )}
        </div>}
        <div className={`MultiRemoveAssetCode__body ${isMultipleView ? "" : 'single '}col`}>{renderContent()}</div>
      </div>
    </AppDrawer>
  );
};

export default MultiRemoveAssetCode;