import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import provisionImg from "../../../Images/general_icons/thinkerbell/thinkerbell_logo.svg";

import './provisionMessages.scss'
import TypingDots from '../../../shared/typingDots/typingDots';

const ProvisionMessages = ({ messages = {}, loading }) => {
    const { user } = useAuth0();
    return (
            <div className="ProvisionMessages col">
              {messages.map(({text, isUser} = {}, idx) => 
              <div key={idx} className={`ProvisionMessages-item ${isUser ? 'user' : ''} row g10`}>
                {isUser ? <div className="ProvisionMessages-item-userIcon" style={{ backgroundImage: `url(${user?.picture})` }}/> :  <img className="ProvisionMessages-item-icon" src={provisionImg}/>}
                <span>{text}</span>
              </div>)}
              {loading &&
                <div className="ProvisionMessages-item loading row g10">
                  <img className="ProvisionMessages-item-icon loading" src={provisionImg}/>
                  <TypingDots />
                </div>
              }
            </div>
    )
}

export default ProvisionMessages;