import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import Configuration from "../../assetConfig/configuration";
import { getInventoryResource } from "../../../../../redux/actions/inventoryActions";
import { getDeletedConfig } from "../../../../../redux/actions/historyActions";
import { useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { getIac } from "../../../../../redux/actions/iacStacksActions";
import { RESOURCE_STATE_TYPES } from "../../../../../consts/general";

const RelationshipAssetConfig = ({ visible, onClose, row }) => {
  const dispatch = useDispatch();
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [code, setCode] = useState(null);

  useEffect(() => {
    if (row) {
      handleFetchConfiguration();
    }
  }, [row]);

  const handleFetchConfiguration = async () => {
    if (!isEmpty(row?.inventoryItem)) {
      setCode(row?.inventoryItem);
      return setLoadingConfig(false);
    }
    setLoadingConfig(true);

    // fetch configuration for deleted assets
    if (row?.state === RESOURCE_STATE_TYPES.deleted) {
      if (isEmpty(row?.metadata) || RESOURCE_STATE_TYPES.deleted) {
        const deletedConfig = await dispatch(
          getDeletedConfig(row?.frn, row?.assetType)
        );
        setCode(deletedConfig);
        return setLoadingConfig(false);
      }
      setCode(row?.metadata);
      return setLoadingConfig(false);
    }
    const shouldGetGhostData = [RESOURCE_STATE_TYPES.ghost, RESOURCE_STATE_TYPES.undetermined].includes(row?.state) ||
    (row?.state === RESOURCE_STATE_TYPES.pending && row?.pendingTo === RESOURCE_STATE_TYPES.ghost);
    //  live assets configurations
    const req = await dispatch(
      shouldGetGhostData ? getIac(row?.frn) :
      getInventoryResource(
        [row?.arn],
        [row?.assetType],
        "inv",
        null,
        null,
        true
      )
    );
    const newCode = shouldGetGhostData ? (req?.attributes || {}) : 
    (!isEmpty(req?.responseObjects) ? req.responseObjects[0]?.inventoryItem : {});

    setCode(newCode);
    setLoadingConfig(false);
  };

  return (
    <Drawer
      title={<div className="bold">{row?.name}</div>}
      placement="right"
      onClose={onClose}
      visible={visible}
      getContainer={false}
      style={{ position: "absolute" }}
      className="RelationshipAssetConfig"
      width={700}
    >
      <Configuration item={code} loading={loadingConfig} />
    </Drawer>
  );
};

export default RelationshipAssetConfig;
