import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { insightsFilterTemplate } from "../../../../consts/insights";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import omitBy from "lodash/omitBy";
import isEqual from "lodash/isEqual";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import pull from "lodash/pull";
import FilterTag from "./filterTag";
import { insightSeverityTypes } from "../../../../consts/insights";
import { formatAwsStringWithUnderscore, providerNameFormatter } from "../../../../utils/formatting";

const InsightFilterBadges = () => {
  const { t } = useTranslation("insights");
  const dispatch = useDispatch();
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const activeFilters = useMemo(() => {
    return omitBy(insightFilters, (value, key) =>
      isEqual(insightsFilterTemplate[key], value)
    );
  }, [insightFilters]);

  const updateFilters = (key, arrayVal) => {
    if (arrayVal) {
      //when the filter is an array and user want to pop it out
      const newArray = pull(insightFilters[key], arrayVal);
      return dispatch(
        setInsightsFilters({ ...insightFilters, [key]: newArray })
      );
    }
    const initalValueForKey = insightsFilterTemplate[key];
    return dispatch(
      setInsightsFilters({ ...insightFilters, [key]: initalValueForKey })
    );
  };

  const getIntegrationNameById = (id) => {
    const integration = flatMap(providerIntegrations).find(integration => integration?.id === id);
    return integration ? integration?.name : 'Selected account';
};

  const renderFiltersByType = useMemo(() => {
    return map(Object.entries(activeFilters || {}), ([key, value]) => {
      switch (key) {
        case "query":
          return (
            <FilterTag
              key={key}
              value={value}
              onClose={() => updateFilters("query")}
            />
          );
        case "id":
          return (
            <FilterTag
              key={key}
              value="Selected control"
              onClose={() => updateFilters("id")}
            />
          );
        case "labels":
          const labelsArr = typeof value === "string" ? [value] : value;
          return map( labelsArr ?? [], val =>
            <FilterTag
              key={val}
              value={val}
              onClose={() => updateFilters("labels")}
            />
          );
        case "isDefault":
          if (value) {
            // if isDefault === true
            return (
              <FilterTag
                key={key}
                value={t("filters.ootb-policy")}
                onClose={() => updateFilters("isDefault")}
              />
            );
          }
          // if isDefault === false
          return (
            <FilterTag
              key={key}
              value={t("filters.cutsom-policy")}
              onClose={() => updateFilters("isDefault")}
            />
          );
        case "onlySubscribed":
          return (
            <FilterTag
              key={key}
              value={t("filters.notifications")}
              onClose={() => updateFilters("onlySubscribed")}
            />
          );
        case "onlyProduction":
          return (
            <FilterTag
              key={key}
              value={t("filters.production")}
              onClose={() => updateFilters("onlyProduction")}
            />
          );
        case "onlyMatchingAssets":
          return (
            <FilterTag
              key={key}
              value={t("filters.violating")}
              onClose={() => updateFilters("onlyMatchingAssets")}
            />
          );
        case "onlyEnabled":
          return (
            <FilterTag
              key={key}
              value={t("filters.enabled")}
              onClose={() => updateFilters("onlyEnabled")}
            />
          );
        case "showExclusion":
          return (
            <FilterTag
              key={key}
              value={t("filters.excluded")}
              onClose={() => updateFilters("showExclusion")}
            />
          );
        case "category":
          return (
            <FilterTag
              key={key}
              value={value}
              onClose={() => updateFilters("category")}
            />
          );
        case "frameworks":
          return (
            <FilterTag
              key={key}
              value={value}
              onClose={() => updateFilters("frameworks")}
            />
          );
        //   array filters
        case "type":
          return map(value || [], (val) => {
            return (
              <FilterTag
                key={val}
                value={formatAwsStringWithUnderscore(val, true)}
                onClose={() => updateFilters("type", val)}
              />
            );
          });
        case "providers":
          return map(value || [], (val) => (
            <FilterTag
              key={val}
              value={providerNameFormatter(val)}
              onClose={() => updateFilters("providers", val)}
            />
          ));
        case "accounts":
          return map(value || [], (val) => (
            <FilterTag
              key={val}
              value={getIntegrationNameById(val)}
              onClose={() => updateFilters("accounts", val)}
            />
          ));
        case "severity":
          return map(value || [], (val) => {
            const severityTitle = insightSeverityTypes[val - 1]?.title || ''
            return <FilterTag
              key={val}
              value={severityTitle}
              onClose={() => updateFilters("severity", val)}
            />
          });
          case "onlyAvailableProviders":
            return (
              <FilterTag
                key={key}
                value={t("filters.onlyAvailableProviders")}
                onClose={() => updateFilters("onlyAvailableProviders")}
              />
            );

        default:
          return;
      }
    });
  }, [insightFilters, activeFilters]);

  return <div className="row g10" style={{ flexWrap: 'wrap' }}>{renderFiltersByType}</div>;
};

export default memo(InsightFilterBadges);
