import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { FixedSizeList as List } from "react-window";
import {
  setSelectedServiceView,
} from "../../../redux/actions/inventoryv3Actions";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";
import AutoSizer from "react-virtualized-auto-sizer";
import ServiceListItem from "./serviceListItem";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import Loading from "../../../shared/loading/loading";
import { filterOutNotRelevantStates } from "../../../utils/inventoryHelper"
import "./servicesList.scss";

const ServicesList = ({ loading }) => {
  const dispatch = useDispatch();
  const aggregationsAssetType = useSelector(
    (state) => state.inventoryReducer.aggregationsAssetType
  );
  const inventoryFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const [list, setList ] = useState({});

  useEffect(() => {
    handleSetList()
  },[aggregationsAssetType, inventoryFilters?.types])

  const handleSetList = () => {
    if(!_.isEmpty(inventoryFilters?.types)) {
      const filteresList = _.filter(aggregationsAssetType?.buckets || {}, i => inventoryFilters?.types?.includes(i?.key))
      return setList(filteresList)
    }
    return setList(aggregationsAssetType?.buckets)
  }

  const serviceBlock = ({ index, style }) => {
    if (!_.isEmpty(list)) {
      const item = list?.[index];
      const filteredBuckets = filterOutNotRelevantStates(item?.asset_type_data?.buckets)

      return (
        <div style={style}>
          <ServiceListItem 
            key={item?.key}
            name={item?.key}
            count={item?.doc_count}
            iacCoverage={filteredBuckets}
            onClick={() => {
              Promise.all([
                dispatch(setSelectedServiceView(item)),
                sendEvent(inventoryEvents.inventoryTypeBlockClicked, { type: item?.key, count: item?.doc_count }),
              ]);
            }}
          />
        </div>
      );
    }
  };

  if (!loading && _.isEmpty(list)) {
    return (
      <div className="ServicesList center">
        <AppEmpty text="No Data" />
      </div>
    );
  }

  if (loading) {
    return (
      <div className="ServicesList center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="ServicesList col">
      <div className="ServicesList__header bold">
        <span>Asset Types</span>
        <span>Asset Count</span>
        <span>IaC Coverage</span>
        <span>Cost</span>
      </div>
      <AutoSizer>
        {({ height, width }) => (
          <List
            className="List"
            height={height - 50}
            itemCount={list?.length}
            itemSize={50}
            width={width}
          >
            {serviceBlock}
          </List>
        )}
      </AutoSizer>
    </div>
  );
};

export default ServicesList;
