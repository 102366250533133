import React from "react";
import moment from "moment";
import _ from "lodash";
import AntdTableWrapper from "../../../shared/antdTableWrapper/antdTableWrapper";
import { useSelector } from "react-redux";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { dateTimeSecondsFormat } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";

const KeyPairsTable = ({
  data,
  loading,
  setSelectedRowsKeys,
  setAccessKeyToEdit,
}) => {
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const { t } = useTranslation('users')
  const accessKeysTableColumns = [
    {
      title: t('access-keys.table.headers.access-key'),
      dataIndex: "access_key",
      sorter: (a, b) => a.access_key.localeCompare(b.access_key),
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: t('access-keys.table.headers.alias'),
      dataIndex: "description",
      width: "300px",
      sorter: (a, b) => a?.description?.localeCompare(b?.description),
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {_.isEmpty(text) ? '-' : text}
        </div>
      ),
    },
    {
      title: t('access-keys.table.headers.created-at'),
      dataIndex: "created_at",
      render: (created_at) =>
        created_at ? moment(created_at).format(dateTimeSecondsFormat) : "-",
      sorter: (a, b) => {
        if (moment(a.created_at).isBefore(moment(b.created_at))) {
          return -1;
        }
        return 1;
      },
    },
    // {
    //   title: t('access-keys.table.headers.last-login'),
    //   dataIndex: "last_login",
    //   render: (last_login) =>
    //     last_login ? moment(last_login).format(dateTimeSecondsFormat) : "-",
    //   sorter: (a, b) => {
    //     if (moment(a.last_login).isBefore(moment(b.last_login))) {
    //       return -1;
    //     }
    //     return 1;
    //   },
    // },
    // {
    //   title: t('access-keys.table.headers.last-ip'),
    //   dataIndex: "last_ip",
    //   sorter: (a, b) => a.login_count - b.login_count,
    //   render: (text, record) => (
    //     <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
    //       {_.isEmpty(text) ? '-' : text}
    //     </div>
    //   ),
    //   className: "login-count-column",
    // },
    {
      title: t('access-keys.table.headers.user'),
      dataIndex: "creator",
      sorter: (a, b) =>
        a?.creator
          ? a?.creator?.localeCompare(b?.creator ? b?.creator : "")
          : "",
      render: (text, record) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {_.isEmpty(text) ? '-' : text}
        </div>
      ),
    },
    {
      title: "",
      width: "40px",
      dataIndex: "access_key",
      render: (access_key, row) => renderEditBtn(row),
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRowsKeys(selectedRowKeys);
    },
  };

  // show edit button for every row
  const renderEditBtn = (row) => {
    return (
      <IconBtn
        onClick={() => {
          setAccessKeyToEdit(row);
        }}
        type="edit"
        colorClass="purple"
        disabled={isViewer}
      />
    );
  };

  return (
    <AntdTableWrapper
      loading={loading}
      rowKey="access_key"
      columns={accessKeysTableColumns}
      dataSource={!_.isEmpty(data) ? data : []}
      pagination={{
        defaultPageSize: 8,
        defaultCurrent: 1,
        showSizeChanger: false,
        total: !_.isEmpty(data) ? data.length : 0,
        showTotal: (total, range) => t('access-keys.table.footer.items-count', { rangeFrom: range[0], rangeTo: range[1], itemsCount: total }),
      }}
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
    />
  );
};

export default KeyPairsTable;
