import {
  SEARCH_DRIFT_FIX,
  FIX_DRIFT,
  CLEAN_DRIFT_FIX_DATA,
  REMEDIATE_AI,
} from "../actions/fixDriftActions";

const initialState = {
  findDriftResult: null,
  fixResult: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case SEARCH_DRIFT_FIX:
      return { ...state, findDriftResult: payload };

    case REMEDIATE_AI:
      return { ...state, findDriftResult: payload };

    case FIX_DRIFT:
      return { ...state, fixResult: payload };

    case CLEAN_DRIFT_FIX_DATA:
      return { ...state, fixResult: null, findDriftResult: null };

    default:
      return state;
  }
}
