import React, { useState } from "react";
import { MANAGEMENT_NEW_PIPELINES } from "../mangementHelper";
import { ReactComponent as GeneratePipelineIcon } from  "../../../../Images/ci/icons/generate-pipeline.svg"
import { ReactComponent as IntegratePipelineIcon } from  "../../../../Images/ci/icons/integrate-pipeline.svg"
import "./workflowsSteps.scss";
import { useTranslation } from "react-i18next";

const IacPipelineSelection = ({ setPipeline, pipeline, setSubmitDisabled ,onItemClick }) => {
    const [hoverDescription, setHoverDescription] = useState("");
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement.iacPipelineSelection" });
    
    const handleItemClick = (key) => {
        setPipeline(key);
        setSubmitDisabled(false);
        onItemClick(key);
    }
    const items = [{
        key: MANAGEMENT_NEW_PIPELINES.generate,
        title: t("generate.title"),
        icon: GeneratePipelineIcon,
        description: t("generate.description")
    },
    {
        key: MANAGEMENT_NEW_PIPELINES.existing,
        title: t("existing.title"),
        icon: IntegratePipelineIcon,
        description: t("existing.description")
    }]

    return (
        <div className="WorkflowsSteps__IacPipelineSelection col center">
            <span className="WorkflowsSteps__IacPipelineSelection-title">{t("title")}</span>
            <div className="WorkflowsSteps__IacPipelineSelection__list">
            {items.map((item = "") => {
                    const IconComponent = item.icon;
                    return <div key={item.key} className="WorkflowsSteps__IacPipelineSelection__list-gradient"
                     onClick={() => handleItemClick(item.key)} onMouseEnter={() => setHoverDescription(item.description)} onMouseLeave={() => setHoverDescription('')} >
                            <div className={`basic-card WorkflowsSteps__IacPipelineSelection__list-item col center`}>
                                <div className="WorkflowsSteps__IacPipelineSelection__list-item-icon row center">
                                    <div className="WorkflowsSteps__IacPipelineSelection__list-item-icon-container row center">
                                        <div className="WorkflowsSteps__IacPipelineSelection__list-item-icon-mask row center">
                                            <IconComponent className="WorkflowsSteps__IacPipelineSelection__list-item-icon-img" />
                                        </div>
                                    </div>
                                </div>
                                <div className="WorkflowsSteps__IacPipelineSelection__list-item-title">{item.title}</div>
                            </div>
                        </div>
                })}
            </div>
            <span className="WorkflowsSteps__IacPipelineSelection-description">{hoverDescription}</span>
        </div>
    )
};

export default IacPipelineSelection;