import React from "react";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AppModal from "../../../shared/appModal/appModal";
import { ReactComponent as WarningSign } from "../../../Images/general_icons/warningSign.svg";
import { ReactComponent as CloudX } from "../../../Images/general_icons/cloudX.svg";
import { providers } from "../../../utils/providers";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { clouds } from "../../../utils/icons";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import { providerNameFormatter } from "../../../utils/formatting";
import { capitalize, keys, map } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFilters } from "../../../redux/actions/iacStacksActions";
import { formatMissingProviderTitle } from "../../../containers/dashboard/utils";
import { IAC_TYPES, PROVIDERS_TITLE_MAP } from "../../../consts/general";

import "./missingIntegrationsModal.scss";

const MissingIntegrationsModal = ({ visible, handleClose, data }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const {t} = useTranslation("dashboard");
  const dispatch = useDispatch();

  const history = useHistory();
  return (
    <AppModal
      title={
        <span className="MissingIntegrationsModal__title row">
          <WarningSign />
          <span>{t('pending-integrations')}</span>
        </span>
      }
      visible={visible}
      handleClose={handleClose}
      bodyClassName="MissingIntegrationsModal col"
      width="35%"
      submitBtnText={`+ Add new integration`}
      handleOnSubmit={() => {
        const isSaas = data?.provider === "saas";
        const pathname = isSaas ? '/integrations' :`/integrations/${providers[data?.provider]?.integrationPath}-integration`
        history.push({
          pathname,
          state: data.provider === 'azurerm' ? data?.missingIntegrations?.[0] : null
        })
      }}
      destroyOnClose
    >
      <div className="MissingIntegrationsModal__summary">
        <div className="MissingIntegrationsModal__summary-section center">
          {keys(providers)?.includes(data?.provider) ? (
            <ProviderIcon provider={`plain${capitalize(data?.provider)}`} customStyle="modal" />
          ) : (
            <img
              alt="cloud"
              src={clouds(`plain${capitalize(data?.provider)}`, themeDark)}
              style={{
                height: "100%",
                transform: "translateY(2px)",
              }}
            />
          )}
        </div>

        <div className="MissingIntegrationsModal__summary-section col center">
          <span className="MissingIntegrationsModal__summary-section-title">{data?.count}</span>
          <span>Accounts</span>
        </div>
        <div className="MissingIntegrationsModal__summary-section col center">
          <span className="MissingIntegrationsModal__summary-section-title">{data?.missingIntegrations?.length || 0}</span>
          <span>Missing</span>
        </div>
      </div>
      <div className="MissingIntegrationsModal__text">
        {`We identified additional ${providerNameFormatter(data?.provider || '')} accounts that are declared in your IaC
        state but are not integrated with Firefly.`}
      </div>
      <div className="MissingIntegrationsModal__list col">
        {map(data?.missingIntegrations, (accountNumber = '') => {
          const isUnknownAccountNumber = accountNumber.includes('Unknown identifier') || !accountNumber;
          const isSaas = data?.provider === 'saas';
          const provider = !isSaas ? data?.provider : (isUnknownAccountNumber ? accountNumber.split(' - ')[0] : 'Unknown provider');
          const title = formatMissingProviderTitle(accountNumber, isSaas);
          return (
          <span className="MissingIntegrationsModal__list-item row" key={uuidv4()}>
            <CloudX />
            <span>{title || 'Unknown identifier'}</span>
            {isUnknownAccountNumber && (<Tooltip overlayClassName="MissingIntegrationsModal__list-item-tooltip" title={
            <div className="row g5">
              {t('tooltips.missingUnknown', { providerName: PROVIDERS_TITLE_MAP[provider] || provider })}
              <FontAwesomeIcon icon="external-link-alt" className="MissingIntegrationsModal__list-item-icon" onClick={() => {
                const providerType = provider === 'k8s' ? 'kubernetes' : provider;
                dispatch(setFilters({
                  ...emptyIacScreenFilters,
                  stateType: [IAC_TYPES.terraform, IAC_TYPES.opentofu],
                  "stackProviders.providerTypeAccountId": [`${providerType}||`],
                  "stackProviders.providerType": [providerType],
                }));
                history.push({
                  pathname: `/iac`,
                  search: `?view=${IAC_TYPES.terraform}`,
                })
              }}/>
            </div>}>
              <QuestionCircleOutlined className="MissingIntegrationsModal__list-item-icon-ques"/>
            </Tooltip>)}
          </span>
        )})}
      </div>
    </AppModal>
  );
};

export default MissingIntegrationsModal;
