import React from "react";
import { Form, Input } from "antd";
import isEmpty from "lodash/isEmpty";

import { nicknameRegex, validate } from "../../../utils/validations";
import { GOOGLE_CHAT_MAIL_URL } from "../../../consts/links";

const GoogleChatCreation = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, webhookUrl } = formValues;

    if (isEmpty(name) || isEmpty(webhookUrl)) {
      formValid = false;
    }

    if (!isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }

    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="GoogleChatCreation col">
      <div className="dark-blue GoogleChatCreation__intro">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue GoogleChatCreation__intro-orderedlist">
          <li key="1">
            <span className="dark-blue">
              <span>
                Go to {" "}
                <a
                  href={GOOGLE_CHAT_MAIL_URL}
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  Google Chat
                </a>
                .
              </span>
            </span>
          </li>
          <li key="2">
            <span className="dark-blue">
              Select <span className="bold">New Chat {'>'} Create a space. </span>
            </span>
          </li>
          <li key="3">
            <span className="dark-blue">
              Select <span className="bold">space name {'>'} Apps & Integrations {'>'} + Add webhooks. </span>
            </span>
          </li>
          <li key="4">
            <span className="dark-blue">
              Add a name and <span className="bold">Save. </span>
            </span>
          </li>
          <li key="5">
            <span className="dark-blue">
              Copy the <span className="bold">Webhook URL </span>
              <span> and </span> <span className="bold">  paste it in the field below. </span>
            </span>
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="aws-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name,
          webhookUrl: wizardState.webhookUrl || "",
        }}
        className="GoogleChatCreation__intro col"
        onValuesChange={(
          changedValues,
          allValues
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter Integration Name" />
        </Form.Item>
        <Form.Item
          label="Webhook URL"
          name="webhookUrl"
          rules={[
            {
              required: true,
              message: "Webhook URL is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input />
        </Form.Item>
      </Form>
    </div>
  );
};

export default GoogleChatCreation;
