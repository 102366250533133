import React from "react";
import { S3_ASSETS } from '../../consts/config';
import { useSelector } from "react-redux";

import "./appEmpty.scss";

const AppEmpty = ({ customStyle, text, big, customIcon = `${S3_ASSETS}/illustrations/investigation.png` }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark);

  const renderImgBasedStyle = () => {
    let imgSrc = `${S3_ASSETS}/illustrations/no-data.svg`;
    if (customStyle === "custom") {
      imgSrc = customIcon
    }
    if (customStyle === "code") {
      imgSrc = `${S3_ASSETS}/illustrations/investigation.png`;
    }
    if (customStyle === "git") {
      imgSrc = `${S3_ASSETS}/illustrations/gradient_git.svg`;
    }
    if (customStyle === "history") {
      imgSrc = `${S3_ASSETS}/illustrations/history_no_diff.svg`;
    }
    if (customStyle === "relationship") {
      imgSrc = `${S3_ASSETS}/illustrations/relationship-${themeDark ? 'dark' : 'light'}.svg`;
    }
    if (customStyle === "cost") {
      imgSrc = `${S3_ASSETS}/illustrations/cost.svg`;
    }
    if (customStyle === "ciResourcesNotFound") {
      imgSrc = `${S3_ASSETS}/illustrations/ci_resources_not_found.svg`;
    }
    return imgSrc;
  };

  return (
    <div className={`AppEmpty center ${customStyle}`}>
      <img src={renderImgBasedStyle()} alt="no data" loading="lazy" />
      {text && <span className={big? 'title' : ''}>{text}</span>}
    </div>
  );
};

export default AppEmpty;
