import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { capitalizeFirst } from "../../../utils/formatting";

import "./providersList.scss";

const ProvidersList = ({ providers = [], onClick, selected, withLabel = false, withCustomIcon }) => {
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  
  return (
    <div className="ProvidersList row g10">
      {providers.map((item = {}) => {
        const { name, icon, iconByMode = () => null, tooltip = '', disabled = false, comingSoon = false } = item;
        const tooltipTitle = comingSoon ? `${tooltip} - Coming Soon` : tooltip;
        return (
          <Tooltip title={tooltipTitle} key={name} placement="top">
            <div 
                onClick={() => !disabled ? onClick(item) : null}
                className={`ProvidersList__item center ${selected === name ? 'active' : ''} ${disabled ? 'disabled': ''} `} 
                key={name}>
              {withCustomIcon ? icon : <img src={icon || iconByMode(themeDark) } alt={name} />}
              {withLabel && <span>{item?.label || capitalizeFirst(name)}</span>}
            </div>
          </Tooltip>
        )})}
    </div>
  );
};

export default ProvidersList;
