import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _, { capitalize } from "lodash";
import CountUp from "react-countup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFilters } from "../../../redux/actions/inventoryActions";
import { ReactComponent as WarningSign } from "../../../Images/general_icons/warningSign.svg";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { providers } from "../../../utils/providers";
import { clouds } from "../../../utils/icons";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";

import "./integration.scss";
import { CLOUD_PROVIDERS } from "../../../consts/general";
import { useTranslation } from "react-i18next";
import { sendEvent } from "../../../utils/amplitude";

const Integration = ({
  provider,
  count,
  name,
  missingIntegrations,
  onlyProd,
  prodAccounts,
  openMissingIntegrations,
}) => {
  const { t } = useTranslation('dashboard')
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const isK8SProvider = provider === CLOUD_PROVIDERS.k8s; 
  const isSass = provider === "saas";
  
  const providerClick = () => {
    const providerName = provider === "azure" ? CLOUD_PROVIDERS.azurerm : provider;
    if (count > 0) {
      sendEvent(`clicked ${providerName} integrations tile (homepage dashboard)`, {integrationsCount: count, pendingIntegrations: missingIntegrations?.length});
      dispatch(
        setFilters({
          ...emptyInventoryScreenFilters,
          providerTypes: {
            providerType: [],
            providerSubType: [],
            provider: onlyProd || isSass ? [] : [providerName],
            providerId: onlyProd ? prodAccounts : [],
          },
          masterType: isSass ? ["saas"] : isK8SProvider ? ["k8s"] : ["cloud"],
        })
      );
      if(isSass){
        history.push({
          pathname: `/inventory`,
          search: `?provider=${providerName}`,
        });
      } else{
        history.push({
          pathname: `/integrations/${providerName}`
        });
      }
    }
  };

  return (
    <div
      className={`Integration center col`}
      onClick={providerClick}
    >
      <div
        className={`Integration ${
          count > 0 ? "active" : ""
        } center col basic-card`}
      >
        {count > 0 && (
          <div className="Integration__arrow">
            <FontAwesomeIcon icon="angle-right" />
          </div>
        )}
        <div className={`row Integration__provider wrapper`}>
          <div
            className="Integration__provider-title col"
          >
            <CountUp start={0} end={count} duration={1} />
            <span className="Integration__provider-title-name">{name}</span>
          </div>
        </div>
        <div className="Integration-icon col center">
          {_.keys(providers)?.includes(provider) ? (
            <ProviderIcon
              provider={`purple${capitalize(provider)}`}
              customStyle="integration"
            />
          ) : (
            <img
              alt="cloud"
              src={clouds(`purple${capitalize(provider)}`, themeDark)}
              style={{
                height: "100%",
                transform: "translateY(2px)",
              }}
            />
          )}
        </div>
        {missingIntegrations?.length > 0 && (
          <div className="Integration__issue" onClick={(e) => {
            e.stopPropagation();
            openMissingIntegrations();
          }}>
            <div className="Integration__issue-detection row">
              <WarningSign />
              {`${missingIntegrations?.length} ${t('pending-integrations')}`}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Integration;
