import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { capitalizeFirst, formatAwsStringWithUnderscore } from "../../../utils/formatting";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as CreateDeleteIcon } from "../../../Images/ci/icons/createdelete.svg";
import { ReactComponent as DeleteCreateIcon } from "../../../Images/ci/icons/deletecreate.svg";
import { ReactComponent as TagIcon } from "../../../Images/ci/guardrails/tag.svg";
import { useTranslation } from "react-i18next";
import { formatAsetrixToAll } from "../guardrailsModal/guardrailsHelper";
import { useDispatch, useSelector } from "react-redux";
import { getClassificationsNames } from "../../../redux/actions/classificationsActions";
import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";

const actionsIconsMap = {
    create: <FontAwesomeIcon className="SingleGuardrail__action-icon create" icon="plus-circle" />,
    delete: <FontAwesomeIcon className="SingleGuardrail__action-icon delete" icon="minus-circle" />,
    update: <FontAwesomeIcon className="SingleGuardrail__action-icon update" icon="pen" />,
    read: <FontAwesomeIcon className="SingleGuardrail__action-icon read" icon="check-double" />,
    "no-op": <FontAwesomeIcon className="SingleGuardrail__action-icon no-op" icon="ban" />,
    import: <FontAwesomeIcon className="SingleGuardrail__action-icon import" icon="download" />,
    createdelete: <CreateDeleteIcon className="SingleGuardrail__action-icon-createdelete" />,
    deletecreate: <DeleteCreateIcon className="SingleGuardrail__action-icon-deletecreate" />,
}

const GuardrailCriteriaPopover = ({ data = {}, isResource, isTag }) => {
    const { t: tCommon } = useTranslation("common");
    const { t: tGuardrails } = useTranslation("ci-pipeline", { keyPrefix: "guardrails.criteriaPopover" });
    const policiesNames = useSelector((state) => state.classificationsReducer.classificationsNames) || [];
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const actions = formatAsetrixToAll(data?.actions || []);
    const specificResources = formatAsetrixToAll(data?.specificResources || []);
    const assetTypes = formatAsetrixToAll(data?.assetTypes || {}, true);
    const regions = formatAsetrixToAll(data?.regions || {}, true);
    const policies = formatAsetrixToAll(data?.policies || {}, true);
    const requiredTags = formatAsetrixToAll(data?.requiredTags || []);
    const severity = data?.severity;

    const assetTypeExcludeIsEmpty = isEmpty(assetTypes.exclude);
    const assetTypeIncludeIsEmpty = isEmpty(assetTypes.include);
    const assetTypesNotEmpty = !assetTypeIncludeIsEmpty || !assetTypeExcludeIsEmpty
    
    const regionsIncludeIsEmpty = isEmpty(regions.include);
    const regionsExcludeIsEmpty = isEmpty(regions.exclude);
    const regionsNotEmpty = !regionsIncludeIsEmpty || !regionsExcludeIsEmpty;

    const policiesIncludeIsEmpty = isEmpty(policies.include);
    const policiesExcludeIsEmpty = isEmpty(policies.exclude);
    const policiesNotEmpty = !policiesIncludeIsEmpty || !policiesExcludeIsEmpty;

    const lowerSeverity = severity?.toLowerCase();

    useEffect(() => {
        if (!isResource && isEmpty(policiesNames)) {
            setLoading(true);
            getPoliciesNames();
            setTimeout(() => setLoading(false), 500);
        }
    }, []);

    const getPoliciesNames = async () => await dispatch(getClassificationsNames());
    const policiesNamesMap = policiesNames.reduce((acc, policy = {}) => {
        acc[policy.id] = policy.name;
        return acc;
    }, { All: 'All' });
    
    return (
        <div className="SingleGuardrail__popover col g10">
            {(isResource || isTag) ? (
            <>
             {!isEmpty(actions) && 
             <div className="SingleGuardrail__popover-section col g5">
                <span className="bold">Actions:</span>
                <div className="SingleGuardrail__popover-list">
                    {actions.map((action) => {
                           const icon = actionsIconsMap[action]
                           return (
                               <div className="row g5" key={action}>
                                 {icon}
                                 {capitalizeFirst(action)}
                               </div>
                           );
                })}
                </div>
             </div>}
            {!isEmpty(specificResources) && specificResources?.[0] !== 'All' && 
            <div className="SingleGuardrail__popover-section col g5">
                <span className="bold">Specific Resources:</span>
                <div className="SingleGuardrail__popover-list">
                    {specificResources.map((resource) => (
                        <span key={resource} className="GuardrailCriteriaPopover__item">{resource}</span>
                    ))}
                </div>
            </div>}
            {assetTypesNotEmpty && (
                <>
                    <div className="SingleGuardrail__popover-section col g5">
                       <span className="bold">{tGuardrails("assetTypes")}</span>
                       {!assetTypeIncludeIsEmpty && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("include")}</span>
                            <div className="SingleGuardrail__popover-list col g5">
                                {assetTypes.include.map((type) => (
                                        <div className="row g8" key={type}>
                                        {type !== 'All' && <AssetTypeImage assetType={type} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>}
                                        <span>{formatAwsStringWithUnderscore(type)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>}
                        {!assetTypeExcludeIsEmpty && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("exclude")}</span>
                            <div className="SingleGuardrail__popover-list col g5">
                                {assetTypes.exclude.map((type) => (
                                    <div className="row g8" key={type}>
                                    {type !== 'All' && <AssetTypeImage assetType={type} customStyle={{ height: "16px", width: "16px", borderRadius: "4px"}}/>}
                                    <span>{formatAwsStringWithUnderscore(type)}</span>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div> 
                </>
            )}
            {regionsNotEmpty && (
                <>
                    <div className="col g5">
                        <span className="bold">{tGuardrails("regions")}</span>
                        {!regionsIncludeIsEmpty && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("include")}</span>
                            <div className="SingleGuardrail__popover-list col g5">
                                {regions.include.map((region) => (
                                    <span key={region} className="GuardrailCriteriaPopover__item">{region}</span>
                                ))}
                            </div>
                        </div>}
                        {!regionsExcludeIsEmpty && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("exclude")}</span>
                            <div className="SingleGuardrail__popover-list col g5">
                                {regions.exclude.map((region) => (
                                    <span key={region} className="GuardrailCriteriaPopover__item">{region}</span>
                                ))}
                            </div>
                        </div>}
                    </div>
                </>
            )}
            {isTag && 
                <div className="col g5">
                    <span className="bold">{tGuardrails("requiredTags")}</span>
                    <div className="SingleGuardrail__popover-list col g5">
                        {requiredTags?.length > 0 ? 
                        requiredTags.map((tag) => (
                        <div className="row g5" key={tag}>
                            <TagIcon className="SingleGuardrail__popover-tagIcon"/>
                            <span className="GuardrailCriteriaPopover__item">{tag}</span>
                        </div>)) : <span className="GuardrailCriteriaPopover__item">Any tag</span>}
                    </div>
                </div>  
            }
          </>)
          : <>
            {policiesNotEmpty && (
                <>
                     <div className={`${severity ? "SingleGuardrail__popover-section " : ""}col g5`}>
                        <span className="bold">{tGuardrails("policies")}</span>
                        {!policiesIncludeIsEmpty && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("include")}</span>
                            <div className="SingleGuardrail__popover-list col g5">
                                {loading ? <SpinnerLoader /> : policies.include.map((policy) => (
                                    <span key={policy} className="GuardrailCriteriaPopover__item">{policiesNamesMap[policy] || ""}</span>
                                ))}
                            </div>
                        </div>}
                        {!policiesExcludeIsEmpty && <div className="col g5 SingleGuardrail__popover-list">
                            <span className="underline">{tCommon("exclude")}</span>
                            <div className="SingleGuardrail__popover-list col g5">
                                {loading ? <SpinnerLoader /> : policies.exclude.map((policy) => (
                                    <span key={policy} className="GuardrailCriteriaPopover__item">{policiesNamesMap[policy] || policy}</span>
                                ))}
                            </div>
                        </div>}
                    </div>
                </>
            )}
            {severity && <div className="col g5">
                <span className="bold">{tGuardrails("severity")}</span>
                <span className={`SingleGuardrail__popover-severityBtn ${lowerSeverity}`}>{capitalizeFirst(lowerSeverity)}</span>
            </div>}
          </> }
        </div>
    )
};

export default GuardrailCriteriaPopover;