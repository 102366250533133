import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip, Form } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import keys from "lodash/keys";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import AntdTableWrapper from "../../../shared/antdTableWrapper/antdTableWrapper";
import { appToast } from "../../../shared/appToast/appToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { queryBackendsData } from "../../../redux/actions/iacStacksActions";
import {
  formatUTCDateTime,
  renderCrawlerIconByType,
  renderCrawlerTitleByType,
} from "../../../utils/formatting";
import { ReactComponent as Encrypted } from "../../../Images/general_icons/encrypted.svg";
import { ReactComponent as EventDriven } from "../../../Images/general_icons/eventdrivenGrey.svg";
import { ReactComponent as IacStack } from "../../../Images/general_icons/iac_stack.svg";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import BackendInfoModal from "./backendInfoModal";
import BackendActionsButton from "./backendActionsButton";
import Loading from "../../../shared/loading/loading";

import { IAC_TYPES } from "../../../consts/general";
import "./backendsTable.scss";

const BackendsTable = ({
  triggerScanNowLambda,
  setFilters,
  backendInfoModalOpen,
  setBackendInfoModalOpen,
  activeInfoTab,
  setActiveInfoTab,
  selectedIacType,
  setSelectedCategory
}) => {
  const { t } = useTranslation('users');
  const [loadingData, setLoadingData] = useState(false);
  const [crawlerLoading, setCrawlerLoading] = useState(null);
  const [backendRow, setBackendRow] = useState({});
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  const backends = useSelector((state) => state.iacStacksReducer.backends.hits);
  const backendsTotal = useSelector((state) => state.iacStacksReducer.backends.total);
  const backendsAggs = useSelector(
    (state) => state.iacStacksReducer.backends.aggs
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const freeTextSearch = useSelector((state) => state.globalAppReducer.searchVal);
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const iconsByType = {
    s3: "aws",
    cloudformation: "aws",
    gcs: "gcp",
    tfc: "terraform",
    "argocd-local": "k8s",
  };

  useEffect(() => {
    queryBackends();
  }, [freeTextSearch]);

  useEffect(() => {
    if (!isEmpty(backendRow)) {
      setBackendInfoModalOpen(true);
    }
  }, [backendRow]);

  const queryBackends = async (pageNum) => {
    const stateType = selectedIacType === IAC_TYPES.terraform ? [IAC_TYPES.terraform, IAC_TYPES.opentofu] : [selectedIacType];
    setLoadingData(true);
    await dispatch(queryBackendsData({
      ...emptyIacScreenFilters,
      stateType,
    }, pageSize, pageNum || pageNumber, freeTextSearch));
    setLoadingData(false);
  };

  const handleScanClick = async (crawlerId) => {
    setCrawlerLoading(crawlerId);
    await triggerScanNowLambda(crawlerId);
    setCrawlerLoading(null);
    return appToast("info", "", "Scanning in progress");
  };

  const handleSettingsClick = async (record) => {
    setBackendRow(record);
  };

  const handleClose = () => {
    setBackendInfoModalOpen(false);
    setActiveInfoTab("exclusions");
    setBackendRow(null);
    form.resetFields();
  };

  const columns = [
    {
      title: "Integration",
      dataIndex: "remoteIntegrationName",
      key: "remoteIntegrationName",
      ellipsis: true,
      sorter: (a, b) => a?.type.localeCompare(b?.type),
      render: (text, record) => (
        <div
          style={{ wordWrap: "break-word", wordBreak: "break-word" }}
          className="BackendsTable__item-row row"
        >
          <ProviderIcon
            provider={
              keys(iconsByType)?.includes(record?.type)
                ? iconsByType[record?.type]
                : record?.type
            }
            customStyle="backends"
          />
          <span>{`${!isEmpty(text) ? text : "-"}`}</span>
        </div>
      ),
    },
    {
      title: "Backend",
      dataIndex: "mainLocation",
      key: "mainLocation",
      ellipsis: true,
      sorter: (a, b) => a?.mainLocation?.localeCompare(b?.mainLocation),
      render: (text, record) => (
        <div
          style={{ wordWrap: "break-word", wordBreak: "break-word" }}
          className="BackendsTable__item-row row"
        >
          <Tooltip
            placement="topLeft"
            title={renderCrawlerTitleByType(record?.type)}
            overlayInnerStyle={{
              width: "130px",
            }}
          >
            <img
              src={renderCrawlerIconByType(record?.type, themeDark)}
              alt="type"
              className="BackendsTable__item-icon"
            />
          </Tooltip>
          <span>{!isEmpty(text) ? text : "-"}</span>
          {record?.type === "gcs" && record?.location?.gcs?.isEncrypted && (
            <Encrypted className="BackendsTable__item-rowIcon" />
          )}
          {record?.isEventDriven && (
            <EventDriven className="BackendsTable__item-rowIcon" />
          )}
          {record?.isEventDrivenNoPermissions && (
            <Tooltip
              placement="topLeft"
              title="No permissions to add event-driven to this backend."
            >
              <FontAwesomeIcon
                icon="exclamation-circle"
                className="BackendsTable__item-exclamation"
              />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Stacks",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 100,
      render: (text, record) => {
        const crawlerAggregation = find(
          backendsAggs,
          (agg) => agg?.key === record?._id
        );
        return (
          <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
            {!isEmpty(crawlerAggregation) ? (
              <div
                className="BackendsTable__item-view-stacks-btn-flag row"
                onClick={async (e) => {
                  const stateType = selectedIacType === IAC_TYPES.terraform ? [IAC_TYPES.terraform, IAC_TYPES.opentofu] : [selectedIacType];
                  e.stopPropagation();
                  await dispatch(
                    setFilters({
                      ...emptyIacScreenFilters,
                      stateType,
                      crawlerId: [record?._id],
                    })
                  );
                  setSelectedCategory("appliedStacks");
                }}
              >
                <IacStack />
                {crawlerAggregation?.doc_count}
              </div>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
    {
      title: "Last Scan Date",
      dataIndex: "lastScan",
      key: "lastScan",
      ellipsis: true,
      width: 150,
      sorter: (a, b) => {
        if (
          moment(new Date(a?.lastScan)).isBefore(moment(new Date(b?.lastScan)))
        ) {
          return -1;
        }
        return 1;
      },
      render: (text) => {
        const date = new Date(text);
          return (
            <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
              {!isEmpty(text) && date.getFullYear() !== 1
                ? formatUTCDateTime(date) : "-"}
            </div>
        )
      },
    },
    {
      title: "",
      dataIndex: "lastFullMap",
      key: "lastFullMap",
      ellipsis: true,
      width: 50,
      render: (text, record) => (
        <BackendActionsButton
          rowData={record}
          handleScanClick={(crawlerId) => handleScanClick(crawlerId)}
          handleSettingsClick={(record) => handleSettingsClick(record)}
          loadingScan={record?._id === crawlerLoading}
        />
      ),
    },
  ];

  return (
    <div className="BackendsTable__container">
      {loadingData ? (
        <div className="BackendsTable__loaderContainer full-page center">
          <Loading />
        </div>
      ) : (
        <AntdTableWrapper
          columns={columns}
          dataSource={backends}
          rowKey="_id"
          pagination={{
            defaultPageSize: pageSize,
            defaultCurrent: pageNumber,
            showSizeChanger: false,
            total: backendsTotal,
            onChange: (pageNum, size) => {
              queryBackends(pageNum);
              setPageNumber(pageNum);
            },
            showTotal: (total, range) => t('user-management.table.footer.items-count', { rangeFrom: range[0], rangeTo: range[1], itemsCount: total })
          }}
          scroll={{ y: 'calc(100vh - 155px - 134px - 51px - 25px - 39px)' }}
          fixed="left"
        />
      )}
      <BackendInfoModal
        visible={backendInfoModalOpen}
        handleCloseModal={handleClose}
        rowId={backendRow?._id}
        form={form}
        activeTab={activeInfoTab}
        setActiveTab={setActiveInfoTab}
      />
    </div>
  );
};

export default BackendsTable;
