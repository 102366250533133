import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { isEmpty } from "lodash";
import CodeDiff from "../../../../../shared/codeDiff/codeDiff";
import { useTranslation } from "react-i18next";
import HistoryNavSlideV2 from "../historyNavSlide/historyNavSlideV2";
import { setRevisionId } from "../../../../../redux/actions/historyActionsV2";
import { ReactComponent as IdenticalRevision } from '../../../../../Images/general_icons/identicalRevision.svg';
import { sendEvent } from "../../../../../utils/amplitude";

const RevisionCompareV2 = ({
  currentRevision,
  requestedRevision,
  revisionId,
  timestamp,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation("asset-history");

  const requestedRevisionId =
    useSelector((state) => state.historyReducerV2.requestedRevisionId) || false;

  const isRevisionIdentical =
    isEmpty(currentRevision) && isEmpty(requestedRevision);

  // move out
  const handleSetComapreBlock = (obj) => {
    if (_.isObject(obj)) {
      const str = JSON.stringify(obj, null, 1);
      return str.slice(1, str.length - 1);
    }
    if (_.isEmpty(obj)) {
      return "";
    }
    return obj;
  };

  const handleSelectedRevision = (selectedRevision, event) => {
    event.stopPropagation();
    sendEvent("selected revision timestamp", {revisionId: selectedRevision});
    if (requestedRevisionId === selectedRevision) {
      dispatch(setRevisionId(null));
    } else {
      dispatch(setRevisionId(selectedRevision));
    }
  };

  useEffect(() => {
    const clearSelection = () => {
      dispatch(setRevisionId(null));
    };
    document.addEventListener("click", clearSelection);
    return () => {
      document.removeEventListener("click", clearSelection);
    };
  }, [dispatch]);

  return (
    <div
      className={`RevisionCompareV2__diff pointer row  ${
        requestedRevisionId === revisionId ? "selected" : ""
      }`}
      onClick={(event) => handleSelectedRevision(revisionId, event)}
    >
      <HistoryNavSlideV2 key={revisionId} time={timestamp} />
      {isRevisionIdentical ? (
        <div className="RevisionCompareV2__identical row center g10 text">
          {IdenticalRevision && <IdenticalRevision />}
          This revision is identical to the current configuration
        </div>
      ) : (
        <div className="RevisionCompareV2__diff-code pad10">
          <CodeDiff
            lang="text"
            oldValue={handleSetComapreBlock(requestedRevision)}
            newValue={handleSetComapreBlock(currentRevision)}
          />
        </div>
      )}
    </div>
  );
};

export default RevisionCompareV2;
