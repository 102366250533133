import React from "react";
import TableWrapper from "../../shared/tableWrapper/tableWrapper";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { clouds } from "../../utils/icons";

import AssetTypeRowCell from "../Insights/insightsTable/assetTypeRowCell";
import IacStatusFlag from "../../shared/iacStatusFlag/iacStatusFlag";

const BackupPoliciesTable = ({  policies = [], loading = false, handlePolicyClick }) => {
    const policiesLength = policies.length;
    const { t } = useTranslation("backupDR");
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    const columns = [
        {
            headerName: t("columns.createdBy"),
            field: "createdBy",
            width: 130,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            valueGetter: ({ row }) => row?.createdBy || ""
        },
        {
          headerName: t("columns.backupName"),
          field: "name",
          flex: 1,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          valueGetter: ({ row }) => row?.name || ""
        },
        {
          headerName:  t("columns.dataSource"),
          field: "dataSource",
          flex: 2,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { dataSource } = row || {};
            const { name = "", provider = ""} = dataSource || {};
            return (
                <div className="row g8">
                    <img src={clouds(provider, themeDark)} alt={name} width={16} />
                    <span>{name}</span>
                </div>
            );
          },
        },
        {
          headerName: t("columns.assetType"),
          field: "assetType",
          flex: 1,
          filterable: false,
          renderCell: (params) => {
            const { assetType = "" } = params?.row || {};
            return <AssetTypeRowCell value={[assetType]} gap="8" borderRadius="4px" />
          },
        },
        {
          headerName:  t("columns.region"),
          field: "region",
          width: 100,
          align: "center",
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          valueGetter: ({ row }) => row?.region || ""
        },
        {
          headerName:  t("columns.period"),
          field: "period",
          width: 100,
          sortable: false,
          filterable: false,
          align: "center",
          disableColumnMenu: true,
          valueGetter: ({ row }) => row?.period || ""
        },
        {
          headerName: t("columns.scopeIacStatus"),
          field: "iacStatus",
          width: 170,
          sortable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => params?.row?.iacStatus === "unmanaged" ?  <IacStatusFlag state={params?.row?.iacStatus} rowData={{provider: params?.row?.dataSource?.provider}} /> : "All types"
        },
    ];
    return (
      <div>
          <TableWrapper
            autoHeight={!!policiesLength}
            rowKey="id"
            tableData={policies || []}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            pageSize={10}
            rowCount={policiesLength}
            // handlePageChange={setPageNumber}
            // onPageSizeChange={setPageSize}
            onRowClick={({ row = {} }) => handlePolicyClick({ name: row?.name, historyId: row.historyId })}
            serverSide
           />
      </div>
   )
}

export default BackupPoliciesTable;