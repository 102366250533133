import React, { useMemo } from "react";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import map from "lodash/map";
import { Tooltip } from "antd";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";

const AssetTypeRowCell = ({ value = [], gap = "15", borderRadius = "0" }) => {
  
  const assetBlocks = useMemo(() => {
    return map(value, (i) => {
      return <Tooltip
        key={i}
        title={formatAwsStringWithUnderscore(i, true)}
        placement="top"
      >
        <div className="AssetTypeCell__item center" style={{ height: "min-content" }}>
        <AssetTypeImage assetType={i} customStyle={{ height: '100%', maxHeight: '23px', borderRadius }} />
        </div>
      </Tooltip>
    });
  }, [value]);

  return <div className={`AssetTypeCell row g${gap}`}>{assetBlocks}</div>;
};

export default AssetTypeRowCell;
