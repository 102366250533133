import React, { useEffect, useState } from "react";

const LongLoader = ({
    customLoader = null,
    loading = false,
    msg1 = '',
    msg2 = '',
    duration = 30000,
    customClassName = ""
}) => {
    const [userWaitingLong, setUserWaitingLong] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            if (loading) {
              setUserWaitingLong(true);
            }
        }, duration);
        return () => clearTimeout(timer);
    }, [loading])
    
    return (
        <div className={`LongLoader ${customClassName}`}>
            {customLoader}
            <span>
                {userWaitingLong ? msg2 : msg1}
            </span>
        </div>
    )
}
export default LongLoader;