import isEmpty from 'lodash/isEmpty';
import { capitalizeFirst, renderEllipsis } from "./formatting";
import moment from "moment";

const CI_STEP_DATE_FORMAT = "MMM DD YYYY, HH:mm"

export const checkIfPolicyOrCostExists = (planObj = {}) => Object.values(planObj.scanResultsSummary?.rego?.notNoOpResourcesViolationsSeverityCount || {}).some(val => val > 0)
|| planObj?.costResultsSummary?.cost?.notNoOpResourcesCostEstimationCount > 0;

export const getGeneralPolicyCostSummaryData = (planObj = {}) => {
    const { scanResultsSummary = {}, costResultsSummary = {} } = planObj;
    const beforeCost = costResultsSummary.cost?.before?.totalMonthlyCost || 0;
    const afterCost = costResultsSummary.cost?.after?.totalMonthlyCost || 0;
    const was = beforeCost == 0 ? beforeCost : parseFloat(beforeCost).toFixed(2);
    const now = afterCost == 0 ? afterCost : parseFloat(afterCost).toFixed(2);

    const severities = scanResultsSummary.rego?.notNoOpResourcesViolationsSeverityCount || {};
    return { policySummary: severities, costSummary: { was, now } }
};

export const getPolicySeveritiesObject = (planArr = [], returnCategoriesByLowerCase = false) => {
    const scanResultsSummary = planArr[0]?.scanResultsSummary || {};
    let severities = scanResultsSummary.rego?.notNoOpResourcesViolationsSeverityCount || { HIGH: 0, MEDIUM: 0, LOW: 0 };
    if (returnCategoriesByLowerCase) {
        severities = ['HIGH', 'MEDIUM', 'LOW'].reduce((acc, key = {}) => {
            const lowerKey = key?.toLowerCase(); 
            acc[lowerKey] = severities[key] || 0;
            return acc;
        }, {});
    }
    return severities;
};
export const getCostSummaryPrices = (planArr = []) => {
    const costResultsSummary = planArr[0]?.costResultsSummary || {};
    const beforeCost = costResultsSummary.cost?.before?.totalMonthlyCost || 0;
    const afterCost = costResultsSummary.cost?.after?.totalMonthlyCost || 0;
    const diffCost = afterCost - beforeCost;
    const wasPrice = beforeCost == 0 ? beforeCost : parseFloat(beforeCost).toFixed(2);
    const nowPrice = afterCost == 0 ? afterCost : parseFloat(afterCost).toFixed(2); 
    const diffPrice = diffCost == 0 ? 0 : parseFloat(Math.abs(diffCost)).toFixed(2);
    return { wasPrice, nowPrice, diffPrice, diffCost, beforeCost, afterCost };
};
export const getActionsCountsSummary = (planArr = [], applyArr = []) => {
    const planSummary = planArr[0];
    const applySummary = applyArr[0];
    const parsedDataSummary = applySummary?.parsedDataSummary?.apply || planSummary?.parsedDataSummary?.plan;
    const { createdResourcesCount: createCount = 0, updatedResourcesCount: updateCount = 0, 
        deletedResourcesCount: deleteCount = 0, importedResourcesCount: importCount = 0,
        failedResourcesCount: failedCount = 0
     }  = parsedDataSummary || {};
    return { create: createCount, update: updateCount, delete: deleteCount, import: importCount, fail: failedCount };
}
export const getMonthlyCost = (costResults = [], action) => {
    const costElement = costResults[0] || {};
    const { before = {}, after = {},  } = costElement;
    const costObj = action === 'delete' ? before : after;
    const { monthlyCost: monthlyTotalCost = "0" } = costObj;
    return monthlyTotalCost;
}

export const formatWorkspaceName = (workspaceName = "") => {
    return renderEllipsis(workspaceName, 25);
};
export const formatRunTimeDate = (lastRunTime = "") => {
    const isRealDate = new Date(lastRunTime).getFullYear() > 1970;
    return lastRunTime && isRealDate ? moment(lastRunTime).format(CI_STEP_DATE_FORMAT) : "-";
}
export const getProperFilters = (actionFilters = {}) => {
    const filters = { "orClauses": {"action": ["create", "update", "delete", "import"], "hasDiagnostics": true } };
    if (isEmpty(actionFilters)) return filters;
    const actionArr = Object.keys(actionFilters).filter(key => actionFilters[key]);
    filters.orClauses.action = actionArr;
    return filters;
};

export const checkIfGuardrailScopeStatementNotEmpty = (scopeStatement, withIncludeExclude =  true) => {
    if (!withIncludeExclude) return scopeStatement?.length > 0 && scopeStatement[0] != "*";
    const { include = [], exclude = [] } = scopeStatement || {};
    return include.length > 0 && include[0] != "*" || exclude.length > 0 && exclude[0] != "*";
}

export const WORKFLOWS_STATUSES_TITLES = {
    "plan_success": "PLAN COMPLETED",
    "plan_error": "PLAN FAILED",
    "apply_error": "APPLY FAILED",
    "apply_success": "APPLIED",
    "init_error": "INIT FAILED",
    "blocked": "BLOCKED",
    "apply_no_changes": "APPLY NO CHANGES",
    "plan_no_changes": "PLAN NO CHANGES",
}
export const formatCiToolName = (str = "") => {
    let newStr = capitalizeFirst(str);
    if (str.includes("-")) {
        newStr = str.split("-").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    }
    return newStr;
}