import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const FETCH_REVISIONS = "FETCH_REVISIONS";
export const FETCH_REVISION = "FETCH_REVISION";
export const SET_REVISION_ID = "SET_REVISION_ID";
export const RESET_HISTORY = "RESET_HISTORY";

const sendPostRequest = async (url, body) => {
  const requestWrapper = new RequestWrapper();
  const req = await requestWrapper.sendRequest(url, "POST", body);

  let data = req.ok ? await req.json() : {};

  return data;
};

const getHistoryRevisions = (provider, integrationId, assetType, frn, queryPage, pageSize) => {
  return action(async (dispatch) => {
    const data = await sendPostRequest(
      `${BACKEND_URL}/asset-history/getRevisions`,
      { provider, integrationId, assetType, frn, queryPage, pageSize }
    );

    dispatch({
      type: FETCH_REVISIONS,
      payload: data,
    });

    return data;
  });
};

const getHistoryRevision = (provider, integrationId, assetType, frn, runId) => {
  return action(async (dispatch) => {
    const data = await sendPostRequest(
      `${BACKEND_URL}/asset-history/getRevision`,
      { provider, integrationId, assetType, frn, runId }
    );

    dispatch({
      type: FETCH_REVISION,
      payload: data,
    });
  });
};

const setRevisionId = (data) => {
  return action((dispatch) => {
    dispatch({
      type: SET_REVISION_ID,
      payload: data,
    });
  });
};

const resetHistoryState = () => {
  return action((dispatch) => {
    dispatch({
      type: RESET_HISTORY,
    });
  });
};

export { getHistoryRevisions, getHistoryRevision, resetHistoryState, setRevisionId };