import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";
import FormLabels from "../../../shared/formLabels/formLabels";

const OpsgenieCreation = ({
  type,
  wizardState,
  setWizardState,
  setSubmitDisabled,
  defaultTags,
}) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, apiKey } = formValues;

    if (_.isEmpty(name) || _.isEmpty(apiKey)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };
  return (
    <div className="WebhookCreation col">
      <div className="WebhookCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue WebhookCreation__intro-orderedlist">
          <li key="1">
            <span className="dark-blue">
              To generate an API key for Opsgenie, go{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.atlassian.com/opsgenie/docs/create-a-default-api-integration/"
                className="underline"
              >
                here
              </a>
              . Copy the API key.
            </span>
          </li>
          <li key="2">
            <span className="dark-blue">
              In Firefly, enter the <span className="bold">Nickname</span> and
              paste the <span className="bold">API key</span> in the box.
            </span>
          </li>
          <li key="3">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="aws-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || _.capitalize(type),
          apiKey: wizardState.apiKey || "",
          tags: wizardState.tags || [],
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Please fill integration name" />
        </Form.Item>
        <Form.Item
          label="API key"
          name="apiKey"
          rules={[
            {
              required: true,
              message: "API key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Opsgenie Tags"
          name="tags"
          style={{ marginBottom: "0", flexDirection: "column" }}
        >
          <FormLabels
            allLabels={defaultTags}
            selectedLabels={(tags) => setWizardState({ ...wizardState, tags })}
            currentSelected={wizardState?.tags || []}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default OpsgenieCreation;
