import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import GoogleChatCreation from "./googleChatCreation";

import {
  testGoogleChatIntegration,
  createProviderIntegration,
} from "../../../redux/actions/integrationsActions";
import { integrationIcons } from "../../../utils/icons";

import "./googleChatIntegration.scss";
import { appToast } from "../../../shared/appToast/appToast";

const GoogleChatIntegration = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({
    webhookUrl: "",
    name: "Google Chat",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { t } = useTranslation("google-chat");

  const configWizard = [
    {
      step_title: t("google-chat-creation.steps.details.title"),
      step_description:  t("google-chat-creation.steps.details.description"),
      content_title:  t("google-chat-creation.steps.details.content-title"),
      content: (
        <GoogleChatCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const googleChatWebhookOk = await dispatch(
          testGoogleChatIntegration(wizardState.webhookUrl)
        );
        if (googleChatWebhookOk) {
          const { name, webhookUrl } = wizardState;
          await dispatch(
            createProviderIntegration("google-chat", { name, webhookUrl })
          );
          setLoadingSubmit(false);
        } else {
          setLoadingSubmit(false);
          appToast("error", "", t("google-chat-creation.error.content"))
          throw new Error("Invalid webhook url");
        }
        return;
      }
    },
    {
      step_title: t("google-chat-creation.steps.completion.title"),
      step_description: t("google-chat-creation.steps.completion.description"),
      content_title: t("google-chat-creation.steps.completion.content-title"),
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="GoogleChatIntegration">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={integrationIcons["googleChat"]}
      />
    </div>
  );
};

export default GoogleChatIntegration;
