import { NOT_RELEVANT_STATES } from '../consts/inventory'

export const filterOutNotRelevantStates = (buckets) => {
  if(!buckets){
    return [];
  }
  const filteredBuckets = buckets.filter((bucket) => {
    return NOT_RELEVANT_STATES.includes(bucket?.key) === false;
  });

  return filteredBuckets;
}