import React from "react";
import ReactPlayer from "react-player";
import quoteImg from "../../../Images/general_icons/quote.svg";
import "./welcomeStep.scss";

const WelcomeStep = () => {
  const videoUrl = "https://youtu.be/Q3ft49cSlY4";
  const appsFlyerStoryUrl =
    "https://www.firefly.ai/success-stories/appsflyer";

  return (
    <div className="WelcomeStep center col">
      <ReactPlayer
        url={videoUrl}
        controls
        config={{
          youtube: {
            playerVars: { showinfo: 2 },
          },
        }}
      />

      <div className="WelcomeStep__quote col">
        <img
          src={quoteImg}
          alt="quote"
          className="WelcomeStep__quote-sign left-sign"
        />
        <div className="WelcomeStep__quote-text col">
          <span className="WelcomeStep__quote-text-title">
            With Firefly we are able to codify hundreds of resources every
            month.
          </span>
          <span className="WelcomeStep__quote-text-sub">
            Eliran Bivas - Cloud Native Horizontal Leader at AppsFlyer{" "}
            <a href={appsFlyerStoryUrl} target="_blank" rel="noreferrer">
              Learn More
            </a>
          </span>
        </div>
        <img
          src={quoteImg}
          alt="quote"
          className="WelcomeStep__quote-sign right-sign"
        />
      </div>
    </div>
  );
};

export default WelcomeStep;
