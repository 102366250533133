import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Thinkerbell } from "../../../Images/general_icons/thinkerbell/thinkerbell.svg";

import { useSelector, useDispatch } from "react-redux";
import {
  fetchAssetDataById,
  setFilters,
  setTerraformCmds,
} from "../../../redux/actions/inventoryv3Actions";
import { appToast } from "../../../shared/appToast/appToast";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import { getExclusions } from "../../../redux/actions/driftsExclusionsActions";
import { setSearchVal } from "../../../redux/actions/globalAppActions";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";

// components
import AppBtn from "../../../shared/appBtn/appBtn";
import FiltersRowBadges from "../filtersRowBadges/filtersRowBadges";
import InventoryTableActions from "../inventoryTableActions/inventoryTableActions";
import AssetDataModal from "../assetDataModal/assetDataModal";
import DriftAnalyzeDrawer from "../../drifts/driftAnalyzeDrawer/driftAnalyzeDrawer";
import TerraformImport from "../../../shared/terraformImport/terraformImport";
import ExportModal from "../exportModal/exportModal";
import MultiRemoveAssetCode from "../multiRemoveAssetCode/multiRemoveAssetCode";
import RemoveResourceDrawer from "../removeResourceDrawer/removeResourceDrawer";
import PullRequestForm from "../pullRequestForm/pullRequestForm";
import CodifyDrawer from "../codifyDrawer/codifyDrawer";
import IssueModal from "../issueModal/issueModal";
import InventoryServiceWrapper from "../inventoryServiceWrapper/inventoryServiceWrapper";
import HeaderSearch from "../../appHeader/headerSearch/headerSearch";
import InventoryTable from "../inventoryTable/inventoryTable";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CODIFY_TYPES, IAC_TYPES, RESOURCE_STATE_TYPES, TABS_KEYS } from "../../../consts/general";
import "./inventoryTableWrapper.scss";
import { getInventoryAmpliData } from "../inventoryTable/inventoryHelper";
import GradientBorderButton from "../../../shared/gradientBorderButton/gradientBorderButton";
import FiltersChat from "./filtersChat/filtersChat";

const InventoryTableWrapper = ({
  handlePageChange,
  handleTableSorterChange,
  tableData,
  fetchingData,
  totalObjects,
  tablePageSize,
  onPageSizeChange,
  // -- active filters arrays
  filterEnvironments,
  filterTypes,
  filterClassifications,
  filterExcludedAssets,
  filterIacTypes,
  filterRegions,
  filterState,
  filterStacks,
  filterYears,
  filterProviderTypes,
  filterOwners,
  filterTags,
  filterFrns,
  filterResourceGroups,
  filterDeleted,
  filterTerraformObjectFullAddress,
  handleRemoveBadgeFilter,
  moduleFromUrl,
  handleClearAllFilters,
  resetPage,
  disableSelectionOnClick,
  selectedRows,
  handleSelectedRows,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [customCodifyText, setCustomCodifyText] = useState("");
  const [codifyDisabled, setCodifyDisabled] = useState(true);
  const [assetDataModalOpen, setAssetDataModalOpen] = useState(false);
  const [codifyDrawerOpen, setCodifyDrawerOpen] = useState(false);
  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [pullRequestOpen, setPullRequestOpen] = useState(false);
  const [analyzeDrawerOpen, setAnalyzeDrawerOpen] = useState(false);
  const [terraformImportOpen, setTerraformImportOpen] = useState(false);
  const [defaultInfoTab, setDefaultInfoTab] = useState("general");
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [openMultiRemoveAsset, setOpenMultiRemoveAsset] = useState(false);
  const [openSingleRemoveAsset, setOpenSingleRemoveAsset] = useState(false);
  const [selectedAssetRemove, setSelectedAssetRemove] = useState(null);
  const [rowClickedData, setRowClickedData] = useState({});
  const [selectedChildParentData, setSelectedChildParentData] = useState(null);
  const [innerSectionTab, setInnerSectionTab] = useState("info");
  const [selectedAssetsView, setSelectedAssetsView] = useState(false);
  const [fetchingAssetLoading, setFetchingAssetLoading] = useState(false);
  const [codifyActiveTab, setCodifyActiveTab] = useState(CODIFY_TYPES.terraform);
  const [showThinkerballFilterChat, setShowThinkerballFilterChat] = useState(false);

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );
  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );
  const excludedAssets = useSelector((state) => state.exclusionsReducer.rules);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const tierType = useSelector(
    (state) => state?.onbordingReducer.account.tier_type
  );
  const isFreeTier = Boolean(tierType === "FREE_TIER");

  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const jiraIntegrations = useSelector(
    (state) => state.jiraIntegrationReducer.integrations
  );

  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const selectedService = useSelector(
    (state) => state.inventoryReducer.selectedService
  );
  const activeFeatures = useSelector((state) => state.activeFeaturesReducer.activeFeatures) || [];
  const isInventoryAIChatEnabled = activeFeatures.find((feature = {}) => feature.path === '/inventory-ai-chat');

    if(isInventoryAIChatEnabled){
        const navigationSearchValue = useSelector(
            (state) => state.navigationReducer.searchValue
        );
        if(navigationSearchValue && navigationSearchValue !== "" && !showThinkerballFilterChat){
            setShowThinkerballFilterChat(true);
        }
        useEffect(async () => {
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }, []);

        const handleKeyDown = (event) => {
            if ((event.metaKey || event.ctrlKey) && event.key === 'j') {
                setShowThinkerballFilterChat(!showThinkerballFilterChat)
            }
        };
    }

  const isDeleteMode = !!screenFilters?.deleted

  useEffect(() => {
    if (location?.search) {
      const searchParams = new URLSearchParams(location.search);
      const sharedAssetId = searchParams.get("asset");
      if (sharedAssetId) {
        (async function () {
          setFetchingAssetLoading(true);
          const data = await dispatch(fetchAssetDataById(encodeURIComponent(sharedAssetId)));
          
          if (!_.isEmpty(data)) {
            setRowClickedData(data);
            setAssetDataModalOpen(true);
          }else{
            appToast("error","Not Found", "Shared asset cannot be found")
          }
          setFetchingAssetLoading(false);
        })();
      }
    }
  }, []);
  
  useEffect(() => {
    handleSelectedRows([]);
  }, [searchVal, screenFilters]);

  useEffect(() => {
    if (_.isEmpty(selectedRows)) {
      setSelectedAssetsView(false);
    }
  }, [selectedRows]);

  useEffect(() => {
    setCustomCodifyText("");
    if (!selectedRows?.length) {
      return setCodifyDisabled(true);
    }
    if (_.some(selectedRows, ["isLocked", true])) {
      setCustomCodifyText("Upgrade to Codify");
      return setCodifyDisabled(true);
    }
    setCodifyDisabled(false);
  }, [selectedRows]);

  const handleRemoveAssetCodeDisabled = () => {
    if (isViewer) {
      return true;
    }
    if (_.isEmpty(selectedRows)) {
      return true;
    }

    if (
      !_.some(
        selectedRows,
        (item) => item?.deleteCommand ||([RESOURCE_STATE_TYPES.ghost, RESOURCE_STATE_TYPES.managed, RESOURCE_STATE_TYPES.modified, RESOURCE_STATE_TYPES.undetermined].includes(item?.state) && item?.iacType === IAC_TYPES.terraform )
      )
    ) {
      return true;
    }
  };

  const handleParentClick = (selectedFrn) => {
    let empotyFiltersWithFrn = { ...emptyInventoryScreenFilters, frns: [selectedFrn], masterType: [] };
    dispatch(setFilters(empotyFiltersWithFrn));
    return setAssetDataModalOpen(false);
  };
  const handleClickTag = (selectedTag) => {
    const newTagsArr =
      _.has(screenFilters, "tags") && !_.isEmpty(screenFilters?.tags)
        ? _.uniq([...screenFilters?.tags, selectedTag])
        : [selectedTag];
    dispatch(setFilters({ ...screenFilters, tags: newTagsArr }));
    return setAssetDataModalOpen(false);
  };
  const handleClickResourceGroup = (selectedResourceGroup) => {
    const newTagsArr =
      _.has(screenFilters, "resourceGroups") && !_.isEmpty(screenFilters?.resourceGroups)
        ? _.uniq([...screenFilters?.resourceGroups, selectedResourceGroup])
        : [selectedResourceGroup];
    dispatch(setFilters({ ...screenFilters, resourceGroups: newTagsArr }));
    return setAssetDataModalOpen(false);
  };

  const checkCodifyDrawerDataTypes = () => {
    if (!_.isEmpty(selectedRows)) {
      // dont allow multiple integration codify
      if (!_.every(selectedRows, { providerId: selectedRows[0].providerId })) {
        return appToast("info", "","Codifying multiple resources can be done only within the same account.");
      }

      if (_.some(selectedRows, { state: RESOURCE_STATE_TYPES.ghost })) {
        return appToast("info", "", "Codifying resource can not be done on Ghost assets.");
      }
      if (_.some(selectedRows, { state: RESOURCE_STATE_TYPES.undetermined })) {
        return appToast("info", "", "Codifying resource can not be done on Undetermined assets.");
      }
      if (_.some(selectedRows, { state: RESOURCE_STATE_TYPES.deleted, prevState: RESOURCE_STATE_TYPES.ghost })) {
        return appToast("info", "", "Codifying resource can not be done on deleted ghost assets.");
      }
      if (_.some(selectedRows, { state: RESOURCE_STATE_TYPES.pending, pendingTo: RESOURCE_STATE_TYPES.ghost })) {
        return appToast("info", "", "Codifying resource can not be done on Pending Ghost assets.");
      }
      return setCodifyDrawerOpen(true);
    }
  };

  const handleCodifyClick = async (rowData) => {
    await handleSelectedRows([rowData]);
    setCodifyDrawerOpen(true);
  };

  const handleCreateJiraIssue = async (rowData) => {
    await handleSelectedRows([rowData]);
    sendEvent(inventoryEvents.createIssue, {
      action: "user clicked create issue button from inventory",
    });
    setIssueModalOpen(true);
  };

  const handleRemoveAsset = async (rowData) => {
    await handleSelectedRows([rowData]);
    handleOpenMultiRemove();
  };

  const handleOpenAnalysis = (data, isTable) => {
    setRowClickedData(data);
    setAnalyzeDrawerOpen(true);
    if (!isTable) return;
    sendEvent(inventoryEvents.clickedDriftDetails, {
      ...getInventoryAmpliData(data),
      accessedVia: 'table iac status'
    });
  };

  const handleOpenInsights = (rowData) => {
    setRowClickedData(rowData);
    setDefaultInfoTab("general");
    setInnerSectionTab("insights");
    setAssetDataModalOpen(true);
  };

  const handleOpenGit = (rowData) => {
    setRowClickedData(rowData);
    setDefaultInfoTab("general");
    setInnerSectionTab("git");
    setAssetDataModalOpen(true);
  };

  const handleCloseAnalyzeDrawer = () => {
    setAnalyzeDrawerOpen(false);
    dispatch(getExclusions());
  };

  const handleOpenMulti = (rowData, defaultTab) => {
    setRowClickedData(rowData);
    if (!_.isEmpty(defaultTab)) {
      setDefaultInfoTab(defaultTab);
    }
    setAssetDataModalOpen(true);
  };

  const handleExportInventory = () => {
    setExportModalOpen(true);
  };

  const handleOpenIssueModal = () => {
    if (!_.isEmpty(selectedRows)) {
      setIssueModalOpen(true);
      sendEvent(inventoryEvents.createIssue, {
        action: "user clicked create issue button from inventory",
      });
    }
  };

  const handleTagClicked = (val) => {
    dispatch(setFilters(emptyInventoryScreenFilters));
    dispatch(setSearchVal(val));
  };

  const closeCodifyDrawer = async () => {
    setCodifyDrawerOpen(false);
    const isCft = !!(codifyActiveTab === CODIFY_TYPES.cloudformation || codifyActiveTab === CODIFY_TYPES.cdk);
    await dispatch(setTerraformCmds([], isCft));
  };

  const handleClickOwner = (displayName) => {
    resetPage();
    dispatch(setFilters({ ...screenFilters, owners: [displayName] }));
  };

  const handleOpenMultiRemove = () => {
    setOpenMultiRemoveAsset(true);
    sendEvent(inventoryEvents.deleteAsset, {
      action: "user clicked delete assets button",
    });
    return;
  };

  const handleOpenCommentsTab = (data) => {
    sendEvent(inventoryEvents.commentActions, {
      action: "User clicked the comments icon from inventory table",
    });
    handleOpenMulti(data, "comments");
    return;
  };

  const handleOpenHistoryTab = (data) => {
    sendEvent(inventoryEvents.commentActions, {
      action: "User clicked the history icon from inventory table",
    });
    handleOpenMulti(data, "history");
    return;
  };

  const handleSelectedItemsData = useCallback(() => {
    const arr = _.map(selectedRows || []);
    return arr;
  }, [selectedRows]);

  const handleOpenConnectionTab = (rowData) => {
    sendEvent(inventoryEvents.commentActions, {
      action: "User clicked the relationship icon from inventory table",
    });
    handleOpenMulti(rowData, "relationship");
    return;
  };

  const currentAssetIndex = tableData?.findIndex(asset => asset?.id === rowClickedData?.id);
 
  const validateNavigation = (asset) => {
    if (defaultInfoTab == TABS_KEYS.history && asset?.revisions === 0) {
      setDefaultInfoTab(TABS_KEYS.general);
    }
  }
  const handleNextAsset = () => {
    const currentIndex = currentAssetIndex;
    if (currentIndex < tableData?.length - 1) {
      const nextRowData = tableData[currentIndex + 1];
      validateNavigation(nextRowData)
      setRowClickedData(nextRowData);
    }
  };
  const handlePrevAsset = () => {
    const currentIndex = currentAssetIndex;
    if (currentIndex > 0) {
      const prevRowData = tableData[currentIndex - 1];
      validateNavigation(prevRowData)
      setRowClickedData(prevRowData);
    }
  };
  const handleFiltersChatClick = () => {
    setShowThinkerballFilterChat(!showThinkerballFilterChat);
  }
  return (
    <div className="basic-card InventoryTableWrapper col">
      {isInventoryAIChatEnabled && <FiltersChat handleClose={() => setShowThinkerballFilterChat(false)} visible={showThinkerballFilterChat} />}
      <div className="InventoryTableWrapper__header row between">
        <div className="InventroyTableWrapper__header-search row g8">
          <HeaderSearch />
          {isInventoryAIChatEnabled && <GradientBorderButton onClick={handleFiltersChatClick} customClassName={showThinkerballFilterChat ? "Inventory__thinkerbell-open": ""}>
            <Thinkerbell className="Inventory__thinkerbell" />
          </GradientBorderButton>}
        </div>
        {/* filters indication */}
        <FiltersRowBadges
          filterEnvironments={filterEnvironments}
          filterTypes={filterTypes}
          filterClassifications={filterClassifications}
          filterExcludedAssets={filterExcludedAssets}
          filterIacTypes={filterIacTypes}
          filterRegions={filterRegions}
          filterState={filterState}
          filterStacks={filterStacks}
          filterYears={filterYears}
          filterProviderTypes={filterProviderTypes}
          filterOwners={filterOwners}
          filterTags={filterTags}
          filterFrns={filterFrns}
          filterResourceGroups={filterResourceGroups}
          filterDeleted={filterDeleted}
          filterTerraformObjectFullAddress={filterTerraformObjectFullAddress}
          filterStateLocationsString={screenFilters?.stateLocationsStrings}
          filterIsExcluded={screenFilters?.excluded}
          handleRemoveFilter={handleRemoveBadgeFilter}
          moduleFromUrl={moduleFromUrl}
          handleClearAllFilters={handleClearAllFilters}
        />
        <div className="InventoryTableWrapper__header-actions row">
          <AppBtn
            icon={`{ }`}
            disabled={codifyDisabled}
            onClick={checkCodifyDrawerDataTypes}
            tooltipText={customCodifyText}
            text="Codify"
          />
          <AppBtn
            icon={<FontAwesomeIcon icon="trash-alt" />}
            disabled={handleRemoveAssetCodeDisabled()}
            onClick={handleOpenMultiRemove}
            tooltipText="Delete assets"
          />
          <InventoryTableActions
            disableExport={_.isEmpty(tableData)}
            onExport={handleExportInventory}
            disabledIssue={!selectedRows?.length || isViewer}
            onCreateIssue={handleOpenIssueModal}
          />
        </div>
      </div>
      
      <InventoryServiceWrapper loading={fetchingData}>
        <InventoryTable
          height={
            _.isEmpty(selectedService)
              ? "calc(100vh - 370px)"
              : "calc(100vh - 420px)"
          }
          tableData={selectedAssetsView ? handleSelectedItemsData() : tableData}
          loading={selectedAssetsView ? false : fetchingData}
          tablePageSize={tablePageSize}
          serverSide={!selectedAssetsView}
          // ---
          rowCount={selectedAssetsView ? selectedRows?.length : totalObjects}
          handleSelectedRowsArr={handleSelectedRows}
          handlePageChange={handlePageChange}
          handleSortChange={handleTableSorterChange}
          onPageSizeChange={onPageSizeChange}
          onRowClick={(row) => {
            const data = row?.row || {};
            if (isFreeTier && data?.isLocked) {
              return;
            }
            setRowClickedData(data);
            setAssetDataModalOpen(true);
            sendEvent(inventoryEvents.assetClick, getInventoryAmpliData(data))
          }}
          disableSelectionOnClick={disableSelectionOnClick}
          refresh={isDeleteMode || selectedAssetsView ? "delete" : "regular"}
          selectedRows={selectedRows}
          handleToggleSelectedView={() =>
            setSelectedAssetsView(!selectedAssetsView)
          }
          selectedAssetsView={selectedAssetsView}
          isDeleteMode={isDeleteMode}
          isFreeTier={isFreeTier}
          excludedAssets={excludedAssets}
          classifications={classifications}
          providerIntegrations={providerIntegrations}
          handleOpenAnalysis={handleOpenAnalysis}
          handleOpenMulti={handleOpenMulti}
          handleOpenInsights={handleOpenInsights}
          handleTagClicked={handleTagClicked}
          handleOpenCommentsTab={handleOpenCommentsTab}
          handleClickOwner={handleClickOwner}
          handleOpenHistoryTab={handleOpenHistoryTab}
          handleOpenGit={handleOpenGit}
          handleOpenConnectionTab={handleOpenConnectionTab}
          movePagination
          isCheckBox
          showRelationFlag
        />
        
      </InventoryServiceWrapper>
      <AssetDataModal
        visible={assetDataModalOpen}
        handleClose={() => {
          setAssetDataModalOpen(false);
          setDefaultInfoTab("general");
          setInnerSectionTab("info");
          setSelectedChildParentData(null);
        }}
        data={rowClickedData}
        onClickTag={handleClickTag}
        onParentClick={handleParentClick}
        onClickResourceGroup={handleClickResourceGroup}
        handleRemoveAsset={handleRemoveAsset}
        handleCodifyClick={handleCodifyClick}
        handleCreateJiraIssue={handleCreateJiraIssue}
        handleOpenAnalysis={handleOpenAnalysis}
        defaultTab={defaultInfoTab}
        innerSectionTab={innerSectionTab}
        parentData={selectedChildParentData}
        fetchingAssetLoading={fetchingAssetLoading}
        handleAssetModalFromChildren={(child, parent) => {
          setRowClickedData(child);
          setSelectedChildParentData(parent);
          setAssetDataModalOpen(true);
        }}
        nextAssetDisabled={currentAssetIndex == tableData?.length -1 || currentAssetIndex === -1}
        handleNextAsset={handleNextAsset}
        prevAssetDisabled={currentAssetIndex <= 0}
        handlePrevAsset={handlePrevAsset}
        handleChangeTab={tab => setDefaultInfoTab(tab)}
        handleResetParentTab={() => setDefaultInfoTab(TABS_KEYS.general)}
      />

      <CodifyDrawer
        visible={codifyDrawerOpen}
        closeDrawer={closeCodifyDrawer}
        handleOpenPullRequest={() => {
          setPullRequestOpen(true);
          sendEvent(inventoryEvents?.tfImportCmdDrawerOpen, {
            assets: _.map(selectedRows || [], (item) => {
              return { assetType: item?.assetType, assetName: item?.name };
            }),
          });
        }}
        handleOpenTerraformImport={(activeTab) => {
          setCodifyActiveTab(activeTab);
          setTerraformImportOpen(true);
          sendEvent(inventoryEvents?.pullRequestDrawerOpen, {
            assets: _.map(selectedRows || [], (item) => {
              return { assetType: item?.assetType, assetName: item?.name };
            }),
          });
        }}
        selectedResources={selectedRows}
      />

      <IssueModal
        visible={issueModalOpen}
        handleClose={() => setIssueModalOpen(false)}
        selectedResources={selectedRows}
        noJiraIntegrations={_.isEmpty(jiraIntegrations)}
      />

      <PullRequestForm
        close={() => setPullRequestOpen(false)}
        visible={pullRequestOpen}
        selectedResources={selectedRows}
        ampliSource="(codification) (inventory)"
      />

      <DriftAnalyzeDrawer
        visible={analyzeDrawerOpen}
        closeDrawer={handleCloseAnalyzeDrawer}
        data={rowClickedData}
        noJiraIntegrations={_.isEmpty(jiraIntegrations)}
      />

      <TerraformImport
        closeDrawer={() => setTerraformImportOpen(false)}
        visible={terraformImportOpen}
        codifyActiveTab={codifyActiveTab}
      />
    
      <ExportModal
        visible={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
      />
      <MultiRemoveAssetCode
        visible={openMultiRemoveAsset}
        handleClose={() => setOpenMultiRemoveAsset(false)}
        selectedAssets={selectedRows}
        onClickRemoveAssetPR={(asset) => {
          setSelectedAssetRemove(asset);
          setOpenSingleRemoveAsset(true);
        }}
      />
      <RemoveResourceDrawer
        close={() => setOpenSingleRemoveAsset(false)}
        visible={openSingleRemoveAsset}
        data={selectedAssetRemove}
      />
    </div>
  );
};

export default InventoryTableWrapper;
