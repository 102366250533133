import action from "../middleware";

export const NAVIGATION_SEARCH = "NAVIGATION_SEARCH"

const setSearchKeywordNavigation = (keyord = '') => {
    return action(async (dispatch) => {
        dispatch({
            type: NAVIGATION_SEARCH,
            payload: keyord,
        });
    });
};

export {
    setSearchKeywordNavigation,
};
