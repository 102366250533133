import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import { Tooltip } from "antd";
import map from "lodash/map";
import find from "lodash/find";
import { ReactComponent as CustomIcon } from "../../../../Images/general_icons/insights-filters/opa.svg";
import { ReactComponent as BuiltinIcon } from "../../../../Images/general_icons/insights-filters/ootb.svg";
import "./policyTypeFilter.scss";
import { sendEvent } from "../../../../utils/amplitude";
import { governanceEvents } from "../../../../utils/amplitudeEvents";

const PolicyTypeFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("insights");
  const insightsFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const filtersOpen = useSelector(
    (state) => state.insightsV2Reducer.filtersOpen
  );

  const isDefaultAggs = useSelector(
    (state) => state.insightsV2Reducer.aggs?.isDefault
  );

    const navigationShowValue = useSelector(
        (state) => state.navigationReducer.showValue
    );

    useEffect(() => {
        if(navigationShowValue && navigationShowValue !== ""){
            const payload = { ...insightsFilters, frameworks: [navigationShowValue] };
            dispatch(setInsightsFilters(payload));
        }
    }, [navigationShowValue]);

  const items = [
    {
      title: t("filters.ootb-policy"),
      icon: <BuiltinIcon />,
      val: true,
      key: "ootb",
      exiest: find(isDefaultAggs || [], (i) => i?.value === true),
    },
    {
      title: t("filters.cutsom-policy"),
      icon: <CustomIcon />,
      val: false,
      key: "custom",
      exiest: find(isDefaultAggs || [], (i) => i?.value === false),
    },
  ];

  const handleSetFilter = (obj) => {
    if (!obj?.exiest) {
      return;
    }
    const newVal = insightsFilters?.isDefault === obj?.val ? null : obj?.val;
    const payload = { ...insightsFilters, isDefault: newVal };
    const { title = '' } = obj;
    sendEvent(governanceEvents.policy, { title: title, filters: payload });
    return dispatch(setInsightsFilters(payload));
  };

  return (
    <div className="PolicyTypeFilter col">
      {map(items, (i) => {
        return (
          <div
            className={`PolicyTypeFilter__item`}
            key={i.key}
            onClick={() => handleSetFilter(i)}
          >
            <Tooltip title={filtersOpen ? null : i.title} placement="right">
              <div
                className={`PolicyTypeFilter__item-inner row g10 ${i.exiest ? "" : "disabled"} ${
                  insightsFilters?.isDefault === i.val ? `active-${i.key}` : ""
                } `}
                style={{
                  padding: filtersOpen ? "0 15px" : 0,
                  justifyContent: filtersOpen ? "start" : "center",
                  transition: "padding 0.5s, justify-content 0.5s",
                }}
              >
                {i.icon}
                {filtersOpen ? i.title : null}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default memo(PolicyTypeFilter);
