import { RESOURCE_STATE_TYPES } from "../../consts/general";

const HISTORY_STATUSES = {
    completed: "completed",
    inProgress: "inProgress",
    failed: "failed",
};
const STATUS_ICONS = {
    completed: "check-circle",
    inProgress: "spinner",
    failed: "times-circle",
};
const HISTORY_STATUSES_TITLES = {
    completed: "Completed",
    inProgress: "In Progress",
    failed: "Failed",
};
const BACKUP_PERIODS = ["Daily", "Weekly", "Monthly"];
const POLICIES_DATA_MOCK = [
    {
        id: 1,
        createdBy: "Eran Bibi",
        name: "Bibi Backup",
        dataSource: {
            name: "cpo-office",
            provider: "aws",
        },
        assetType: "aws_s3_bucket",
        region: "us-east-1",
        period:"Monthly",
        iacStatus: RESOURCE_STATE_TYPES.unmanaged,
        historyId: 7,
    },
    {
        id: 2,
        createdBy: "Dudi Levi",
        name: "Dudi Backup",
        dataSource: {
            name: "k8s-prod",
            provider: "k8s",
        },
        assetType: "aws_iam_user",
        region: "us-east-1",
        period: "Monthly",
        iacStatus: RESOURCE_STATE_TYPES.managed,
        historyId: 5,
    },
    {
        id: 3,
        createdBy: "Naor Paz",
        name: "Naor Weekly Backup",
        dataSource: {
            name: "AWS Integration",
            provider: "aws",
        },
        assetType: "aws_s3_bucket",
        region: "us-west-1",
        period: "Weekly",
        iacStatus: RESOURCE_STATE_TYPES.modified,
        historyId: 1,
    },
    {
        id: 4,
        createdBy: "Yuval Fatal",
        name: "Yuval Backup",
        dataSource: {
            name: "k8s-prod",
            provider: "k8s",
        },
        assetType: "aws_instance",
        region: "us-east-1",
        period: "Weekly",
        iacStatus: RESOURCE_STATE_TYPES.unmanaged,
        historyId: 6,
    },
    {
        id: 5,
        createdBy: "Gil Cohen",
        name: "Gilco Backup",
        dataSource: {
            name: "AWS Firefly-stag",
            provider: "aws",
        },
        assetType: "aws_s3_bucket",
        region: "us-east-1",
        period: "Monthly",
        iacStatus: RESOURCE_STATE_TYPES.managed,
        historyId: 4,
    },

];
const HISTORY_DATA_MOCK = [
    {
        id: 1,
        date: "2024-05-07T09:32:23.149Z",
        period: "Weekly",
        name: "Naor Weekly Backup",
        assetType: "aws_s3_bucket",
        matchingAssets: 10,
        status: HISTORY_STATUSES.completed,
    },
    {
        id: 2,
        date: "2024-01-02T19:32:23.149Z",
        period: "Monthly",
        name: "Monthly Backup",
        assetType: "aws_iam_user",
        matchingAssets: 321,
        status: HISTORY_STATUSES.inProgress,
    },
    {
        id: 3,
        date: "2023-11-17T08:32:53.149Z",
        period: "Daily",
        name: "Daily Backup",
        assetType: "aws_instance",
        status: HISTORY_STATUSES.failed,
    },
    {
        id: 4,
        date: "2024-03-17T15:32:23.149Z",
        period: "Monthly",
        name: "Gilco Backup",
        assetType: "aws_s3_bucket",
        matchingAssets: 1251,
        status: HISTORY_STATUSES.completed,
    },
    {
        id: 5,
        date: "2024-01-22T19:22:23.149Z",
        period: "Monthly",
        name: "Dudi Backup",
        assetType: "aws_iam_user",
        matchingAssets: 11,
        status: HISTORY_STATUSES.inProgress,
    },
    {
        id: 6,
        date: "2023-11-17T08:32:53.149Z",
        period: "Daily",
        name: "Yuval Backup",
        assetType: "aws_instance",
        matchingAssets: 121,
        status: HISTORY_STATUSES.inProgress,
    },
    {
        id: 7,
        date: "2024-03-17T15:32:23.149Z",
        period: "Monthly",
        name: "Bibi Backup",
        assetType: "aws_s3_bucket",
        matchingAssets: 1251,
        status: HISTORY_STATUSES.completed,
    }
]
// Regions for Azure
const azureRegions = [
    "East US",
    "West US",
    "Central US",
    "North Europe",
    "West Europe",
    // Add more Azure regions as needed
  ];
  
  // Regions for AWS
  const awsRegions = [
    "us-east-1",
    "us-west-2",
    "eu-west-1",
    "ap-southeast-1",
    "ap-northeast-1",
    // Add more AWS regions as needed
  ];
  
  // Regions for GCP (Google Cloud Platform)
  const gcpRegions = [
    "us-west1",
    "us-central1",
    "europe-west1",
    "asia-east1",
    "asia-southeast1",
    // Add more GCP regions as needed
  ];
  
  // Namespaces for Kubernetes with example namespaces
  const k8sNamespaces = [
    "default",
    "kube-system",
    "development",
    "production",
    "testing",
    // Add more Kubernetes namespaces as needed
];
const REGIONS_BY_PROVIDER = {
    aws: awsRegions,
    azurerm: azureRegions,
    gcp: gcpRegions,
    k8s: k8sNamespaces,
}
const ASSET_TYPES_BY_PROVIDER = {
    aws: ["aws_s3_bucket", "aws_iam_user", "aws_instance"],
    azurerm: ["azurerm_storage_account", "azurerm_resource_group", "azurerm_virtual_network"],
    gcp: ["google_bigquery_table", "google_logging_project_sink", "google_service_account"],
    k8s: ["kubernetes_integration", "kubernetes_service_account", "kubernetes_namespace"],
};
export {
    HISTORY_STATUSES,
    STATUS_ICONS,
    HISTORY_STATUSES_TITLES,
    HISTORY_DATA_MOCK,
    POLICIES_DATA_MOCK,
    BACKUP_PERIODS,
    REGIONS_BY_PROVIDER,
    ASSET_TYPES_BY_PROVIDER,
};