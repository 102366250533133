import LogRocket from "logrocket";
import { cutDomainFromEmail, getSubdomain } from "./helpers";
import { REB2B_KEY } from "../consts/config";

const FIREFLIES = ["gofirefly", "infralight", "firefly"];

export const initLogRocket = async (user = {}) => {
    const subDomain = getSubdomain();
    const isLocal = subDomain?.includes("localhost");
    const recordLogRocket = !isLocal && !FIREFLIES.includes(subDomain);
    const company = await cutDomainFromEmail(user.email);
    if (recordLogRocket) {
      LogRocket.init("duddjo/firefly", {
        network: {
          requestSanitizer: (request = {}) => {
            request.headers.Authorization = null;
            request.headers.authorization = null;
            if (request.body?.includes("token") || request.body?.includes("secret") ||
                request.body?.includes("password") || request.body?.includes("apiKey") || 
                request.body?.includes("apiToken") || request.body?.includes("apiSecret")) {
              request.body = null;
            }
            return request;
          },
          responseSanitizer: (response = {}) => {
            response.headers.Authorization = null;
            response.headers.authorization = null;
            if (response.body?.includes("token") || response.body?.includes("secret") || response.body?.includes("password") || response.body?.includes("apiKey")) {
              response.body = null;
            }
            return response;
          },
        }
      });
      LogRocket.identify(user.sub, {
        name: user.name,
        email: user.email,
        company,
      });
    }
};
export const setReb2bScript = () => {
  !function () {
    var reb2b = window.reb2b = window.reb2b || [];
    if (reb2b.invoked) return;
    reb2b.invoked = true;
    reb2b.methods = ["identify", "collect"];
    reb2b.factory = function (method) {
      return function () {
        var args = Array.prototype.slice.call(arguments);
        args.unshift(method); reb2b.push(args);
        return reb2b;
      };
    };
    for (var i = 0; i < reb2b.methods.length; i++) {
      var key = reb2b.methods[i];
      reb2b[key] = reb2b.factory(key);
    }
    reb2b.load = function (key) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.async = true;
      script.src = "https://s3-us-west-2.amazonaws.com/b2bjsstore/b/" + key + "/reb2b.js.gz";
      var first = document.getElementsByTagName("script")[0];
      first.parentNode.insertBefore(script, first);
    };
    reb2b.SNIPPET_VERSION = "1.0.1";
    reb2b.load(REB2B_KEY);
  }();
}