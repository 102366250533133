import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import AppPopoverSelect from '../../../shared/appPopoverSelect/appPopoverSelect';
import { capitalizeFirst } from '../../../utils/formatting';
import { ReactComponent as UserIcon } from "../../../Images/general_icons/user_icon.svg";
import { ReactComponent as LabelsIcon } from "../../../Images/general_icons/tags.svg";
import { ReactComponent as WorkflowIcon } from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { ReactComponent as RepoIcon } from "../../../Images/ci/guardrails/repo.svg";
import { ReactComponent as TagIcon } from '../../../Images/ci/guardrails/tag.svg';

import { ReactComponent as CostIcon } from '../../../Images/ci/guardrails/cost.svg';
import { ReactComponent as PolicyIcon } from '../../../Images/ci/guardrails/policy.svg';
import { ReactComponent as ResourceIcon } from '../../../Images/ci/guardrails/resources.svg';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';

import { getGuardrailsAggs, setGuardrailsFilters as setFilters } from '../../../redux/actions/workflowsGuardrailsActions';
import { sendEvent } from '../../../utils/amplitude';
import { guardrailsEvents } from '../../../utils/amplitudeEvents';

import { useTranslation } from 'react-i18next';

import './guradrailsFilters.scss';

const TYPE_ICONS = {
    cost: CostIcon,
    policy: PolicyIcon,
    resource: ResourceIcon,
    tag: TagIcon,
}
const GuardrailsFilters = ({ setSearchInput }) => {
    const { t } = useTranslation("ci-pipeline", {keyPrefix: "guardrails.filters"});
    const guardrailsAggs = useSelector((state) => state.ciWorkflowsReducer.guardrailsAggs) || {};
    const filters = useSelector((state) => state.ciWorkflowsReducer.guardrailsFilters) || {};
    const { createdBy = {}, type = {}, scope_workspaces_include = {}, scope_labels_include = {}, scope_repositories_include = {} } = guardrailsAggs;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchGuardrailsAggs();
    }, [])

    const allUsers = useMemo(() => Object.keys(createdBy) , [createdBy]);
    const allTypes = useMemo(() => Object.keys(type), [type]);
    const allWorkspaces = useMemo(() => Object.keys(scope_workspaces_include), [scope_workspaces_include]);
    const allLabels = useMemo(() => Object.keys(scope_labels_include), [scope_labels_include]);
    const allRepos = useMemo(() => Object.keys(scope_repositories_include), [scope_repositories_include]);

    const fetchGuardrailsAggs = async () => {
        await dispatch(getGuardrailsAggs());
    };
    
    const onFilterSelect = (change, isClear = false) => {
        const newFilters = isClear ? {} : { ...filters, ...change };
        dispatch(setFilters(newFilters));
        const eventPayload = {
            filterType: { createdBy: !isEmpty(newFilters?.createdBy), labels: !isEmpty(newFilters["scope.labels.include"]), repos: !isEmpty(newFilters["scope.repositories.include"]), types: !isEmpty(newFilters?.type), workspaces: !isEmpty(newFilters["scope.workspaces.include"]), clearAll: isClear },
            labels: newFilters["scope.labels.include"],
            repository: newFilters["scope.repositories.include"],
            workspace: newFilters["scope.workspaces.include"],
            types: newFilters?.type,
            createdBy: newFilters?.createdBy
        };
        sendEvent(guardrailsEvents.filterSelected, eventPayload);
        if (isClear) {
            setSearchInput("");
        }
    };

    return (
        <div className="row g10">
             <AppPopoverSelect
                    label={t("createdBy")}
                    options={allUsers.map((user) => ({
                    label: <span className="GuardrailsFilters__item row g5" key={user}>
                                <UserIcon className="GuardrailsFilters__icon users"/>
                                {capitalizeFirst(user)}
                            </span>,
                    value: user
                    }))}
                    checkedOptions={filters.createdBy || []}
                    onSubmit={(selected) => onFilterSelect({ createdBy: selected }) }
             />
                <AppPopoverSelect
                   label={t("type")}
                   options={allTypes.map((type) => {
                    const Icon = TYPE_ICONS[type];
                    return ({
                            label: <span className="GuardrailsFilters__item row g5" key={type}>
                                        {Icon && <Icon className="GuardrailsFilters__icon type"/>}
                                        {capitalizeFirst(type)}
                                    </span>,
                            value: type
                    })
                })}
                    checkedOptions={filters.type || []}
                    onSubmit={(selected) => onFilterSelect({ type: selected })}/>
                <AppPopoverSelect
                    label={t("label")}
                    options={allLabels.map((label) => ({
                        label: <span className="GuardrailsFilters__item row g5" key={label}>
                                    <LabelsIcon className="GuardrailsFilters__icon tags"/>
                                    {label}
                                </span>,
                        value: label
                    }))}
                    checkedOptions={filters["scope.labels.include"] || []}
                    onSubmit={(selected) => onFilterSelect({ "scope.labels.include": selected })}/>
                <AppPopoverSelect
                    label={t("repo")}
                    options={allRepos.map((repo) => ({
                        label: <span className="GuardrailsFilters__item row g5" key={repo}>
                                    <RepoIcon className="GuardrailsFilters__icon repo"/>
                                    {repo}
                                </span>,
                        value: repo
                    }))}
                    checkedOptions={filters["scope.repositories.include"] || []}
                    onSubmit={(selected) => onFilterSelect({ "scope.repositories.include": selected })}/>
                <AppPopoverSelect
                    label={t("workspace")}
                    options={allWorkspaces.map((workspace) => ({
                        label: <span className="GuardrailsFilters__item row g5" key={workspace}>
                                <WorkflowIcon className="GuardrailsFilters__icon workflows"/>
                                {workspace}
                            </span>,
                        value: workspace
                    }))}
                    checkedOptions={filters["scope.workspaces.include"] || []}
                    onSubmit={(selected) => onFilterSelect({ "scope.workspaces.include": selected })}/>
                    <span className="GuardrailsFilters__divider"/>
                    <Button type="link" className="GuardrailsFilters__clear-btn" onClick={() => onFilterSelect({}, true)}>{t("clearAll")}</Button>
        </div>
    )
};

export default GuardrailsFilters;