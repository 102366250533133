import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SavingsIcon }  from "../../../Images/dashboard/savings.svg";
import { ReactComponent as WorkflowsIcon }  from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { setInsightsFilters } from "../../../redux/actions/insightsV2Actions";
import { getAbbreviateNumber } from "../../../utils/helpers";
import { numberWithCommas } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";
import { getWorkflowsDashboardData } from "../../../redux/actions/dashboardActions";
import { getTotalCost } from "../../../redux/actions/classificationsActions";

import SpinnerLoader from "../../../shared/spinnerLoader/spinnerLoader";
import "./cloudWorkflows.scss";

const CloudWorkflows = () => {
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation("dashboard");

    const onlyProd = useSelector((state) => state.dashboardReducer.onlyProd);
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const totalCost = useSelector((state) => state.classificationsReducer.totalCost);
    const workflowsAssetAffected = useSelector((state) => state.dashboardReducer.workflowsAssetAffected);
    const insightsFilters = useSelector((state) => state.insightsV2Reducer.filters);
    
    const costInYear = totalCost * 12;
    const savingsTotalCost = costInYear == 0 ? 0 
    : costInYear > 10000 ? getAbbreviateNumber(costInYear, 1) 
    : numberWithCommas(costInYear.toFixed(0));
    
    const history = useHistory();
    const dispatch = useDispatch();
    
    useEffect(() => {
        fetchBlocksData();
    }, []);

    const fetchBlocksData = async () => {
        setLoading(true);
        await Promise.all([
            dispatch(getWorkflowsDashboardData()),
            dispatch(getTotalCost(onlyProd)),
        ]);
        setLoading(false);
    };  

    const handleTotalSavingsClick = () => {
        if (loading) return;
        const payload = { ...insightsFilters, frameworks: ["Cloud Waste"] };
        dispatch(setInsightsFilters(payload));
        history.push({ pathname: `/governance` });
    };

    const handleWorkflowsClick = () => {
        if (loading) return;
        history.push({ pathname: `/workflows` });
    };
    
    const isWorkflowAffected = workflowsAssetAffected > 0;
    return (
        <div className="CloudWorkflows col g10">
            <div className={`basic-card CloudWorkflows__card savings ${!themeDark ? "light" : ""} col g8 center`} onClick={handleTotalSavingsClick}>
                <SavingsIcon />
                <span className="CloudWorkflows__card-cost">
                    {loading ? <SpinnerLoader customStyle={{ color: "#63B773" ,fontSize: "32px" }} /> : ( 
                    <>
                        <span className="CloudWorkflows__card-cost-sum">${savingsTotalCost}</span>
                        <span className="CloudWorkflows__card-cost-slash">/yr</span>
                    </>)}
                </span>
                <span className="CloudWorkflows__card-title">Projected Savings</span>
            </div>
            <span className="muted">Workflows</span>
            <div className="basic-card CloudWorkflows__card workflows col g8 center" onClick={handleWorkflowsClick}>
                <WorkflowsIcon className="CloudWorkflows__icon"/>
                <span className="CloudWorkflows__card-cost workflows">
                   {loading ? <SpinnerLoader customStyle={{ color: "#AFA1D6" ,fontSize: "32px" }} /> 
                   : isWorkflowAffected && <span className="CloudWorkflows__card-cost-sum">{workflowsAssetAffected}</span>}
                </span>
                <div className="col g5 center">
                    <span className="CloudWorkflows__card-title">{isWorkflowAffected ? (
                        workflowsAssetAffected === 1 ? t("workflowsCube.titleOne") : t("workflowsCube.titleMany"))
                        : <div className="col g5">
                            Workflows
                            <span className="purple-flag">{t("workflowsCube.noData")}</span>
                          </div>
                    }</span>
                    {isWorkflowAffected && <span className="CloudWorkflows__card-cost workflows font-12">{t("workflowsCube.time")}</span>}
                </div>
            </div>
        </div>
    );
};

export default CloudWorkflows;