import React, { useCallback, useMemo, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { tableColumns } from "../iacStacksTable/config/iacStacksConfig";
import { useDispatch, useSelector } from "react-redux";

// components
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import FiltersRowBadges from "../filtersRowBadges/filtersRowBadges";
import ModuleBlocksTable from "../moduleBlocksTable/moduleBlocksTable";

import "../iacStacksTable/iacStacksTable.scss";
import { setModulesFilters } from "../../../redux/actions/iacStacksActions";
import { emptyModulesScreenFilters } from "../../../consts/iacStacks";

const TerraformStacksTable = ({
  handleSelectedRows,
  handlePageChange,
  handleTableSorterChange,
  tableData,
  fetchingData,
  totalObjects,
  selectedColumns,
  tablePageSize,
  onPageSizeChange,
  customHeight,
  movePagination,
  filersRowComponent,
  handleRemoveBadgeFilter,
  setStackViewAssets,
  setStackDrawerOpen,
  setStackRowData,
  screenFilters,
  setModuleViewAssets,
  setSelectedCategory,
  iacTableActions,
}) => {
  const apiRef = useRef();
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const dispatch = useDispatch();

  const getDetailPanelContent = useCallback(
    ({ row }) =>
      !isEmpty(row?.metadata?.terraformMetadata?.moduleBlocksTreeSlice) ? (
        <ModuleBlocksTable
          treeData={row?.metadata?.terraformMetadata?.moduleBlocksTreeSlice}
          setModuleViewAssets={setModuleViewAssets}
          stackProviders={row?.stackProviders}
          setSelectedCategory={setSelectedCategory}
          partiallyAppliedModules={row?.partiallyAppliedModules}
        />
      ) : null,
    []
  );

  const getDetailPanelHeight = useCallback(() => "auto", []);

  const handleRowClick = (rowId) => {
    const expandedRows = apiRef?.current?.getExpandedDetailPanels();
    // close row content
    if (expandedRows?.includes(rowId)) {
      const newExpandedRows = filter(expandedRows, (row) => row !== rowId);
      apiRef?.current?.setExpandedDetailPanels(newExpandedRows);
    } else {
      //open row content
      apiRef?.current?.setExpandedDetailPanels([...expandedRows, rowId]);
    }
  };

  const handleClickPartialModule = async (sourceModuleId) => {
    await dispatch(setModulesFilters({ ...emptyModulesScreenFilters, _id: [sourceModuleId] }));
    setSelectedCategory("modules");
  };

  return (
    <div className="IacStacksTable col">
      <div className="IacStacksTable__header row between">
      {/* <AppSwitch
          items={[
            { value: "stacks", text: "Stacks" },
            { value: "modules", text: "Modules" },
          ]}
          active={"modules"}
          onChange={()=>{}}
        /> */}
        {filersRowComponent && (
          <FiltersRowBadges
            screenFilters={screenFilters}
            handleRemoveFilter={handleRemoveBadgeFilter}
          />
        )}
        {iacTableActions && iacTableActions}
      </div>

      <TableWrapper
        // height={!isEmpty(customHeight) ? customHeight : "calc(100vh - 285px)"}
        rowKey="stackId"
        tableData={tableData}
        columns={
          isEmpty(selectedColumns)
            ? tableColumns(
                setStackViewAssets,
                setStackDrawerOpen,
                setStackRowData,
                themeDark,
                "terraform",
                apiRef,
                null,
                handleClickPartialModule,
              )
            : selectedColumns
        }
        loading={fetchingData}
        pageSize={tablePageSize}
        serverSide
        rowCount={totalObjects}
        selectedRowsArr={handleSelectedRows}
        handlePageChange={handlePageChange}
        handleSortChange={handleTableSorterChange}
        movePagination={movePagination}
        disableSelectionOnClick
        onPageSizeChange={onPageSizeChange}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        onRowClick={(row) => handleRowClick(row?.id)}
        hasCustomColumnMenu
      />
    </div>
  );
};

export default TerraformStacksTable;
