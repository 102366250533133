import React, { useEffect, useState } from "react";
import AppModal from "../../../shared/appModal/appModal";
import { Col, Form, Input, Row, Select,Radio, Button } from "antd";
import CheckedMultiSelect from "../../../shared/checkedMultiSelect/CheckedMultiSelect";
import RequestWrapper from "../../../utils/requestWrapper";
import { BACKEND_URL } from "../../../consts/config";
import AssetTypeSelect from "../../createInsight/assetTypeSelect/assetTypeSelect";
import { groupByCloud } from "../../../utils/formatting";
import ProviderSelect from "../../createInsight/providerSelect/providerSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClouds,
  getAllNotificationIntegrations,
} from "../../../redux/actions/globalAppActions";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { integrationIcons } from "../../../utils/icons";
import notificationIcon from "../../../Images/general_icons/insight-notification.svg";
import notifyMeIcon from "../../../Images/general_icons/Notifying_Icon.svg";
import destinationIcon from "../../../Images/general_icons/Destination_Icon.svg";
import criteriaIcon from "../../../Images/general_icons/Criteria_Icon.svg";
import {
  getAllSlackChannels,
  getPagerdutyServices,
  getWebexRooms,
  addBotToWebexRooms
} from "../../../redux/actions/integrationsActions";
import {
  create1Notification,
  deleteNotification,
  getAllNotifications,
  updateNotification,
} from "../../../redux/actions/notificationsActions";
import OwnerIndication from "../../inventory/ownerIndication/ownerIndication";
import { appToast } from "../../../shared/appToast/appToast";
import { formatWorkflowGuardrailsConfig, notificationTypesIndex, notificationValues } from "./notificationConfig";

import { useAuth0 } from "@auth0/auth0-react";
import { getAllProfiles } from "../../../redux/actions/profilesActions";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";
import WorkflowsSection from "./workflowsSection";
import GuardrailsScope from "./guardrailsScope";

import "./createNotification.scss";
const initialFilters = {
  providers: [],
  owners: [],
  locations: [],
  assetTypes: [],
  tags: [],
};

const CreateNotification2 = ({ visible, handleClose, defaultWizardOpen, initialValues, insightsLoading, insights, onSubmitCallback, skippable, deletable, onDeleteCallback, onCancelCallback }) => {
  const [form] = Form.useForm();
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [ownersData, setOwnersData] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [filtersLoading, setFiltersLoading] = useState(false);
  const [providersLoading, setProvidersLoading] = useState(false);
  const [notificationsLoading, setNotificationsLoading] = useState(false);
  const [slackChannelsLoading, setSlackChannelsLoading] = useState(false);
  const [webexRoomsLoading, setWebexRoomsLoading] = useState(false);
  const [pdServiceLoading, setPdServiceLoading] = useState(false);
  const [emailAddressesLoading, setEmailAddressesLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [selectedFilters, setSelectedFilters] = useState(initialFilters);
  const [notificationIntegrations, setNotificationIntegrations] = useState({});
  const [didMount, setDidMount] = useState(false);
  const [fetchCloudsMounted, setFetchCloudMounted] = useState(false);
  const [tagsOperation, setTagsOperation] = useState(0);
  const [emailSearchValue, setEmailSearchValue] = useState("")
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const providers = useSelector((state) => state.globalAppReducer.clouds);

  const slackChannels = useSelector(
    (state) => state.integrationsReducer.channels
  );
  const webexRooms = useSelector(
    (state) => state.integrationsReducer.rooms
  );
  const pdServices = useSelector(
    (state) => state.integrationsReducer.pdServices
  );
  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );
  const users = useSelector((state) => state.profilesReducer.profiles);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  
  const eventType = form.getFieldValue("eventType");
  const isInsightDetectSelected = eventType === notificationValues.insightDetected;
  const isWorkflowSelected = eventType === notificationValues.workflowRunLifecycle;
  const isGuardrailSelected = eventType === notificationValues.workspaceGuardrailViolation;

  useEffect(() => {
    if (visible) {
      if (!didMount) {
        (async () => {
          handleFetchNotificationIntegration();
          handleFetchCloudsProviders();
          setDidMount(true);
          if(_.isEmpty(users)){
           handleFetchEmailAddresses();
          }
        })();
      }

      if (!_.isEmpty(initialValues)) {
        form.resetFields();
        const customFilters = {
          assetTypes: initialValues.scope.assetTypes,
          locations: initialValues.scope.locations,
          owners: initialValues.scope.owners,
          providers: initialValues.scope.providers,
          tags: initialValues.scope.tags,
          tagsOp: initialValues.scope.tagsOp,
          ...(!_.isEmpty(initialValues.scope.insights) && {insights:initialValues.scope.insights[0]})
        };
        const payload = {
          eventType: initialValues.eventType,
          name:initialValues.displayName,
          isEnabled: initialValues.isEnabled,
          minimizedAlert: initialValues.minimizedAlert,
          notificationIntegrations:
            initialValues.notificationIntegrations?.[0].id,
          channels: initialValues.notificationIntegrations?.[0]?.channels ?? initialValues?.channels,
          pd_service: initialValues.notificationIntegrations?.[0]?.pd_service ?? initialValues?.channels,
          ...customFilters,
        };
        form.setFieldsValue(payload);
        setSelectedFilters(customFilters);
        setTagsOperation(initialValues.scope.tagsOp);
      } else {
        handleFetchAndSetCriteriaFilters();
      }
    }
  }, [visible]);

  useEffect(() => {
    if(_.isEmpty(initialValues) && insights){
      form.setFieldsValue({
        eventType:notificationValues.insightDetected,
        insights:insights
      });
    }
  }, [visible])

  useEffect(() => {
    if (didMount && fetchCloudsMounted && visible) {
      (async () => {
        setFiltersLoading(true);
        await handleFetchAndSetCriteriaFilters();
        setFiltersLoading(false);
      })();
    }
  }, [selectedFilters.providers]);

  useEffect(() => {
    if (didMount && visible) {
      const slackAppSelected = checkSlackAppSelected();
      const webexSelected = checkWebexSelected();
      if (slackAppSelected && _.isEmpty(slackChannels)) {
        handleFetchSlackChannels();
      } else if (checkPdServiceAdminSelected() && _.isEmpty(pdServices)) {
        handleFetchPdServices(formValues.notificationIntegrations);
      } else if(webexSelected && _.isEmpty(webexRooms)){
        handleWebexRooms();
      } else if(formValues.notificationIntegrations == 'email' && _.isEmpty(users)){
        handleFetchEmailAddresses();
      }
    }
  }, [formValues.notificationIntegrations, notificationIntegrations, visible]);

  useEffect(() => {
    if(didMount){
      form.setFieldsValue({channels:[]});
    }
  }, [formValues.notificationIntegrations])

  
  const handleValidateFormValuesWithCriteria = (newFilters) => {
    const filteredValues = {};
    const values = form.getFieldsValue();
    for (const key in values) {
      if (Array.isArray(values[key]) && Array.isArray(newFilters[key])) {
        const commonValues = _.intersection(values[key], newFilters[key]);
        filteredValues[key] = commonValues;
      }
    }
    delete filteredValues.providers;
    form.setFieldsValue(filteredValues);
  };

  const getPDPublicKeyNoAdminSelected = selectedInteg => {
    if(!selectedInteg) {
      return [];
    }
    // find the full integration obj and get its publicKey value
    const pdIntegObj = _.find(notificationIntegrations?.pagerduty, i=> i?.id === selectedInteg);
    const output = _.isEmpty(pdIntegObj) ? [] : [pdIntegObj?.publicKey];
    return output;
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields();

      setSubmitLoading(true);
      const values = form.getFieldsValue();
      const payload = {
        eventType: values.eventType,
        notificationIntegrations: [values.notificationIntegrations],
        isEnabled: true,
        name:user.name,
        displayName:values.name,
        scope: {
          insights: values.insights? [values.insights] : [],
          providers: getMappedProviders(values.providers ?? []) ,
          assetTypes: values.assetTypes ?? [],
          tags: values.tags ?? [],
          locations: values.locations ?? [],
          owners: values.owners ?? [],
          tagsOp: tagsOperation,
        },
        description: "",
        channels: values.channels ?? [],
        pd_service: values.pd_service ?? null,
        integrationKey: values.pd_service ? initialValues?.notificationIntegrations[0]?.integrationKey : getPDPublicKeyNoAdminSelected(values.notificationIntegrations),
      };
      formatWorkflowGuardrailsConfig(payload, values, isWorkflowSelected, isGuardrailSelected);
      let req;
      let successMessage;
      if (_.isEmpty(initialValues) || defaultWizardOpen) {
        const webexSelected = checkWebexSelected()
        if(webexSelected){
          req = await dispatch(addBotToWebexRooms(payload.notificationIntegrations[0], payload.channels));
          if(!req?.ok){
            appToast("error", "Notification", "Failed adding bot to webex rooms");
            setSubmitLoading(false);
            return
          }
        }
        req = await dispatch(create1Notification(payload));
        successMessage = "Notification has been created successfully";
      } else {
        req = await dispatch(updateNotification(initialValues?._id, payload));
        successMessage = "Notification has been updated successfully";
      }
      if (req?.ok) {
        appToast("success", "Notification", successMessage);
        dispatch(getAllNotifications());
        handleClose();
        handleReset();
        if(onSubmitCallback){
          onSubmitCallback()
        }
      } else {
        appToast("error", "Notification", "Something went wrong");
      }
      setSubmitLoading(false);
    } catch (error) {
      appToast("info", "Notification", "Notification wizard is not valid");
    }
  };
 
  const handleFetchAndSetCriteriaFilters = async () => {
    setFiltersLoading(true);
    const requestWrapper = new RequestWrapper();
    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/notifications/aggs`,
        "POST",
        { 
          filters: {
            providerTypes: findKeyNames(selectedFilters.providers || []),
          } 
        }
      );
      const data = (await req.json()) || {};
      const newFilters = {
        providers: _.map(data.providers, "key"),
        tags: _.map(data.tags, "key"),
        locations: _.map(data.locations, "key"),
        owners: _.map(data.owners, "key"),
        assetTypes: _.map(data.assetTypes, "key"),
      };
      setFilters(newFilters);
      handleValidateFormValuesWithCriteria(newFilters);
      setOwnersData(data.owners);
    } catch (err) {
      return appToast("error", "Notification", "Something went wrong");
    }
    setFiltersLoading(false);
  };
  const handleFetchCloudsProviders = async () => {
    if (_.isEmpty(providers)) {
      setProvidersLoading(true);
      await dispatch(getAllClouds());
      setProvidersLoading(false);
    }
    setFetchCloudMounted(true);
  };
  const handleFetchNotificationIntegration = async () => {
    setNotificationsLoading(true);
    const notifications = await dispatch(getAllNotificationIntegrations());
    setNotificationIntegrations(notifications);
    setNotificationsLoading(false);
  };

  const handleFetchSlackChannels = async () => {
    const slackAppId = notificationIntegrations?.slack?.find(
      (item) => item?.service === "slackApp"
    )?.id;
    if (slackAppId) {
      setSlackChannelsLoading(true);
      await dispatch(getAllSlackChannels(slackAppId));
      setSlackChannelsLoading(false);
    }
  };

  const handleWebexRooms = async () => {
    const webexIntegrationId = notificationIntegrations?.webex?.find(
      (item) => item?.service === "webex"
    )?.id;
    if (webexIntegrationId) {
      setWebexRoomsLoading(true);
      await dispatch(getWebexRooms(webexIntegrationId));
      setWebexRoomsLoading(false);
    }
  };

  const handleFetchPdServices = async (id) => {
    setPdServiceLoading(true);
    await dispatch(getPagerdutyServices(id));
    setPdServiceLoading(false);
  };

  const handleFetchEmailAddresses = async () => {
    setEmailAddressesLoading(true);
    await dispatch(getAllProfiles({ pageNum:1, perPage:100 }));
    setEmailAddressesLoading(false);
  };
  const handleDeleteNotification = async () => {
    try {
      if(initialValues?._id){
        setDeleteLoading(true);
        await dispatch(deleteNotification(initialValues._id));
        handleClose();
        handleReset()
        if(onDeleteCallback){
          onDeleteCallback()
        }
      }
    } catch (error) {
      appToast("error", "Failed to delete notification");
    }
    setDeleteLoading(false);
    setConfirmDeleteOpen(false);
  };

  const getEventTypes = () => {
    return notificationTypesIndex.map((data = {}) => ({
      title: (
        <span className="CheckedMultiSelect__dropdown-item" key={data.id}>
          {data.icon && (
           typeof data.icon == "string" ? <FontAwesomeIcon icon={data.icon} className={data.value} /> : data.icon
          )}
          <span>{data.name}</span>
        </span>
      ),
      value: data.value,
      disabled: data.disabled,
    }));
  };
  const mapTreeData = (type) => {
    const isTypeOwners = type == "owners";
    // handle sort by selected items
    const clonedTreeData = _.cloneDeep(filters[type]);

    let sortedBySelectedTreeData = clonedTreeData;
  
    if(form.getFieldValue(type)){
      sortedBySelectedTreeData = _.sortBy(clonedTreeData, item => !form.getFieldValue(type).includes(item));
    }  
   
    return sortedBySelectedTreeData.map((value, index) => ({
      title: (
        <span
          className={`CheckedMultiSelect__dropdown-item ${
            type == "tags" && "AppTag minimal"
          }`}
          key={index}>
          {isTypeOwners && (
            <OwnerIndication data={ownersData?.find(o => o.key == value)?.hits?.ownerData} />
          )}
          <span style={{ marginLeft: isTypeOwners ? 10 : 0 }}>{value}</span>
        </span>
      ),
      value: value,
    }));
  };
  const getMappedProviders = (selectedProviders) => {
    let mappedProviders = [];
    selectedProviders.forEach(provider => {
      if(provider.includes("_all") && _.has(providers, provider.replace(/_all$/, ""))){
        mappedProviders = mappedProviders.concat(providers[provider.replace(/_all$/, "")]?.map(p => p.id));
      }else{
        mappedProviders.push(provider);
      }
    });
    return mappedProviders;
  };

  const renderIntegrations = () => {
    const arrays = _.values(notificationIntegrations || {});
    const flatten = _.flatten(arrays);
    flatten.push({
      id:'emailAddresses',
      service:'email',
      name:'Email Address'
    });
    return _.map(flatten || [], (item) => {
      return (
        <Select.Option key={item?.id} value={item?.id}>
          <span className="InsightNotification__select row">
            <img
              src={integrationIcons[item?.service]}
              alt="integration icon"
              className={item?.service}
              width={16}
              style={{ marginRight: 12 }}
            />
            {item.name}
          </span>
        </Select.Option>
      );
    });
  };


  const checkSlackAppSelected = () =>
  !_.isEmpty(
      notificationIntegrations?.slack?.find(
        (item) =>
          item?.service === "slackApp" &&
          form.getFieldValue("notificationIntegrations") == item?.id
      )
    );

  const checkWebexSelected = () =>
    !_.isEmpty(
        notificationIntegrations?.webex?.find(
          (item) =>
            item?.service === "webex" &&
            form.getFieldValue("notificationIntegrations") == item?.id
        )
      );

  const onChangeTagsOperation = (event) => setTagsOperation(event.target.value);
    
  const renderTagsLabel = () => {
    return (
      <div className="tagsLabelWrapper">
        <span> Tags (optional)</span>

        <Radio.Group onChange={onChangeTagsOperation} value={tagsOperation}>
          <Radio value={0} key="0">Or</Radio>
          <Radio value={1} key="1">And</Radio>
        </Radio.Group>
      </div>
    )
  }

  const renderInsights = () => {
    return _.map(classifications || [], (item) => (
      <Select.Option
        value={item.id}
        key={item.id}
        className="classificationOptions">
        {item.name}
      </Select.Option>
    ));
  };
  const renderSlackChannels = () => {
    if (!slackChannelsLoading && !_.isEmpty(slackChannels)) {
      return _.map(slackChannels || [], (channel) => (
        <Select.Option value={channel.id} key={channel.id}>
          <span>
            {`# `}
            {channel.name}
          </span>
        </Select.Option>
      ));
    }
  };

  const renderWebexChannels = () => {
    if (!webexRoomsLoading && !_.isEmpty(webexRooms)) {
      return _.map(webexRooms || [], (room) => (
        <Select.Option value={room.id} key={room.id}>
          <span>
            {`# `}
            {room.title}
          </span>
        </Select.Option>
      ));
    }
  };

  const checkPdServiceAdminSelected = () =>
    !_.isEmpty(
      notificationIntegrations?.pagerduty?.find(
        (item) =>
          item?.service === "pagerduty" &&
          form.getFieldValue("notificationIntegrations") == item?.id &&
          _.isEmpty(item.publicKey)
      )
    );

  const renderPdServices = () => {
    if (!pdServiceLoading && !_.isEmpty(pdServices)) {
      return _.map(pdServices || [], (service) => (
        <Select.Option value={service?.id} key={service?.id}>
          <span>{service?.name}</span>
        </Select.Option>
      ));
    }
  };

  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    return _.isString(email) && emailRegex.test(email);
  }

  const renderEmailAddresses = () => {

    if (!emailAddressesLoading && !_.isEmpty(users)) {
      const usersClone = _.clone(users);
      if(isValidEmail(emailSearchValue)){
        usersClone.push({
          email:emailSearchValue,
          user_id:emailSearchValue
        })
      }
      return _.map(usersClone || [], (user) => (
        <Select.Option value={user.email} key={user.user_id} onClick={() => setEmailSearchValue("")}>
          <span>{user.email}</span>
        </Select.Option>
      ));
    }
  };

  const handleReset = () => {
    form.resetFields();
    setSelectedFilters(initialFilters);
    setFormValues({});
  };

  function findKeyNames(ids) {
    var keyNames = [];
    ids.forEach(id => {
      if(id.includes("_all")){
        keyNames.push(_.trimEnd(id,"_all"));
      }else{
        keyNames.push(_.findKey(providers, SourcesList => SourcesList.some(source => source.id == id)))
      }
    });
    return keyNames;
  }
  const handleEmailsInputKeyDown = (e) => {
    if (e.key == ' ' && isValidEmail(emailSearchValue)) {
      e.preventDefault();
      let selectedEmails = form.getFieldValue('channels') ?? [];
      selectedEmails = selectedEmails.concat(emailSearchValue);
      form.setFieldsValue({ channels: selectedEmails });
      setFormValues({...formValues,channels:selectedEmails})
      setEmailSearchValue('');
    }
  };

  return (
    <>
      <AppModal
        visible={visible}
        handleClose={() => {
          handleClose();
          handleReset();
          if(onCancelCallback){
            onCancelCallback();
          }
        }}
        title={`${
          _.isEmpty(initialValues) || defaultWizardOpen ? "Create New" : "Update"
        } Notification`}
        width={1150}
        submitBtnText={_.isEmpty(initialValues) || defaultWizardOpen ? "Create" : "Update"}
        bodyClassName="NotificationGranularity__model-body"
        iconSrc={notificationIcon}
        loadingSubmit={submitLoading}
        submitBtnDisabled={submitLoading || filtersLoading || providersLoading}
        cancelBtnText={ skippable ? "Skip" : "Cancel" }
        handleOnSubmit={handleSubmit}
        footerExtraItemOnRight
        customFoter={deletable && initialValues && !defaultWizardOpen && !isViewer ? (
          <Button
            type="primary"
            danger
            onClick={() => setConfirmDeleteOpen(true)}
          >
            Delete
          </Button>
        ) : null}
        >
        <div className="NotificationGranularity">
          <Form
            layout="vertical"
            form={form}
            onValuesChange={(values) => {
              setFormValues({...formValues, ...values});
            }}>
            <section>
              <div className="NotificationGranularity__section-title">
                <img src={notifyMeIcon} alt="Bell icon" />
                <span>Notification</span>
              </div>
              <Row justify="space-between" gutter={[0, 24]}>
                <Col span={11}>
                  <Form.Item
                    label="Event Type"
                    name="eventType"
                    rules={[
                      {
                        required: true,
                        message: "Event Type is required",
                      },
                    ]}>
                    <CheckedMultiSelect
                      disabled={insights != null}
                      multiple={false}
                      value={form.getFieldValue("eventType")}
                      onChange={(value) =>
                        form.setFieldsValue({ eventType: value })
                      }
                      placeholder="Select event type"
                      treeData={getEventTypes()}
                    />
                  </Form.Item>
                </Col>
                <Col span={11}>
                  <Form.Item
                    label="Name (Optional)"
                    name="name"
                   >
                    <Input />
                  </Form.Item>
                </Col>
                {isInsightDetectSelected && (
                  <Col span={11}>
                    <Form.Item
                      label="Policy"
                      name="insights"
                      rules={[
                        {
                          required: true,
                          message: "Policy is required",
                        },
                      ]}>
                      <Select
                        placeholder="Select policy"
                        loading={insightsLoading}
                        showArrow
                        showSearch
                        filterOption={(input, option) =>
                          option?.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        >
                        {renderInsights()}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </section>
            <hr />
            {!isInsightDetectSelected &&
              <section>
                <div className="NotificationGranularity__section-title">
                  <img src={criteriaIcon} alt="Filter icon" />
                  <span>Criteria</span>
                </div>
                {isWorkflowSelected ? <WorkflowsSection form={form} initialValues={initialValues} defaultWizardOpen={defaultWizardOpen}/>
                : isGuardrailSelected ? <GuardrailsScope form={form} initialValues={initialValues}/>
                : <>
                 <Row style={{ marginBottom: 24 }}>
                  <Col span={11}>
                    <Form.Item
                      label="Data source"
                      name="providers"
                      rules={[
                        {
                          required: true,
                          message: "Data source is required",
                          type: "array",
                          min: 1,
                        },
                      ]}>
                      <ProviderSelect
                        getPopupContainer={(trigger) => trigger.parentNode}
                        readOnly={filtersLoading || providersLoading}
                        maxTagCount={2}
                        loading={providersLoading || filtersLoading}
                        selected={form.getFieldValue("providers")}
                        onChange={(providers) => {
                          form.setFieldsValue({ providers });
                          setSelectedFilters({ ...selectedFilters, providers });
                        }}
                        providers={providers}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="space-between" gutter={[0, 24]}>
                  <Col span={11}>
                    <Form.Item
                      label="Asset type"
                      requiredMark="optional"
                      name="assetTypes">
                      <AssetTypeSelect
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showCheckedStrategy="SHOW_CHILD"
                        readOnly={filtersLoading || providersLoading}
                        maxTagCount={2}
                        loading={filtersLoading}
                        types={groupByCloud(filters.assetTypes, form.getFieldValue('assetTypes'))}
                        onChange={(assetTypes) => {
                          form.setFieldsValue({ assetTypes });
                          setSelectedFilters({ ...selectedFilters, assetTypes });
                        }}
                        selected={form.getFieldValue("assetTypes")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label="Owner" requiredMark="optional" name="owners">
                      <CheckedMultiSelect
                        disabled={filtersLoading || providersLoading}
                        maxTagCount={2}
                        loading={filtersLoading}
                        placeholder="Select owner"
                        treeData={mapTreeData("owners")}
                        onChange={(owners) => {
                          form.setFieldsValue({ owners });
                          setSelectedFilters({ ...selectedFilters, owners });
                        }}
                        value={form.getFieldValue("owners")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
      
                    <Form.Item label={renderTagsLabel()} name="tags">
                      <CheckedMultiSelect
                        disabled={filtersLoading || providersLoading}
                        maxTagCount={2}
                        loading={filtersLoading}
                        placeholder="Select tags"
                        treeData={mapTreeData("tags")}
                        onChange={(tags) => {
                          form.setFieldsValue({ tags });
                          setSelectedFilters({ ...selectedFilters, tags });
                        }}
                        value={form.getFieldValue("tags")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item
                      label="Location"
                      requiredMark="optional"
                      name="locations">
                      <CheckedMultiSelect
                        disabled={filtersLoading || providersLoading}
                        maxTagCount={2}
                        loading={filtersLoading}
                        placeholder="Select location"
                        treeData={mapTreeData("locations")}
                        onChange={(locations) => {
                          form.setFieldsValue({ locations });
                          setSelectedFilters({ ...selectedFilters, locations });
                        }}
                        value={form.getFieldValue("locations")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                </>}
              </section>
            }
            {!isInsightDetectSelected && <hr />}
            <section>
              <div className="NotificationGranularity__section-title">
                <img src={destinationIcon} alt="Laptop icon" />
                <span>Destination</span>
              </div>
              <Row justify="space-between">
                <Col span={11}>
                  <Form.Item
                    label="Destination"
                    name="notificationIntegrations"
                    rules={[
                      {
                        required: true,
                        message: "Selecting a destination is required",
                      },
                    ]}>
                    <Select
                      placeholder="Select Destination"
                      loading={notificationsLoading}
                      disabled={pdServiceLoading || slackChannelsLoading || webexRoomsLoading}
                      showArrow
                      showSearch
                      getPopupContainer={(trigger) => trigger.parentNode}
                      value={form.getFieldValue("notificationIntegrations")}
                      filterOption={(input, option) =>
                        option?.children?.props?.children[1]
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }>
                      {renderIntegrations()}
                    </Select>
                  </Form.Item>
                </Col>
                {didMount && checkSlackAppSelected() && (
                  <Col span={11}>
                    <Form.Item
                      label="Channels"
                      name="channels"
                      rules={[
                        {
                          required: true,
                          message: "Selecting a channel is required",
                        },
                      ]}
                      style={{ marginBottom: "1.2rem", flexDirection: "column" }}>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        maxTagCount={2}
                        disabled={slackChannelsLoading}
                        placeholder="Select channels*"
                        loading={slackChannelsLoading}
                        mode="multiple"
                        showArrow
                        showSearch
                        filterOption={(input, option) =>
                          option?.children?.props?.children[1]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {renderSlackChannels()}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {didMount && checkWebexSelected() && (
                  <Col span={11}>
                    <Form.Item
                      label="Rooms"
                      name="channels"
                      rules={[
                        {
                          required: true,
                          message: "Selecting a room is required",
                        },
                      ]}
                      style={{ marginBottom: "1.2rem", flexDirection: "column" }}>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        maxTagCount={2}
                        disabled={webexRoomsLoading}
                        placeholder="Select rooms*"
                        loading={webexRoomsLoading}
                        mode="multiple"
                        showArrow
                        showSearch
                        filterOption={(input, option) =>
                          option?.children?.props?.children[1]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {renderWebexChannels()}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {didMount && checkPdServiceAdminSelected() && (
                  <Col span={11}>
                    <Form.Item
                      label="Pagerduty"
                      name="pd_service"
                      rules={[
                        {
                          required: true,
                          message: "selecting a service is required",
                        },
                      ]}
                      style={{ marginBottom: "1.2rem", flexDirection: "column" }}>
                      <Select
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabled={pdServiceLoading}
                        placeholder="Select Pagerduty Service"
                        loading={pdServiceLoading}
                        showArrow
                        showSearch
                        filterOption={(input, option) =>
                          option?.children?.props?.children[1]
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }>
                        {renderPdServices()}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                {didMount && form.getFieldValue('notificationIntegrations') == 'emailAddresses' && (
                  <Col span={11}>
                    <Form.Item
                      label="Email Addresses"
                      name="channels"
                      rules={[
                        {
                          required: true,
                          message: "Selecting an email address is required",
                        },
                      ]}
                      style={{ marginBottom: "1.2rem", flexDirection: "column" }}>
                      <Select
                        mode="multiple"
                        showArrow
                        showSearch
                        getPopupContainer={(trigger) => trigger.parentNode}
                        disabled={emailAddressesLoading}
                        searchValue={emailSearchValue}
                        onSearch={setEmailSearchValue}
                        onSelect={_ => setEmailSearchValue("")}
                        placeholder="Select email addresses"
                        loading={emailAddressesLoading}
                        onInputKeyDown={handleEmailsInputKeyDown}
                       >
                        {renderEmailAddresses()}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
              </Row>
            </section>
          </Form>
        </div>
      </AppModal>
      {deletable && initialValues && 
        <ConfirmationModal
          visible={confirmDeleteOpen}
          handleClose={() => setConfirmDeleteOpen(false)}
          title={'Delete notification'}
          description={'Delete this notification? This action cannot be undone.'}
          loadingConfirm={deleteLoading}
          onConfirm={handleDeleteNotification}
        />
      }
    </>
  );
};

export default CreateNotification2;
