import React, { useCallback } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import ActionIcon from "../../../../shared/actionIcon/actionIcon";
import CreateAccessKey from "../../../users/accessKeys/createAccessKey";
import { CI_TOOLS } from "./ci-tools-options";

const AZURE_DEVOPS_EXTENSION = "https://marketplace.visualstudio.com/items?itemName=ms-devlabs.custom-terraform-tasks";

const AuthenticationSection = ({ ciTool, t, handleCreateKeyPair, handleCloseCreateKeyPair, keyPair, createModalOpen, keyPairLoading }) => {
    if (!ciTool) return null;
    
    const renderStep2 = useCallback(() => {
        return (
        <ol type="a" className="WorkflowsSteps__integrate-orderedlist sub">
            {ciTool === CI_TOOLS.github ?
             <>
                <li key="a">
                    <div className="form-label">
                        {t("general.instructions.2a-github-actions")}
                        <span className="row g5"><span className="bold"> Name</span> field, put <span
                            className="purple-flag font-12 row g5">FIREFLY_ACCESS_KEY<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="FIREFLY_ACCESS_KEY"/></span>{" "}
                            and in the <span className="bold">Secret</span> field, paste the Access key value.</span>
                    </div>
                </li>
                <li key="b">
                    <div className="form-label">
                        {t("general.instructions.2b-github-actions")}
                        <span className="row g5 flex-wrap"><span className="bold"> New repository secret</span> in the <span
                            className="bold">Name</span> field, put <span
                            className="purple-flag font-12 row g5">FIREFLY_SECRET_KEY<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="FIREFLY_SECRET_KEY"/></span>{" "}
                            and in the <span className="bold">Secret</span> field, paste the Secret key value.</span>
                    </div>
                </li>
            </> 
            : ciTool === CI_TOOLS.azure ?  
            <>
                <li key="a">
                    <div className="row g5 flex-wrap">
                        <span className="form-label">{t("general.instructions.2a-azure-pipelines")}</span>
                        <span className="purple-flag font-12 row g5 mgBtm5"> fireflySecrets<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="fireflySecrets"/></span>.
                    </div>
                </li>
                <li key="b">
                    <div className="form-label">
                        <span className="row g5 flex-wrap">Add a new variable named<span className="purple-flag font-12 row g5">FIREFLY_ACCESS_KEY<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="FIREFLY_ACCESS_KEY"/></span>{" "}
                         and paste the Access key value into the <span className="bold">value</span> field.</span>
                    </div>
                </li>
                <li key="c">
                    <div className="form-label">
                        <span className="row g5 flex-wrap">Add a new variable named<span className="purple-flag font-12 row g5">FIREFLY_SECRET_KEY<ActionIcon action="copy" customActionClassName="WorkflowsSteps-copyIcon" stringToAction="FIREFLY_SECRET_KEY"/></span>{" "}
                         and paste the Access key value into the <span className="bold">value</span> field.</span>
                    </div>
                </li>
                <li key="d">
                    <div className="form-label">
                        <span>Save your changes.</span>
                    </div>
                </li>
            </> 
            : null}
        </ol>)
    }, [ciTool]);

    const renderStep3 = useCallback(() => {
        if (ciTool === CI_TOOLS.azure) {
            return (<li key="3">
                <div className="row g5">
                    <span className="form-label">{t("general.instructions.3-azure-pipelines")}</span>
                    <a href={AZURE_DEVOPS_EXTENSION} target="_blank" rel="noreferrer" className="mgBtm5">here</a>
                </div>
            </li>)
        }
    }, [ciTool]);

    const renderStep4 = useCallback(() => {
        if (ciTool === CI_TOOLS.azure) {
            return (<li key="4">
                <div className="col g5">
                    <span className="form-label">{t("general.instructions.4-azure-pipelines")}</span>
                    <span className="form-label">{t("general.instructions.4a-azure-pipelines")}</span>
                </div>
            </li>)
        }
    }, [ciTool]);

    return (
    <div className="WorkflowsSteps__general-authenticate">
        <span className="wb-text">{t("general.authenticateTitle")}</span>
        <ol className="WorkflowsSteps__integrate-orderedlist general">
            <li key="1">
                <div className="row g5"><span
                    className="form-label">{t("integrateStep.procedure.1a")}{" "}</span>
                    <div className="row g5 purple-text WorkflowsSteps__integrate-keyPair"
                        onClick={handleCreateKeyPair}>
                        <FontAwesomeIcon icon={faKey}/>
                        {keyPairLoading ? <Spin indicator={<LoadingOutlined/>}/>
                            : <span className="underline">{t("integrateStep.procedure.1b")}</span>}
                    </div>
                </div>
                <CreateAccessKey
                visible={createModalOpen}
                handleClose={handleCloseCreateKeyPair}
                keyPair={keyPair}
                showAsLine
            />
            </li>
            <li key="2">
                <span className="form-label">{t(`general.instructions.2-${ciTool}`)} { ciTool === CI_TOOLS.azure ? <span><span className="bold"> fireflySecrets</span> variable group in your Pipeline Library: </span> : null }</span>
                {renderStep2()}
            </li>
            {renderStep3()}
            {renderStep4()}
        </ol>
    </div>)
};

export default AuthenticationSection;