import React from "react";
import _ from "lodash";
import { Checkbox, Radio, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { numberWithCommas } from "../../../utils/formatting";

const FilterContent = ({
  name,
  setClickedCheckbox,
  icon,
  count,
  isProdFlag,
  element,
  formattedName,
  checkedList,
  noAnimation,
  radioMode,
  provider,
  hideText,
  tooltip="",
}) => {

  if (radioMode) {
    return (
      <Radio
        value={name}
        onChange={(e) => setClickedCheckbox(e.target.value)}
        className={`InventorySingelFilter__types-item ${!_.isEmpty(checkedList) && checkedList[0] === name && "active"}`}
      >
        <div
          className={`InventorySingelFilter__types-item-wrapper`}
        >
          <div className="InventorySingelFilter__types-item-wrapper-inner">
            <div className="InventorySingelFilter__types-item-wrapper-inner-content row between">
              {!_.isEmpty(icon) && <img src={icon}/>}
              {!_.isEmpty(element) && (
                <span className="element">{element}</span>
              )}
              <span className="InventorySingelFilter__types-item-wrapper-inner-text">{`${formattedName}`}</span>
              {count && (
                <span className="InventorySingelFilter__types-item-wrapper-inner-count">{`${numberWithCommas(count)}`}</span>
              )}
            </div>
          </div>
        </div>
      </Radio>
    );
  }

  return (
    <Checkbox
      value={name}
      onChange={(e) => setClickedCheckbox(e.target.value)}
      className={`InventorySingelFilter__types-item row ${!_.isEmpty(checkedList) && checkedList?.includes(name) && "active"}`}
    >
      <div
        className={`InventorySingelFilter__types-item-wrapper`}
      >
        <div className="InventorySingelFilter__types-item-wrapper-inner">
          <div className="InventorySingelFilter__types-item-wrapper-inner-content row between">
            {!_.isEmpty(icon) && <img src={icon} className={provider} />}
            {!_.isEmpty(element) && <span className="element">{element}</span>}
            {!hideText && <Tooltip title={tooltip}><span className="InventorySingelFilter__types-item-wrapper-inner-text">{`${formattedName}`}</span></Tooltip>}
            {count && (
              <span className="InventorySingelFilter__types-item-wrapper-inner-count">{`${numberWithCommas(count)}`}</span>
            )}
            {isProdFlag && (
              <span className="InventorySingelFilter__types-item-wrapper-inner-count">
                <FontAwesomeIcon icon={faFlag} style={{ opacity: '.7', marginLeft: '1px' }} />
              </span>
            )}
          </div>
        </div>
      </div>
    </Checkbox>
  );
};

export default FilterContent;
