import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_ASSET_EVENTS = 'GET_ASSET_EVENTS'
export const RESET_ASSET_EVENTS = 'RESET_ASSET_EVENTS'
export const GET_ASSET_EVENTS_COUNT = 'GET_ASSET_EVENTS_COUNT'
export const GET_ASSET_EVENTS_GRAPH_DATE = 'GET_ASSET_EVENTS_GRAPH_DATE'
export const GET_ASSET_EVENTS_AGGS = 'GET_ASSET_EVENTS_AGGS'

const getAssetEvents = (
    integrationId,
    region,
    arn = '',
    resourceId,
    resourceName,
    assetType,
    service,
    pageNumber,
    pageSize,
    querySearch,
    sorter,
    namesFilter,
    fetchOnlyFilterOptions,
    provider,
) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const assetEventsUrl = `${BACKEND_URL}/asset-events${fetchOnlyFilterOptions ? '/base-aggs' : ''}`;
        const req = await requestWrapper.sendRequest(
            assetEventsUrl,
            "POST",
            {
                assetData: {
                    integrationId,
                    region,
                    arn,
                    resourceId,
                    resourceName,
                    assetType,
                    service,
                    provider,
                },
                pageNumber,
                pageSize,
                querySearch,
                sorter,
                namesFilter,
                shouldFetchFilterOptions: fetchOnlyFilterOptions,
            },
        );

        let data = [];
        if (req?.ok) {
            data = await req.json();
        }
        return dispatch({
            type: fetchOnlyFilterOptions ? GET_ASSET_EVENTS_AGGS : GET_ASSET_EVENTS,
            payload: data,
        });
    });
}

const getAssetEventsCount = ({
    integrationId,
    region,
    arn = '',
    resourceId,
    name: resourceName,
    assetType,
    service,
    provider
}) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/asset-events/count`,
            "POST",
            {
                assetData: {
                    integrationId,
                    region,
                    arn,
                    resourceId,
                    resourceName,
                    assetType,
                    service,
                    provider,
                },
            },
        );

        let data = [];
        if (req?.ok) {
            data = await req.json();
        }
        return dispatch({
            type: GET_ASSET_EVENTS_COUNT,
            payload: data,
        });
    });
}

const getAssetEventsGraphData = (assetData, byTime) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/asset-events/graph`,
            "POST",
            {assetData, byTime}
        );

        let data = [];
        if (req?.ok) {
            data = await req.json()
        }
        return dispatch({
            type: GET_ASSET_EVENTS_GRAPH_DATE,
            payload: { byTime, ...data },
        });
    })
}
const resetAssetEvents = () => (action(async (dispatch) => {
    dispatch({
        type: RESET_ASSET_EVENTS,
    });
}))

export {
    getAssetEvents,
    resetAssetEvents,
    getAssetEventsCount,
    getAssetEventsGraphData,
}