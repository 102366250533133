import _ from "lodash";
import React from "react";
import { Tooltip } from "antd";

const AssetClickableChips = ({  chips, onClickChip, isObject = false, tooltip=null }) => {

  const renderChip = (key, value) => {
    return (
        <span
            className="tag-flag-big-clickable fs-12"
            key={key}
            onClick={() => onClickChip(key)}
        >
            {value}
        </span>
    )
  }

  const renderChips = () => {
      if (!_.isEmpty(chips) && _.isArray(chips)) {
          return (
              <div className="row" style={{ gap: "10px", flexWrap: "wrap" }}>
                  {_.map(chips || [], (chip) => {
                      if (isObject) {
                            if(tooltip){
                                return (
                                    <Tooltip title={tooltip}>
                                        {renderChip(chip.key, chip.value)}
                                    </Tooltip>
                                )
                            }else{
                                return renderChip(chip.key, chip.value)
                            }
                      }else{
                          return renderChip(chip, chip)
                      }
                  })}
              </div>
          );
      }
      return null;
  };

  return renderChips();
};

export default AssetClickableChips;
