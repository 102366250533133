import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AppBtn from "../../../shared/appBtn/appBtn";
import HeaderSearchBox from "../../../shared/headerSearchBox/headerSearchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setInsightsFilters } from "../../../redux/actions/insightsV2Actions";
import InsightFilterBadges from "./insightFilterBadges/insightFilterBadges";
import { sendEvent } from "../../../utils/amplitude";
import { governanceEvents } from "../../../utils/amplitudeEvents";
import "./insightsHeader.scss";

const InsightsHeader = ({ allLabels }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("insights");
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const insightsFilters = useSelector((state) => state.insightsV2Reducer.filters);

  const handleAddNew = () => {
    return history.push({
      pathname: "/governance/create-custom-control",
      state: { labels: allLabels },
    });
  };

  const handleSetSearchVal = (val) => {
    const newFilters = {...insightsFilters, query: val};
    sendEvent(governanceEvents.searchMainPanel, { searchedText: val, filters: newFilters });
    return dispatch(setInsightsFilters(newFilters));
  }

  return (
    <div className="basic-card InsightsHeader row between">
      <div className="row g20">
      <HeaderSearchBox
        placeholder={t('header.search')}
        value={handleSetSearchVal}
        currentValue={insightsFilters.query}
        resetSearch={handleSetSearchVal}
      />
      <InsightFilterBadges />
      </div>
      <AppBtn
        text={t('header.add-btn')}
        onClick={handleAddNew}
        icon={<FontAwesomeIcon icon="plus" />}
        disabled={isViewer}
      />
    </div>
  );
};

export default InsightsHeader;
