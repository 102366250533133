import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import {
  getUserScreenViews,
  createView,
  deleteView,
  toggleDefault,
} from "../../../redux/actions/screenViewActions";
import { appToast } from "../../../shared/appToast/appToast";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";
import ViewsList from "./viewsList";
import AppModal from "../../../shared/appModal/appModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./viewManagerModal.scss";

const ViewManagerModal = ({ visible, handleClose, isEditMode }) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();
  const [form] = Form.useForm();
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveMode, setSaveMode] = useState(false);
  const [swithcDefault, setSwithcDefault] = useState(false);

  //redux
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );
  const views = useSelector((state) => state.screenViewReducer.views);
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);

  useEffect(() => {
    if (visible) {
      form.resetFields();
      setLoadingSubmit(false);
      setSaveMode(isEditMode);
      return fetchViews();
    }
  }, [visible]);

  const fetchViews = async () => {
    setLoading(true);
    await dispatch(getUserScreenViews("inventory", user?.sub));
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    // check if there is identical view
    if (!_.isEmpty(views)) {
      const match = views?.find((view) =>
        _.isEqual(screenFilters, view?.filters)
      );
      if (match) {
        setLoadingSubmit(false);
        return appToast("info", "", `You have already created this kind of View: "${match?.name}"`);
      }
    }

    const name = form.getFieldValue("name");

    let payload = {
      screen: "inventory",
      user_id: user?.sub,
      filters: { ...screenFilters, freeSearchText: searchVal },
      name: !_.isEmpty(name) ? name : "New-view",
      isDefault: swithcDefault,
    };

    await dispatch(createView(payload));
    await dispatch(getUserScreenViews("inventory", user?.sub));
    setLoadingSubmit(false);
    setSaveMode(false);
    appToast("success", "", "Your view saved successfully");
    return;
  };

  const handleDeleteView = async (id) => {
    setLoading(true);
    await dispatch(deleteView(id));
    await dispatch(getUserScreenViews("inventory", user?.sub));
    setLoading(false);
  };

  const handleToggleIsDefault = async (id) => {
    setLoading(true);
    await dispatch(toggleDefault(id));
    await dispatch(getUserScreenViews("inventory", user?.sub));
    setLoading(false);
  };

  const handleCheckIfFilters = () => {
    // check if the user selected any filters
    if (_.values(screenFilters).every(_.isEmpty) && _.isEmpty(searchVal)) {
      setLoadingSubmit(false);
      return appToast("info", "", "You must select at least one filter");
    } else {
      return setSaveMode(true);
    }
  };

  const renderForm = () => {
    return (
      <Form name="create-view" form={form} onFinish={handleSubmit}>
        <Form.Item
          label="Name your view"
          name="name"
          style={{ marginBottom: "1.2rem", flexDirection: "column" }}
        >
          <Input />
        </Form.Item>
        <div className="ViewManagerModal__form-toggle row">
          <Checkbox checked={swithcDefault} onChange={() => {
              setSwithcDefault(!swithcDefault);
            }}>
          <span className="form-label">Set as Default view?</span>
          </Checkbox>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadingSubmit}>
            Save view
          </Button>
        </Form.Item>
      </Form>
    );
  };

  return (
    <AppModal
      title={
        saveMode ? (
          <div className="row" style={{ gap: "15px" }}>
            <FontAwesomeIcon
              icon="undo-alt"
              onClick={() => setSaveMode(false)}
              style={{ cursor: "pointer" }}
            />
            New View
          </div>
        ) : (
          `Custom views`
        )
      }
      visible={visible}
      handleClose={handleClose}
      hideFooter
      width="550px"
      bodyClassName="ViewManagerModal"
    >
      {saveMode ? (
        <div className="ViewManagerModal__save">{renderForm()}</div>
      ) : (
        <ViewsList
          handleDelete={handleDeleteView}
          handleDefault={handleToggleIsDefault}
          loading={loading}
          handleSave={handleCheckIfFilters}
        />
      )}
    </AppModal>
  );
};

export default ViewManagerModal;
