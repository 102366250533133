import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import isArray from "lodash/isArray";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";
import { cleanData as cleanFixDriftData, fixDrift, getCftDrift, remediateAi} from "../../../../redux/actions/fixDriftActions";
import { ReactComponent as PullRequestIcon } from "../../../../Images/ci/icons/ci-pull-request.svg";

import AppBtn from "../../../../shared/appBtn/appBtn";
import { getFixDriftError, SYNC_STATE_ERROR_CODES } from "./fixDriftHelper";
import Loading from "../../../../shared/loading/loading";
import TypingDots from "../../../../shared/typingDots/typingDots";
import { Collapse } from "antd";
import CodeDiff from "../../../../shared/codeDiff/codeDiff";
import { sendEvent } from "../../../../utils/amplitude";
import { inventoryEvents } from "../../../../utils/amplitudeEvents";
import { appToast } from "../../../../shared/appToast/appToast";
import { IAC_TYPES } from "../../../../consts/general";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToolFilled } from "@ant-design/icons";
import LongLoader from "../../../../shared/longLoader/longLoader";
import "./fixDrift.scss";

// const SUCCESS_IMG_URL = `${S3_ASSETS}/illustrations/success_circle_rocket.svg`;

const { Panel } = Collapse;
const FixDrift = ({ assetData = {}, pullRequestResponse = {}, setPullRequestResponse }) => {
    const { terraformObjectFullAddress = "", terraformObjectReferencesAncestry,
     vcsWorkingDirectory, drift, frn, vcsId, iacType = "", vcsCodeLink = "", integrationId = ""} = assetData || {};
    const dispatch = useDispatch();
    const {t } = useTranslation('inventory', {keyPrefix: 'fix-drift'});
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingVcs, setLoadingVcs] = useState(false);
    const [prLoading, setPrLoading] = useState(false);
    const [prActionSuccess, setPrActionSuccess] = useState(false);
    const [cftData, setCftData] = useState([]);

    const foundFixDrift = useSelector((state) => state.fixDriftReducer.findDriftResult) || [];
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    const { repo: repoName, filesInfo = {}, numUpdated = 0, error } = foundFixDrift?.[0] || {};
    
    const isDriftsFiles = !isEmpty(filesInfo);
    const isTerraform = iacType === IAC_TYPES.terraform;
    const isCFT = iacType?.toLowerCase() === IAC_TYPES.cloudformation;
    const enableSearch = isTerraform || isCFT;

    const isSyncState = isTerraform && SYNC_STATE_ERROR_CODES.includes(error);

    const alignIacDescription = isTerraform ? `${t("descriptions.tfAlignIac")} ${repoName}:` : t("descriptions.cftAlignIac");
    const alignIacDescription2 = `${t("descriptions.tfAlignIac2")}:`;
    const reconcileText = `terraform apply -target ${terraformObjectFullAddress}`;
    const syncState  = `terraform apply -refresh-only -target ${terraformObjectFullAddress}`;
    const isFilesDisplay = isDriftsFiles || !isEmpty(cftData);
    const prUrl = pullRequestResponse?.data?.url;
    const isRemediate = isTerraform || !isEmpty(cftData);

    useEffect(() => {
        runSearch();
        return () => {
            setPullRequestResponse({});
            dispatch(cleanFixDriftData())
        }
    }, []);

    useEffect(() => {
        if (prActionSuccess){ 
            setTimeout(() => {
                openPrLink();
                setPrActionSuccess(false);
            }, 500);
        }
    },[prActionSuccess])

    const runSearch = async () => {
        if (!enableSearch || isTerraform && !vcsCodeLink) return;
        setLoadingSearch(true);
        
        // const res = await dispatch(isTerraform ? searchDriftFix(terraformObjectReferencesAncestry, vcsWorkingDirectory, drift) : getCftDrift(frn));
        const res = await dispatch(isTerraform ? remediateAi(integrationId, frn, terraformObjectReferencesAncestry, vcsWorkingDirectory, drift) : getCftDrift(frn));
        if (res?.abort) return;
        if (isCFT && isArray(res?.data)) {
            setCftData(res.data);
        }
        setLoadingSearch(false);
    };
    
    const openPrLink = () => window.open(prUrl, "_blank");

    const handleCreatePR = async () => {
        if (prActionSuccess || prUrl) return openPrLink();
        setPrLoading(true);
        const result = await dispatch(fixDrift(frn, vcsId, repoName, filesInfo, ""));
        if (!result?.ok) {
            const errMsg = getFixDriftError(result?.data?.error, { numUpdated, repo: repoName, filesInfo }) || "Failed to create pull request";
            appToast("error", "", errMsg);
        }
        setPullRequestResponse(result);
        setPrLoading(false);
        if (result?.ok) {
            setPrActionSuccess(true);
        }
        sendEvent(inventoryEvents.clickedCreatePrDriftDetailsButton, { frn: assetData?.frn })
    };
    
    const handleOnChangeCollapse = (openCollapses) => {
        const isOpenCollapse = !isEmpty(openCollapses);
        sendEvent(inventoryEvents.collapsedAlignIacAsset, { type: isOpenCollapse ? "open" : "close" });
    };
    const renderCollapse = ({ filePath, id, oldValue, newValue }) =>  (
    <Collapse className="FixDrift__content-collapse" defaultActiveKey={id} onChange={handleOnChangeCollapse} expandIconPosition="right">
        <Panel header={filePath} key={id}>
            <div className="FixDrift__content-collapse-content">
                <CodeDiff oldValue={oldValue} newValue={newValue} lang="hcl" hideLineNumbers={false} showDiffOnly />
            </div>
        </Panel>
    </Collapse>)

    if (loadingSearch || loadingVcs) {
        return (
            <div className="center basic-100">
                <LongLoader customLoader={<Loading />} duration={10000} loading={loadingSearch || loadingVcs}
                msg1={<span className="row g8">Generating a drift remediation <TypingDots isInText/></span>} 
                msg2={<span className="row g8">Hold on, it is almost ready <TypingDots isInText/></span>}
                customClassName="col g10 center font-18 bold"/>
            </div>
        );
    }
    return (
        <div className="col g10">
            {isRemediate && (
                <div className="col g10">
                    <div className="FixDrift-divider"/>
                    <div className="row g8">
                        <ToolFilled style={{ fontSize: '16px', color: '#9872FE' }}/>
                        <span className="font-16 bold">{t("titles.remediateDrift")}</span>
                    </div> 
                </div>
            )}
            {isFilesDisplay && (
            <div className="FixDrift col g10 pad20">
                <div className="col g10">
                    <div className="row between">
                        <span className="FixDrift__title font-16 bold">{isFilesDisplay && isTerraform ? "Option 1: " : ""}{t("titles.alignIac")}</span>
                        {isTerraform && <AppBtn disabled={isViewer} loading={prLoading} onClick={handleCreatePR} text={prUrl ? "Pull request" : "Create pull request"} key="create-pr" 
                        icon={prActionSuccess ? <FontAwesomeIcon icon="check"/> : prUrl ? <FontAwesomeIcon icon='external-link-alt'/>
                         : <PullRequestIcon className="FixDrift-prIcon" />} />}
                    </div>
                    <span className="FixDrift__description"><span className="bold">{isTerraform ? "Step 1: " : ""}</span>{alignIacDescription}</span>
                    {isTerraform ? 
                    <>
                    {Object.entries(filesInfo).map(([filePath = "", fileContent = {}]) => {
                        const { oldFileContent = "", newFileContent = ""} = fileContent;
                        const oldValue = isString(oldFileContent) ? oldFileContent : JSON.stringify(oldFileContent, null, 2);
                        const newValue = isString(newFileContent) ? newFileContent : JSON.stringify(newFileContent, null, 2);
                        const id = uuidv4();
                        return (
                        <div key={id}>
                            {renderCollapse({ filePath, id, oldValue, newValue})}
                        </div>)
                    })}
                    <span className="FixDrift__description"><span className="bold">{"Step 2: "}</span>{alignIacDescription2}</span>
                    <DarkCopyBox text={syncState} />
                    </>
                    : !isEmpty(cftData) && (
                    <div className="FixDrift__cft col g10">
                        {renderCollapse({ filePath: cftData[0]?.stackName, id: cftData[0]?.stackId, oldValue: cftData[0]?.oldCode, newValue: cftData[0]?.newCode })}
                        <>
                            <span className="FixDrift__description">{t("descriptions.driftSolution")}</span>
                            <DarkCopyBox text={cftData[0]?.driftSolution} />
                        </>
                    </div>)}
                </div>
            </div>)}
            {isTerraform && terraformObjectFullAddress && (isSyncState &&
            <div className="FixDrift col g10 pad20">
                <div className="col g5">
                    <span className="FixDrift__title font-16 bold">{"Option 1: "}{t("titles.syncState")}</span>
                    <span className="FixDrift__description">{t("descriptions.syncState")}</span>
                </div>
                <DarkCopyBox text={syncState} />
            </div>)}
            {isTerraform && terraformObjectFullAddress && (
            <div className="FixDrift col g10 pad20">
                <div className="col g5">
                    <span className="FixDrift__title font-16 bold">{isFilesDisplay || isSyncState ? "Option 2: " : ""}{t("titles.reconcileConfiguration")}</span>
                    <span className="FixDrift__description">{t("descriptions.reconcileConfiguration")}</span>
                </div>
                <DarkCopyBox text={reconcileText} />
            </div>)}
        </div>
    );
};

export default FixDrift;