import React from 'react';
import AppModal from '../../../../shared/appModal/appModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { 
  getWebexRooms, 
  updateIntegrationsById, 
  addBotToWebexRooms 
} from '../../../../redux/actions/integrationsActions';
import { sendTestNotification } from '../../../../redux/actions/notificationsActions';
import { appToast } from '../../../../shared/appToast/appToast';
import { sendEvent } from '../../../../utils/amplitude';
import { integrationEvents } from '../../../../utils/amplitudeEvents';


const WebexRoomsPopup = ({ visible, selectedWebex = {}, setSelectedWebex = () => null, setShowWebexRoomsPopup = () => null, testNotificationLoading = {}, setTestNotificationLoading = () => null }) => {
    const { t } = useTranslation('integrations');
    const webexRooms = useSelector((state) => state.integrationsReducer.rooms);
    const dispatch = useDispatch();

    const handleSendTestNotification = async(item = {}) => {
      const notificationIntegrationId = item._id;
      const payload = { notificationIntegrationId: notificationIntegrationId }
      setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: true });
      if (item.service === "webex") {
        if (item.slackChannel) {
          payload.slackChannel = item.slackChannel;
        } else {
          await dispatch(getWebexRooms(notificationIntegrationId));
          setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false});
          setSelectedWebex(item);
          return setShowWebexRoomsPopup(true);
        }
      }
      if(item.service === "webex"){
        const response = await dispatch(addBotToWebexRooms(notificationIntegrationId, [payload.slackChannel]));
        if(!response?.ok){
          appToast("error", "Notification", "Failed adding bot to webex rooms");
          setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false});
          return
        }
      }
      const response = await dispatch(sendTestNotification(payload));
      setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false});
      
      const { status = { lastState: 'unhealthy'} } = response || {};
      dispatch(updateIntegrationsById(notificationIntegrationId, { status }));
      const lastState = status.lastState;
      const isSuccessfulTest = lastState === "healthy";
      sendEvent(integrationEvents.sendTestNotification, { integrationId: notificationIntegrationId, integrationName: item.name,  integrationType: item.service, success: isSuccessfulTest })
      if (isSuccessfulTest) {
        return appToast("success", "", t("notification-heartbeat.alerts.success"))
      }
      if (!isSuccessfulTest && status.error) {
        return appToast("error", "", `${t("notification-heartbeat.alerts.failure")} - ${status.error}`)
      }
    }

    const renderWebexRooms = () => (
        <div className="SingleIntegrationItem-slackChannels col g10">
          <div className="row g5">
            <FontAwesomeIcon className="SingleIntegrationItem-slackChannels-icon" icon="info-circle"/>
            <span className="SingleIntegrationItem-slackChannels-desc">{t("notification-heartbeat.webexDisclaimer")}</span>
          </div>
          <Select
            className="SingleIntegrationItem-slackChannels-select"
            placeholder="Select room"
            showArrow
            value={selectedWebex?.slackChannel}
            showSearch
            onSelect={(value) => setSelectedWebex({...selectedWebex, slackChannel: value})}
            filterOption={(input, option) =>
              option?.children?.props?.children[1]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }>
          {map(webexRooms || [], (room) => (
              <Select.Option value={room.id} key={room.id}>
                <span>
                  {`# `}
                  {room.title}
                </span>
              </Select.Option>))}
          </Select>
        </div>
    )
    
    return (
        <AppModal 
          visible={visible}
          title="Select a Webex room"
          handleClose={() => {
            setShowWebexRoomsPopup(false);
            setSelectedWebex({});
          }}
          handleOnSubmit={() =>{
            handleSendTestNotification(selectedWebex);
            setShowWebexRoomsPopup(false);
          }}
          submitBtnDisabled={!selectedWebex?.slackChannel}
          submitBtnText="Send Notification">
            {renderWebexRooms({})}
        </AppModal>
    );
};

export default WebexRoomsPopup;