import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_ALL_KEYPAIRS = "GET_ALL_KEYPAIRS";
export const CREATE_KEYPAIR = "CREATE_KEYPAIR";
export const DELETE_KEYPAIR = "DELETE_KEYPAIR";
export const UPDATE_KEYPAIR = "UPDATE_KEYPAIR";
export const GET_INSTRUCTIONS_CODE = "GET_INSTRUCTIONS_CODE";

const getAllKeyPairs = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/access_keys`,
      "GET"
    );
    const keyPairs = await req.json();

    dispatch({
      type: GET_ALL_KEYPAIRS,
      payload: { keyPairs: keyPairs.keys },
    });

    return keyPairs.keys;
  });
};

const createKeyPair = (userName, alias) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/access_keys`,
      "POST",
      { userName, alias }
    );
    const keyPair = await req.json();

    dispatch({
      type: CREATE_KEYPAIR,
      payload: { keyPair },
    });

    return keyPair;
  });
};

const deleteKeyPair = (accessKey) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/access_keys/${accessKey}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_KEYPAIR,
      payload: {},
    });

    return true;
  });
};

const updateKeyPair = (key, description) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/access_keys`,
      "PUT",
      {key, description}
    );

    dispatch({
      type: UPDATE_KEYPAIR,
      payload: {},
    });

    return true;
  });
};

const getInstructionsCode = (type, integData) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/instructions/${type}`,
      "POST",
      integData
    );
    const data = await req.json();

    dispatch({
      type: GET_INSTRUCTIONS_CODE,
      payload: { data },
    });

    return data;
  });
};

export { getAllKeyPairs, createKeyPair, deleteKeyPair, updateKeyPair, getInstructionsCode };
