import React, { useState, Suspense } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import { IntercomProvider } from "react-use-intercom";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { oktaOrganizations, azureOrganizations, pingOrganizations } from './consts/sso';
import { addUtmParams, getSubdomain, isDemoApplication } from './utils/helpers';
import store from "./store";
import CookiesManager from "./utils/cookiesManager";
import MainWrapper from "./mainWrapper";
import CreateAccount from "./containers/createAccount/createAccount";
import { AUTH0_NS, INTERCOM_ID } from "./consts/config";
import AuthError from "./components/authError";
import WelcomeBlockUsers from "./components/welcomeBlockUsers/welcomeBlockUsers";
import SsoLogin from "./containers/ssoLogin/ssoLogin";
import SplashScreen from './shared/splashScreen/splashScreen';
import { sendEvent } from './utils/amplitude';
import { generalEvents } from './utils/amplitudeEvents';

// globel styles
import "./utils/fontawesome"; //globalicons
import "./theme.light.less";
import "./theme.dark.less";
import "react-toastify/dist/ReactToastify.css";
import './styles/customTheme.scss';
import "./App.scss";
import "./styles/layouts.scss";
import "./styles/typography.scss";
import "./styles/general.scss";
import "./styles/animations.scss";
import "./styles/antAdjustments.scss";

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    user,
    error,
  } = useAuth0();

  const INTERCOM_APP_ID = INTERCOM_ID;
  const cookieMgr = new CookiesManager();
  const [isCookie, setIsCookie] = useState(false);

  if (!isAuthenticated) {
      if (isDemoApplication()){
        const accessToken = cookieMgr.getAccessToken();
        if(accessToken){
          logout({ returnTo: 'https://try.firefly.ai/' });
        }
      }
    //Delete Cookie in case we are not authenticated
    cookieMgr.setAccessToken(null, 0);

    (async () => {
      let token = null;
      try {
        token = await getAccessTokenSilently();
        cookieMgr.setAccessToken(token);
        setIsCookie(true);
      } catch (e) {
        if (e.error === "login_required" || e.error === "consent_required") {
          const subDomain = getSubdomain();
          return [...oktaOrganizations, ...azureOrganizations, ...pingOrganizations]?.includes(subDomain) ? <SsoLogin /> : loginWithRedirect({ demo: isDemoApplication(), ...addUtmParams()});
        } else {
          throw e;
        }
      }
    })();
  }

  if (error) {
    if(error.message === "personal_email_error") {
      return <WelcomeBlockUsers />;
    }
    else{
        return <AuthError error={error} />;
    }
  }

  // stop the inifinite loading if user not verified email or he is on the blacklist
  if (isUndefined(user) && !isLoading && !isAuthenticated) {
    const subDomain = getSubdomain();
    return [...oktaOrganizations, ...azureOrganizations, ...pingOrganizations]?.includes(subDomain) ? <SsoLogin /> : loginWithRedirect({demo: isDemoApplication(), ...addUtmParams()});
  }

  if (isLoading || isEmpty(user) || !isCookie) {
    return (
      <SplashScreen />
    );
  }

  // is there an account ID for the user in the app_metadata from
  // Auth0?
  if (isEmpty(user[`${AUTH0_NS}/account_id`])) {
    // user has no account set up, show the account creation form
    return <CreateAccount />;
  }

  // user has an account, everything is fine, continue to the app
  return (
    <Suspense fallback={<SplashScreen />}>
      <IntercomProvider appId={INTERCOM_APP_ID} onShow={
        () => sendEvent(generalEvents.intercomOpen, {action: 'Intercom button clicked'})
      }>
        <ReduxProvider store={store}>
        <MainWrapper />
          <ToastContainer
            hideProgressBar={false}
            autoClose={7000}
            position="top-right"
            draggable
          />
        </ReduxProvider>
      </IntercomProvider>
    </Suspense>
  );
};

export default App;