import { library } from "@fortawesome/fontawesome-svg-core";

// *** HOW TO USE THIS ***
// <FontAwesomeIcon icon='code' />
// icon names are in fontawesome site => https://fontawesome.com/

import {
  faLayerGroup,
  faStream,
  faRocket,
  faCog,
  faChartLine,
  faPlus,
  faMinus,
  faUserCircle,
  faExclamationCircle,
  faCodeBranch,
  faSearch,
  faGhost,
  faCode,
  faTrash,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faFolderOpen,
  faArrowRight,
  faEllipsisV,
  faTable,
  faChevronDown,
  faPowerOff,
  faOutdent,
  faIndent,
  faSave,
  faUndoAlt,
  faCloud,
  faDownload,
  faBookOpen,
  faComment,
  faLaptopCode,
  faEye,
  faEyeSlash,
  faArrowAltCircleRight,
  faSyncAlt,
  faChartArea,
  faChartBar,
  faLongArrowAltRight,
  faTag,
  faThumbtack,
  faPlayCircle,
  faPlug,
  faQuestionCircle,
  faStar,
  faDatabase,
  faSearchPlus,
  faSearchMinus,
  faAlignCenter,
  faCompressArrowsAlt,
  faRedo,
  faSitemap,
  faArrowCircleRight,
  faDollarSign,
  faLightbulb,
  faCircle,
  faDotCircle,
  faBolt,
  faFlag,
  faLongArrowAltLeft,
  faCheck,
  faPlay,
  faMagic,
  faTrashAlt,
  faTools,
  faTimes,
  faCalendarAlt,
  faCopy,
  faFileCode,
  faMoon,
  faSun,
  faChartPie,
  faHourglass,
  faBell,
  faReply,
  faHeart,
  faPen,
  faArrowLeft,
  faGlobe,
  faExternalLinkAlt,
  faCommentDots,
  faCommentAlt,
  faExclamationTriangle,
  faAngleRight,
  faAngleLeft,
  faArrowAltCircleLeft,
  faHistory,
  faQuestion,
  faHome,
  faBan,
  faEdit,
  faInfoCircle,
  faChild,
  faList,
  faProjectDiagram,
  faAsterisk,
  faFingerprint,
  faCheckDouble,
  faEllipsisH,
  faExclamation,
  faMinusCircle,
  faPlusCircle,
  faPaperPlane,
  faArrowDown,
  faArrowUp,
  faUserPlus,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";

import { faJira, faGitAlt, faAws } from "@fortawesome/free-brands-svg-icons";

import { faEyeSlash as exclusion, faQuestionCircle as question, faHeart as outlineHeart, faFile, faCalendar, faTrashAlt as outlineTrash, faClock } from "@fortawesome/free-regular-svg-icons";

library.add(
  faLayerGroup,
  faStream,
  faRocket,
  faCog,
  faChartLine,
  faPlus,
  faMinus,
  faUserCircle,
  faExclamationCircle,
  faExclamation,
  faCodeBranch,
  faSearch,
  faGhost,
  faCode,
  faTrash,
  faCheckCircle,
  faTimesCircle,
  faSpinner,
  faFolderOpen,
  faCloud,
  faArrowRight,
  faEllipsisV,
  exclusion,
  faTable,
  faChevronDown,
  faPowerOff,
  faOutdent,
  faIndent,
  faTimesCircle,
  faSave,
  faUndoAlt,
  faDownload,
  faCloud,
  faBookOpen,
  faComment,
  faLaptopCode,
  faJira,
  faEye,
  faEyeSlash,
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faSyncAlt,
  faChartArea,
  faChartBar,
  faLongArrowAltRight,
  faTag,
  faThumbtack,
  faPlayCircle,
  faPlug,
  faQuestionCircle,
  faStar,
  faDatabase,
  faSearchPlus,
  faSearchMinus,
  faAlignCenter,
  faCompressArrowsAlt,
  faRedo,
  faSitemap,
  faArrowCircleRight,
  faDollarSign,
  faLightbulb,
  faCircle,
  faDotCircle,
  faBolt,
  faFlag,
  question,
  faLongArrowAltLeft,
  faCheck,
  faPlay,
  faMagic,
  faTrashAlt,
  faTools,
  faTimes,
  faGitAlt,
  faCalendarAlt,
  faCopy,
  faFileCode,
  faMoon,
  faSun,
  faChartPie,
  faHourglass,
  faBell,
  faReply,
  faHeart,
  faPen,
  outlineHeart,
  faArrowLeft,
  faGlobe,
  faExternalLinkAlt,
  faCommentDots,
  faExclamationTriangle,
  faAngleRight,
  faAngleLeft,
  faFile,
  faAws,
  faHistory,
  faQuestion,
  faCommentAlt,
  faCalendar,
  faHome,
  outlineTrash,
  faBan,
  faEdit,
  faInfo,
  faInfoCircle,
  faClock,
  faChild,
  faList,
  faProjectDiagram,
  faAsterisk,
  faFingerprint,
  faExclamationTriangle,
  faCheckDouble,
  faEllipsisH,
  faMinusCircle,
  faPlusCircle,
  faPaperPlane,
  faArrowDown,
  faArrowUp,
  faUserPlus
);
