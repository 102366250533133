import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import PostPlanTable from '../postPlanTable';
import PostPlanGraph from '../postPlanGraph/postPlanGraph';
import ActionsSummary from '../actionsSummary/actionsSummary';
import { useDispatch } from 'react-redux';
import { getWorkflowResources } from '../../../../redux/actions/ciWorkflowsActions';
import AppEmpty from '../../../../shared/appEmpty/appEmpty';
import DiagnosticArr from '../postApply/diagnostic/diagnosticArr';
import { getProperFilters } from '../../../../utils/workflowsHelper';

const MAX_GRAPH_LENGTH = 100;
const MODES = {
    table: "table",
    graph: "graph",
}
const PostPlan = ({ planId, parsedDataSummary = {}, handleOpenConfig, workspaceData, taskId }) => {
    const { notNoOpResourcesCount: planResourcesCount = 0, hasDiagnosticResources = false, diagnostics: globalDiagnostics = [] } = parsedDataSummary;
    const defaultMode = planResourcesCount > MAX_GRAPH_LENGTH ? MODES.table : MODES.graph;
    
    const [dataByMode, setDataByMode] = useState({});
    const [loadingByMode, setLoadingByMode] = useState({});
    const [planPageNumber, setPlanPageNumber] = useState(1);
    const [planPageSize, setPlanPageSize] = useState(5);
    const [planTableSort, setPlanTableSort] = useState({ name: 1 });

    const [actionFilters, setActionFilters] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        fetchRunData(defaultMode); // usually graph
    }, []);

    useEffect(() => {
        if (dataByMode?.table) {
            fetchRunData(MODES.table);
        }
    }, [planPageSize, planPageNumber, planTableSort]);

    useEffect(() => {
        if (dataByMode?.graph && !dataByMode?.table) { // once graph is fetched, fetch table
            fetchRunData(MODES.table);
        }
    }, [dataByMode]);

    useEffect(() => {
        if (dataByMode?.graph && dataByMode?.table) {
            fetchRunData(MODES.table)
        }
    }, [actionFilters])

    const renderGlobalDiagnostics = useCallback(() => (
        <div className="col g10">
            <span className="bold">Global diagnostics</span>
            <DiagnosticArr diagnostics={globalDiagnostics} taskId={taskId}/>
        </div>
    ), [globalDiagnostics]);

    const fetchRunData = async (mode) => {
        if (planResourcesCount == 0 && !hasDiagnosticResources) return;
        setLoadingByMode({ ...loadingByMode, [mode]: true });
        const options = { mode, filters: getProperFilters(actionFilters) };
        if (mode === MODES.table) {
            const newOffset = (planPageNumber - 1) * planPageSize;
            options.offset = newOffset > planResourcesCount ? 0 : newOffset;
            options.size = planPageSize;
            options.sort = planTableSort;
        }
        const response = await dispatch(getWorkflowResources(planId, options));
        if (response) {
            setLoadingByMode({ ...loadingByMode, [mode]: false });
            return setDataByMode({ ...dataByMode, [mode]: response.resources });
        } 
        setLoadingByMode({ ...loadingByMode, [mode]: false });
    };


    const noGraphOrTableData = (!loadingByMode.graph && !loadingByMode.table && isEmpty(dataByMode?.graph?.nodes) && isEmpty(dataByMode?.table));
    if ((planResourcesCount == 0 && !hasDiagnosticResources) || noGraphOrTableData) {
        if (globalDiagnostics?.length > 0) return renderGlobalDiagnostics();
        return (
          <div className="PostPlanChart center empty">
            <AppEmpty customStyle="ciResourcesNotFound" text="The current Terraform plan does not contain any infrastructure changes" />
          </div>
        );
    }

    return (
        <div className="PostPlan col g10">
           <ActionsSummary data={parsedDataSummary} actionFilters={actionFilters} />
           {defaultMode === "graph" && <PostPlanGraph data={dataByMode?.graph} loading={loadingByMode?.graph} 
           handleOpenConfig={handleOpenConfig} workspaceData={workspaceData}
            actionFilters={actionFilters} setActionFilters={setActionFilters}/> }
           <PostPlanTable data={dataByMode?.table} loading={loadingByMode?.table || loadingByMode?.graph} 
             parsedDataSummary={parsedDataSummary} pageSize={planPageSize} setPageSize={setPlanPageSize} taskId={taskId}
             setPageNumber={setPlanPageNumber} handleOpenConfig={handleOpenConfig} hasDiagnosticResources={hasDiagnosticResources} workspaceData={workspaceData} setPlanTableSort={setPlanTableSort} /> 
           {globalDiagnostics.length ? renderGlobalDiagnostics() : null}
        </div>
    );
};
export default PostPlan;