import React, { memo } from "react";
import has from "lodash/has";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import { timeDifferenceInWords } from "../../../utils/formatting";
import { isDateLessThan24Hours } from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WarningTriangle from "../../../shared/warningTriangle/warningTriangle";
import { Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";


const IntegrationSyncBadge = ({ data = {}, category }) => {
  const { t } = useTranslation("integrations");

  const disableOutOfSync = useSelector(
    (state) => state.onbordingReducer.account?.disableFeatures?.outOfSync
  );

  const checkOutOfSync = () => {
    if (disableOutOfSync){
      return false;
    }

    if (isEmpty(data?.lastSuccessfulSyncTime)) {
      return true;
    } else {
      const diff = moment().diff(moment(data?.lastSuccessfulSyncTime));
      const durationHours = moment.duration(diff).asHours();
      if (data?.dailyFetch) {
        return durationHours > 30 ? true : false;
      }
      return durationHours > 24 ? true : false;
    }
  };

  const getStatusByDate = (date = '') => {
    const nDate = new Date(date);
    if (nDate.getFullYear() < 2000) return "" // case of unrelavent date
    return timeDifferenceInWords("", moment(date), moment()) || "1 second ago";
  }

  const renderNotificationStatus = () => {
    const statusObj = data?.status || {};
    if (isEmpty(statusObj)) return null;
    const { lastState, error = '', lastNotificationSuccessAt } = statusObj;
    const actualStatus = getStatusByDate(lastNotificationSuccessAt);
    const isUnhealthyStatus = !lastState || lastState === "unhealthy" || !actualStatus;
    const tooltipTitle = isUnhealthyStatus ? error : t("notification-heartbeat.tooltips.success");
    const icon = isUnhealthyStatus ? <WarningTriangle type="red" minimal /> : (lastNotificationSuccessAt && <FontAwesomeIcon icon="check-circle" color="rgb(113, 201, 202)" />)
    const status = isUnhealthyStatus ? "Unreachable"  : (lastNotificationSuccessAt ? actualStatus : "");
    return (
      <Tooltip title={tooltipTitle} className="SingleIntegrationItem__content-account">
            <span className="row" style={{ gap: "5px" }}>
              {icon}
              <span>{status}</span>
            </span>
        </Tooltip>)
  }

  if (category === "cloud") {
    return (
      <span className="SingleIntegrationItem__content-account">
        {!checkOutOfSync() ? (
          has(data, "initialScan") &&
          !data?.initialScan &&
          isDateLessThan24Hours(data?.createdAt) ? (
            <span className="row" style={{ gap: "5px" }}>
              <FontAwesomeIcon icon="spinner" spin />
              <span>Initial scanning in progress</span>
            </span>
          ) : (
            <span className="row" style={{ gap: "5px" }}>
              <FontAwesomeIcon icon="check-circle" color="rgb(113, 201, 202)" />
              <span>
                {timeDifferenceInWords(
                  "",
                  moment(data?.lastSuccessfulSyncTime),
                  moment()
                )}
              </span>
            </span>
          )
        ) : (
          <span className="row" style={{ gap: "5px" }}>
            <WarningTriangle type="orange" minimal />
            <span>Out of Sync</span>
          </span>
        )}
      </span>
    );
  }

  if (category === "vcs") {
    return (
      <span className="SingleIntegrationItem__content-account">
        {data?.syncStatus === "synced" || disableOutOfSync ? (
          <span className="row" style={{ gap: "5px" }}>
            <FontAwesomeIcon icon="check-circle" color="rgb(113, 201, 202)" />
            <span>
              {timeDifferenceInWords(
                "",
                moment(data?.lastFetchSuccess),
                moment()
              )}
            </span>
          </span>
        ) : data?.syncStatus === "scanning" ? (
          <span className="row" style={{ gap: "5px" }}>
            <FontAwesomeIcon icon="spinner" spin />
            <span>Scanning</span>
          </span>
        ) : (
          <span className="row" style={{ gap: "5px" }}>
            <WarningTriangle type="orange" minimal />
            <span>Out of Sync</span>
          </span>
        )}
      </span>
    );
  }
  if (category === "notification") {
     return renderNotificationStatus();
  }

  return <span />;
};

export default memo(IntegrationSyncBadge);
