import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as SendMessage } from "../../../../Images/general_icons/send_msg_gradient.svg";
import { ReactComponent as ThinkerbellIcon } from "../../../../Images/general_icons/thinkerbell/thinkerbell_logo.svg";
import { ReactComponent as ThinkerbellStars } from "../../../../Images/general_icons/thinkerbell/thinkerbell_stars.svg";

import { Input } from 'antd';
import { setInventoryFiltersByPrompt } from '../../../../redux/actions/inventoryv3Actions';
import TypingDots from '../../../../shared/typingDots/typingDots';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import './filtersChat.scss';

const FAILURE_MESSAGES = ["Coludn't find it. Another go?", "No results. Give it another shot.", "Oops, nothing there. Retry?", "Nothing found. Please try again."];
const SUCCESS_MESSAGES = ["Done! Here’s what we found for you.", "Search complete! Check out these results.", "Success! Take a look at your search results."];
const FiltersChat = ({ handleClose, visible }) => {
    const { user } = useAuth0();
    const [messages, setMessages] = useState([]);
    const [value, setValue] = useState(null);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const navigationSearchValue = useSelector(
        (state) => state.navigationReducer.searchValue
    );

    useEffect(() => {
        if (messages.length || !visible) return;
        if (visible) {
            window.addEventListener('keydown', (e) => e.key === 'Escape' && handleClose());
        }
    }, [visible]);

    useEffect(() => {
        if(navigationSearchValue) {
            handleOnNewMessage(navigationSearchValue);
        }
    }, [navigationSearchValue]);

    const isConversationDate = messages?.[0]?.createdAt;
    const handleOnNewMessage = async (searchValue) => {
        const tempValue = searchValue || value;
        if (!tempValue) return;
        const userMsg = { isUser: true, text: tempValue, createdAt: moment(), creator: user.name };
        const newMessagesArray = [...messages, userMsg, { loadMessage: true }];
        setMessages(newMessagesArray);
        setValue("");
        setLoading(true);
        const resOk = await dispatch(setInventoryFiltersByPrompt(tempValue));
        const cloneArr = [...newMessagesArray];
        cloneArr[cloneArr.length - 1] = { text: resOk ? SUCCESS_MESSAGES[Math.floor(Math.random() * SUCCESS_MESSAGES.length)] :  FAILURE_MESSAGES[Math.floor(Math.random() * FAILURE_MESSAGES.length)], isUser: false, loadMessage: false, creator: "Thinkerbell AI", createdAt: moment() };
        setLoading(false);
        setMessages(cloneArr);

        if(searchValue) {
            dispatch({
                type: "NAVIGATION_SEARCH",
                payload: { searchValue: undefined},
            });
        }
    }

    if (!visible) return null;

    return (
        <div className="FiltersChat grow-animation">
            <div className="FiltersChat__container">
                <FontAwesomeIcon icon="times" className="FiltersChat__close" onClick={handleClose} />
                <span className="purple-flag-outline FiltersChat__preview">Preview</span>
                <div className="FiltersChat__header col g5 center">
                    <ThinkerbellIcon className='FiltersChat__header-icon'/>
                    <div className='FiltersChat__title row g5'>
                        <span className='gradient-text bold'>Thinkerbell AI</span>
                        assistant
                    </div>
                </div>
                <div className='FiltersChat__messages col g20' id="aiChat">
                    {isConversationDate && <span className="FiltersChat__conversationTime font-12">{messages[0].createdAt?.format('DD MMM')}</span>}
                    {messages.map((msg = {}) => {
                        const { text, isUser, loadMessage, createdAt, creator = "" } = msg;
                        return loadMessage ? <TypingDots key={uuidv4()} />
                        : <div className={`FiltersChat__message ${!isUser ? 'thinkerbell' : ''} col g5`} key={uuidv4()}>
                            <span className='FiltersChat__message-creator row g5 bold'>
                                {creator}
                                {!isUser && <ThinkerbellStars className="FiltersChat__message-creator-thinker" />}
                            
                            </span>
                            {text}
                            <span className='FiltersChat__message-time '>{createdAt?.format('h:mm A')}</span>
                            {isUser ? <div className="FiltersChat__message-userIcon" style={{ backgroundImage: `url(${user?.picture})` }}/>
                                : <ThinkerbellIcon className='FiltersChat__message-thinkerbellIcon'/>}
                        </div>
                    })}
                </div>
                <div className='FiltersChat__input row'>
                    <Input type="text" placeholder="Ask me about your cloud assets..." value={value} onChange={(e) => setValue(e.target.value)} onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleOnNewMessage();
                            e.preventDefault();
                        }
                    }} disabled={loading} />
                    <ThinkerbellStars className="FiltersChat__input-thinkerIcon" />
                    <SendMessage className={`FiltersChat__input-sendIcon ${value ? 'pointer': ''}`} onClick={() => handleOnNewMessage()} />
                </div>
            </div>
        </div>
    );
};

export default FiltersChat;