import { useEffect, useState } from "react";

const useScrollPosition = (element, defaultHasMoreToScroll = true) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [hasMoreToScroll, setHasMoreToScroll] = useState(defaultHasMoreToScroll);

  useEffect(() => {
    if (!element) return;

    const updatePosition = () => {
      const newScrollPosition = element.scrollTop || 0;
      const hasToScroll = ((element.scrollHeight - newScrollPosition) -  element.clientHeight > 10);
      setScrollPosition(newScrollPosition);
      setHasMoreToScroll(hasToScroll);
    };

    if (!defaultHasMoreToScroll) {
      updatePosition();
    }

    element.addEventListener("scroll", updatePosition);

    return () => element && element.removeEventListener("scroll", updatePosition);
  }, [element]);

  return { hasMoreToScroll, scrollPosition };
};

export default useScrollPosition;
