import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from 'antd';

const UnavailableIconText = ( { toolTipTitle = ""} ) => {
  return (
    <Tooltip title={toolTipTitle}>
    <span className="grey-flag row g5">
      <FontAwesomeIcon icon="layer-group" className="disabledText font-12" />
      <span className="disabledText font-12 bold">N/A</span>
    </span>
    </Tooltip>
  );
};

export default UnavailableIconText;
