import { ERR_FILES_NOT_FOUND_IN_REPO, ERR_FOUND_SOME_DRIFTS_NO_CHANGE, ERR_INTERNAL_SERVER_ERROR, ERR_NO_CHANGE, ERR_UPDATED_SOME_FILES, ERR_VCS_API_ERROR, ERR_VCS_INTEGRATION_NOT_FOUND, ERR_VCS_REPO_NOT_FOUND } from "../../../../consts/errorCodes"

export const getFixDriftError = (errCode, data = {}) => {
    if (!errCode) return "";
    switch(errCode) {
        case ERR_VCS_INTEGRATION_NOT_FOUND:
            return "Didn't find VCS integration."
        case ERR_VCS_REPO_NOT_FOUND: 
            return`Failed to find the VCS repo ${data.repo || ""}.`
        case ERR_FILES_NOT_FOUND_IN_REPO:
            return `Files not found in repo ${data.repo}.`
        case ERR_VCS_API_ERROR:
            return "error in VCS API."
        case ERR_INTERNAL_SERVER_ERROR:
            return "Internal server error."
        case ERR_UPDATED_SOME_FILES:
            const filesInfoLength = data.filesInfo?.length || 0;
            return `Fixed ${data.numUpdated || 0}/${filesInfoLength} files. Couldn't find the other files in the repo.`
        default:
            return "An error occurred."
    }
}
export const SYNC_STATE_ERROR_CODES = [ERR_NO_CHANGE, ERR_FOUND_SOME_DRIFTS_NO_CHANGE];