import React, { useState, useEffect } from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { validate, nicknameRegex } from "../../../utils/validations";
import AppModal from "../../../shared/appModal/appModal";
import { useDispatch } from "react-redux";
import { editCloudFlareIntegration, getCloudflareIntegrationSecret } from "../../../redux/actions/cloudFlareIntegrationActions";

const EditCloudflareIntegration = ({ visible, handleClose, item, handleCloseRefreshEdit }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const layout = {};

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isProd, setIsProd] = useState(false);
  const [formValid, setFormValid] = useState(true);
  const [cloudflareSecret, setCloudflareSecret] = useState("");

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    setIsProd(item?.isProd);
    getCloudflareSecret();
  }, [item]);

  useEffect(() => {
    form.resetFields();
  }, [cloudflareSecret]);

  const handleCheckIfFormValid = (formValues) => {
    if (
      _.isEmpty(formValues.name) ||
      !validate(nicknameRegex.regex, formValues.name)
    ) {
      return setFormValid(false);
    }
    return setFormValid(true);
  };

  const getCloudflareSecret = async (values) => {
    setLoadingSubmit(true);
    if(!item?._id) {
      return;
    }

    const res = await dispatch(getCloudflareIntegrationSecret(item?._id))
    if (!res) {
      return;
    }
    setCloudflareSecret(res?.apiToken);
    setLoadingSubmit(false);
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    const newName = values?.name || item?.name;

    if(values?.apiToken.includes("XXXX-XXXX-XXXX")){
      values.apiToken = "";
    }

    const body ={
      name: newName,
      apiToken: values?.apiToken
    }

    const req = await dispatch(editCloudFlareIntegration(item?._id, body))
    setLoadingSubmit(false);
    if (!req) {
      return;
    }
    handleCloseRefreshEdit({ name: newName, isProd });
  };

  const handleCloseModal = () => {
    form.resetFields();
    setLoadingSubmit(false);
    setFormValid(true);
    handleClose();
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleCloseModal}
      title={`Edit ${_.capitalize(item?.type || "")} integration`}
      loadingSubmit={loadingSubmit}
      formName="edit-integration-form"
      submitBtnText="Save"
      submitBtnDisabled={!formValid}
    >
      <Form
        {...layout}
        name="edit-integration-form"
        form={form}
        className="EditIntegration"
        onFinish={handleSubmit}
        initialValues={{
          name: item?.name || "Choose a name",
          apiToken: cloudflareSecret,
        }}
        onValuesChange={(changedValues, allValues) => {
          handleCheckIfFormValid(allValues);
        }}
      >
        <Form.Item
            label="Name"
            name="name"
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Name is required",
              },
              {
                pattern: nicknameRegex.regex,
                message: nicknameRegex.msg,
              },
            ]}
          >
          <Input />
        </Form.Item>
        <Form.Item
            requiredMark={false}
            label="API Token"
            name="apiToken"
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
            hasFeedback
          >
          <Input.Password placeholder="Enter your api token" />
        </Form.Item>
      </Form>
    </AppModal>
  );
};

export default EditCloudflareIntegration;
