import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import AntdTableWrapper from "../../../shared/antdTableWrapper/antdTableWrapper";
import { appToast } from "../../../shared/appToast/appToast";
import { queryRepositoriesData, setModulesFilters, triggerVcsScan } from "../../../redux/actions/iacStacksActions";
import { ReactComponent as IacStack } from "../../../Images/general_icons/iac_stack.svg";
import { ReactComponent as ModulesIcon } from "../../../Images/general_icons/Modules_icon.svg";
import { emptyIacScreenFilters, emptyModulesScreenFilters } from "../../../consts/iacStacks";
import Loading from "../../../shared/loading/loading";
import { getAllVcs } from "../../../redux/actions/globalAppActions";
import {ReactComponent  as EvaluateIcon} from "../../../Images/general_icons/scan_icon.svg"
import {ReactComponent  as AlertIcon} from "../../../Images/general_icons/Warning icon.svg"
import "./repositoryTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateTimeFormat } from "../../../utils/formatting";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";
import { useTranslation } from "react-i18next";


const RepositoryTable = ({
  setSelectedCategory,
  searchVal
}) => {
  const [loadingData, setLoadingData] = useState(false);
  const [repoScanLoading, setRepoScanLoading] = useState(null);
  const [tableCurrentTotal, setTableCurrentTotal] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('users');

  const repositories = useSelector((state) => state.iacStacksReducer.repositories);
  const vcsIntegrations = useSelector((state) => state.globalAppReducer.vcs);
  const accountId = useSelector((state) => state.onbordingReducer.account?._id);

  useEffect(() => {
    queryRepositories();
  }, [searchVal]);

  const queryRepositories = async () => {
    setLoadingData(true);
    await Promise.all([
      dispatch(getAllVcs()),
      dispatch(queryRepositoriesData(searchVal, {
        ...emptyIacScreenFilters
      }))
    ]);
    setLoadingData(false);
  };

  const handleScanClick = async (repo) => {
    setRepoScanLoading(repo.repoFullName);
    const data = {
      accountId: accountId,
      vcsId: repo?.topHit?.vcsId,
      vcsType: repo?.topHit?.vcsType,
      shouldForce: true,
      ignoreBan: true,
      filters: [repo.repoFullName]
    }
    let triggered = await dispatch(triggerVcsScan(data));
    setRepoScanLoading(null);
    if(triggered)
      return appToast("info", "", "Scanning in progress");

  };

  const handleResourcesClick = (e, vcsRepo, vcsId) => {
    e.stopPropagation();
    dispatch(setFilters({
      ...emptyInventoryScreenFilters,
      vcsId,
      vcsRepo,
      masterType: []
    }))
    history.push({
      pathname: "/inventory",
      search: `?vcs=${vcsRepo}${vcsId}`,
    });
  }
  const handleModuleClick = (e, repoFullName, vcsId) => {
    e.stopPropagation();
    dispatch(setModulesFilters({
      ...emptyModulesScreenFilters,
      repoFullName: [repoFullName],
      vcsId: [vcsId],
    }))
    setSelectedCategory("modules");
  }
  const columns = [
    {
      title: "VCS Integration",
      dataIndex: "integrationName",
      key: "integrationName",
      ellipsis: true,
      width:170,
      sorter: (a, b) => a?.topHit?.vcsType?.localeCompare(b?.topHit.vcsType),
      render: (text, record) => {
        return (
          <div
            className="RepositoriesTable__item-row row"
          >
            <ProviderIcon
              provider={record.topHit.vcsType}
              customStyle="backends"
            />
            <span>{vcsIntegrations[record.topHit?.vcsType]?.find(integ => integ.id === record.topHit?.vcsId)?.name ?? '-'}</span>
          </div>
        );
      },
    },
    {
      title: "Repository Name",
      dataIndex: "repoFullName",
      key: "repoFullName",
      width:300,
      ellipsis: true,
      sorter: (a, b) => a?.repoFullName?.localeCompare(b?.repoFullName),
      render: (text, record) => (
        <div
          className="RepositoriesTable__item-row row"
        >
          <span>{!isEmpty(text) ? text : "-"}</span> {record.vcsFilePathCount == 0 && <AlertIcon />}
        </div>
      ),
    },
    {
      title: "Modules Discovered",
      dataIndex: "modulesCount",
      key: "modulesCount",
      ellipsis: true,
      width: 140,
      sorter: (a, b) => (a?.topHit?.modulesCount ?? 0) - (b?.topHit?.modulesCount ?? 0),
      render: (text, record) => {
        const { modulesCount = 0, vcsId = "" } = record.topHit || {};
        const repoFullName = record.repoFullName || "";
        return (
          <div>
             {modulesCount ? <div className="RepositoriesTable__item-view-stacks-btn-flag transparent row" onClick={(e) => handleModuleClick(e, repoFullName, vcsId)}>
                <ModulesIcon />
                {modulesCount}
              </div> : null}
          </div>
        );
      },
    },
    {
      title: "Resources Managed",
      dataIndex: "vcsFilePathCount",
      key: "vcsFilePathCount",
      ellipsis: true,
      sorter: (a, b) => (a?.topHit?.resourcesCount ?? 0) - (b?.topHit?.resourcesCount ?? 0),
      width: 130,
      render: (text, record) => {
        const { resourcesCount = 0, vcsId = "" } = record.topHit || {};
        const vcsRepo = record.repoFullName || "";
        return (
          <div>
             {resourcesCount ? <div className="RepositoriesTable__item-view-stacks-btn-flag row" onClick={(e) => handleResourcesClick(e, vcsRepo, vcsId)}>
                <IacStack />
                {resourcesCount}
              </div> : null}
          </div>
        );
      },
    },
    {
      title: "Last Scan Date",
      dataIndex: "maxTimestamp",
      key: "maxTimestamp",
      ellipsis: true,
      width: 150,
      sorter: (a, b) => {
        if (
          moment(new Date(a?.maxTimestamp)).isBefore(moment(new Date(b?.maxTimestamp)))
        ) {
          return -1;
        }
        return 1;
      },
      render: (text, record) => (
        <div className="RepositoriesTable__item-row">
         {moment(record.maxTimestamp).format(dateTimeFormat)}
        </div>
      ),
    },
    {
      title: "Scan Now",
      dataIndex: "lastFullMap",
      key: "lastFullMap",
      ellipsis: true,
      width: 80,
      render: (text, record) => (
       <div onClick={()=> handleScanClick(record)} className="evaluate-btn">
         {repoScanLoading == record.repoFullName ?  <FontAwesomeIcon
            icon="spinner"
            spin
            className="RepositoriesTable__item-icon actions spin"
          /> :<EvaluateIcon /> } 
       </div>
      ),
    },
  ];
  const onTableChange = (pagination, filters, sorter, extra) => {
    setTableCurrentTotal(extra.currentDataSource.length);
  };
  return (
    <div className="RepositoriesTable__container">
      {loadingData ? (
        <div className="RepositoriesTable__loaderContainer full-page center">
          <Loading />
        </div>
      ) : (
        <AntdTableWrapper
          columns={columns}
          dataSource={repositories}
          rowKey="_id"
          onChange={onTableChange}
          pagination={{
            defaultPageSize: 15,
            defaultCurrent: 1,
            showSizeChanger: false,
            total: repositories?.length ? tableCurrentTotal : 0,
            showTotal: (total, range) => t('user-management.table.footer.items-count', { rangeFrom: range[0], rangeTo: range[1], itemsCount: total })
          }}
          scroll={{ y: 'calc(100vh - 155px - 134px - 51px - 25px - 39px)'}}
          fixed="left"
        />
      )}
    </div>
  );
};

export default RepositoryTable;
