export const insightsFilterTemplate = {
    query: "",
    labels: [],
    frameworks: [],
    category: '',
    isDefault: null,
    onlySubscribed: false,
    onlyProduction: false,
    onlyMatchingAssets: false,
    onlyEnabled: false,
    onlyAvailableProviders: false,
    showExclusion: false,
    type: [],
    providers: [],
    integrations: [],
    severity: [],
    id: null
}

export const insightSeverityTypes = [
    { title: 'TRACE', color: '#B9A5FF' },
    { title: 'INFO', color: '#9872FE' },
    { title: 'LOW', color: '#3FD3D8' },
    { title: 'MEDIUM', color: '#498BC8' },
    { title: 'HIGH', color: '#4E64C0' },
    { title: 'CRITICAL', color: '#562AB2' }
  ]

  export const COMPLIANCE_LINE_CHART = {
     tooltipTitle: "Violating / Total"
  }