import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";
import { getOnPremiseIp } from "../../../redux/actions/integrationsActions";

const ConsulCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();
  const publicIps = useSelector((state) => state.integrationsReducer.publicIps);

  const [loadingIps, setLoadingIps] = useState(false);

  useEffect(() => {
    handleFetchOnpremIPS();
  }, []);

  const handleFetchOnpremIPS = async () => {
    setLoadingIps(true);
    await dispatch(getOnPremiseIp());
    setLoadingIps(false);
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, publicClusterAddress, token } = formValues;

    if (
      (_.isEmpty(name) || _.isEmpty(publicClusterAddress), _.isEmpty(token))
    ) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const renderIps = () => {
    if (_.isEmpty(publicIps)) {
      return null;
    }
    return (
      <div className="col">
        <span>Please allow external access to the following IPs:</span>
        <div className="row" style={{ gap: "5px" }}>
          {_.map(publicIps, (item) => (
            <span className="code-flag" key={item}>
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="ConsulCreation col">
      <div className="ConsulCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue ConsulCreation__intro-orderedlist">
          <li key="1">
            Go{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.consul.io/docs/security/acl/acl-tokens"
              style={{ textDecoration: "underline" }}
            >
              here
            </a>
            , to create a Consul access token for your account. Copy the token.
          </li>
          <li key="2">
            In Firefly, enter a <span className="bold">Nickname</span>,{" "}
            <span className="bold">Public cluster address</span>, and paste the{" "}
            <span className="bold">Consul access token</span>.
          </li>
          <li key="3">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="tfc-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Consul Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Please fill integration name" />
        </Form.Item>
        <Form.Item
          label="Public cluster address"
          name="publicClusterAddress"
          rules={[
            {
              required: true,
              message: "public cluster address is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          help={
            <span
              className="small-dark-blue"
              style={{
                opacity: 0.7,
                fontSize: "12px",
                transform: "translateY(10px)",
              }}
            >
              {loadingIps ? "loading.." : renderIps()}
            </span>
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Consul access token"
          name="token"
          rules={[
            {
              required: true,
              message: "consul access token is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ConsulCreation;
