import React, { useEffect, useMemo } from "react";
import isEmpty from "lodash/isEmpty";
import { useTranslation } from "react-i18next";
import ProviderIcon from "../../../../shared/providerIcon/providerIcon";
import { useSelector } from "react-redux";
import { Checkbox, Collapse, Form, Input, Select, Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditableTagGroup from "../../../../shared/editableTagGroup/editableTagGroup";
import { nameRegex } from "../../../../utils/validations";

import './workflowsSteps.scss';

const validateNameRules = [
    {
      required: true,
      pattern: nameRegex.regex,
      message: nameRegex.msg,
    },
]

const { Panel } = Collapse;

const AdvancedWorkflowConfiguration = ({ wizardState = {}, setWizardState, setSubmitDisabled, isEditMode }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });
    const ciLabels = useSelector((state) => state.ciWorkflowsReducer.ciAggs?.labels) || [];
    const allLabels = useMemo(() => ciLabels.map((label) => label?.name || ""), [ciLabels]);

    useEffect(() => {
        setSubmitDisabled(false);
    }, []);

    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const onElementChange = (newVal = {}, isProvidersCredentials, isRunnerEnvironment) => {
        const { awsProfiles = [], providersCredentials = [], runnerEnvironment = [] } = wizardState;
        const arrToMap = isProvidersCredentials ? providersCredentials : isRunnerEnvironment ? runnerEnvironment : awsProfiles;
        const updatedArray = arrToMap.map((elem) => { return elem.key === newVal.key ? newVal : elem });
        const updatedKey = isProvidersCredentials ? 'providersCredentials' : isRunnerEnvironment ? 'runnerEnvironment' : 'awsProfiles';
        onValuesChange({ [updatedKey]: updatedArray });
    }

    const handleDeleteElement = (key, isProvidersCredentials, isRunnerEnvironment) => {
        const { awsProfiles = [], providersCredentials = [], runnerEnvironment = [] } = wizardState;
        const arrayToFilter = isProvidersCredentials ? providersCredentials : isRunnerEnvironment ? runnerEnvironment : awsProfiles;
        const updatedArray = arrayToFilter.filter((elem) => elem.key !== key);
        const updatedKey = isProvidersCredentials ? 'providersCredentials' : isRunnerEnvironment ? 'runnerEnvironment' : 'awsProfiles';
        onValuesChange({ [updatedKey]: updatedArray });
    }

    const onAddNewElements = (isMultiple, isProvidersCredentials, isRunnerEnvironment) => {
        const newElements = [];
        newElements.push(isProvidersCredentials || isRunnerEnvironment ? { name: '', value: '', key: uuidv4() } : { profileName: '', key: uuidv4() });
        if (isMultiple) {
            newElements.push({ name: '', value: '',  key: uuidv4()}, { name: '', value: '',  key: uuidv4()});
        }
        const { awsProfiles = [], providersCredentials = [], runnerEnvironment = [] } = wizardState;
        const arrayKey = isProvidersCredentials ? 'providersCredentials' : isRunnerEnvironment ? 'runnerEnvironment' :'awsProfiles';
        const oldArrayValue = isProvidersCredentials ? providersCredentials : isRunnerEnvironment ? runnerEnvironment : awsProfiles;
        onValuesChange({ [arrayKey]: [...oldArrayValue, ...newElements] });
    };
    
    const handleChangeRules = (newRulesArray = []) => onValuesChange({ selfHostedRunnerLabels: newRulesArray });

    const awsProfileValidation = (awsProfile = {}) => {
        const { profileName, secretAccessKey, accessKeyId } = awsProfile;
        const isValid = profileName ? secretAccessKey && accessKeyId : true;
        return setSubmitDisabled(!isValid);
    }
    const renderProviderType = (item) => {
        if (isEmpty(item)) return <div style={{ color: "rgb(97 97 104)" }}>Enter Provider Type</div>
        return <div className="row g8">
                    <ProviderIcon provider={item.provider} customStyle="inventory" />
                    {item.name}
        </div>
    };
    return (
        <div className="WorkflowsSteps col">
           <span className="bold wb-text">{t("advanced.title")}</span>
           <span className="form-label">{t("descriptions.advanced")}</span>
           <div className="col g10">
           <Form
                name="advance-config-form"
                form={form}
                initialValues={{
                    vcsEnvironment: wizardState.vcsEnvironment || ""
                }}
          >
             {!isEditMode ? <Form.Item
                name="fireflyLabels"
                label="Firefly workflow labels (optional)"
                tooltip={t("tooltips.fireflyLabels")}
                style={{ flexDirection: "column" }}
                >
                  <Select
                        disabled={isEditMode}
                        placeholder="Enter workflow labels"
                        showSearch
                        filterOption={(input, option) => option.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
                        mode="tags"
                        style={{ width: '50%' }}
                        onChange={(newLabels) => onValuesChange({labels: newLabels})}
                        defaultValue={wizardState.labels || []}>
                            {allLabels.map((label) => <Select.Option value={label} key={uuidv4()}>{label}</Select.Option>)}
                    </Select>
                </Form.Item> : null}
               <Form.Item
                name="selfHostedRunnerLabels"
                label="Self hosted runner labels (optional)"
                tooltip={t("tooltips.selfHostedRunnerLabels")}
                style={{ flexDirection: "column" }}
              >
               <EditableTagGroup onChange={handleChangeRules} defaultValues={wizardState.selfHostedRunnerLabels || []} rowDisplay/>
              </Form.Item>
                <Form.Item
                    name="vcsEnvironment"
                    label="VCS Deployment Environment (optional)"
                    tooltip={t("tooltips.vcsEnvironment")}
                    style={{ flexDirection: "column" }}
                >
                    <Input placeholder="Enter the VCS deployment environment name" value={wizardState.vcsEnvironment} onChange={(e) => onValuesChange({ vcsEnvironment: e.target.value })}/>
                </Form.Item>
       
                <Form.Item
                    label="AWS Profile (optional)"
                    name="awsProfiles"
                    tooltip={t("tooltips.awsProfile")}
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="col g10">
                        {(wizardState.awsProfiles || []).map((awsProfile = {}) => {
                            const awsProfileDom = `profileName - ${awsProfile.key}`;
                            const secretKeyDom = `secretAccessKey - ${awsProfile.key}`;
                            const accessKeyIdDom = `accessKeyId - ${awsProfile.key}`;
                            const regionDom = `region - ${awsProfile.key}`;
                            return (
                                <div className="row g10" key={awsProfile.key}>
                                    <Collapse
                                        className="WorkflowsSteps__collapse"
                                        expandIconPosition="right"
                                        defaultActiveKey="1">
                                        <Panel header={`Profile - ${awsProfile.profileName || ""}`} key="1">
                                            <div className="WorkflowsSteps__collapse-content">
                                                <div className="col g5" key={awsProfileDom}>
                                                    <div className="row g5">
                                                        <span>Profile name</span>
                                                        <Tooltip title={t("tooltips.awsProfileName")}>
                                                            <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                                                        </Tooltip>
                                                    </div>
                                                    <Form.Item name={awsProfileDom} key={awsProfileDom} initialValue={awsProfile.profileName} style={{marginBottom: '0px'}}>
                                                        <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter profile name" value={awsProfile.profileName} onChange={(e) => {
                                                            const newAwsProfile = { ...awsProfile, profileName: e.target.value }; 
                                                            awsProfileValidation(newAwsProfile)
                                                            onElementChange(newAwsProfile)
                                                        }}/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col g5" key={accessKeyIdDom}>
                                                    <div className="row g5">
                                                        <span>Access key (VCS)</span>
                                                        <Tooltip title={t("tooltips.awsProfileAccessId")}>
                                                            <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                                                        </Tooltip>
                                                    </div>
                                                    <Form.Item name={accessKeyIdDom} key={accessKeyIdDom} initialValue={awsProfile.accessKeyId} {...(awsProfile.profileName && !awsProfile.accessKeyId) && {help: "Must to enter access key", validateStatus: "error"}} style={{marginBottom: '0px'}}>
                                                        <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter access key (VCS)" value={awsProfile.accessKeyId} onChange={(e) => {
                                                            const newAwsProfile = { ...awsProfile, accessKeyId: e.target.value };
                                                            awsProfileValidation(newAwsProfile)
                                                            onElementChange(newAwsProfile)
                                                        }}/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col g5" key={secretKeyDom}>
                                                    <div className="row g5">
                                                        <span>Secret key (VCS)</span>
                                                        <Tooltip title={t("tooltips.awsProfileAccessKey")}>
                                                            <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                                                        </Tooltip>
                                                    </div>
                                                    <Form.Item name={secretKeyDom} key={secretKeyDom} initialValue={awsProfile.secretAccessKey} {...(awsProfile.profileName && !awsProfile.secretAccessKey) && {help: "Must to enter secret key", validateStatus: "error"}} style={{marginBottom: '0px'}}>
                                                        <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter secret key (VCS)" value={awsProfile.secretAccessKey} onChange={(e) => {
                                                            const newAwsProfile = { ...awsProfile, secretAccessKey: e.target.value };
                                                            awsProfileValidation(newAwsProfile)
                                                            onElementChange(newAwsProfile)
                                                        }}/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col g5" key={regionDom} >
                                                    <div className="row g5">
                                                        <span>Default Region</span>
                                                        <Tooltip title={t("tooltips.defaultRegion")}>
                                                            <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                                                        </Tooltip>
                                                    </div>
                                                    <Form.Item name={regionDom} key={regionDom} initialValue={awsProfile.region} style={{marginBottom: '0px'}}>
                                                        <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter region" value={awsProfile.region} onChange={(e) => onElementChange({ ...awsProfile, region: e.target.value })}/>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                        </Panel>
                                     </Collapse>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn profile" onClick={() => handleDeleteElement(awsProfile.key)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewElements()}>Add new profile</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label="Providers Credentials (optional)"
                    name="providersCredentials"
                    tooltip={t("tooltips.providerCredentials")}
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row WorkflowsSteps__tfConfiguration-vars">
                        <span className="WorkflowsSteps__tfConfiguration-vars-title row g5">
                            Name
                            <Tooltip title={t("tooltips.providerCredentialsName")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                        <span className="WorkflowsSteps__tfConfiguration-vars-title val row g5">
                            Secret name (in VCS)
                            <Tooltip title={t("tooltips.providerCredentialsSecret")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="col g10">
                        {(wizardState.providersCredentials || []).map((providerCredential = {}) => {
                            return (
                                <div className="row g10 WorkflowsSteps-inputsRow" key={providerCredential.key}>
                                    <Form.Item name={providerCredential.key} rules={validateNameRules} initialValue={providerCredential.name} className="WorkflowsSteps__tfConfiguration-vars-input no-margin" key={providerCredential.key}>
                                        <Input placeholder="Enter Secret name" value={providerCredential.name} onChange={(e) => {
                                            onElementChange({ ...providerCredential, name: e.target.value  }, true)
                                        }}/>
                                    </Form.Item>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Secret value (VCS)" value={providerCredential.value} onChange={(e) => onElementChange({ ...providerCredential, value: e.target.value }, true)}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteElement(providerCredential.key, true)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewElements(false, true)}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewElements(true, true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label="Runner Environment Variables (optional)"
                    name="runnerEnvironment"
                    tooltip={t("tooltips.runnerEnvironment")}
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row WorkflowsSteps__tfConfiguration-vars">
                        <span className="WorkflowsSteps__tfConfiguration-vars-title row g5">
                            Name
                            <Tooltip title={t("tooltips.runnerEnvironmentName")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                        <span className="WorkflowsSteps__tfConfiguration-vars-title val row g5">
                            Value
                            <Tooltip title={t("tooltips.runnerEnvironmentValue")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                        <span className="row g5">
                            Var?
                            <Tooltip title={t("tooltips.runnerEnvironmentVar")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="col g10">
                        {(wizardState.runnerEnvironment || []).map((runnerEnvironment = {}) => {
                            return (
                                <div className="row g10 WorkflowsSteps-inputsRow" key={runnerEnvironment.key}>
                                    <Form.Item name={runnerEnvironment.key} rules={validateNameRules} initialValue={runnerEnvironment.name} className="WorkflowsSteps__tfConfiguration-vars-input no-margin" key={runnerEnvironment.key}>
                                        <Input placeholder="Enter the environment variable name" value={runnerEnvironment.name} onChange={(e) => {
                                            onElementChange({ ...runnerEnvironment, name: e.target.value  }, false, true);
                                        }}/>
                                    </Form.Item>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder=" Enter the environment variable value" value={runnerEnvironment.value} onChange={(e) => onElementChange({ ...runnerEnvironment, value: e.target.value }, false, true)}/>
                                    <Checkbox className="WorkflowsSteps-checkbox" checked={runnerEnvironment.isActionVar} onChange={(e) => onElementChange({ ...runnerEnvironment, isActionVar: e.target.checked }, false, true)}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteElement(runnerEnvironment.key, false, true)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewElements(false, false, true)}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewElements(true, false, true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label="Web identity token config (optional)"
                    name="webIdentityTokenConfig1"
                    data-private
                    tooltip={t("tooltips.webIdentityTokenConfig")}
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row WorkflowsSteps__tfConfiguration-vars">
                        <span className="WorkflowsSteps__tfConfiguration-vars-title row g5">
                            Provider type
                            <Tooltip title={t("tooltips.providerType")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                        <span className="WorkflowsSteps__tfConfiguration-vars-title val row g5">
                           Token file path
                            <Tooltip title={t("tooltips.tokenFilePath")}>
                                <QuestionCircleOutlined className="WorkflowsSteps-questionIcon"/>
                            </Tooltip>
                        </span>
                    </div>
                    <div className="col g10">
                            <div className="row g10 WorkflowsSteps-inputsRow">
                                    <Form.Item className="WorkflowsSteps__tfConfiguration-vars-input no-margin" name="webIdentityTokenConfig">
                                    <Select
                                        placeholder="Select Provider Type"
                                        showSearch
                                        filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        labelInValue
                                        defaultValue={{ value: wizardState?.webIdentityTokenConfig?.providerType, label: renderProviderType(wizardState?.webIdentityTokenConfig?.providerType ? { provider: "aws", name: "AWS" } : {})}}
                                        value={{ value: wizardState?.webIdentityTokenConfig?.providerType, label: renderProviderType(wizardState?.webIdentityTokenConfig?.providerType ? { provider: "aws", name: "AWS" } : {})}}
                                        style={{ width: "100%" }}
                                        onChange={(val) => onValuesChange({ webIdentityTokenConfig : { ...(wizardState?.webIdentityTokenConfig || {}), providerType: "aws" }})}
                                    >
                                        <Select.Option value={["aws", "AWS"]}>
                                            {renderProviderType({ provider: "aws", name: "AWS"})}
                                        </Select.Option>
                                    </Select>
                                    </Form.Item>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" data-private placeholder="Enter Token file path" value={wizardState?.webIdentityTokenConfig?.webIdentityTokenFilePath} 
                                    onChange={(e) => onValuesChange({ webIdentityTokenConfig : { ...(wizardState?.webIdentityTokenConfig || {}), webIdentityTokenFilePath: e.target.value }})}/>
                        </div>    
                    </div>
                  </div>
                </Form.Item>
         </Form>
        </div>
       </div>
    );
};

export default AdvancedWorkflowConfiguration;