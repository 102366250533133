import { isEmpty } from "lodash";
import { formatUTCDateTime } from "../../../../utils/formatting";
import CFNIcon from '../../../../Images/general_icons/cfn.png'
import CDKIcon from '../../../../Images/general_icons/cdk.png'

const CTF_STATUSES_CATEGORIES = {
    COMPLETE: "success",
    PROGRESS: "progress",
    FAILED: "failure",
  };

const getCftSuccessOrFail = (status) => {
    const statusWords = status?.split("_");
    if (statusWords[0] === "ROLLBACK") {
      return "failure";
    }
    let statusSuffix = statusWords[statusWords?.length - 1];
    return CTF_STATUSES_CATEGORIES[statusSuffix];
};

export const cloudFormationConfig = (mutualColumns, commonFinalColumns) => [
      mutualColumns?.name?.cloudformation,
      {
        headerName: "Status",
        field: "remoteType",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          return params?.row?.metadata?.stateStatus ? (
            <ul
              className={`IacStacksTable__statusFlag ${getCftSuccessOrFail(
                params?.row?.metadata?.stateStatus
              )}`}
            >
              <li className="bold">{params?.row?.metadata?.stateStatus}</li>
            </ul>
          ) : (
            ""
          );
        },
      },
      mutualColumns?.status,
      {
        headerName: "Description",
        field: "metadata",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          return params?.row?.metadata?.description
            ? params?.row?.metadata?.description
            : "";
        },
      },
      {
        headerName: "Region",
        field: "crawlerId",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          return params?.row?.stateRemoteLocation.cloudFormationLocation.region
            ? params?.row?.stateRemoteLocation.cloudFormationLocation.region
            : "";
        },
      },
      {
        headerName: "Language",
        field: "runtime",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
         if(params?.row?.metadata?.cloudFormationMetadata?.isCdk){
           const cdkMetadata = params?.row?.metadata?.cloudFormationMetadata?.cdkMetadata;
           return <span className="IacStacksTable__runtimeType">
             {cdkMetadata?.language && `${cdkMetadata?.language} (${cdkMetadata?.languageVersion})`}
           </span> 
         }
          return <span />
        },
      },
      {
        headerName: "Created time",
        field: "creationTime",
        flex: 1,
        filterable: false,
        renderCell: (params) => {
          const createdTime = params.value;
          const createdTimeDate = new Date(createdTime);
          if (
            !isEmpty(createdTime) &&
            createdTimeDate?.getFullYear() !== 1
          ) {
            return formatUTCDateTime(createdTimeDate);
          }
          return "-";
        },
      },
      mutualColumns?.assets,
      mutualColumns?.stackData,
];