import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Empty, Spin } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";

import { testExclusionRule } from "../../../../redux/actions/exclusionsActions";

import "./assetsList.scss";
import { useTranslation } from "react-i18next";

const AssetsList = ({ selectedPolicy, visible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('excluded-assets')
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    setAssets([]);
    setIsFetched(false);
    setLoading(false);
  }, [visible]);

  const fetchAssets = async () => {
    setLoading(true);
    const { name, description, rego, type, unknowns } = selectedPolicy;
    const code = rego;

    try {
      const assetsArray = await dispatch(
        testExclusionRule(name, description, code, type, [], unknowns)
      );
      setAssets(assetsArray.result);
      setIsFetched(true);
      setLoading(false);
    } catch (error) {
      return setLoading(false);
    }
  };

  const renderAssets = () => {
    if (!_.isEmpty(assets)) {
      return _.map(assets, (asset) => (
        <div className="AssetsList__assets-item" key={asset.id}>
          {asset.arn}
        </div>
      ));
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <div className="AssetsList">
      <span className="title AssetsList__title">
        {t('assets-list.title')}
        {!_.isEmpty(assets) && !loading && (
          <span className="AssetsList__title-sum">{`(${assets.length})`}</span>
        )}
      </span>

      {!isFetched && !loading && (
        <div className="AssetsList__execute" onClick={() => fetchAssets()}>
          <span className="AssetsList__execute-btn">
            {t('assets-list.execute-btn')}{" "}
            <FontAwesomeIcon
              icon={faPlay}
              className="AssetsList__execute-btn-icon"
            />
          </span>
        </div>
      )}

      {loading && (
        <div className="AssetsList__loading">
          <Spin />
        </div>
      )}

      {isFetched && !loading && (
        <div className="AssetsList__assets">{renderAssets()}</div>
      )}
    </div>
  );
};

export default AssetsList;
